import { Box, Dialog, Paper, PaperProps, Typography } from '@mui/material'
import { CloseModalIcon } from 'images'
import React, { FC, memo, ReactNode, useCallback } from 'react'
import { SDialogHeader, SDialogContent } from '../../../taxonomy/ui/ActionDialog/styled'
import { Button } from '../..'
import Draggable from 'react-draggable'

interface WrapperProps {
    title: string
    buttonText: string
    disabled?: boolean
    submitAction: () => void
    children?: ReactNode
    onClose?: () => void
    isOpen: boolean
}

const ActionDialogWrapper: FC<WrapperProps> = memo(
    ({ title, buttonText, disabled, submitAction, onClose, isOpen, children }) => {
        const nodeRef = React.useRef(null)

        const PaperComponent = useCallback(
            (props: PaperProps) => {
                return (
                    <Draggable
                        handle="#draggable-dialog-title"
                        cancel={'[class*="MuiDialogContent-root"]'}
                        nodeRef={nodeRef}
                    >
                        <Paper {...props} ref={nodeRef} />
                    </Draggable>
                )
            },
            [nodeRef]
        )

        return (
            <Dialog
                ref={nodeRef}
                open={isOpen}
                onClose={onClose}
                hideBackdrop
                fullWidth={true}
                maxWidth={'xs'}
                aria-labelledby="draggable-dialog-title"
                PaperComponent={PaperComponent}
            >
                <SDialogHeader id="draggable-dialog-title">
                    <Typography variant="h4">{title}</Typography>
                    <Box component="div" sx={{ cursor: 'pointer' }}>
                        <CloseModalIcon onClick={onClose} />
                    </Box>
                </SDialogHeader>
                <SDialogContent>
                    {children}
                    <Box component="div">
                        <Button variant="contained" onClick={submitAction} disabled={disabled}>
                            {buttonText}
                        </Button>
                    </Box>
                </SDialogContent>
            </Dialog>
        )
    }
)

export default ActionDialogWrapper
