import { isEqual } from 'lodash'
import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { roleDataSelector } from 'store/selectors/admin-selectors'
import { AdminState } from 'store/slices/admin'
import { RootState } from 'store/store'
import { Roles } from 'utils/enums/different'
import { Role, RoleActions, RoleTableData } from 'utils/types/admin'
import { ControlsWrapper, DescriptionContainer, FooterContainer, HeavySpan } from './styled'
import { Button } from '../../core'

interface Props {
    rolesArray: Role[]
    resetPermissions: () => void
    updatePermissions: (body: RoleActions) => void
    currentPermissions: RoleTableData
}

export const RoleFooter: FC<Props> = ({
    rolesArray,
    resetPermissions,
    updatePermissions,
    currentPermissions,
}) => {
    const allRoles: Role[] = useSelector<RootState, AdminState>((state) => state.admin).roles

    const roleActions: RoleActions = allRoles.reduce(
        (acc, cur) => ({
            ...acc,
            [cur.id]: {
                id: cur.id,
                permittedActions: [],
            },
        }),
        {}
    )

    useEffect(() => {
        Object.values(currentPermissions).forEach((line) => {
            line.actions.forEach((action) => {
                action.permissions.forEach((perm) => {
                    if (perm.checked) roleActions[perm.role_id].permittedActions.push(action.id)
                })
            })
        })
    }, [currentPermissions])

    const data = useSelector(roleDataSelector)
    return (
        <FooterContainer>
            <DescriptionContainer>
                {rolesArray.map((role) => {
                    if (role.code === Roles.admin) return null
                    return (
                        <div key={role.id}>
                            <HeavySpan>{role.name}</HeavySpan> —{role.description}
                        </div>
                    )
                })}
            </DescriptionContainer>
            <ControlsWrapper>
                <Button onClick={resetPermissions} sx={{ marginRight: '12px' }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disabled={isEqual(currentPermissions, data)}
                    onClick={() => {
                        updatePermissions(roleActions)
                    }}
                >
                    Save
                </Button>
            </ControlsWrapper>
        </FooterContainer>
    )
}
