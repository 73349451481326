import { Dialog, lighten } from '@mui/material'
import { styled } from 'core'
import { red, white } from 'utils/theme/colors'

interface VisibilityProps {
    shouldBeRendered?: boolean
}

export const ConditionalDialogButton = styled('button')`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
    height: 30px;
    width: 91px;
    padding: 0 10px;
    border-radius: 5px;
    color: ${white};
    border: none;
    background-color: ${red};
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: ${() => lighten(`${red}`, 0.1)};
    }
`
export const ButtonsWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 70px 36px;
`
export const CheckboxWrapper = styled('div', {
    shouldForwardProp: (prop: string) => prop !== 'shouldBeRendered',
})<VisibilityProps>`
    display: ${({ shouldBeRendered }) => (shouldBeRendered ? 'block' : 'none')};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 24px;
    transform: translateX(-7px);
`
export const StyledDialog = styled(Dialog)`
    .MuiDialogTitle-root {
        padding-top: 36px;
        padding-bottom: 24px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
        text-align: center;
    }
`
