import React, {
    FC,
    Ref,
    Suspense,
    createContext,
    memo,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'
import * as THREE from 'three'

import { TaxonomyContextProvider } from 'taxonomy'
import { ReactFlowProvider } from 'react-flow-renderer'
import { SkillsTreeHeader } from './parts/SkillsTreeHeader'
import { PageLoader } from '../../components/MoonLoader'
import NodeActionMenu from '../../taxonomy/ui/NodeActionMenu/NodeActionMenu'
import { useTaxonomyContext } from '../../taxonomy/context/TaxonomyContext'
import { CustomWizardNode } from '../../taxonomy/ui/CustomWizardNode/CustomWizardNode'
import { withAppLayout } from '../../parts/AppLayout/AppLayout'
import { styled } from '../../core'
import { borderGrey } from '../../utils/theme/colors'
import { Canvas } from '@react-three/fiber'
import { MapControls } from '@react-three/drei'
import { Nodes } from './TreeParts/Nodes'
import { Node } from './TreeParts/Node'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/store'
import { userSkillsSelector, userIdSelector } from 'store/selectors/auth-selectors'
import { useNotistackWrapper } from 'notifications'
import { selectAutoSuggestValue } from 'store/slices/autosuggest/selectors'
import { FlowNode } from 'taxonomy/types'
import { onNodeClick } from './utils/onNodeClick'

export const SWrapper = styled('section')`
    width: 100%;
    height: 100%;
    max-height: 100vh;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    padding-top: 12px;
    gap: 12px;
`

const SDelimeter = styled('hr')`
    border: 0;
    border-top: 1px solid ${borderGrey};
    margin: 0 24px;
`

export const CanvasWrapper = styled('section')<{ hidden: boolean }>`
    width: 100%;
    height: 100%;
    display: ${(hidden) => (hidden ? 'hidden' : 'block')};
    z-index: 0;
`

//@ts-ignore
const BridgeContext = createContext()

const BridgeContainer = ({ value, children }: { value: any; children: any }) => {
    return <BridgeContext.Provider value={{ ...value }}>{children}</BridgeContext.Provider>
}

const SkillsTree: FC = memo(() => {
    const dispatch = useAppDispatch()
    const skills = useSelector(userSkillsSelector)
    const userId = useSelector(userIdSelector)

    const autoSuggestState = useSelector(selectAutoSuggestValue)
    const autoSuggestValue = autoSuggestState.autoSuggestValue

    const { enqueueSuccess, enqueueError } = useNotistackWrapper()

    const taxonomyContext = useTaxonomyContext()
    const { nodes, edges, isLoading, updateNodesView, handleExpandAll, handleCollapseAll } = taxonomyContext

    const [isTreeLoading, setIsTreeLoading] = useState(true)

    const timer = useRef<number>()

    //@ts-ignore
    const controlsRef = useRef<MapControls>()
    const canvasRef = useRef<HTMLCanvasElement>()

    const mapControls = controlsRef.current
    const camera = mapControls?.object

    const setCameraView = (x: number, y: number) => {
        mapControls?.target?.set(x, y, 0)
        camera?.position?.set(x, y, 50)
        mapControls?.update()
    }

    const focusOnNode = (node?: FlowNode, xPos?: number, yPos?: number) => {
        const [x, y] =
            xPos !== undefined && yPos !== undefined ? [xPos, yPos] : [node?.position.x, node?.position.y]

        if (mapControls && mapControls.update && camera && x && y) {
            camera.zoom = 1

            setCameraView(x + 101.5, -y - 12)
        }
    }

    const setInitialCameraView = () => {
        const pointX = (canvasRef.current?.clientWidth || 0) / 2
        const pointY = -(canvasRef.current?.clientHeight || 0) / 2
        if (camera) camera.zoom = 1

        setCameraView(pointX, pointY)
    }

    useEffect(() => {
        const targetNode = nodes.find((n) => n.id === autoSuggestValue)

        if (targetNode) focusOnNode(targetNode)
    }, [autoSuggestState.autoSuggestValue, nodes])

    useEffect(() => {
        setInitialCameraView()
        updateNodesView()
    }, [isTreeLoading])

    useLayoutEffect(() => {
        if (canvasRef && nodes.length && !isLoading && camera && mapControls) setIsTreeLoading(false)
    }, [canvasRef, nodes.length, isLoading, camera, mapControls])

    const handleCollapseAllCanvas = () => {
        handleCollapseAll()
        setInitialCameraView()
    }

    return (
        <>
            <SkillsTreeHeader
                handleExpandAll={handleExpandAll}
                handleCollapseAll={handleCollapseAllCanvas}
                updateNodesView={updateNodesView}
            />
            <SDelimeter />
            <CanvasWrapper hidden={isTreeLoading}>
                <Canvas
                    frameloop="demand"
                    orthographic
                    camera={{ position: [0, 0, 50], zoom: 1, up: [0, 0, 1], far: 10000 }}
                    ref={canvasRef as Ref<HTMLCanvasElement> | undefined}
                >
                    <BridgeContainer value={taxonomyContext}>
                        <Suspense fallback={<PageLoader />}>
                            {nodes.length && (
                                <Nodes>
                                    {nodes.map((node) => {
                                        const targetEdges = edges.filter((n) => n.source === node.id)
                                        const targetNodeIds = targetEdges.map((n) => n.target)
                                        const targetNodes = targetNodeIds.map((item) =>
                                            nodes.find((n) => n.id === item)
                                        )

                                        const canvasEdges: any[] = []

                                        targetNodes.forEach((targetNode) => {
                                            const sourcePosition = { x: node.position.x, y: -node.position.y }

                                            if (!targetNode) return

                                            const targetPosition = {
                                                x: targetNode.position.x,
                                                y: -targetNode.position.y,
                                            }

                                            const leftNodePointPosition = { x: 0, y: -11, z: 0 }
                                            const rightNodePointPosition = { x: 203, y: -11, z: 0 }

                                            const calculatedSourceNodePointPosition =
                                                node.sourcePosition === 'right'
                                                    ? rightNodePointPosition
                                                    : leftNodePointPosition
                                            const calculatedTargetNodePointPosition =
                                                node.targetPosition === 'right'
                                                    ? rightNodePointPosition
                                                    : leftNodePointPosition

                                            canvasEdges.push({
                                                start: new THREE.Vector3(
                                                    sourcePosition.x,
                                                    sourcePosition.y,
                                                    0
                                                )
                                                    .clone()
                                                    .add(calculatedSourceNodePointPosition as THREE.Vector3),
                                                end: new THREE.Vector3(targetPosition.x, targetPosition.y, 0)
                                                    .clone()
                                                    .add(calculatedTargetNodePointPosition as THREE.Vector3),
                                                midA: new THREE.Vector3(
                                                    sourcePosition.x + 50,
                                                    sourcePosition.y,
                                                    0
                                                )
                                                    .clone()
                                                    .add(calculatedSourceNodePointPosition as THREE.Vector3),
                                                midB: new THREE.Vector3(
                                                    targetPosition.x - 50,
                                                    targetPosition.y,
                                                    0
                                                )
                                                    .clone()
                                                    .add(calculatedTargetNodePointPosition as THREE.Vector3),
                                            })
                                        })

                                        return (
                                            //@ts-ignore
                                            <Node
                                                // ref={node.ref}
                                                data={node.data}
                                                // different coordinates systems: react-flow and react-three-fiber
                                                position={[node.position.x, -node.position.y, 0]}
                                                connectedTo={canvasEdges}
                                                key={node.id}
                                            >
                                                <CustomWizardNode
                                                    data={node.data}
                                                    taxonomyContext={taxonomyContext}
                                                    dispatch={dispatch}
                                                    skills={skills}
                                                    userId={userId}
                                                    enqueueSuccess={enqueueSuccess}
                                                    enqueueError={enqueueError}
                                                    autoSuggestState={autoSuggestState}
                                                    onClick={(e) =>
                                                        onNodeClick(e, node, taxonomyContext, timer)
                                                    }
                                                />
                                            </Node>
                                        )
                                    })}
                                </Nodes>
                            )}
                        </Suspense>
                        <MapControls
                            ref={controlsRef}
                            enableRotate={false}
                            minZoom={0.2}
                            maxZoom={2}
                            enableDamping={false}
                        />
                    </BridgeContainer>
                    {/* <Stats /> */}
                </Canvas>
            </CanvasWrapper>
            {(isLoading || isTreeLoading) && <PageLoader />}
            <NodeActionMenu inWizardMode />
        </>
    )
})

export const SkillsTreePage = withAppLayout(
    () => {
        return (
            <SWrapper>
                <ReactFlowProvider>
                    {/* TODO: remove */}
                    <TaxonomyContextProvider workMode={1}>
                        <SkillsTree />
                    </TaxonomyContextProvider>
                </ReactFlowProvider>
            </SWrapper>
        )
    },
    { wideView: true }
)
