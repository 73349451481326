import { css, styled } from 'core'
import { DontWantToLearnIcon, RecentIcon, WantToLearnIcon } from 'images'
import { borderGrey, moonBlue } from 'utils/theme/colors'
import { NodeData } from '../../types'
import { NodesColors } from '../ReactFlowGraph/styled'

interface CustomNodeProps {
    data: NodeData
    isHovering: boolean
    isHighlighted?: boolean
}

interface MainBlockProps {
    loading: boolean
}

interface LevelBlockProps {
    isHovering: boolean
}

interface LevelIconProps {
    isActive: boolean
    isDisabled?: boolean
}

export const ContextMenuWrapper = styled('div')`
    width: 100%;
`

const iconHoverStyles = css`
    background-color: #e9f5ff;
    border-radius: 16px;
    & > path {
        fill: #2977ff;
    }
`

const iconActiveStyles = css`
    background-color: #d2ebff;
    border-radius: 16px;
    & > path {
        fill: #2977ff;
    }
`

const shortCutCss = css`
    color: ${NodesColors.SHORTCUT_TEXT_LABEL};
    background-color: ${NodesColors.WHITE};
    border: 1px dashed ${NodesColors.SHORTCUT_BORDER};
    box-sizing: border-box;
    border-radius: 2px;
`

export const CustomNodeStyled = styled('div', {
    shouldForwardProp: (prop: string) => prop !== 'isHovering' && prop !== 'isHighlighted',
})<CustomNodeProps>`
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    position: relative;
    width: 204px;
    max-height: 24px;
    border-radius: 2px;
    border: none;
    padding: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    color: ${NodesColors.CATEGORY_TEXT_LABEL};
    background-color: ${(props) =>
        props.isHighlighted ? moonBlue : props.data.isCategory ? '#eff1f4' : '#E7ECF9'};
    gap: 5px;
    overflow: hidden;
    transition: max-height 0.15s ease-out;

    ${(props) =>
        props.isHovering &&
        !props.data.isCategory &&
        // !props.data.isShortcut &&
        `max-height: 60px;
            border: 1px solid #54687d;
            `}

    &:hover {
        z-index: 100;
        .arrow-button-wrapper {
            display: block;
        }
    }
    ${(props) => props.data.isShortcut && shortCutCss}
`

export const LabelStyled = styled('div')`
    overflow: hidden;
    display: flex;
    align-items: center;

    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
`

export const MainBlock = styled('div', {
    shouldForwardProp: (prop: string) => prop !== 'nodeMenuShown' && prop !== 'loading',
})<MainBlockProps>`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    ${(props) => props.loading && 'pointer-events: none;'}
    ${(props) => props.loading && 'opacity: 0.5;'}
`

export const LevelBlock = styled('div', {
    shouldForwardProp: (prop: string) => prop !== 'isHovering',
})<LevelBlockProps>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    ${(props) => !props.isHovering && 'display: none'}
`

export const LoaderWrapper = styled('div')`
    position: fixed;
    top: 10px;
    left: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
`

export const IconsBlock = styled('div')`
    width: 50%;
    display: flex;
    justify-content: end;
    padding-left: 10px;
    border-left: 1px solid ${borderGrey};
`

export const CountStyled = styled('div')`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #9dacbc;
`

export const RecentIconStyled = styled(RecentIcon)<LevelIconProps>`
    display: flex;
    padding: 5px;
    width: 14px;
    height: 14px;
    ${(props) => props.isActive && iconActiveStyles}
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

    :hover {
        ${(props) => !props.isDisabled && iconHoverStyles}
    }
`

export const WantToLearnIconStyled = styled(WantToLearnIcon, {
    shouldForwardProp: (prop: string) => prop !== 'isActive' && prop !== 'isDisabled',
})<LevelIconProps>`
    display: flex;
    padding: 5px;
    width: 14px;
    height: 14px;
    ${(props) => props.isActive && iconActiveStyles}
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

    :hover {
        ${(props) => !props.isDisabled && iconHoverStyles}
    }
`

export const DontWantToLearnIconStyled = styled(DontWantToLearnIcon, {
    shouldForwardProp: (prop: string) => prop !== 'isActive' && prop !== 'isDisabled',
})<LevelIconProps>`
    display: flex;
    width: 14px;
    height: 14px;
    padding: 5px;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    ${(props) => props.isActive && iconActiveStyles}

    :hover {
        ${(props) => !props.isDisabled && iconHoverStyles}
    }
`

export const NodeInfo = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const NodeIcons = styled('div')`
    display: flex;
    align-items: center;

    > svg:last-child {
        padding-left: 3px;
    }
`
