import { Skill } from 'utils/types/skills'
import { DialogueModal, DialogueModalType } from './notifications.types'

interface CreateDeleteSkillModalParams {
    skillId: string
    onClick: () => void
}

export const createDeleteSkillModal = ({ skillId, onClick }: CreateDeleteSkillModalParams): DialogueModal => {
    const DELETING_MESSAGE = 'Are you sure you want to delete this skill?'
    const CAN_BE_HIDDEN = true

    return {
        canBeHidden: CAN_BE_HIDDEN,
        id: skillId.toString(),
        message: DELETING_MESSAGE,
        onClick,
        type: DialogueModalType.DELETE_SKILL,
        redButtonMessage: 'Cancel',
        greenButtonMessage: 'Delete',
    }
}

export const createExitModal = (onClick: () => void, newMsg?: string, onSave?: () => void): DialogueModal => {
    const MESSAGE = 'Are you sure you want to leave this page?'
    const CAN_BE_HIDDEN = false

    return {
        canBeHidden: CAN_BE_HIDDEN,
        id: Date.now().toString(),
        message: newMsg || MESSAGE,
        onClick: onClick,
        onSave: onSave,
        type: DialogueModalType.EXIT_FROM_PAGE,
        redButtonMessage: (newMsg && 'Save') || 'Cancel',
        greenButtonMessage: (newMsg && 'Discard') || 'Yes',
    }
}

export const createResetModal = (onClick: () => void): DialogueModal => {
    const CAN_BE_HIDDEN = true
    const MESSAGE = 'Are you sure you want to delete all these skills?'

    return {
        canBeHidden: CAN_BE_HIDDEN,
        id: Date.now().toString(),
        message: MESSAGE,
        onClick: onClick,
        type: DialogueModalType.DELETE_SKILL,
        redButtonMessage: 'Cancel',
        greenButtonMessage: 'Delete',
    }
}

interface ChangeSkillModalParams {
    skill: Skill
    onClick: () => void
}

export const changeSkillModal = ({ skill, onClick }: ChangeSkillModalParams): DialogueModal => {
    const DELETING_MESSAGE = 'Are you sure you want to delete this skill?'
    const CAN_BE_HIDDEN = true

    return {
        canBeHidden: CAN_BE_HIDDEN,
        id: skill.node_id,
        message: DELETING_MESSAGE,
        onClick,
        type: DialogueModalType.DELETE_SKILL,
        redButtonMessage: 'Cancel',
        greenButtonMessage: 'Delete',
    }
}
