import React, { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { EmployeeInfo, FilterSkill, OrderByField, SearchContextValue, SearchFilters } from './types'
import { useQuery } from 'react-query'
import { getCompetencies, getLocations, getPresets, searchUsersRequest } from 'search/api/requests'
import { constructSearchBody, parseFilters, stringifyFilters } from './converters'
import { useSelector } from 'react-redux'
import { selectTree } from 'store/slices/tree/selectors'
import { useNodeWithPath } from 'utils/hooks/useSkillPathTree'
import { Preset, PresetDTO } from 'utils/types/presets'
import { transformPresetDTOIntoPreset } from 'search/utils/functions/transformPresetDTO'
import { authInfo } from 'store/selectors/auth-selectors'
import { Roles } from 'utils/enums/different'

const SearchContext = createContext<SearchContextValue | null>(null)

interface Props {
    children: React.ReactNode
}

export const SearchContextProvider: FC<Props> = ({ children }) => {
    const auth = useSelector(authInfo)
    const isUserIsExternalConsultant = auth.user?.role === Roles.externalConsultant ? true : false

    const { data: fetchedLocations } = useQuery('fetchLocations', () => getLocations(), {
        enabled: !isUserIsExternalConsultant,
        refetchOnWindowFocus: false,
        select: (data) => data.sort(),
    })
    const { data: fetchedCompetencies } = useQuery('fetchCompetencies', () => getCompetencies(), {
        enabled: !isUserIsExternalConsultant,
        refetchOnWindowFocus: false,
        select: (data) => data.sort(),
    })
    // eslint-disable-next-line no-empty-pattern
    const {} = useQuery('fetchPresets', () => getPresets(), {
        enabled: !isUserIsExternalConsultant,
        refetchOnWindowFocus: false,
        onSuccess(data) {
            setPresets([...data.map((v: PresetDTO) => transformPresetDTOIntoPreset(v))])
        },
    })

    const [searchParams, setSearchParams] = useSearchParams()

    const tree = useSelector(selectTree)
    const allSkills = useNodeWithPath(tree)

    // restore filters from URL
    const parsedParams = useMemo(() => parseFilters(searchParams, allSkills), [searchParams, allSkills])
    const [skills, setSkills] = useState<Record<string, FilterSkill>>(parsedParams.skills)
    const [competencies, setCompetencies] = useState<string[]>(parsedParams.competencies)
    const [locations, setLocations] = useState<string[]>(parsedParams.locations)
    const [searchString, setSearchString] = useState<string>(parsedParams.searchString)
    const [availableOnly, setAvailableOnly] = useState<boolean>(parsedParams.availableOnly)
    const [onBench, setOnBench] = useState<boolean>(parsedParams.onBench)
    const [orderBy, setOrderBy] = useState<OrderByField | null>(parsedParams.orderBy)

    const [offset, setOffset] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [presets, setPresets] = useState<Preset[]>([])
    const [preset, setPreset] = useState<Preset | null>(null)
    const [results, setResults] = useState<EmployeeInfo[]>([])
    const [showCompareTable, setShowCompareTable] = useState<boolean>(false)
    const [comparingPeople, setComparingPeople] = useState<string[]>([])

    const checkedParsedParams = Object.values(skills).every((item) => item.is_category !== undefined)

    useEffect(() => {
        if (allSkills.length && checkedParsedParams && !isUserIsExternalConsultant) {
            const searchUsers = async (params: SearchFilters) => {
                setLoading(true)
                const result = await searchUsersRequest(constructSearchBody(params))
                setLoading(false)
                if (result?.data) {
                    setResults(result?.data)
                    setTotal(result?.total)
                    setOffset(20)
                }
            }

            setOffset(0)
            setTotal(0)

            setSearchParams(
                stringifyFilters({
                    skills,
                    locations,
                    competencies,
                    searchString,
                    availableOnly,
                    orderBy,
                    onBench,
                })
            )
            searchUsers({
                skills,
                locations,
                competencies,
                searchString,
                availableOnly,
                orderBy,
                offset: 0,
                onBench,
            })
        }
    }, [
        skills,
        locations,
        orderBy,
        competencies,
        searchString,
        availableOnly,
        onBench,
        allSkills.length,
        checkedParsedParams,
        isUserIsExternalConsultant,
    ])

    useEffect(() => {
        setSkills(parsedParams.skills)
        setSearchString(parsedParams.searchString)
        setLocations(parsedParams.locations)
        setCompetencies(parsedParams.competencies)
        setAvailableOnly(parsedParams.availableOnly)
        setOrderBy(parsedParams.orderBy)
        setOnBench(parsedParams.onBench)
    }, [])

    useEffect(() => {
        setSkills(parseFilters(searchParams, allSkills).skills)
    }, [allSkills.length])

    const value = useMemo(
        (): SearchContextValue => ({
            allSkills,
            loading,
            total,
            setTotal,
            offset,
            setOffset,
            showCompareTable,
            setShowCompareTable,
            searchString,
            setSearchString,
            comparingPeople,
            setComparingPeople,
            skills,
            setSkills,
            presets,
            setPresets,
            results,
            setResults,
            preset,
            setPreset,
            locations,
            setLocations,
            competencies,
            setCompetencies,
            availableOnly,
            setAvailableOnly,
            orderBy,
            setOrderBy,
            fetchedLocations,
            fetchedCompetencies,
            onBench,
            setOnBench,
        }),
        [
            skills,
            loading,
            offset,
            total,
            setTotal,
            preset,
            setPreset,
            setOffset,
            setSkills,
            searchString,
            results,
            setResults,
            presets,
            setPresets,
            comparingPeople,
            setComparingPeople,
            orderBy,
            setOrderBy,
            showCompareTable,
            setShowCompareTable,
            setSearchString,
            fetchedLocations,
            fetchedCompetencies,
            locations,
            setLocations,
            allSkills,
            competencies,
            setCompetencies,
            onBench,
            setOnBench,
        ]
    )

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
}

export const useSearchContext = (): SearchContextValue => {
    const value = useContext(SearchContext)
    if (!value) {
        throw new Error('useSearchContext should be used within SearchContextProvider')
    }
    return value
}
