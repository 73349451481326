import { styled } from 'core'

export const LoginForm = styled('div')`
    margin: 5px auto;
    background: #ffffff;
    border: 1px solid #8c8c8c;
    color: #5e5e5e;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
    cursor: pointer;
    box-sizing: border-box;
    width: 210px;
    :hover {
        background: #2f2f2f;
        border: 1px solid #ffffff;
        color: #ffffff;
    }
`

export const LoginLabel = styled('div')`
    font-family: 'Segoe UI';
    font-weight: 600;
    font-size: 15px;
    margin-left: 12px;
    color: inherit;
`
