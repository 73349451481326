import { SortIcon } from 'images'
import React, { FC } from 'react'
import { useSearchContext } from 'search/context/SearchContext'
import { HeaderCell, HeaderItemContent, SortIconContainer } from './styled'

interface Props {
    title: string
    name?: 'competence' | 'name' | 'location' | 'availability' | 'grade'
}

export const HeaderItem: FC<Props> = ({ title, name }) => {
    const { orderBy, setOrderBy } = useSearchContext()
    const onFilterChange = () => {
        if (name) {
            if (orderBy && orderBy.field === name) {
                if (orderBy.asc) {
                    setOrderBy({ field: name, asc: false })
                } else {
                    setOrderBy(null)
                }
            } else {
                setOrderBy({ field: name, asc: true })
            }
        }
    }

    return (
        <HeaderCell>
            <HeaderItemContent onClick={onFilterChange}>
                {title}{' '}
                {name && (
                    <SortIconContainer
                        hidden={orderBy === null || orderBy.field !== name}
                        reversed={!!(orderBy && orderBy.field === name && !orderBy.asc)}
                    >
                        <SortIcon />
                    </SortIconContainer>
                )}
            </HeaderItemContent>
        </HeaderCell>
    )
}
