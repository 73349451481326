import { streetGrey, tannBlue } from 'utils/theme/colors'
import { styled } from '../../utils/core.styled'

export const TabsContainer = styled('div')`
    .MuiTabs-root {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-height: 10px;
        padding-top: 24px;
        .MuiTabs-indicator {
            background-color: transparent;
        }
    }

    .MuiTab-root {
        min-width: 30px;
        min-height: 10px;
        cursor: pointer;
        padding: 0;
        margin: 0 8px;
        text-transform: none;
        &.Mui-selected {
            color: ${tannBlue};
            font-weight: bold;
            font-size: 20px;
        }
    }
    .MuiTabPanel-root {
        padding: 0;
    }
`
TabsContainer.displayName = 'TabsContainer'

export const TabsList = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 24px;
    padding: 0px calc(20px - (100vw - 100%)) 24px 0px;
`

export const TabsWrapUpStyled = styled('div')`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid ${streetGrey};
`
