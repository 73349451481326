import { AppHeader } from '../AppHeader/AppHeader'
import React from 'react'
import { styled } from '../../core'
import { SearchContextProvider } from 'search/context/SearchContext'
import ContributeButton from 'components/ContributeComponents/ContributeButton'
import ContributeModal from 'components/ContributeComponents/ContributeModal'

export const SCenteredWrapper = styled('div')`
    margin: 0 auto;
    padding: 24px;
    flex-direction: column;
    width: 1200px;
    height: 100%;
    overflow: auto;
    min-height: 100px;
`

export const SWideWrapper = styled('div')`
    flex-grow: 1;
    overflow: hidden;
`

export interface AppLayoutProps {
    wideView?: boolean
}

export const withAppLayout: (Component: React.FC, props?: AppLayoutProps) => React.FC = (
    Component,
    props = {}
) => {
    const { wideView } = props

    const Wrapper = wideView ? SWideWrapper : SCenteredWrapper

    return () => {
        return (
            <>
                <SearchContextProvider>
                    <AppHeader />
                    <Wrapper>
                        <Component />
                        <ContributeButton />
                        <ContributeModal />
                    </Wrapper>
                </SearchContextProvider>
            </>
        )
    }
}
