import { format } from 'date-fns'
import { UserProfile } from 'utils/types/auth'
import { ActionType, ChangeRequest, ChangeRequestHistoryItem, CRItem, HistoryType } from '../../types'

export const formChangeRequestTitleString = (changeRequest: ChangeRequest, userRole?: string) => {
    switch (changeRequest.status) {
        case HistoryType.DRAFT: {
            return ` Draft`
        }
        case HistoryType.APPROVED: {
            return ` Approved`
        }
        case HistoryType.ARCHIVED: {
            return ` Discarded`
        }
        case HistoryType.ADJUSTMENT_REQUESTED: {
            return ` Adjustment requested`
        }
        case HistoryType.REASSIGNED: {
            return ` Reassigned`
        }
        case HistoryType.ON_REVIEW: {
            if (userRole === 'adm') return ` Pending Admin's review`
            else return ` Submitted for review`
        }
    }
}

export const formChangeRequestActionString = (item: CRItem) => {
    switch (item.action) {
        case ActionType.MOVE: {
            return `"${item.name}" and all its children to "${item.newParentName}"`
        }
        case ActionType.RENAME: {
            return `"${item.prevName}" to "${item.newName}"`
        }
        case ActionType.CREATE_SHORTCUT: {
            return `"${item.childName}" shortcut for "${item.parentName}"`
        }
        case ActionType.ARCHIVE: {
            return `"${item.name}"`
        }
        case ActionType.CREATE_CATEGORY:
        case ActionType.CREATE_ROOT_ITEM:
        case ActionType.CREATE_SKILL:
            return `"${item.name}" ${item.isCategory ? 'category' : 'skill'}`
        case ActionType.RESTORE:
            return `"${item.name}"`
        case ActionType.REMOVE:
            return `"${item.childName}" shortcut from "${item.parentName}"`
    }
}

export const getHistoryItemTimestamp = (timestamp: Date) => format(timestamp, 'dd.MM.yy hh:mm:ss')

export const formChangeRequestHistoryString = (historyItem: ChangeRequestHistoryItem, owner: UserProfile) => {
    const firstName = historyItem.user.name.split(' ')[0]
    const lastName = historyItem.user.name.split(' ')[1]
    switch (historyItem.status) {
        case HistoryType.DRAFT: {
            return `Draft created by ${firstName} ${lastName}`
        }
        case HistoryType.ON_REVIEW: {
            return `Submitted for Review by ${firstName} ${lastName}`
        }
        case HistoryType.ADJUSTMENT_REQUESTED: {
            return `Adjustment Requested by Admin ${historyItem.user.name}`
        }
        case HistoryType.APPROVED: {
            return `Approved by ${firstName} ${lastName}`
        }
        case HistoryType.ARCHIVED: {
            return `Discarded by ${firstName} ${lastName}`
        }
        case HistoryType.REASSIGNED: {
            return `Reassigned by ${firstName} ${lastName} to ${owner.name}`
        }
    }
}
