import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { userSelector } from 'store/selectors/auth-selectors'
import { updateUserActions } from 'store/shared/actions'
import { putEmployeeRole } from 'store/slices/admin/requests'
import { updateUserRole } from 'store/slices/auth'
import { RootState, useAppDispatch } from 'store/store'
import {
    findFullRoleByShortcut,
    findShortcutRoleByFull,
    getActionCodeListForRole,
} from 'utils/functions/common'
import { employeeRolesOptionForAdminPage } from 'utils/helpers/selects-options'
import { FoundRole, Role, SelectOption } from 'utils/types/admin'
import { UserProfile } from 'utils/types/auth'
import { Action } from 'utils/types/common'
import MenuRole from './RoleMenu'
import { checkUser } from 'store/slices/auth/requests'
interface Props {
    employee: UserProfile
}

const defaultValueForSelect = (roleFromAPI: string) => {
    if (!roleFromAPI) return
    const foundRole: FoundRole | undefined = findFullRoleByShortcut(roleFromAPI)
    if (foundRole) {
        return employeeRolesOptionForAdminPage.find((entry) => entry.label === foundRole.name)
    }
}

export const ChangeEmployeeRole: FC<Props> = ({ employee }) => {
    const [selectValue, setSelectValue] = useState(defaultValueForSelect(employee.role))

    const user: UserProfile | undefined = useSelector(userSelector)
    const roles = useSelector<RootState, Role[]>((state) => state.admin.roles)
    const actions = useSelector<RootState, Action[]>((state) => state.admin.actions)

    const dispatch = useAppDispatch()

    const updateRole = (value: SelectOption) => {
        const validRole = findShortcutRoleByFull(value.label)?.contracted
        if (!validRole) return

        const actionCodeList = getActionCodeListForRole(validRole, roles, actions)

        const onUpdate = () => {
            setSelectValue(value)
            // if user changes role for himself
            if (employee.user_id === user?.user_id) {
                dispatch(checkUser())

                dispatch(updateUserActions(actionCodeList))
                dispatch(updateUserRole(validRole))
            }
        }
        dispatch(putEmployeeRole(employee.user_id, validRole, onUpdate))
    }
    if (user?.role !== 'adm' && user?.role !== 'ch') {
        return <div>{selectValue?.label}</div>
    }
    return (
        //todo change menu role to a simple selector
        <MenuRole
            defaultValue={selectValue}
            onChange={(value) => {
                updateRole(value!)
            }}
        />
    )
}
