import { TextField } from 'core'
import { useNotistackWrapper } from 'notifications'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { removeSpaces } from 'utils/functions/common'

import { useSearchContext } from 'search/context/SearchContext'
import { createPreset, replacePreset } from 'search/api/requests'
import { constructPreset } from 'search/context/converters'
import { transformPresetDTOIntoPreset } from 'search/utils/functions/transformPresetDTO'
import ActionDialogWrapper from 'core/ui/ActionDialog/ActionDialogWrapper'
import { PresetErrorMessage } from './styled'

interface AddPresetProps {
    setIsOpen: (arg: boolean) => void
    isOpen: boolean
}

const messagePresetWithSuchNameExists = 'Preset with such name exists. It will be replaced after saving.'

const AddPresetModal: FC<AddPresetProps> = ({ setIsOpen, isOpen }) => {
    const {
        presets,
        setPresets,
        setPreset,
        skills,
        locations,
        competencies,
        searchString,
        availableOnly,
        onBench,
    } = useSearchContext()
    const { enqueueSuccess, enqueueError } = useNotistackWrapper()
    const [presetName, setPresetName] = useState<string>('')
    const [doesContainError, setDoesContainError] = useState<boolean>(false)

    const existingPreset = useMemo(() => {
        return presets?.find((preset) => preset.name === removeSpaces(presetName))
    }, [presetName, presets])

    useEffect(() => {
        if (existingPreset) setDoesContainError(true)
        else setDoesContainError(false)
    }, [presetName])

    const handleAddPresetClick = async () => {
        const searchParams = constructPreset({
            skills,
            locations,
            competencies,
            searchString,
            availableOnly,
            onBench,
        })
        const presetObject = { ...searchParams, name: presetName }

        if (!presetName) enqueueError('Preset name must not be empty')
        else {
            if (existingPreset) {
                const result = await replacePreset(presetObject, existingPreset.id)
                if (result) {
                    const newPreset = transformPresetDTOIntoPreset(result)
                    setPreset(newPreset)
                    setPresets((prev) => [...prev.filter((v) => v.id !== existingPreset.id), newPreset])
                    enqueueSuccess('Preset saved successfully')
                }
            } else {
                const result = await createPreset(presetObject)
                if (result) {
                    const newPreset = transformPresetDTOIntoPreset(result)
                    setPreset(newPreset)
                    setPresets((prev) => [...prev, newPreset])
                    enqueueSuccess('Preset saved successfully')
                }
            }
            setIsOpen(false)
        }
    }

    const handleCancelClick = () => {
        setIsOpen(false)
    }

    return (
        <ActionDialogWrapper
            title={'Save preset'}
            buttonText="Save"
            disabled={!presetName}
            submitAction={handleAddPresetClick}
            isOpen={isOpen}
            onClose={handleCancelClick}
        >
            <TextField
                id="create-preset-modal"
                placeholder={'Preset Name'}
                label={'New preset name'}
                value={presetName}
                onChange={(event) => setPresetName(event.target.value)}
                autoFocus={true}
            />
            {doesContainError ? (
                <PresetErrorMessage>{messagePresetWithSuchNameExists}</PresetErrorMessage>
            ) : null}
        </ActionDialogWrapper>
    )
}

export default AddPresetModal
