import { css } from 'core'
import { TypographyColors } from './colors'

export const smallFont = css`
    font-weight: 400;
    color: ${TypographyColors.DARK_CYAN};
    font-size: 14px;
    line-height: 20px;
`

export const smallMediumFont = css`
    font-weight: 400;
    color: ${TypographyColors.DARK_CYAN};
    font-size: 14px;
    line-height: 20px;
`
