import { AlertColor } from '@mui/material'
import { Ref } from 'core'
import { SnackbarKey, SnackbarMessage } from 'notistack'
import React, { FC, forwardRef } from 'react'
import { CloseButton } from '../CloseButton'
import { AlertContainer } from './NotificationAlert.styled'

interface AlertProp {
    id: SnackbarKey
    variant: AlertColor
    message: SnackbarMessage
}

export const NotificationAlert: FC<AlertProp> = forwardRef(({ id, message, variant }, ref: Ref) => {
    return (
        <div ref={ref}>
            <AlertContainer severity={variant} icon={false} action={<CloseButton keyId={id} />}>
                {message}
            </AlertContainer>
        </div>
    )
})
