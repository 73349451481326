export const styleForImage = (
    borderRadius: string | undefined,
    border: string | undefined,
    boxSizing: any
) => {
    return {
        borderRadius: borderRadius,
        border: border,
        boxSizing: boxSizing,
    }
}
