import React, { useEffect, useMemo, useState } from 'react'
import { removeSpaces } from 'utils/functions/common'
import { useTaxonomyContext } from '../../context/TaxonomyContext'
import { useNodeActions } from '../../hooks/useNodeActions'
import { ActionType } from '../../types'
import ActionDialogWrapper from '../../../core/ui/ActionDialog/ActionDialogWrapper'
import { ErrorButton, ErrorLabel, NameErrorContainer, SDialogLabel } from './styled'
import { TextField } from '../../../core'

type errorProps = {
    error: boolean
    errorLabel: string
}

export const UniqueNameError: React.FunctionComponent<errorProps> = ({ error, errorLabel }) => {
    const { selectedNodeId, showActionDialog: handleNodeAction } = useTaxonomyContext()
    if (!error) return null
    return (
        <NameErrorContainer>
            <ErrorLabel>{errorLabel}</ErrorLabel>
            <ErrorButton onClick={() => handleNodeAction(selectedNodeId!, ActionType.CREATE_SHORTCUT)}>
                <u>add a shortcut instead?</u>
            </ErrorButton>
        </NameErrorContainer>
    )
}

const CreateCategoryDialog = () => {
    const { selectedNodeId, flowNodes, reactFlowNodesMap, hideActionDialog, action } = useTaxonomyContext()
    const { createNode } = useNodeActions()

    const [nodeTitle, setNodeTitle] = React.useState<string>('')
    const [error, setError] = useState<boolean>(false)

    const existingNodeName = useMemo(() => {
        const lowerCaseTitle = removeSpaces(nodeTitle).toLowerCase()
        return flowNodes.some((node) => removeSpaces(node.data.label).toLowerCase() === lowerCaseTitle)
    }, [nodeTitle, flowNodes])

    useEffect(() => {
        if (existingNodeName) setError(true)
        else setError(false)
    }, [existingNodeName])

    return (
        <ActionDialogWrapper
            title={'Add new child category'}
            buttonText="Add"
            disabled={!nodeTitle || error}
            submitAction={() => createNode(nodeTitle, ActionType.CREATE_CATEGORY, selectedNodeId)}
            onClose={hideActionDialog}
            isOpen={!!action}
        >
            <SDialogLabel>to {reactFlowNodesMap[selectedNodeId!]?.data.label}</SDialogLabel>
            <TextField
                id="create-category-input"
                label={'Category Name'}
                placeholder={'Type Category Name'}
                value={nodeTitle}
                onChange={(e) => setNodeTitle(e.target.value)}
                autoFocus={true}
            />
            <UniqueNameError error={error} errorLabel={'Category name should be unique,'} />
        </ActionDialogWrapper>
    )
}

export default CreateCategoryDialog
