import React, { FC, useMemo } from 'react'
import { Root, MainController, ToolController, SkillsContainer, NoContent } from './styled'
import { PenIcon, PenIconDisabled } from '../../../images'
import { ColorBox } from '../../../components/ColorBox'
import { white } from '../../../utils/theme/colors'
import { getLevelById, getLevelColor } from '../../../utils/functions/presenters'
import { useUserProfile } from '../../../utils/hooks/useUserProfile'
import { Box, Typography } from '@mui/material'

export const FocusSkills: FC = () => {
    const { skills, readonly, editMode, isEditing } = useUserProfile()

    const showingSkills = useMemo(
        () =>
            skills
                .filter((skill) => !skill.dislike && skill.seniority_level)
                .sort((x, y) => (y.seniority_level || 0) - (x.seniority_level || 0)),
        [skills]
    )

    const getToolbox = () => {
        if (!readonly) {
            return (
                <ToolController>
                    <Box
                        component="div"
                        data-automation-id="button-to-edit"
                        onClick={() => !isEditing && editMode(true)}
                        sx={{ cursor: 'pointer' }}
                    >
                        {isEditing ? <PenIconDisabled /> : <PenIcon />}
                    </Box>
                </ToolController>
            )
        }
    }

    const insertSkills = () => {
        if (showingSkills.length) {
            return showingSkills
                .filter((s) => !s.is_archived)
                .slice(0, 12)
                .map((skill) => {
                    return (
                        <ColorBox
                            data-automation-id={`label-focus-skill-${skill.node_id}`}
                            key={skill.node_id}
                            color={white}
                            backgroundColor={getLevelColor(getLevelById(skill.seniority_level))}
                        >
                            {skill.name}
                        </ColorBox>
                    )
                })
        }
        return <NoContent>There&apos;s nothing here yet</NoContent>
    }

    return (
        <Root data-automation-id="container-focus-skills">
            <MainController isEditable={!readonly}>
                <Typography
                    variant="h4"
                    data-automation-id="header-focus-skills"
                    sx={{
                        whiteSpace: 'nowrap',
                        marginRight: '24px',
                    }}
                >
                    Focus Skills
                </Typography>
                <SkillsContainer>{insertSkills()}</SkillsContainer>
            </MainController>
            {getToolbox()}
        </Root>
    )
}
