import React, { FC, useMemo, useState } from 'react'
import { ExpandButtonContainer, Header, HeaderIconContainer, RootContainer } from './styles'
import { Levels } from '../../utils/enums/different'
import {
    getIconByLevelName,
    getIdByLevelName,
    groupSkillsByCompetencies,
} from '../../utils/functions/presenters'
import { PointsButton, ArrowUpGrey } from '../../images'
import { Skill } from '../../utils/types/skills'
import { useUserProfile } from '../../utils/hooks/useUserProfile'
import { SkillsRow } from './parts/SkillsRow'
import { useSelector } from 'react-redux'
import { selectTree } from 'store/slices/tree/selectors'
import ErrorBoundary from 'components/ErrorBoundary'
import { useNodeWithPath } from 'utils/hooks/useSkillPathTree'

interface Props {
    level: Levels
}

export const LevelTable: FC<Props> = ({ level }) => {
    const CLOSED_LIMIT = 3
    const skillsLevel = useMemo(() => getIdByLevelName(level), [level])

    let predicata: (skill: Skill) => boolean

    switch (level) {
        case Levels.WTL:
            predicata = (skill: Skill) => skill.want_to_learn
            break
        case Levels.DWTL:
            predicata = (skill: Skill) => skill.dislike
            break
        default:
            predicata = (skill: Skill) => skill.seniority_level === skillsLevel
            break
    }

    const [expand, setExpand] = useState<boolean>(false)
    const { skills } = useUserProfile()
    const tree = useSelector(selectTree)
    const allSkills = useNodeWithPath(tree, true, true)
    const tableSkills = useMemo(() => skills.filter(predicata), [skills])

    const groupedSkills = useMemo(() => groupSkillsByCompetencies(tableSkills), [skills])

    const competencyKeys = useMemo(() => Object.keys(groupedSkills), [skills])

    const getExpandButton = () => {
        if (competencyKeys.length > 3) {
            return (
                <ExpandButtonContainer
                    data-automation-id="button-expand-level-table"
                    onClick={() => setExpand((prev) => !prev)}
                >
                    {!expand ? <PointsButton /> : <ArrowUpGrey />}
                </ExpandButtonContainer>
            )
        }
    }

    const getShowingIds = (): string[] => {
        if (expand) {
            return competencyKeys
        }

        return competencyKeys.slice(0, CLOSED_LIMIT)
    }

    if (!tableSkills.length) return null

    return (
        <ErrorBoundary>
            <RootContainer data-automation-id="container-level">
                <Header>
                    <HeaderIconContainer data-automation-id={`header-level-${level}`}>
                        {getIconByLevelName(level)}
                    </HeaderIconContainer>{' '}
                    {level}
                </Header>
                <div>
                    {getShowingIds().map((id) => (
                        <SkillsRow
                            skills={groupedSkills[id]}
                            path={
                                allSkills.filter((elem) => elem.node_id === groupedSkills[id][0].node_id)[0]
                                    ?.nodePath
                            }
                            key={id}
                            level={level}
                        />
                    ))}
                </div>
                {getExpandButton()}
            </RootContainer>
        </ErrorBoundary>
    )
}
