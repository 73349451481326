import React, { FC } from 'react'
import { Skill, SkillColor } from './styled'

interface Props {
    name: string
    color: string
}

const SkillItem: FC<Props> = ({ name, color }) => {
    return (
        <Skill>
            <SkillColor color={color} />
            <span>{name}</span>
        </Skill>
    )
}

export default SkillItem
