import { client } from 'axiosClient'
import { ComposedAjaxError } from 'axiosClient/ErrorComposer'
import { ChangeRequestDto, NodeDTO, CRItem, ShortcutDto } from 'taxonomy/types'
import { ResponseData } from 'utils/types/common'
import { getChangeRequestFromDto, getDtoFromCRItem, getNodeFromDto, getShortcutFromDto } from './converters'

export const getSkillsTreeNodes = async () => {
    const response = await client.get<ResponseData<NodeDTO>>('api/skills-tree/nodes/')
    return response.data.map(getNodeFromDto)
}

export const getSkillsTreeShortcuts = async () => {
    const response = await client.get<ResponseData<ShortcutDto>>('api/skills-tree/shortcuts/')
    return response.data.map(getShortcutFromDto)
}

export const getActiveChangeRequest = async (workMode: number) => {
    try {
        if (workMode === 1) {
            return []
        }
        const response = await client.get<ResponseData<ChangeRequestDto>>('api/change-requests/?active=true')
        return response.data.map(getChangeRequestFromDto)
    } catch (error: any) {
        ComposedAjaxError.handleError('Error receiving Active change request', error)
    }
}

export const getChangeRequests = async () => {
    try {
        const response = await client.get<ResponseData<ChangeRequestDto>>('api/change-requests/')
        return response.data.map(getChangeRequestFromDto)
    } catch (error: any) {
        ComposedAjaxError.handleError('Error receiving Active change request', error)
    }
}

export const updateCurrentOrCreateCR = async (item: CRItem) => {
    try {
        const itemDto = getDtoFromCRItem(item)
        const response = await client.post<ChangeRequestDto>('api/change-requests/current/items', itemDto)
        return getChangeRequestFromDto(response)
    } catch (error: any) {
        ComposedAjaxError.handleError('There is another active ChangeRequest', error)
    }
}

export const getSkillTreeShortcuts = () => {}

export const discardActiveChangeRequest = async (id: number, comment: string) => {
    try {
        const response = await client.post<ChangeRequestDto>(`api/change-requests/${id}/archive`, {
            comment,
        })
        return getChangeRequestFromDto(response)
    } catch (error: any) {
        ComposedAjaxError.handleError('Change request not archived', error)
    }
}

export const submitChangeRequestChanges = async (id: number, comment: string) => {
    try {
        const response = await client.post<ChangeRequestDto>(`api/change-requests/${id}/submit`, {
            comment,
        })
        return getChangeRequestFromDto(response)
    } catch (error: any) {
        ComposedAjaxError.handleError('Change request not submitted', error)
    }
}

export const approveChangeRequestChanges = async (id: number, comment: string) => {
    try {
        const response = await client.post<ChangeRequestDto>(`api/change-requests/${id}/approve`, {
            comment,
        })
        return getChangeRequestFromDto(response)
    } catch (error: any) {
        ComposedAjaxError.handleError('Change request not approved', error)
    }
}

export const revokeSubmittedChangeRequest = async (id: number, comment: string) => {
    try {
        const response = await client.post<ChangeRequestDto>(`api/change-requests/${id}/revoke`, {
            comment,
        })
        return getChangeRequestFromDto(response)
    } catch (error: any) {
        ComposedAjaxError.handleError('Change request not revoked', error)
    }
}

export const reassignSubmittedChangeRequest = async (id: number, comment: string, newOwnerId: string) => {
    try {
        const response = await client.post<ChangeRequestDto>(`api/change-requests/${id}/reassign`, {
            comment,
            new_owner_id: newOwnerId,
        })
        return getChangeRequestFromDto(response)
    } catch (error: any) {
        ComposedAjaxError.handleError('Change request not reassigned', error)
    }
}

export const adjustSubmittedChangeRequest = async (id: number, comment: string) => {
    try {
        const response = await client.post<ChangeRequestDto>(`api/change-requests/${id}/request-adjustment`, {
            comment,
        })
        return getChangeRequestFromDto(response)
    } catch (error: any) {
        ComposedAjaxError.handleError('Cannot sent to adjust requestments', error)
    }
}
