import { Button, Ref } from 'core'
import { SnackbarKey, useSnackbar } from 'notistack'
import React, { FC, forwardRef, useCallback, useMemo, useState } from 'react'
import { DialogueModal } from '../../utils/notifications.types'
import { CloseButton } from '../CloseButton'
import { ButtonContainers, NotificationsRoot, TextLine } from './NotificationModal.styled'
import Checkbox from '@mui/material/Checkbox'
import { FormControlLabel, Typography } from '@mui/material'

interface NotificationProps {
    modal: DialogueModal
    id: SnackbarKey
}

export const NotificationModal: FC<NotificationProps> = forwardRef(
    ({ modal, id }: NotificationProps, ref: Ref) => {
        const [disableModal, setDisableModal] = useState<boolean>(false)
        const { closeSnackbar } = useSnackbar()

        const { onClick, message, canBeHidden, type, greenButtonMessage, redButtonMessage, onSave } = modal
        const cancelModal = useCallback(() => {
            if (onSave) {
                onSave()
            }
            closeSnackbar(id)
        }, [onSave, message])

        const showAgain = useMemo(() => {
            if (!canBeHidden) {
                return
            }

            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            name="availableOnly"
                            checked={disableModal}
                            size="small"
                            onChange={(e) => setDisableModal(e.target.checked)}
                        />
                    }
                    label="Do not show this message again"
                />
            )
        }, [canBeHidden, disableModal])

        const handle = useCallback(() => {
            if (disableModal) {
                localStorage.setItem(type, 'true')
            }

            onClick()
            closeSnackbar(id)
        }, [onClick, disableModal])

        return (
            <NotificationsRoot ref={ref}>
                <TextLine>
                    <Typography variant="h4">{message}</Typography>
                    <CloseButton keyId={id} />
                </TextLine>
                {showAgain}
                <ButtonContainers>
                    <Button
                        data-automation-id="button-notification-modal-onCancel"
                        variant="contained"
                        onClick={cancelModal}
                        sx={{
                            marginRight: '12px',
                        }}
                    >
                        {redButtonMessage}
                    </Button>
                    <Button
                        data-automation-id="button-notification-modal-onSubmit"
                        variant="outlined"
                        onClick={handle}
                    >
                        {greenButtonMessage}
                    </Button>
                </ButtonContainers>
            </NotificationsRoot>
        )
    }
)
