import ErrorBoundary from '../../components/ErrorBoundary'
import { ReactFlowGraph, SkillsGraph, TaxonomyContextProvider } from '../../taxonomy'
import { ReactFlowProvider } from 'react-flow-renderer'
import React from 'react'
import { withAppLayout } from '../../parts/AppLayout/AppLayout'

export const TaxonomyPage: React.FC = withAppLayout(
    () => {
        return (
            <TaxonomyContextProvider workMode={2}>
                <ReactFlowProvider>
                    <ErrorBoundary>
                        <SkillsGraph>
                            <ReactFlowGraph />
                        </SkillsGraph>
                    </ErrorBoundary>
                </ReactFlowProvider>
            </TaxonomyContextProvider>
        )
    },
    { wideView: true }
)
