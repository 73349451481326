import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchExtraUserInfo, fetchUser } from '../../shared/requests'
import { UserProfile } from '../../../utils/types/auth'
import { RootState } from '../../store'

interface Thunk {
    rejectValue: string
    state: RootState
}

export const getProfile = createAsyncThunk<UserProfile, string, Thunk>(
    'profile/getProfile',
    async (id, { rejectWithValue, getState }) => {
        try {
            const user = getState().auth.user

            if (user && user.user_id === id) {
                return user
            }

            return await fetchUser(id)
        } catch (ex: any) {
            return rejectWithValue('No user found')
        }
    }
)

export const getExtraProfileInfo = createAsyncThunk(
    'profile/getExtraProfileInfo',
    async (id: string, { rejectWithValue }) => {
        try {
            const additionalInfo = await fetchExtraUserInfo(id)

            return additionalInfo.userByGid[0]
        } catch (e) {
            return rejectWithValue('No user found')
        }
    }
)
