import React, { FC } from 'react'
import { useSearchContext } from '../../context/SearchContext'
import Checkbox from '@mui/material/Checkbox'
import { Box, FormControlLabel } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { SWrapper } from './styled'
import { TextField } from '../../../core'
import { FilterIcon } from '../../../images'
import { debounce } from 'lodash'

export const AdditionalFilters: FC = (props: any) => {
    const { searchString, setSearchString, availableOnly, setAvailableOnly, onBench, setOnBench } =
        useSearchContext()

    const handleChange = debounce((e) => {
        setSearchString(e.target?.value)
    }, 500)

    return (
        <SWrapper {...props}>
            <Box component="div" sx={{ width: '360px' }}>
                <TextField
                    id="search-input"
                    value={searchString}
                    placeholder="Filter employees"
                    size="small"
                    variant="standard"
                    fullWidth={true}
                    InputProps={{
                        startAdornment: <FilterIcon />,
                        disableUnderline: true,
                    }}
                    sx={{ paddingLeft: '9px' }}
                    clearable={true}
                    onChange={handleChange}
                />
            </Box>

            <Box component="div">
                <FormControl sx={{ flexDirection: 'row' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="availableOnly"
                                checked={availableOnly}
                                onChange={(e) => setAvailableOnly(e.target.checked)}
                                size="small"
                            />
                        }
                        label={'Available only'}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="splitByBench"
                                checked={onBench}
                                onChange={(e) => setOnBench(e.target.checked)}
                                size="small"
                            />
                        }
                        label={'Split by bench'}
                    />
                </FormControl>
            </Box>
        </SWrapper>
    )
}
