import React, { createContext, useContext, useEffect, useMemo, useState, createRef } from 'react'
import { Node, ReactFlowInstance } from 'react-flow-renderer'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { userSkillsSelector } from 'store/selectors/auth-selectors'
import { UserProfile } from 'utils/types/auth'
import { getActiveChangeRequest, getSkillsTreeNodes, getSkillsTreeShortcuts } from '../api/requests'
import { ActionType, ChangeRequest, FlowNode, NodeData, TaxonomyContextValue } from '../types'
import {
    compareNodes,
    getLayoutedElements,
    isNodeOpen,
    mapToFlowNodes,
    recursiveCollapse,
    recursiveExpand,
    resetViewport,
    showEdges,
} from '../utils/nodes'
import { useNotistackWrapper } from 'notifications'

interface Props {
    children: React.ReactNode
    workMode: number
}

const TaxonomyContext = createContext<TaxonomyContextValue | null>(null)

/*
            nodes - all existing nodes with archived nodes and shortcuts,
            edges - all connections between nodes (arrows),
            visibleNodes - nodes which user see in Graph. Depends of flowNodes,
            reactFlowNodesMap - updating nodes. Depends of actions with nodes,
            flowNodes - nodes after compare. Depends of reactFlowNodes,
            action - action with nodes: rename, editing, etc,
            isLoading - bool are showed complete load of fetchedActiveChangeRequest and fetchedNodes,
            selectedNodeId - active node id (when user opens ActionDialog of node),
            selectedNode - active node as above,
            categories - array of categories - the highest level nodes,
            menuAnchor -  ???,
            changeRequest - change request in draft (active),
            isShowArchived - bool switches showing archived node (by default = false),
            fetchedActiveChangeRequest - all change requests from DB,
            countSkillsInNode - object where key = node id, value = count of children which have a seniority_level
            renderCount - update when  skills re-render in CustomWizardNode
*/

export const TaxonomyContextProvider: React.FC<Props> = ({ children, workMode }) => {
    const {
        refetch: nodeRefetch,
        isLoading: isLoadingNodes,
        data: fetchedNodes,
    } = useQuery('fetchNodes', () => getSkillsTreeNodes(), {
        refetchOnWindowFocus: false,
    })
    const {
        refetch,
        isLoading: isLoadingActiveCr,
        isRefetching: isRefetchingActiveCr,
        data: fetchedActiveChangeRequest,
    } = useQuery('fetchCRs', () => getActiveChangeRequest(workMode), {
        refetchOnWindowFocus: false,
    })
    const {
        refetch: shortcutRefetch,
        data: fetchedShortcuts,
        isLoading: isLoadingShortcuts,
    } = useQuery('fetchShortcuts', () => getSkillsTreeShortcuts(), {
        refetchOnWindowFocus: false,
    })

    const user = useSelector<RootState, UserProfile | undefined>((state) => state.auth.user)
    const skills = useSelector(userSkillsSelector)
    const { enqueueError } = useNotistackWrapper()

    const [action, setAction] = useState<ActionType | null>(null)
    const [menuAnchor, setMenuAnchor] = useState<(EventTarget & Element) | null>(null)
    const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null)
    const [reactFlowNodesMap, setReactFlowNodesMap] = useState<Record<string, FlowNode>>({})
    const [changeRequest, setChangeRequest] = useState<ChangeRequest | null>(null)
    const [isShowArchived, setIsShowArchived] = useState<boolean>(false)
    const [isUserSkillsAdded, setIsUserSkillsAdded] = useState(false)
    const [isActionInProgress, setIsActionInProgress] = useState(false)
    const [renderCount, setRenderCount] = useState(0)
    const [countSkillsInNode, setCountSkillsInNode] = useState<Record<string, number>>({})

    const flowNodes = useMemo(
        () =>
            Object.values(reactFlowNodesMap)
                .sort(compareNodes)
                .map((item) => ({ ...item, ref: !item.ref ? createRef() : item.ref })),
        [reactFlowNodesMap]
    )
    const categories = useMemo(() => flowNodes.filter((node) => node.data.isCategory), [flowNodes])
    const isLoading = useMemo(
        () => isLoadingActiveCr || isLoadingNodes || isLoadingShortcuts,
        [isLoadingActiveCr, isLoadingNodes, isLoadingShortcuts]
    )
    const fetchedActiveCR = useMemo(() => {
        if (!fetchedActiveChangeRequest) return null
        return fetchedActiveChangeRequest[0]
    }, [fetchedActiveChangeRequest])
    const selectedNode = useMemo(
        () => flowNodes.find((elem) => elem.id === selectedNodeId),
        [flowNodes, selectedNodeId]
    )
    const noAnotherActiveCR =
        !fetchedActiveCR || (fetchedActiveCR && fetchedActiveCR?.owner.id === user?.user_id)
    const alertActiveCR = () =>
        user?.role === 'adm'
            ? enqueueError(`${fetchedActiveCR?.owner.name} has an active ChangeRequest`)
            : enqueueError('There is another active ChangeRequest')

    useEffect(() => {
        // if fetchedActiveChangeRequest and fetchedNodes is load, reactFlowNodesMap updated
        if (isLoading || !fetchedNodes || !fetchedShortcuts) return
        const nodesMap = mapToFlowNodes(fetchedNodes, fetchedShortcuts, fetchedActiveCR?.items)
        setReactFlowNodesMap(nodesMap)
    }, [isLoading])

    useEffect(() => {
        // if user discard changeRequest reactFlowNodesMap updated
        if (!fetchedActiveCR && changeRequest && fetchedNodes && fetchedShortcuts) {
            const nodesMap = mapToFlowNodes(fetchedNodes, fetchedShortcuts, [])
            setReactFlowNodesMap(nodesMap)
        }
    }, [fetchedActiveCR?.id, changeRequest?.id])

    useEffect(() => {
        // ChangeRequest updates after fetchedActiveCR and reactFlowNodesMap
        setChangeRequest(fetchedActiveCR)
    }, [fetchedActiveCR])

    const toggleNodeOpen = (node: FlowNode) => {
        // TODO on node shortcut click expand the specific branch up to its main position
        if (isNodeOpen(node)) {
            recursiveCollapse(node)
        } else {
            node.data.isOpen = true
            node.data.children.forEach((n) => {
                n.hidden = n.data.isArchived && !isShowArchived
            })
        }
        setReactFlowNodesMap({ ...reactFlowNodesMap })
    }

    const recursiveExpandUpdate = (node: FlowNode) => {
        recursiveExpand(node, isShowArchived)
        setReactFlowNodesMap({ ...reactFlowNodesMap })
    }
    const handleExpandAll = (instance?: ReactFlowInstance) => {
        setReactFlowNodesMap((prevState) => {
            const flowNodeCopy = { ...prevState }
            for (const key in flowNodeCopy) {
                flowNodeCopy[key].data.isOpen = true
                flowNodeCopy[key].hidden = flowNodeCopy[key].data.isArchived && !isShowArchived
            }
            return flowNodeCopy
        })
        if (instance) resetViewport(instance)
    }

    const handleCollapseAll = (instance?: ReactFlowInstance) => {
        setReactFlowNodesMap((prevState) => {
            const flownodeCopy = { ...prevState }
            for (const key in flownodeCopy) {
                flownodeCopy[key].hidden = flownodeCopy[key].type !== 'input'
                flownodeCopy[key].data.isOpen = false
            }
            return flownodeCopy
        })
        if (instance) resetViewport(instance)
    }

    const showActionDialog = (id: string, action: ActionType) => {
        setAction(action)
        setSelectedNodeId(id)
    }

    const hideActionDialog = () => {
        setAction(null)
        setSelectedNodeId(null)
    }

    const toggleNodeActionMenu = (element: Element, node: FlowNode) => {
        if (noAnotherActiveCR) {
            setSelectedNodeId(node.id)
            setMenuAnchor(menuAnchor ? null : element)
        } else {
            alertActiveCR()
        }
    }

    const handleRootCreation = () => {
        if (noAnotherActiveCR) {
            setAction(ActionType.CREATE_ROOT_ITEM)
        } else {
            alertActiveCR()
        }
    }

    const hideMenu = () => {
        setMenuAnchor(null)
    }

    const handleVisibleArchived = () => {
        setIsShowArchived((prev) => !prev)
        setReactFlowNodesMap((prevState) => {
            const flownodeCopy = { ...prevState }
            for (const key in flownodeCopy) {
                if (flownodeCopy[key].data.isArchived) {
                    if (flownodeCopy[key].data.parent?.data.isOpen || flownodeCopy[key].data.parent === null)
                        flownodeCopy[key].hidden = isShowArchived
                }
            }
            return flownodeCopy
        })
    }

    const updateCountUserSkills = () => {
        const newSkillCount: Record<string, number> = {}

        for (let id in reactFlowNodesMap) {
            let currentNode: Node<NodeData> | null | undefined = reactFlowNodesMap[id]

            if (!currentNode.data.isCategory) {
                const skillLevel = skills.find(
                    (skill: { node_id: string | undefined }) => skill.node_id === currentNode?.data.id
                )?.seniority_level
                if (skillLevel) {
                    while (true) {
                        currentNode = currentNode?.data?.parent
                        if (!currentNode) break
                        if (!(currentNode.id in newSkillCount)) newSkillCount[currentNode.id] = 0
                        newSkillCount[currentNode.id]++
                    }
                }
            }
        }
        setCountSkillsInNode(newSkillCount)
    }

    const updateNodesView = () => {
        setReactFlowNodesMap({ ...reactFlowNodesMap })
    }

    const focusNode = (node: FlowNode, instance: ReactFlowInstance) => {
        instance.setCenter(reactFlowNodesMap[node.id].position.x, reactFlowNodesMap[node.id].position.y, {
            duration: 400,
            zoom: 1,
        })
    }

    const сhildToParentExpand = (node: FlowNode, isShowArchived: boolean) => {
        let parent = node.data.parent
        parent?.data.children.forEach((child: FlowNode) => {
            child.data.isOpen = true
            child.hidden = child.data.isArchived && !isShowArchived
        })
        while (parent) {
            parent.data.isOpen = true
            parent.hidden = parent.data.isArchived && !isShowArchived
            parent = parent?.data.parent
        }
    }

    const visibleNodes = useMemo(() => {
        if (workMode === 1 && !isUserSkillsAdded) {
            if (user?.skills && flowNodes.length > 0) {
                user.skills.forEach((userNode) => {
                    const node = flowNodes.find((elem) => elem.id === userNode.node_id)
                    if (node) сhildToParentExpand(node, true)
                })
                setIsUserSkillsAdded(true)
            }
        }

        return flowNodes.filter((node) => !node.hidden && (!node.data.isArchived || isShowArchived))
    }, [flowNodes, isUserSkillsAdded])

    const { nodes, edges } = useMemo(
        () => getLayoutedElements(visibleNodes, showEdges(visibleNodes), workMode),
        [visibleNodes]
    )

    const value = useMemo(
        (): TaxonomyContextValue => ({
            nodes,
            edges,
            visibleNodes,
            reactFlowNodesMap,
            flowNodes,
            action,
            isLoading,
            сhildToParentExpand,
            updateNodesView,
            isRefetchingActiveCr,
            isActionInProgress,
            setIsActionInProgress,
            selectedNodeId,
            selectedNode,
            categories,
            menuAnchor,
            changeRequest,
            isShowArchived,
            fetchedActiveChangeRequest,
            countSkillsInNode,
            renderCount,
            handleRootCreation,
            toggleNodeOpen,
            focusNode,
            recursiveExpandUpdate,
            setReactFlowNodesMap,
            handleExpandAll,
            handleCollapseAll,
            showActionDialog,
            hideActionDialog,
            toggleNodeActionMenu,
            hideMenu,
            setAction,
            handleVisibleArchived,
            setIsShowArchived,
            nodeRefetch,
            shortcutRefetch,
            refetch,
            updateCountUserSkills,
            setRenderCount,
        }),
        [
            nodes,
            edges,
            visibleNodes,
            reactFlowNodesMap,
            flowNodes,
            action,
            isLoading,
            сhildToParentExpand,
            updateNodesView,
            isActionInProgress,
            setIsActionInProgress,
            isRefetchingActiveCr,
            selectedNodeId,
            selectedNode,
            categories,
            menuAnchor,
            changeRequest,
            isShowArchived,
            fetchedActiveChangeRequest,
            countSkillsInNode,
            renderCount,
        ]
    )

    return <TaxonomyContext.Provider value={value}>{children}</TaxonomyContext.Provider>
}

export const useTaxonomyContext = (): TaxonomyContextValue => {
    const value = useContext(TaxonomyContext)
    if (!value) {
        throw new Error('useTaxonomyContext should be used within TaxonomyContextProvider')
    }
    return value
}
