import { styled } from 'core'
import { NotificationColors } from 'utils/theme/colors'

export const NotificationsRoot = styled('div')`
    display: flex;
    flex-direction: column;
    width: 428px;
    left: 32px;
    top: 24px;
    border-radius: 2px;
    padding: 24px 16px 24px 32px;
    box-sizing: border-box;
    opacity: 0.8;
    backdrop-filter: blur(4px);
    background: ${NotificationColors.GREEN};
    border: 1px solid ${NotificationColors.GREEN_BORDER};
`
export const TextLine = styled('div')`
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-bottom: 8px;
    fill: ${NotificationColors.CLOSE_GREEN};
`
export const ButtonContainers = styled('div')`
    margin-top: 16px;
    display: flex;
`
