import { Button, styled } from 'core'

export const ControlsWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    margin-bottom: 11px;
`

export const ButtonSaveLoadPreset = styled(Button)`
    &.MuiButton-sizeMedium {
        font-size: 14px;
        line-height: 20px;
        padding: 6px 8px;
    }
`
