import { styled } from 'core'
import { lightGreen } from 'utils/theme/colors'

export const Root = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
`
export const Availability = styled('span')`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
`
export const Circle = styled('div')`
    display: flex;
    width: 16px;
    height: 16px;
    background-color: ${lightGreen};
    border-radius: 50%;
`
