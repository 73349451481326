import { styled } from 'core'

export const Input = styled('input')({
    display: 'none',
})

export interface LabelProps {
    htmlFor: string
}

export const Label = styled('label')<LabelProps>({
    cursor: 'pointer',
})

// export const ButtonWrapper = styled(Button)`
//     &.MuiButton-sizeMedium {
//         line-height: 20px;
//         font-weight: 700;
//     }
//     &.MuiButton-outlined {
//         fill: ${TypographyColors.DARK_CYAN};
//         &.MuiButton-sizeMedium {
//             font-size: 14px;
//             color: ${TypographyColors.DARK_CYAN};
//             border: 1px solid ${grey7};
//             padding: 6px 12px;
//         }
//         &:hover {
//             border: 1px solid ${TypographyColors.DARK_CYAN};
//             fill: ${TypographyColors.DARK_CYAN};
//         }
//     }
// `
