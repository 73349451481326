import { DeploymentEnv, EnvConfig } from 'core'

declare global {
    interface Window {
        _env_: {
            REACT_APP_MICROSOFT_CLIENT_ID?: string
            REACT_APP_API?: string
            REACT_APP_ENV?: DeploymentEnv
        }
    }
}

const getEnvConfig = (): EnvConfig => {
    if (process.env.NODE_ENV === 'development') {
        return {
            baseUrl: process.env.REACT_APP_API ?? '',
            env: 'development',
            microsoftClientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID ?? '',
        }
    }
    return {
        baseUrl: window._env_.REACT_APP_API ?? '',
        env: window._env_.REACT_APP_ENV ?? 'development',
        microsoftClientId: window._env_.REACT_APP_MICROSOFT_CLIENT_ID ?? '',
    }
}

export const envConfig = getEnvConfig()
