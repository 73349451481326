import { history } from 'app/store'
import { Router } from 'core'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider, QueryClient } from 'react-query'
import { Provider } from 'react-redux'
import 'typeface-roboto'
import WebFont from 'webfontloader'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import { store } from './store/store'
import { quietLogsInProdEnv } from './utils/functions/global'

quietLogsInProdEnv()

const queryClient = new QueryClient()

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')

const root = createRoot(rootElement)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router history={history}>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </Router>
        </Provider>
    </React.StrictMode>
)

WebFont.load({
    google: {
        families: ['Roboto'],
    },
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
