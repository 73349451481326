import { client } from '../../axiosClient'
import { badFaithFactor } from '../../utils/helpers/constants'
import { UsersWithLoad } from '../../utils/types/search'
import { Entity, ResponseData } from '../../utils/types/common'
import { Employee } from '../../utils/types/employee'
import { Competence, Skill } from '../../utils/types/skills'
import { getSkillsSum } from '../../utils/functions/common'
import { UserProfile } from '../../utils/types/auth'
import { AdditionalInfo, ContributionData } from '../../utils/types/profile'
import { User } from '../../utils/types/users'

export interface FoundSkill extends Entity {
    category_id: number
    competency_id: number
}

interface Version {
    version: string
}

interface Secure {
    is_secure: boolean
}

const GET_ADDITIONAL_INFO = (gid: string) =>
    `{userByGid (gids: "${gid}") {\n` +
    'certificates\n skills\n position\n availability\n solidLineManager\n isExternalConsultant\n nearestAvailability\n nearestAvailabilityDay\n' +
    '}}'

const GET_LOADS_QUERY = (gids: string) =>
    `{userByGid(gids:${gids}){\n` +
    ' globalId\n' +
    '    totalLoad\n' +
    '    availability\n' +
    '    nearestAvailability\n' +
    '    nearestAvailabilityDay\n' +
    '  }}'

function fetchExtraUserInfo(id: string): Promise<AdditionalInfo> {
    return client.post<AdditionalInfo>('/api/staffing/proxy', {
        query: GET_ADDITIONAL_INFO(id),
    })
}

function fetchCompetencies(): Promise<ResponseData<Competence>> {
    return client.get<ResponseData<Competence>>('/api/competencies')
}

function fetchCategories(): Promise<ResponseData<Entity>> {
    return client.get<ResponseData<Entity>>('/api/categories')
}

function fetchAllUsers(): Promise<ResponseData<User>> {
    return client.get<ResponseData<User>>('/api/users')
}

function fetchAllUsersWithCreateCrPermission(): Promise<ResponseData<User>> {
    return client.get<ResponseData<User>>('api/actions/cuacr/users')
}

function getSkillsInCompetencies(competencies: string[]): Promise<ResponseData<Skill>> {
    const search = new URLSearchParams(competencies.map((c) => ['competency_ids', c]))
    const url = `/api/competencies/skills?${search.toString()}`
    return client.get<ResponseData<Skill>>(url)
}

function getSkillsInCategoryInCompetence(
    competenceId: number | undefined,
    categoryId: number | undefined
): Promise<ResponseData<Skill>> {
    return client.get<ResponseData<Skill>>(`/api/skills/competency/${competenceId}/category/${categoryId}`)
}

function sendContributionRequest(data: ContributionData): Promise<any> {
    return client.post(`/api/contribute`, data)
}

function fetchSkills(skillName: string = ''): Promise<ResponseData<FoundSkill>> {
    return client.get<ResponseData<FoundSkill>>(`/api/skills?q=${skillName}`)
}

function fetchUser(id: string): Promise<UserProfile> {
    return client.get(`api/users/${id}`)
}

function fetchAppVersion(): Promise<Version> {
    return client.get<Version>('/api/version')
}

function fetchIsSecured(): Promise<Secure> {
    return client.get<Secure>('/api/secured')
}

function getFetchedEmployeesLoadsThroughProxy(gids: string[]): Promise<UsersWithLoad> {
    return client.post('/api/staffing/proxy', {
        query: GET_LOADS_QUERY(JSON.stringify(gids)),
    })
}

function fetchEmployees(): Promise<ResponseData<Employee>> {
    return client.get<ResponseData<Employee>>('/api/users/admin')
}

export {
    fetchUser,
    fetchCompetencies,
    fetchCategories,
    fetchExtraUserInfo,
    getSkillsInCompetencies,
    getSkillsInCategoryInCompetence,
    sendContributionRequest,
    fetchSkills,
    fetchAppVersion,
    fetchEmployees,
    fetchAllUsers,
    fetchIsSecured,
    getFetchedEmployeesLoadsThroughProxy,
    fetchAllUsersWithCreateCrPermission,
}

export async function getBadFaithFactor() {
    const res = await fetchEmployees()
    const sum = getSkillsSum(res.data)
    localStorage.setItem(badFaithFactor, JSON.stringify(sum / res.data.length))
}
