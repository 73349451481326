import { LoaderWrapper } from '../../utils/functions/presenters'
import ErrorBoundary from '../../components/ErrorBoundary'
import { RoleSubscreen } from '../../parts/RoleSubscreen'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../store/store'
import { AdminState } from '../../store/slices/admin'
import { composeRoles } from '../../store/slices/admin/requests'
import { withAppLayout } from '../../parts/AppLayout/AppLayout'

export const RolesManagementPage = withAppLayout(() => {
    const dispatch = useAppDispatch()
    const admin = useSelector<RootState, AdminState>((state) => state.admin)
    useEffect(() => {
        dispatch(composeRoles())
    }, [])

    const isLoadingRoleData = admin.hasValidRolesData === null

    return (
        <LoaderWrapper isLoading={isLoadingRoleData} hasNoContent={admin.hasValidRolesData === false}>
            <ErrorBoundary>
                <RoleSubscreen />
            </ErrorBoundary>
        </LoaderWrapper>
    )
})
