import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/store'
import { userIdSelector, userSkillsSelector } from 'store/selectors/auth-selectors'

const selectSlice = (state: RootState) => state.profile

export const editingSkillsSelector = createSelector(selectSlice, (profile) => profile.editingSkills)

export const profileIdSelector = createSelector(selectSlice, (profile) => profile.profile?.user_id ?? '')

export const profileSkillsSelector = createSelector(selectSlice, (profile) => profile.profile?.skills ?? [])

export const isOwnProfileSelector = createSelector(
    [userIdSelector, profileIdSelector],
    (userId, profileId) => profileId.toLowerCase() === userId.toLowerCase()
)

export const actualSkillsSelector = createSelector(
    [profileSkillsSelector, userSkillsSelector, isOwnProfileSelector],
    (profileSkills, userSkills, isOwnProfile) => (isOwnProfile ? userSkills : profileSkills || [])
)
