import { Box } from '@mui/material'
import { styled } from 'core'
import { deepBlue, transparent } from 'utils/theme/colors'
import { FallBackProps, LazyImageWrapper } from 'utils/types/styling'

export const CommonWrapper = styled(Box)<LazyImageWrapper>`
    position: relative;
    border-radius: 50%;
    border: ${({ isInterviewer, withoutBorder }) =>
        withoutBorder ? 'none' : isInterviewer ? `4px solid ${deepBlue};` : `4px solid ${transparent};`};
    display: flex;
`

export const FallbackImageWrapper = styled('span', {
    shouldForwardProp: (prop: string) => prop !== 'borderRadius',
})<FallBackProps>`
    border-radius: ${(props) => props.borderRadius};
    ${({ width }) => `width: ${width}`};
    ${({ height }) => `height:${height}`};
`
export const IndicatorWrapper = styled('div')`
    position: absolute;
    bottom: 0;
    right: 1%;
`
