import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SortingOptions } from 'utils/enums/different'
import { filterDataFromInvalidValues } from 'utils/functions/common'
import { OrderState, Role } from 'utils/types/admin'
import { Action } from 'utils/types/common'
import { Employee } from 'utils/types/employee'

export interface AdminState {
    hasValidEmployeeData: boolean | null
    employees: Employee[]
    hasValidAdminTreeData: boolean | null
    roles: Role[]
    actions: Action[]
    hasValidRolesData: boolean | null
    dropdownOptions: string | string[]
    order: OrderState
}

const initialState: AdminState = {
    hasValidEmployeeData: null,
    employees: [],
    hasValidAdminTreeData: null,
    roles: [],
    actions: [],
    hasValidRolesData: null,
    dropdownOptions: '',
    order: { column: '', direction: SortingOptions.desc },
}

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setHasValidEmployeeData(state, action: PayloadAction<boolean | null>) {
            state.hasValidEmployeeData = action.payload
        },
        setEmployees(state, action: PayloadAction<Employee[]>) {
            state.employees = filterDataFromInvalidValues(action.payload)
        },
        clearEmployees(state) {
            state.employees = []
        },
        updateEmployeeBlockStatus(state, action: PayloadAction<string>) {
            const targetEmployeeIndex = state.employees.findIndex(
                (employee: Employee) => employee.employee_id === action.payload
            )
            if (targetEmployeeIndex !== -1)
                state.employees[targetEmployeeIndex].is_blocked =
                    !state.employees[targetEmployeeIndex].is_blocked
        },
        setHasValidAdminTreeData(state, action: PayloadAction<boolean | null>) {
            state.hasValidAdminTreeData = action.payload
        },
        setRoles(state, action: PayloadAction<Role[]>) {
            state.roles = action.payload
        },
        setActions(state, action: PayloadAction<Action[]>) {
            state.actions = action.payload
        },
        setHasValidRolesData(state, action: PayloadAction<boolean | null>) {
            state.hasValidRolesData = action.payload
        },
        setDropdownOptions(state, action: PayloadAction<string | string[]>) {
            state.dropdownOptions = action.payload
        },
        resetDropdownOptions(state) {
            state.dropdownOptions = ''
        },
        setOrder(state, actions: PayloadAction<OrderState>) {
            state.order = actions.payload
        },
        resetOrder(state) {
            state.order = { column: '', direction: SortingOptions.desc }
        },
    },
})

export const {
    setHasValidEmployeeData,
    setEmployees,
    clearEmployees,
    updateEmployeeBlockStatus,
    setHasValidAdminTreeData,
    setRoles,
    setActions,
    setHasValidRolesData,
    setDropdownOptions,
    resetDropdownOptions,
    setOrder,
    resetOrder,
} = adminSlice.actions

export default adminSlice.reducer
