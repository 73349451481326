import { Box } from '@mui/material'
import { styled } from 'core'
import { bluePrimary, separatorGrey } from 'utils/theme/colors'
import { smallMediumFont } from 'utils/theme/fontStyles'

export const ExpandButton = styled('div')`
    display: flex;
    box-sizing: border-box;
    padding: 6px;
    align-items: flex-start;
    height: fit-content;
    margin: 10px 0;
`

export const CompetencyContainer = styled('div')`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${separatorGrey};
    width: auto;
    height: auto;
    box-sizing: border-box;
    padding: 5px 0 8px 0;
    flex-grow: 1;
    :last-child {
        border-bottom: none;
    }
`

export const CompetencyName = styled('div')`
    ${smallMediumFont};
    line-height: 20px;
    font-size: 11px;
    height: 24px;
    width: 192px;
    display: flex;
    text-align: center;
    align-items: center;
    margin: 4px 0;
`

export const SkillPath = styled('div')`
    display: flex;
    align-items: center;
    width: 239px;
    padding: 16px 0px 13px 0px;
    white-space: nowrap;

    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;

    color: ${bluePrimary};
`

export const SkillsPart = styled('div')`
    display: flex;
    flex-direction: row;
    min-width: 672px;
`

interface SkillsContainerProp {
    isExpanded?: boolean
}

export const SkillsContainer = styled(Box)<SkillsContainerProp>`
    overflow-y: hidden;
    white-space: break-spaces;
    width: 648px;
    flex-grow: 1;
    align-items: center;
    box-sizing: border-box;
    height: ${({ isExpanded }) => (isExpanded ? 'fit-content' : '40px')};
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`

export const ButtonContainer = styled('div')`
    margin-left: 10px;
    cursor: pointer;
`

export const RowFlex = styled('div')`
    display: flex;
    height: 16px;
`
