import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/store'
import { useNotistackWrapper } from '../../hooks/useSnackbarWrapper'
import {
    removeDialoguesNotification,
    removeErrorNotification,
    removeInfoNotification,
} from '../../slice/notifications.slice'
import { NotificationsState } from '../../utils/notifications.types'

export const Notifier: FC = () => {
    const { enqueueError, enqueueSuccess, enqueueModal } = useNotistackWrapper()
    const dispatch = useAppDispatch()
    const notificationsData = useSelector<RootState, NotificationsState>((state) => state.notifications)
    useEffect(() => {
        notificationsData.errors.forEach((error) => {
            enqueueError(error)
            dispatch(removeErrorNotification(error))
        })
        notificationsData.infos.forEach((info) => {
            enqueueSuccess(info)
            dispatch(removeInfoNotification(info))
        })
        notificationsData.dialogues.forEach((dialogue) => {
            enqueueModal(dialogue)
            dispatch(removeDialoguesNotification(dialogue))
        })
    }, [
        notificationsData.errors,
        notificationsData.infos,
        notificationsData.dialogues,
        enqueueError,
        enqueueSuccess,
        enqueueModal,
        dispatch,
    ])
    return null
}
