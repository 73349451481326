import { styled } from 'core'

export const SWrapper = styled('div')`
    height: 100%;
    background-color: white;
`

export const SContainerWrapper = styled('div')`
    display: flex;
    flex-grow: 1;
    gap: 20px;
    background-color: white;
    margin: 0 auto;
    padding: 24px;
    flex-direction: column;
    width: 1200px;
    height: 100%;
    overflow: auto;
    min-height: 100px;
    box-sizing: border-box;

    ::-webkit-scrollbar-track {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: #c6ced8;
        border-radius: 10px;
    }
`
