import React from 'react'

export interface MenuContextProps {
    isMenuOpen: boolean
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
    handleMenuClose: () => void
    onMenuItemClick: () => void
}

export const MenuContext = React.createContext<MenuContextProps | null>(null)
