import React, { FC, ReactNode } from 'react'
import { useTaxonomyContext } from 'taxonomy/context/TaxonomyContext'
import ChangeRequestWindow from '../ChangeRequestWindow'
import { TaxonomyHeader } from '../TaxonomyHeader/TaxonomyHeader'
import { Main, SWrapper, SDelimeter } from './styled'

interface Props {
    children?: ReactNode
}

export const SkillsGraph: FC<Props> = ({ children }) => {
    const { changeRequest } = useTaxonomyContext()

    return (
        <SWrapper>
            <TaxonomyHeader />
            <SDelimeter />
            <Main>{children}</Main>
            {changeRequest && <ChangeRequestWindow />}
        </SWrapper>
    )
}
