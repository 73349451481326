import { ButtonComponentProps } from 'core/ui/Button/Button.styled'
import { ArrowUploadCv } from 'images'
import React, { FC, memo } from 'react'
import { Input, Label } from './UploadButton.styled'
import { Button } from '../../../../core'

export interface UploadButtonProps extends Omit<ButtonComponentProps, 'onInputChange'> {
    accept: string
    disabled: boolean
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const UploadButton: FC<UploadButtonProps> = memo(
    ({ accept, children, disabled, onInputChange, ...props }) => {
        return (
            <Label htmlFor={`${props.id}-input-file`}>
                <Input
                    id={`${props.id}-input-file`}
                    disabled={disabled}
                    type="file"
                    accept={accept}
                    onChange={onInputChange}
                />
                <Button sx={{ pointerEvents: 'none' }} {...props} startIcon={<ArrowUploadCv />}>
                    {children || 'Upload'}
                </Button>
            </Label>
        )
    }
)
