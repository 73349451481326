import { Fab } from '@mui/material'
import React, { memo } from 'react'
import { setIsContributeModalShowed } from 'store/slices/profile'
import { useAppDispatch } from 'store/store'
import { ButtonColors } from 'utils/theme/colors'

const ContributeButton = memo(() => {
    const dispatch = useAppDispatch()

    return (
        <>
            <Fab
                variant="extended"
                sx={{ position: 'fixed', bottom: 40, right: 40, backgroundColor: ButtonColors.WHITE }}
                onClick={() => dispatch(setIsContributeModalShowed(true))}
            >
                Contribute
            </Fab>
        </>
    )
})

export default ContributeButton
