import { PopoverOrigin } from '@mui/material'
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { MenuContextProps, MenuContext } from 'taxonomy/context/MenuContext'
import { MenuStyled } from './styled'

interface contextMenuProps {
    id: string
    menuAnchor: (EventTarget & Element) | null
    open: boolean
    onClose: () => void
    children: ReactNode
    transitionDuration?: number
    anchorOrigin?: PopoverOrigin | undefined
}

export const ContextMenu: FC<contextMenuProps> = ({
    id,
    menuAnchor,
    open,
    transitionDuration,
    children,
    onClose,
    anchorOrigin,
    ...props
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(open)

    useEffect(() => {
        setIsMenuOpen(open)
    }, [open])

    const hideMenu = () => {
        setIsMenuOpen(false)
        onClose()
    }

    const state: MenuContextProps = useMemo(
        () => ({
            isMenuOpen,
            setIsMenuOpen,
            handleMenuClose: () => {
                setIsMenuOpen(false)
            },
            onMenuItemClick: hideMenu,
        }),
        [isMenuOpen, setIsMenuOpen]
    )

    return (
        <MenuContext.Provider value={state}>
            <MenuStyled
                data-automation-id={`${id}-context-menu`}
                anchorEl={menuAnchor}
                open={isMenuOpen}
                onClose={hideMenu}
                anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                transitionDuration={transitionDuration !== undefined ? transitionDuration : 'auto'}
                {...props}
            >
                {children}
            </MenuStyled>
        </MenuContext.Provider>
    )
}

export default ContextMenu
