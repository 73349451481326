import { Box } from '@mui/material'
import { css, styled } from 'core'
import { ProfileType } from 'utils/types/profile'
import { CellProps, ModalRootProps, RowProps, TabProps, OverflowPositionProps } from 'utils/types/styling'
import {
    anthracite,
    black,
    deepBlue,
    deepGrey,
    disabledFeaturedBlue,
    featuredBlue,
    gainsboro,
    lightBlue,
    lightFeaturedBlue,
    lightGrey,
    transparent,
    white,
    yellow,
} from './colors'
import { RowFlex } from './styled'

export const CursorPointer = styled('div')`
    cursor: pointer;
`
export const pointer = css`
    cursor: pointer;
`
export const baseButtonStyles = css`
    background-color: ${featuredBlue};
    border-radius: 16px;
    cursor: pointer;
    height: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: ${white};
    transition: background 0.1s linear;
    border: none;

    &:focus {
        outline: none;
    }

    &[disabled] {
        background-color: ${disabledFeaturedBlue} !important;
        color: ${deepGrey};
        cursor: inherit;
    }

    &:hover {
        background-color: ${lightFeaturedBlue};
    }
`

export const HeaderContainer = styled('div')`
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${lightGrey};
`
export const Tab = styled(Box)<TabProps>`
    margin-left: ${({ marginLeft }) => marginLeft || '20px'};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    color: ${({ isActive }) => (isActive ? black : lightGrey)};
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
`
export const Root = styled('div')<OverflowPositionProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: ${({ hidden }) => (hidden ? 'hidden' : 'auto')};
`
export const SearchRoot = styled('div')`
    width: 225px;
    height: 40px;
    position: absolute;
    border: 1px solid ${lightGrey};
    box-sizing: border-box;
    border-radius: 20px;
    background: ${white};
    top: -6px;
    left: 200px;
`
export const IconsGroup = styled('div')`
    cursor: pointer;
    padding: 4px;
`
export const IconWrapper = styled('div')`
    display: flex;
    padding: 0 4px;
    align-content: center;
`
export const ModalRoot = styled('div')<ModalRootProps>`
    background-color: ${white};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    padding: ${({ padding }) => padding};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
`
export const CloseBtn = styled('div')`
    cursor: pointer;
`
export const ViewportModalHeader = styled('h1')`
    color: ${anthracite};
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const ViewportModalStep = styled(RowFlex)`
    font-size: 24px;
    font-weight: 700;
    color: ${anthracite};
    justify-content: space-between;
    align-items: center;
`
export const ButtonsWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 32px;
`
export const StealIconWrapper = styled('span')`
    &:hover {
        svg path {
            fill: ${featuredBlue} !important;
        }
    }
`
export const modalInput = css`
    width: 440px;
    border-radius: 5px;
    border: 1px solid ${lightGrey};
    box-sizing: border-box;
`
export const ViewportModalInput = styled('input')`
    ${modalInput};
    font-size: 18px;
    height: 40px;
    outline: none;

    &:focus {
        border: 2px solid ${featuredBlue};
        box-shadow: 0 0 10px 4px ${lightBlue};
    }
`
export const AdminEmployeesTableRoot = styled('div')`
    width: 100%;
    background: transparent;
    margin-top: 37px;
`
export const Row = styled(Box)<RowProps>`
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    align-items: center;
    position: relative;
    border-bottom: 1px solid ${gainsboro};
    padding-bottom: ${({ paddingBottom }) => paddingBottom && paddingBottom};
    color: ${({ shouldBeGreyed }) => shouldBeGreyed && lightGrey};
    border-left: ${({ userIsInterviewer }) => `4px solid ${userIsInterviewer ? deepBlue : transparent}`};
`
export const Cell = styled(Box)<CellProps>`
    color: ${anthracite};
    flex: ${({ width }) => (width ? `0 0 ${width}` : '0 0 240px')};
    font-weight: ${({ isBold }) => isBold && '700'};
    text-align: ${({ textAlign }) => textAlign && textAlign};
    font-size: ${({ fontSize }) => fontSize && fontSize};
    cursor: ${({ pointer }) => pointer && 'pointer'};
`
export const MatchLine = styled('p')`
    font-weight: 700;
`
export const MatchPercentageSpan = styled('span')`
    font-weight: 600;
    padding: 0 5px 0 5px;
    color: ${featuredBlue};
    min-width: 20px;
`
export const IconsContainer = styled(Box)<ProfileType>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 2px 5px 12px;
    background-color: ${({ isBadFaith }) => isBadFaith && yellow};
    border-radius: 4px;
`

export const Avatar = styled('div')`
    display: flex;
    align-items: center;
    margin: 0px 11px;
    cursor: pointer;
`

export const EmployeeName = styled('div')`
    margin-left: 12px;
    font-size: 16px;
`

export const AvatarNameWrapper = styled(Avatar)`
    align-items: center;
    cursor: pointer;
    padding-left: 12px;
`

interface MarginProps {
    margin: string
}

export const MarginContainer = styled('div')<MarginProps>`
    margin: ${({ margin }) => margin};
`

export interface SelectOption {
    value: number
    label: string
}
