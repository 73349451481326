import { FlowNode, FlowNodesMap, NodeData } from 'taxonomy/types'
import { NodeWithPath } from 'utils/types/skills'
import { Node as NodeReact } from 'react-flow-renderer/dist/esm/types/nodes'

const getCutString = (skillValueString: string): string | string => {
    // let stringWordsArray = skillValueString.split(' ')
    // if (stringWordsArray.length >= 3) {
    //     for (let i = 0; i < stringWordsArray.length; i++) {
    //         stringWordsArray[1] = ' ... '
    //     }
    //     let cutString = stringWordsArray.join('')
    //     return cutString
    // }

    return skillValueString
}

export const useNodeWithPath = (
    tree: FlowNodesMap,
    includeArchivedSkills?: boolean,
    excludeCategories?: boolean
) => {
    let nodeWithPath = Object.values(tree).reduce((acc: NodeWithPath[], value) => {
        if (!includeArchivedSkills && value.data.isArchived) return acc
        acc.push({
            node_id: value.data.id,
            name: getCutString(value.data.name),
            nodePath: getNodeWithPath(value).join(' • '),
            nodePathForAutoSuggest: getNodeWithPath(value).join(' • '),
            isCategory: value.data.isCategory,
        })

        return acc
    }, [])

    if (excludeCategories) return nodeWithPath.filter((node) => !node.isCategory)
    return nodeWithPath
}

export const getNodePath = (node: FlowNode): string | undefined => {
    let arr: string[] = []
    const getNameString = (current: FlowNode) => {
        if (!current.data.parent) {
            return
        } else {
            arr.push(current.data.parent.data.name)
            getNameString(current.data.parent)
        }
    }
    getNameString(node)
    return arr.reverse().join(' • ')
}

export const getNodeWithPath = (skill: NodeReact<NodeData>) => {
    let skillBreadCrumbArray: string[] = []

    let currentSkill: NodeReact<NodeData> | null | undefined = skill
    while (currentSkill) {
        if (currentSkill !== skill) {
            skillBreadCrumbArray.unshift(currentSkill?.data?.name)
        }
        currentSkill = currentSkill?.data?.parent
    }
    return skillBreadCrumbArray
}
