import { createAction } from '@reduxjs/toolkit'
import { Entity } from '../../utils/types/common'
import { SharedAction } from '../../utils/enums/different'
import { Competence } from '../../utils/types/skills'

export const addCategory = createAction<Entity>(SharedAction.AddCategory)
export const addCompetence = createAction<Competence>(SharedAction.AddCompetence)
export const updateUserActions = createAction<string[]>(SharedAction.UpdateUserActions)
export const clearProfile = createAction<void>(SharedAction.clearProfile)
export const deleteUserSkill = createAction<string>(SharedAction.DeleteUserSkill)
