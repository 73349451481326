import { useSelector } from 'react-redux'
import {
    canViewOtherProfiles,
    canViewRoleMatrix,
    canViewTaxonomy,
} from '../../store/selectors/role-selectors'
import React from 'react'
import { ProfilePage } from '../../pages/profile/ProfilePage'
import { SkillsTreePage } from '../../pages/skillsTree/SkillsTreePage'
import { EmployeesSearchPage } from '../../pages/employeesSearch/EmployeesSearchPage'
import { TaxonomyPage } from '../../pages/taxonomy/TaxonomyPage'
import { ChangeRequestsPage } from '../../pages/changeRequests/ChangeRequestsPage'
import { RolesManagementPage } from '../../pages/roles/RolesManagementPage'
import { Navigate, RouteObject } from 'react-router-dom'

export interface AppRoutesConfigItem extends RouteObject {
    id: string
    label: string
    condition?: () => boolean
    children?: AppRoutesConfigItem[]
}

export const useAppRoutesConfig = (redirectUrl: string) => {
    let hasEmployeesMenuItem = !!useSelector(canViewOtherProfiles)
    let hasTaxonomyMenuItem = !!useSelector(canViewTaxonomy)
    let hasRolesMenuItem = !!useSelector(canViewRoleMatrix)

    const routes: AppRoutesConfigItem[] = [
        {
            id: 'Profile',
            label: 'Profile',
            path: 'profile/:id',
            element: <ProfilePage />,
        },
        {
            id: 'Skills',
            label: 'Skills',
            path: 'skills',
            element: <SkillsTreePage />,
        },
        {
            id: 'Employees',
            label: 'Employees',
            path: 'search',
            condition: () => hasEmployeesMenuItem,
            element: <EmployeesSearchPage />,
        },
        {
            id: 'Taxonomy',
            label: 'Taxonomy',
            path: 'taxonomy',
            condition: () => hasTaxonomyMenuItem,
            element: <TaxonomyPage />,
        },
        {
            id: 'ChangeRequests',
            label: 'Change Requests',
            path: 'change-requests',
            condition: () => hasTaxonomyMenuItem,
            element: <ChangeRequestsPage />,
        },
        {
            id: 'Roles',
            label: 'Roles',
            path: 'roles',
            condition: () => hasRolesMenuItem,
            element: <RolesManagementPage />,
        },
        {
            id: 'default',
            label: '',
            path: '*',
            element: <Navigate to={redirectUrl} replace />,
        },
    ]

    return {
        routes: routes.filter((item) => item.condition === undefined || item.condition()),
    }
}
