import ErrorBoundary from 'components/ErrorBoundary'
import { Logo } from 'components/Logo'
import { WarningIcon } from 'images'
import { QuickSearch } from 'parts/QuickSearch'
import React from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { canViewOtherProfiles } from 'store/selectors/role-selectors'
import { fetchIsSecured } from 'store/shared/requests'
import { useSystemInfo } from 'utils/hooks/useSystemInfo'
import { red } from 'utils/theme/colors'
import { AccountMenu } from '../../parts/AccountMenu'
import { InfoWrapper, SecuredLine, SHeaderContent, SLink, SRightPanel, SWrapper } from './AppHeader.styled'
import { AppHeaderMenu } from './AppHeaderMenu'

export interface AppHeaderProps {}

export const AppHeader: React.FC<AppHeaderProps> = () => {
    const securedConfig = useSystemInfo('secured status')
    let areProfilesAccessible = !!useSelector(canViewOtherProfiles)

    const { data: securedInfo } = useQuery(['secured'], () => fetchIsSecured(), securedConfig())
    return (
        <ErrorBoundary>
            <SWrapper>
                <SHeaderContent>
                    <SLink to="/">
                        <Logo width={108} height={35} />
                    </SLink>
                    {securedInfo && !securedInfo?.is_secure && (
                        <InfoWrapper color={red} top={90}>
                            <SecuredLine>
                                <WarningIcon />
                                Insecure Auth
                            </SecuredLine>
                        </InfoWrapper>
                    )}

                    <AppHeaderMenu />

                    <SRightPanel>
                        {areProfilesAccessible && <QuickSearch />}
                        <AccountMenu />
                    </SRightPanel>
                </SHeaderContent>
            </SWrapper>
        </ErrorBoundary>
    )
}
