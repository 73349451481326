import React from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/store'
import { DialogPopUp } from '../../components/DialogPopUp'
import { LazyImage } from '../../components/LazyImage'
import { DefaultIconBlue, LogOutIcon } from '../../images'
import { authInfo } from '../../store/selectors/auth-selectors'
import { setDialogToVisible, uncheckUser } from '../../store/slices/auth/requests'
import { Avatar } from '../../utils/theme/Lib'
import { useNavigate } from 'react-router-dom'

export const AccountMenu = () => {
    const authUser = useSelector(authInfo).user
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [isConfirmLogoutDialogOpen, setIsConfirmLogoutDialogOpen] = React.useState(false)

    return (
        <>
            <Avatar onClick={() => navigate('/')}>
                <LazyImage
                    width="32px"
                    height="32px"
                    borderRadius="50%"
                    src={authUser?.staffing_pic || authUser?.google_pic}
                    fallbackImg={<DefaultIconBlue />}
                    withoutBorder
                />
            </Avatar>
            <LogOutIcon cursor={'pointer'} onClick={() => setIsConfirmLogoutDialogOpen(true)} />
            <DialogPopUp
                title="Are you sure you want to logout?"
                btnOkTitle="Logout"
                btnCancelTitle="Cancel"
                open={isConfirmLogoutDialogOpen}
                onClose={() => setIsConfirmLogoutDialogOpen(false)}
                onApprove={() => {
                    dispatch(uncheckUser(true))
                    dispatch(setDialogToVisible())
                    setIsConfirmLogoutDialogOpen(false)
                }}
                shouldBeRendered={false}
            />
        </>
    )
}
