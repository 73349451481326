import { Menu } from 'core'
import { ArrowDownGrey, ArrowUpGrey } from 'images'
import * as React from 'react'
import { employeeRolesOptionForAdminPage, isRoleOptionSelected } from 'utils/helpers/selects-options'
import { SelectOption } from '../../../utils/types/admin'

import { WrapperRenderProp } from './styled'

interface RoleMenuProp {
    defaultValue?: SelectOption
    onChange: (value: SelectOption | null) => void
}

const IconComponent = (props: any) => (
    <span {...props} className={`material-icons ${props.className}`}>
        {props.open ? <ArrowUpGrey /> : <ArrowDownGrey />}
    </span>
)

export const RoleMenu = ({ defaultValue, onChange }: RoleMenuProp) => {
    return (
        <Menu<SelectOption>
            onChange={onChange}
            options={employeeRolesOptionForAdminPage}
            getName={(option) => option.label}
            getId={(option) => option.value}
            getIsSelected={(option) => isRoleOptionSelected(option, defaultValue)}
            renderToggle={(isOpen) => (
                <WrapperRenderProp>
                    {defaultValue?.label} <IconComponent open={isOpen} />
                </WrapperRenderProp>
            )}
        />
    )
}
