import { useSelector } from 'react-redux'
import {
    canViewOtherProfiles,
    canViewRoleMatrix,
    canViewTaxonomy,
} from '../../store/selectors/role-selectors'
import { authInfo } from '../../store/selectors/auth-selectors'

interface MenuItem {
    id: string
    label: string
    url: string
    condition?: () => boolean
}

export const useAppHeaderMenuItems = () => {
    const auth = useSelector(authInfo)
    let hasEmployeesMenuItem = !!useSelector(canViewOtherProfiles)
    let hasTaxonomyMenuItem = !!useSelector(canViewTaxonomy)
    let hasRolesMenuItem = !!useSelector(canViewRoleMatrix)

    const menuItems: MenuItem[] = [
        {
            id: 'Profile',
            label: 'Profile',
            url: `/profile/${auth.user?.user_id}`,
        },
        {
            id: 'Skills',
            label: 'Skills',
            url: '/skills',
        },
        {
            id: 'Employees',
            label: 'Employees',
            url: '/search',
            condition: () => hasEmployeesMenuItem,
        },
        {
            id: 'Taxonomy',
            label: 'Taxonomy',
            url: '/taxonomy',
            condition: () => hasTaxonomyMenuItem,
        },
        {
            id: 'ChangeRequests',
            label: 'Change Requests',
            url: '/change-requests',
            condition: () => hasTaxonomyMenuItem,
        },
        {
            id: 'Roles',
            label: 'Roles',
            url: '/roles',
            condition: () => hasRolesMenuItem,
        },
    ]

    return {
        menuItems: menuItems.filter((item) => item.condition === undefined || item.condition()),
    }
}
