import { Box } from '@mui/material'
import { styled } from 'core'
import { separatorGrey, white } from 'utils/theme/colors'
import { smallMediumFont } from 'utils/theme/fontStyles'

export const Header = styled('div')`
    ${smallMediumFont};
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    box-sizing: border-box;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${separatorGrey};
`

export const HeaderIconContainer = styled('div')`
    margin-right: 12px;
    height: 24px;
`
interface RootContainerProp {
    smoothBorders?: boolean
}
export const RootContainer = styled(Box)<RootContainerProp>`
    display: flex;
    flex-direction: column;
    width: auto;
    background: ${white};
    border-radius: 5px;
    height: fit-content;
    margin-top: 16px;
    margin-right: 0;
    padding: 16px 24px;
    box-sizing: border-box;
    :first-of-type {
        margin-top: 0;
        border-radius: 0 0 5px 5px;
    }
    &.radius {
        :first-of-type {
            border-radius: 5px;
        }
    }
`

export const ExpandButtonContainer = styled('div')`
    width: 24px;
    height: 18px;
    box-sizing: border-box;
    margin: 0 auto;
    cursor: pointer;
`
