import React, { FC, ReactNode } from 'react'
import { lightBlueGrey, TypographyColors } from '../../utils/theme/colors'
import { Box } from '@mui/material'
import { styled } from '../../core'

const SBox = styled(Box)`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-radius: 2px;
    padding: 4px 8px;
    white-space: nowrap;
`

export interface BoxStyles {
    color: string
    backgroundColor: string
    bordered: boolean
}

interface ColorBoxProps extends Partial<BoxStyles> {
    children?: ReactNode
}

export const ColorBox: FC<ColorBoxProps> = ({ children, ...restProps }) => {
    const getStyles = (props: ColorBoxProps) => ({
        color: props.color ?? TypographyColors.DARK_GREY,
        backgroundColor: props.backgroundColor ?? lightBlueGrey,
        outline: props.bordered ? `2px solid ${TypographyColors.DARK_CYAN}` : undefined,
        outlineOffset: props.bordered ? '-2px' : 'none',
    })

    return <SBox sx={getStyles(restProps)}>{children}</SBox>
}
