import { useRef } from 'react'
// import { getShortcutChildren } from 'taxonomy/utils/nodes'
import { useTaxonomyContext } from '../context/TaxonomyContext'
import { FlowNode, IntervalType } from '../types'

export const useNodeClick = () => {
    const timer = useRef<number>()
    const { toggleNodeOpen, recursiveExpandUpdate, toggleNodeActionMenu } = useTaxonomyContext()
    // const flow = useReactFlow()
    // const { x: vX, y: vY, zoom } = useViewport()
    const handleNodeClick = (node: FlowNode) => {
        if (node.type === 'output') return

        toggleNodeOpen(node)

        // What is it made for?

        //  We expect that code to work will be executed before react flow render
        // if (node) {
        //     const updatedNode = reactFlowNodesMap[node.id]
        //     if (updatedNode) {
        //         flow.setViewport({
        //             x: vX - (updatedNode.position.x - node.position.x),
        //             y: vY - (updatedNode.position.y - node.position.y),
        //             zoom: zoom,
        //         })
        //     }
        // }
    }

    const handleNodeDoubleClick = (node: FlowNode) => {
        recursiveExpandUpdate(node)
    }

    const clearTimer = () => {
        clearTimeout(timer.current)
        timer.current = undefined
    }

    return (e: React.MouseEvent, node: FlowNode) => {
        const target = e.target as HTMLElement
        const nodeRoot = target.closest('.CustomNode-root')
        const arrow = nodeRoot?.querySelector('.arrow-button-wrapper')
        if (arrow && arrow.contains(target)) {
            if (nodeRoot) toggleNodeActionMenu(nodeRoot, node)
        } else if (timer.current) {
            clearTimer()
            handleNodeDoubleClick(node)
        } else {
            timer.current = setTimeout(() => {
                clearTimer()
                handleNodeClick(node)
            }, IntervalType.DOUBLE_CLICK) as any as number
        }
    }
}
