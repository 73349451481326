import { Alert, styled } from '@mui/material'
import { NotificationColors, TypographyColors } from 'utils/theme/colors'

export const AlertContainer = styled(Alert)`
    width: 428px;
    border-radius: 2px;
    color: ${TypographyColors.DARK_CYAN};
    padding: 24px 32px;
    box-sizing: border-box;
    backdrop-filter: blur(4px);

    .MuiAlert-message {
        width: 304px;
        padding: 0;
        font-size: 16px;
        line-height: 24px;
    }

    .MuiAlert-action {
        padding: 0;
    }

    &.MuiAlert-standardError {
        background-color: ${NotificationColors.RED};
        border: 1px solid ${NotificationColors.RED_BORDER};

        .MuiAlert-action {
            fill: ${NotificationColors.CLOSE_RED};
        }
    }

    &.MuiAlert-standardSuccess {
        background-color: ${NotificationColors.GREEN};
        border: 1px solid ${NotificationColors.GREEN_BORDER};

        .MuiAlert-action {
            fill: ${NotificationColors.CLOSE_GREEN};
        }
    }
`
