import React, { useEffect, useMemo, useState } from 'react'
import { removeSpaces } from 'utils/functions/common'
import { useTaxonomyContext } from '../../context/TaxonomyContext'
import { useNodeActions } from '../../hooks/useNodeActions'
import { ActionType } from '../../types'
import ActionDialogWrapper from '../../../core/ui/ActionDialog/ActionDialogWrapper'
import { UniqueNameError } from './CreateCategoryDialog'
import { TextField } from '../../../core'

const CreateRootItem = () => {
    const { flowNodes, hideActionDialog, action } = useTaxonomyContext()
    const { createNode } = useNodeActions()

    const [nodeTitle, setNodeTitle] = useState<string>('')
    const [error, setError] = useState<boolean>(false)

    const existingNodeName = useMemo(() => {
        const lowerCaseTitle = removeSpaces(nodeTitle).toLowerCase()
        return flowNodes.some((node) => removeSpaces(node.data.label).toLowerCase() === lowerCaseTitle)
    }, [nodeTitle, flowNodes])

    useEffect(() => {
        if (existingNodeName) setError(true)
        else setError(false)
    }, [existingNodeName])

    return (
        <ActionDialogWrapper
            title={'Add new root category'}
            buttonText="Add"
            disabled={!nodeTitle || error}
            submitAction={() => createNode(nodeTitle, ActionType.CREATE_ROOT_ITEM)}
            onClose={hideActionDialog}
            isOpen={!!action}
        >
            <TextField
                id="create-root-item-input"
                value={nodeTitle}
                placeholder={'Type root category name'}
                label={'New category name'}
                onChange={(e) => {
                    setNodeTitle(e.target.value)
                }}
                autoFocus={true}
            />
            <UniqueNameError error={error} errorLabel={'Category name should be unique,'} />
        </ActionDialogWrapper>
    )
}

export default CreateRootItem
