import axios from 'axios'
import { envConfig } from 'envConfig'

export const BASE_URL = envConfig.baseUrl

export const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
})

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('access_token')
    config.headers = {
        ...config.headers,
        Authorization: token ? `Bearer ${token}` : '',
    }
    return config
})
