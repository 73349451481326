import { Logo } from 'components/Logo'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { getCurrentYear } from 'utils/functions/common'
import { Copyright, LoginContent, LoginRoot, LogoWrapper } from './LoginPage.styled'

export const LoginPage = () => {
    return (
        <LoginRoot>
            <LoginContent>
                <LogoWrapper>
                    <Logo width={400} height={166} />
                </LogoWrapper>

                <Outlet />

                <Copyright>
                    Quantori, LLC &#169;
                    {getCurrentYear()}
                </Copyright>
            </LoginContent>
        </LoginRoot>
    )
}
