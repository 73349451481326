import { AccordionSummary, Box, Divider, keyframes, List, styled, TextField } from '@mui/material'
import {
    lightPurple,
    navyGrey,
    purpleLavender,
    purpleRose,
    TypographyColors,
    white,
    ChangeRequestColors,
    yellowMacaroon,
    BackgroundColor,
} from '../../../utils/theme/colors'

interface HistoryStatusProps {
    isAdjustment?: boolean
}

interface ActionItemProps {
    isArchived?: boolean
}

export const HistoryItem = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 5px 0;
`

export const HistoryItemComment = styled('div', {
    shouldForwardProp: (prop: string) => prop !== 'isAdjustment',
})<HistoryStatusProps>`
    margin: 4px 0 10px 0;
    padding: 10px;
    ${({ isAdjustment }) => `background-color: ${isAdjustment ? purpleRose : lightPurple}`};
    border-radius: 5px;
    color: ${TypographyColors.BLACK};
    font-size: 14px;
`

export const HistoryItemStatus = styled('span', {
    shouldForwardProp: (prop: string) => prop !== 'isAdjustment',
})<HistoryStatusProps>`
    ${({ isAdjustment }) => `color: ${isAdjustment ? purpleLavender : navyGrey}`};
    font-size: 14px;
`

interface IdTitleTextProp {
    discarded?: any
}
export const IdTitleText = styled('div')<IdTitleTextProp>`
    margin-right: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    ${({ discarded }) => `color: ${discarded ? ChangeRequestColors.GREY_CR_TITLE : undefined} `};
`

interface TitleColorProps {
    color?: string
}
export const TitleColoredText = styled(IdTitleText)<TitleColorProps>`
    ${({ color }) => color && `color: ${color}`};
`

export const TitleData = styled(IdTitleText)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-right: 0;
    color: ${navyGrey};
`

export const HistoryItemTimestamp = styled('span')`
    color: ${navyGrey};
    font-size: 14px;
`
export const CommentBox = styled(TextField)``
export const ButtonsBox = styled(Box)`
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    gap: 10px;
`
interface ListedActionsProps {
    status?: boolean
}
export const ListedActions = styled(List)<ListedActionsProps>`
    ${({ status }) => `background-color: ${status ? white : yellowMacaroon}`};
    border-radius: 8px;
    padding: 10px;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
`
export const ActionsContainer = styled('span')`
    background-color: ${lightPurple};
    max-height: 300px;
    overflow: auto;
`
export const ActionItem = styled('span', {
    shouldForwardProp: (prop: string) => prop !== 'isArchived',
})<ActionItemProps>`
    ${({ isArchived }) => `color: ${isArchived ? 'red' : 'black'}`};

    & > span:first-of-type {
        color: black;
    }
`

export const ActionItemStatus = styled('span')`
    font-weight: bold;
`
export const ButtonsContainer = styled('div')`
    display: flex;
    gap: 8px;
`

export const SCRBody = styled('div')`
    display: flex;
    flex-direction: column;

    > * {
        padding: 20px;
    }
`

export const SCRPopupWrapper = styled('div')`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 480px;
    z-index: 1000;
`

export const StyledActionsContainer = styled('div')`
    background-color: ${BackgroundColor.INACTIVE_GREY};
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 14px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`

export const StyledDivider = styled(Divider)`
    padding: 0;
`

const shimmer = keyframes`
    100% { -webkit-mask-position: left }
`

export const StyledAccordionSummary = styled(AccordionSummary)`
    display: inline-block;
    mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: ${shimmer} 2.5s infinite;
    width: 100%;
`
