import React, { FC, memo, useLayoutEffect, useRef, useState } from 'react'
import { Skill } from '../../../utils/types/skills'
import { CompetencyContainer, ExpandButton, SkillPath, SkillsContainer, SkillsPart } from './styled'
import { ArrowDownGrey, ArrowUpGrey } from '../../../images'
import { SkillName } from './SkillName'
import { Levels } from '../../../utils/enums/different'

interface Props {
    skills: Skill[]
    path: string
    level: Levels
}

export const SkillsRow: FC<Props> = memo(({ skills, path, level }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [expanded, setExpanded] = useState<boolean>(false)
    const [button, setButton] = useState<boolean>(false)

    useLayoutEffect(() => {
        const LINE_HEIGHT = 30
        if (!ref || !ref.current) return

        const firstChildPosition = (ref.current.firstChild as HTMLDivElement).getBoundingClientRect().y
        const lastChildPosition = (ref.current.lastChild as HTMLDivElement).getBoundingClientRect().y

        const isExpanding = lastChildPosition - firstChildPosition >= LINE_HEIGHT
        setButton(isExpanding)
    }, [skills])

    const expand = () => {
        if (expanded) {
            setExpanded((prev) => !prev)
            return
        }

        setExpanded((prev) => !prev)
    }

    const getExpandButton = () => {
        if (!button) return
        return (
            <ExpandButton data-automation-id={`button-level-expand-${path}`} onClick={expand}>
                {expanded ? <ArrowUpGrey /> : <ArrowDownGrey />}
            </ExpandButton>
        )
    }

    return (
        <CompetencyContainer>
            <SkillPath data-automation-id="label-skill-row-competence-name">{path}</SkillPath>
            <SkillsPart>
                <SkillsContainer ref={ref} isExpanded={true}>
                    {skills.map((skill) => (
                        <SkillName key={skill.node_id} skill={skill} level={level} />
                    ))}
                </SkillsContainer>
                {getExpandButton()}
            </SkillsPart>
        </CompetencyContainer>
    )
})
