import { MicrosoftLogo } from 'images'
import { useNotistackWrapper } from 'notifications'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthState, setAuthInProgress } from 'store/slices/auth'
import { navigate } from 'app/store'
import { LoginForm, LoginLabel } from './MicrosoftAuth.styled'
import { authConfig } from './authConfig'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'

export const MicrosoftAuth = () => {
    const auth = useSelector<RootState, AuthState>((state) => state.auth)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const errorMessage = searchParams.get('error')
    const { enqueueError } = useNotistackWrapper()

    useEffect(() => {
        if (auth.isAuthorized === null) {
            return
        }
        if (errorMessage) {
            enqueueError(errorMessage)
        }
    }, [])

    const handleLogin = async () => {
        setAuthInProgress(true)
        navigate(
            `https://login.microsoftonline.com/${authConfig.tenantId}/oauth2/v2.0/authorize?client_id=${authConfig.clientId}&response_type=code&redirect_uri=${authConfig.redirectUri}&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2F${authConfig.scope}`
        )
        setAuthInProgress(false)
    }
    return (
        <LoginForm onClick={handleLogin}>
            <MicrosoftLogo />
            <LoginLabel>Sign in with Microsoft</LoginLabel>
        </LoginForm>
    )
}
