import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { ElementType } from 'react'
import { BackgroundColor } from 'utils/theme/colors'
import { styled } from '../../utils/core.styled'

export interface IconButtonComponentProps extends Omit<IconButtonProps, 'id'> {
    id: string
    component?: ElementType
}

export const IconButtonStyled = styled(IconButton)<IconButtonComponentProps>`
    width: 32px;
    height: 32px;
    border-radius: 50%;

    &:hover {
        background-color: ${BackgroundColor.BORDER_GREY};
    }
`
