import React, { FC } from 'react'
import { deepGrey } from '../../utils/theme/colors'
import { IconBaseColorModel } from '../../utils/types/styling'

interface Props extends IconBaseColorModel {
    onClick?: () => void
}

export const StaffingIcon: FC<Props> = ({ color = deepGrey, onClick }) => {
    return (
        <svg
            onClick={onClick}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.99517 20C15.4761 20 20 15.4739 20 10C20 4.52611 15.4664 0 9.9855 0C4.51426 0 0 4.52611 0 10C0 15.4739 4.52392 20 9.99517 20ZM9.99517 18.0077C5.55824 18.0077 2.01063 14.4391 2.01063 10C2.01063 5.56093 5.55824 2.00193 9.9855 2.00193C14.4224 2.00193 17.9894 5.56093 17.999 10C18.0087 14.4391 14.4321 18.0077 9.99517 18.0077ZM9.8695 14.5261C12.1025 14.5261 13.5524 13.4429 13.5524 11.7505C13.5524 10.4449 12.6438 9.70986 10.9425 9.30367L9.71484 8.9942C8.78685 8.77176 8.41953 8.46228 8.41953 7.93037C8.41953 7.2147 9.00918 6.76983 9.9275 6.76983C10.7878 6.76983 11.3388 7.09865 11.6868 7.80464C11.8995 8.12379 12.1121 8.2882 12.5085 8.2882C12.9531 8.2882 13.2721 8.03675 13.2721 7.56286C13.2721 7.40812 13.2334 7.23404 13.1464 7.04062C12.7598 6.0735 11.5805 5.35783 9.97583 5.35783C7.9362 5.35783 6.53456 6.47002 6.53456 8.05609C6.53456 9.30367 7.36588 10.0387 8.89318 10.4159L10.1885 10.764C11.3098 11.0445 11.6868 11.3056 11.6868 11.9052C11.6868 12.6596 10.9522 13.0754 9.9855 13.0754C9.06718 13.0754 8.43886 12.7369 8.0522 12.0406C7.83954 11.7118 7.60754 11.5571 7.24988 11.5571C6.77622 11.5571 6.46689 11.8569 6.46689 12.3211C6.46689 12.4952 6.50556 12.6692 6.59256 12.8433C6.95988 13.7427 8.1392 14.5261 9.8695 14.5261Z"
                fill={color}
            />
        </svg>
    )
}
