import ErrorBoundary from 'components/ErrorBoundary'
import React, { FC, ReactNode } from 'react'
import { useUserProfile } from '../../utils/hooks/useUserProfile'
import { FileWrapper, NothingHereYet, TextWrapper } from './styled'

interface NoContentProps {
    children?: ReactNode
}

export const NoContentsFallback: FC<NoContentProps> = ({ children }) => {
    const { readonly } = useUserProfile()

    return (
        <FileWrapper data-automation-id="container-no-content">
            <TextWrapper>
                <ErrorBoundary>
                    <NothingHereYet data-automation-id="label-no-content-text">
                        There&apos;s nothing here yet
                    </NothingHereYet>
                </ErrorBoundary>
            </TextWrapper>
            {!readonly && children}
        </FileWrapper>
    )
}
