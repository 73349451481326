import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { TabsContext } from 'core'
import { ArrowDownGrey } from 'images'
import { isEmpty } from 'lodash'
import React, { FC, ReactNode, memo, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { ActionType, ActionTypeTranslations, ChangeRequest, HistoryType } from 'taxonomy/types'
import { UserProfile } from 'utils/types/auth'
import {
    getChangeRequestTitleColor,
    getCRsCommentBackground,
    isActiveChangeRequest,
    showIntTimeFormat,
} from '../../utils/changeRequests'
import { AccordionEditableContainer } from './AccordionEditableContainer'
import {
    ActionItem,
    ActionItemStatus,
    ActionsContainer,
    HistoryItem,
    HistoryItemComment,
    HistoryItemStatus,
    HistoryItemTimestamp,
    IdTitleText,
    ListedActions,
    SCRBody,
    StyledActionsContainer,
    StyledDivider,
    TitleColoredText,
    TitleData,
} from './styled'
import {
    formChangeRequestActionString,
    formChangeRequestHistoryString,
    formChangeRequestTitleString,
    getHistoryItemTimestamp,
} from './utils'
import { BackgroundColor } from 'utils/theme/colors'

interface CRAccordionProps {
    changeRequest: ChangeRequest | null
    expandIcon?: ReactNode
}

const CRAccordion: FC<CRAccordionProps> = memo(({ changeRequest, expandIcon = <ArrowDownGrey /> }) => {
    const { currentTab } = useContext(TabsContext)
    const user = useSelector<RootState, UserProfile | undefined>((state) => state.auth.user)

    const [open, setOpen] = useState<boolean>(currentTab === '4' && isActiveChangeRequest(changeRequest))

    const approvedOrDiscardedTime = useMemo(() => showIntTimeFormat(changeRequest!), [changeRequest])
    const titleColor = useMemo(() => getChangeRequestTitleColor(changeRequest!.status), [changeRequest])
    const commentBackground = useMemo(() => getCRsCommentBackground(changeRequest!.status), [changeRequest])

    return (
        <Accordion expanded={open}>
            <AccordionSummary
                sx={{ backgroundColor: BackgroundColor.INACTIVE_GREY }}
                expandIcon={expandIcon}
                onClick={() => setOpen(!open)}
            >
                {changeRequest && (
                    <IdTitleText discarded={+(changeRequest.status === HistoryType.ARCHIVED)}>
                        #{changeRequest?.id}
                    </IdTitleText>
                )}
                <TitleColoredText color={titleColor}>
                    {changeRequest ? formChangeRequestTitleString(changeRequest, user?.role) : 'Draft'}
                </TitleColoredText>
                <TitleData>
                    {changeRequest &&
                        !isActiveChangeRequest(changeRequest) &&
                        `on ${approvedOrDiscardedTime}`}
                </TitleData>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
                <SCRBody>
                    {changeRequest && (
                        <div>
                            {changeRequest.history.map((item) => (
                                <HistoryItem key={item.id}>
                                    <div>
                                        <HistoryItemTimestamp>
                                            {`${getHistoryItemTimestamp(item.timestamp)} - `}
                                        </HistoryItemTimestamp>
                                        <HistoryItemStatus
                                            isAdjustment={item.status === HistoryType.ADJUSTMENT_REQUESTED}
                                        >
                                            {formChangeRequestHistoryString(item, changeRequest.owner)}
                                        </HistoryItemStatus>
                                    </div>
                                    {item.comment && (
                                        <HistoryItemComment
                                            isAdjustment={item.status === HistoryType.ADJUSTMENT_REQUESTED}
                                        >
                                            {item.comment}
                                        </HistoryItemComment>
                                    )}
                                </HistoryItem>
                            ))}
                        </div>
                    )}
                    <StyledDivider />
                    <StyledActionsContainer>
                        <ActionsContainer>
                            {changeRequest && !isEmpty(changeRequest.items) && (
                                <ListedActions sx={{ backgroundColor: commentBackground }}>
                                    {changeRequest.items.map((item, index) => (
                                        <ActionItem
                                            key={index}
                                            isArchived={item.action === ActionType.ARCHIVE}
                                        >
                                            <span>{`${index + 1}. `}</span>
                                            <ActionItemStatus>
                                                {ActionTypeTranslations[item.action]}
                                            </ActionItemStatus>
                                            {` ${formChangeRequestActionString(item)}`}
                                        </ActionItem>
                                    ))}
                                </ListedActions>
                            )}
                        </ActionsContainer>
                        <AccordionEditableContainer changeRequest={changeRequest} />
                    </StyledActionsContainer>
                </SCRBody>
            </AccordionDetails>
        </Accordion>
    )
})

export default CRAccordion
