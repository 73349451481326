import React, { FC, memo, useLayoutEffect, useRef } from 'react'
import { Slider } from '@mui/material'
import { debounce } from 'lodash'
import { SkillLevelColors } from 'utils/theme/colors'

interface SkillSliderProps {
    levelFrom: number
    levelTo: number
    setSkillLevel: (level_from: number, level_to: number) => void
}

export const SkillSlider: FC<SkillSliderProps> = memo(({ levelFrom, levelTo, setSkillLevel }) => {
    const marks = [
        {
            value: 1,
            label: 'Novice',
        },
        {
            value: 2,
            label: 'Basic',
        },
        {
            value: 3,
            label: 'Middle',
        },
        {
            value: 4,
            label: 'Advanced',
        },
        {
            value: 5,
            label: 'Expert',
        },
    ]

    const DISTANCE = 1

    const handleChange = debounce((event: Event, newValue: number | number[], activeThumb: number) => {
        if (!Array.isArray(newValue)) {
            return
        }

        if (activeThumb === 0) {
            setSkillLevel(Math.min(newValue[0], levelTo - DISTANCE), levelTo)
        } else {
            setSkillLevel(levelFrom, Math.max(newValue[1], levelFrom + DISTANCE))
        }
    }, 200)

    const refSlider = useRef<HTMLElement>(null)

    useLayoutEffect(() => {
        const sliderElement = refSlider.current

        if (sliderElement) {
            const rangeLabels: any[] = Array.from(sliderElement.getElementsByClassName('MuiSlider-markLabel'))
            const rangeActiveLabels = sliderElement.getElementsByClassName('MuiSlider-markLabelActive')
            const rangeMarks: any[] = Array.from(sliderElement.getElementsByClassName('MuiSlider-mark'))
            const sliderRails: any[] = Array.from(sliderElement.getElementsByClassName('MuiSlider-rail'))
            const sliderTracks: any[] = Array.from(sliderElement.getElementsByClassName('MuiSlider-track'))
            const arr = Array.from(rangeActiveLabels)
            const activeLabels: any[] = [arr[0], arr[arr.length - 1]]

            sliderRails.forEach((item) => (item.style.width = '100%'))

            sliderTracks.forEach((item) => (item.style.border = 'none'))
            rangeMarks.forEach((item) => (item.style.opacity = 1))

            rangeLabels.forEach((span) => (span.style.color = 'transparent'))
            activeLabels.forEach((span) => (span.style.color = '#54687D'))

            const allThumbs = sliderElement.querySelectorAll('.MuiSlider-thumb > input')

            for (let i = 0; i < allThumbs.length; i++) {
                const inputElement = allThumbs[i]
                const spanElement = inputElement.parentElement

                if (spanElement !== null) {
                    const inputValue = inputElement.getAttribute('value')

                    const skillLevelColorsVars = {
                        '1': SkillLevelColors.NOVICE,
                        '2': SkillLevelColors.BASIC,
                        '3': SkillLevelColors.MIDDLE,
                        '4': SkillLevelColors.ADVANCED,
                        '5': SkillLevelColors.EXPERT,
                    }

                    if (inputValue)
                        spanElement.style.color =
                            skillLevelColorsVars[inputValue as keyof typeof skillLevelColorsVars]
                }
            }
        }
    }, [setSkillLevel])

    return (
        <Slider
            ref={refSlider}
            sx={{ width: '95%', margin: 'auto' }}
            value={[levelFrom, levelTo]}
            onChange={handleChange}
            min={1}
            max={5}
            step={null}
            marks={marks}
        />
    )
})
