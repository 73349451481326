import React, { FC } from 'react'
import { SkillsHeader } from './SkillsHeader'
import { useUserProfile } from '../../../utils/hooks/useUserProfile'
import { LevelTable } from 'parts/LevelTable'
import { Levels } from 'utils/enums/different'
import { NoSkillsProfile } from 'core/ui/NoSkillsProfile'
import { Box } from '@mui/material'

export const ProfileSkills: FC = () => {
    const { skills } = useUserProfile()
    const skillsArePresent = skills.length > 0

    if (!skillsArePresent) {
        return <NoSkillsProfile />
    }

    return (
        <Box component="div" sx={{ margin: 0, width: '100%' }}>
            <SkillsHeader />
            <div>
                {Object.values(Levels)
                    .reverse()
                    .map((level) => (
                        <LevelTable level={level} key={level} />
                    ))}
            </div>
        </Box>
    )
}
