import { useSelector } from 'react-redux'

import {
    actualSkillsSelector,
    editingSkillsSelector,
    isOwnProfileSelector,
    profileIdSelector,
} from '../../store/selectors/profile-selectors'
import { Skill } from '../types/skills'
import { useProfileActions } from 'utils/hooks/useProfileActions'

interface UserSkillsContextState {
    userId: string
    skills: Skill[]
    readonly: boolean
    isEditing: boolean
    editMode: (mode: boolean) => void
}

export const useUserProfile: () => UserSkillsContextState = () => {
    const isOwnProfile = useSelector(isOwnProfileSelector)
    const editingSkills = useSelector(editingSkillsSelector)
    const profileId = useSelector(profileIdSelector)
    const actualSkills = useSelector(actualSkillsSelector)

    const { setEditingSkills } = useProfileActions()

    return {
        userId: profileId ?? '',
        skills: actualSkills,
        readonly: !isOwnProfile,
        isEditing: editingSkills,
        editMode: (mode) => setEditingSkills(mode),
    }
}
