import { Button } from 'core'
import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { checkUserAndToken, loginWithBasicAuth } from 'store/slices/auth/requests'
import { useAppDispatch } from 'store/store'
import { isBasicAuthEnabled } from '../../utils/isBasicAuthEnabled'
import { FormStyled, TextFieldStyled } from './BasicAuth.styled'

export const BasicAuth = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    if (!isBasicAuthEnabled()) {
        return <Navigate to="/login" replace />
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const data = new FormData(event.currentTarget)
        const email = data.get('email')?.toString()
        if (!email) return

        await dispatch(loginWithBasicAuth(email))
        await dispatch(checkUserAndToken())
        navigate('/')
    }

    return (
        <FormStyled onSubmit={handleSubmit}>
            <TextFieldStyled
                required
                id="email"
                label="Email"
                name="email"
                size="small"
                fullWidth
                variant="filled"
                value=""
            />
            <Button id="sign-in" type="submit" variant="contained">
                Sign In
            </Button>
        </FormStyled>
    )
}
