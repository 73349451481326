import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { useSearchContext } from 'search/context/SearchContext'
import FiltersBlock from './FiltersBlock'
import SkillRange from './SkillRange'
import {
    SWrapper,
    FilterBlock,
    FilterLabelContent,
    SelectedFiltersWrapper,
    FilterLabelContentWrapper,
} from './styled'
import { SCircleIcon, SFolderIcon } from 'parts/EmployeeAndSkillAutoSuggestOption/styled'

const AppliedFilters = () => {
    const { skills, setSkills, locations, setLocations, competencies, setCompetencies } = useSearchContext()

    const setSkillLevel = (skill_id: string) => (level_from: number, level_to: number) => {
        setSkills((prev) => ({
            ...prev,
            [skill_id]: { ...prev[skill_id], level_from: level_from, level_to: level_to },
        }))
    }

    const skillsValues = Object.values(skills)

    const renderFilterLabelContent = () => {
        const isSkillsListNotEmpty = () => skillsValues.some((item) => item.is_category === false)
        const isCategoriesListNotEmpty = () => skillsValues.some((item) => item.is_category === true)

        const skillsLength = skillsValues.filter((item) => item.is_category === false).length
        const categoriesLength = skillsValues.length - skillsLength

        return (
            <FilterLabelContentWrapper>
                {isCategoriesListNotEmpty() && (
                    <FilterLabelContent>
                        <SFolderIcon />
                        {`Skill Category (${categoriesLength})`}
                    </FilterLabelContent>
                )}
                {isSkillsListNotEmpty() && (
                    <FilterLabelContent>
                        <SCircleIcon />
                        {`Skills (${skillsLength})`}
                    </FilterLabelContent>
                )}
            </FilterLabelContentWrapper>
        )
    }

    return useMemo(() => {
        return (
            <SWrapper>
                {!isEmpty(skills) && (
                    <FilterBlock>
                        {renderFilterLabelContent()}
                        <SelectedFiltersWrapper>
                            {skillsValues.map((skill) => (
                                <SkillRange
                                    skill={skill}
                                    key={skill.id}
                                    setSkillLevel={setSkillLevel(skill?.id!)}
                                />
                            ))}
                        </SelectedFiltersWrapper>
                    </FilterBlock>
                )}
                <FiltersBlock
                    label={`Location (${locations?.length})`}
                    filters={locations}
                    setFilters={setLocations}
                />
                <FiltersBlock
                    label={`Business unit (${competencies?.length})`}
                    filters={competencies}
                    setFilters={setCompetencies}
                />
            </SWrapper>
        )
    }, [skills, setSkills, locations, setLocations, competencies, setCompetencies])
}
export default AppliedFilters
