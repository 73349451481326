import { isEqual, isNil } from 'lodash'
import { SetStateAction } from 'react'
import { rolesToShortRoles } from '../helpers/constants'
import { Role } from '../types/admin'
import { Action, Entity, Predicate } from '../types/common'
import { Employee } from '../types/employee'
import { NodeWithPath, Skill } from '../types/skills'

/**
 * Check if string is valid JSON.
 * @param str - String representing JSON inside
 */
export const isValidJson = (str: string): boolean => {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

export const getCurrentYear = (): number => new Date().getFullYear()

export const sortByRole = (data: Employee[], isAsc: boolean): Employee[] => {
    const sortedData = data.sort((a, b) => a.employee_role.localeCompare(b.employee_role))
    return isAsc ? sortedData : sortedData.reverse()
}

export const sortByCompetence = (data: Employee[], isAsc: boolean): Employee[] => {
    const sortedData = data.sort((a, b) => a.staffing_competence.localeCompare(b.staffing_competence))
    return isAsc ? sortedData : sortedData.reverse()
}

export const getBool = (s: string): boolean => {
    return !!s && isValidJson(s) && s === 'true'
}

/**
 * Return string with capitalized first letter, if input string has nullable value, returns empty string.
 * @param line - input string
 */
export const capitalizeFirstLetter = (line: string | null | undefined): string => {
    if (!line) {
        return ''
    }

    return line[0].toUpperCase() + line.slice(1)
}

/**
 * Return array elements in random positions
 * @param array - shuffling array
 */
export const arrayShuffle = <T>(array: T[]) => {
    array = [...array]

    for (let index = array.length - 1; index > 0; index--) {
        const newIndex = Math.floor(Math.random() * (index + 1))
        ;[array[index], array[newIndex]] = [array[newIndex], array[index]]
    }

    return array
}
export const getRandomColor = () => {
    const letters = '0123456789ABCDEF'.split('')
    let color = '#'
    for (let i = 0; i < 6; i++) {
        color += letters[Math.round(Math.random() * 15)]
    }
    return color
}
export const formatAMPM = (date: Date) => {
    return new Date(date).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')
}
export const getValidStringBeforeDecodingURI = (s: string) => {
    if (!s) {
        return s
    }
    if (decodeURI(s)) return s

    return s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')
}
export const filterDataFromInvalidValues = (data: Employee[]): Employee[] => {
    return data.map((entry) => {
        if (isNil(entry.staffing_competence)) {
            entry.staffing_competence = ''
        }
        if (isNil(entry.employee_role)) {
            entry.employee_role = ''
        }
        return entry
    })
}

export const matchNameById = (id: number, entity: Entity[]): string | undefined =>
    entity.find((element) => element.id === id)?.name

export const getActionCodeListForRole = (roleCode: string, roles: Role[], actions: Action[]): string[] => {
    const possibleActions = new Set<number>(roles.find((role) => role.code === roleCode)?.actions || [])
    return actions.filter((action) => possibleActions.has(action.id)).map((action) => action.code)
}
export const calcCurrentDate = (): string => {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear()

    return dd + '/' + mm + '/' + yyyy
}
export const generateRandomHex = (size: number) =>
    [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')
export const removeSpaces = (input: string) => input.trim()
export const findShortcutRoleByFull = (empRole: string) => {
    return rolesToShortRoles.find((role) => role.name === empRole)
}
export const findFullRoleByShortcut = (empRole: string) => {
    return rolesToShortRoles.find((role) => role.contracted === empRole)
}
export const getSkillsSum = (data: Employee[]) => {
    // @ts-ignore
    return data.reduce((acc, curr) => acc + curr.novice + curr.advanced + curr.expert + curr.want_to_learn, 0)
}

export const getNumberValue = (object: any, field: string): number => {
    return object[field] || 0
}

export const notEmptySkill: Predicate<Skill> = ({ want_to_learn, dislike, seniority_level, recent }) => {
    return !(!want_to_learn && !dislike && !seniority_level && !recent)
}

export const focusTimer = (id: string) => {
    return setTimeout(() => {
        document.getElementById(id)?.focus()
    }, 100)
}

/**
 * Check if two arrays have similar elements arranged in any order by given predicate
 * @param array1 - first array
 * @param array2 - second array to compare with
 * @param predicate - predicate for comparing
 */
export const haveEqualElements = <T extends object, E extends object>(
    array1: Array<T>,
    array2: Array<E>,
    predicate?: (a: T, b: E) => boolean
): boolean => {
    if (array1.length === array2.length) {
        if (!predicate) return isEqual(array1, array2)
        return array1.every((elem, index) => {
            const currElement = array2[index]
            return predicate(elem, currElement)
        })
    }

    return false
}

export const showMoreSkillItems = (
    filteredSkills: string | any[],
    showedSkillNumber: number,
    changeShownSkillNumber: { (value: SetStateAction<number>): void; (arg0: any): void }
) => {
    const moreHiddenSkills = filteredSkills.length > showedSkillNumber
    if (moreHiddenSkills) {
        changeShownSkillNumber(showedSkillNumber + 5)
    }
}

export function sortSkillsByCategory(a: NodeWithPath, b: NodeWithPath) {
    return a.isCategory ? -1 : b.isCategory ? 1 : 0
}
