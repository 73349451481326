// import { tannBlue } from 'utils/theme/colors'
import { AutocompleteRenderOptionState, ListItem } from '@mui/material'
import { SSkillPath, SEmployeeConpetence, SEmpLabel, SCircleIcon, SFolderIcon } from './styled'
// import match from 'autosuggest-highlight/match'
// import parse from 'autosuggest-highlight/parse'
import React from 'react'
import { getNodePath } from 'utils/hooks/useSkillPathTree'
import { NodeWithPath } from '../../utils/types/skills'
import EmployeeImagesGallery from 'components/EmployeeImagesGallery'
import { EmployeeInfo } from 'search/context/types'

export const NodeAutoSuggestOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
    getName: (option: any) => string
): React.ReactNode => {
    // const matches = match(getName(option), state.inputValue, { insideWords: true })
    // const parts = parse(getName(option), matches)

    const path = () => {
        if ('nodePathForAutoSuggest' in option) {
            return option.nodePathForAutoSuggest
        } else return getNodePath(option)
    }

    const defineKey = () => {
        if (!option.node_id) return option.id
        else return option.node_id
    }

    return (
        <ListItem {...props} key={defineKey()}>
            {/* {parts.map((part, index) => (
                <SLabel key={index} style={part.highlight ? { color: tannBlue } : {}}>
                    {part.text}
                </SLabel>
            ))} */}
            {option.isCategory ? <SFolderIcon /> : <SCircleIcon />}
            {getName(option)}
            <SSkillPath>{path()}</SSkillPath>
        </ListItem>
    )
}

export const skillSelectOptionRenderFn = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
    getName?: (option: any) => string
): React.ReactNode =>
    NodeAutoSuggestOption(props, option, state, (option: NodeWithPath) =>
        getName ? getName(option) : option.name
    )

export const EmployeeAutoSuggestOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: EmployeeInfo
) => {
    return (
        <ListItem {...props} key={option.user_id}>
            <EmployeeImagesGallery withoutBorder pic={option?.google_pic || option?.staffing_pic} />
            <SEmpLabel key={option.user_id}>{option.name}</SEmpLabel>
            <SEmployeeConpetence>{option.staffing_competence}</SEmployeeConpetence>
        </ListItem>
    )
}
