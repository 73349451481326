import { ComposedAjaxError } from 'axiosClient/ErrorComposer'
import { AppDispatch } from 'store/store'
import { getSkillsTreeNodes } from 'taxonomy/api/requests'
import { setSkillNodes } from '.'

export const getTreeNodes = () => async (dispatch: AppDispatch) => {
    try {
        const skills = await getSkillsTreeNodes()
        dispatch(setSkillNodes(skills))
    } catch (ex: any) {
        ComposedAjaxError.handleError('Error loading tree nodes', ex)
    }
}
