import { get, isEmpty } from 'lodash'
import { ActionType, CRItem, FlowNodesMap, Node, Shortcut } from '../types'
import { getFlowNodeFromNode, getFlowNodeFromShortcut, initFlowNodeParent, recursiveArchive } from './nodes'

type ActionFunc = (map: FlowNodesMap, item: CRItem) => void

const archiveNode: ActionFunc = (map, { itemId }) => {
    const node = map[itemId]

    node.data.isArchived = true

    recursiveArchive(node.data.children)
}

const createNode: ActionFunc = (map, { itemId, parentId, name, action, isCategory }) => {
    const node: Node = {
        id: itemId,
        name: name!,
        parentId,
        isCategory: isCategory!,
        isArchived: false,
    }

    const flowNode = getFlowNodeFromNode(node)
    initFlowNodeParent(map, flowNode)

    flowNode.data.isChanged = true

    map[flowNode.id] = flowNode
}

const renameNode: ActionFunc = (map, { itemId, newName }) => {
    const node = map[itemId]

    node.data.label = newName!
    node.data.name = newName!
    node.data.isChanged = true
}

const moveNode: ActionFunc = (map, { itemId, newParentId }) => {
    const node = map[itemId]
    const prevParent = node.data.parent
    const newParent = newParentId ? map[newParentId] : null

    node.data.parent = null
    node.data.parentId = null
    node.data.isChanged = true

    if (prevParent) {
        prevParent.data.children = prevParent.data.children.filter((child) => child.id !== itemId)
    }

    if (newParent) {
        node.data.parentId = newParentId
        node.data.parent = newParent
        node.hidden = !newParent.data.isOpen || newParent.hidden

        newParent.data.children = [...newParent.data.children, node]
    }
}

const restoreNode: ActionFunc = (map, { itemId }) => {
    const node = map[itemId]

    node.data.isArchived = false
}

const createShortcut: ActionFunc = (map, { itemId, parentId, childId }) => {
    const shortcut: Shortcut = {
        id: itemId,
        parentId: parentId!,
        childId: childId!,
    }

    let flowNode = getFlowNodeFromShortcut(map, shortcut)
    flowNode.data.isChanged = true
    initFlowNodeParent(map, flowNode)

    map[flowNode.id] = flowNode
}

const removeShortcut: ActionFunc = (map, { itemId, parentId, childId }) => {
    let flowNode = get(map, itemId)
    const parent = map[flowNode.data.parent!.id]
    parent.data.children = parent.data.children.filter((node) => node.id !== itemId)
    delete map[itemId]
}

const NodesActions: Record<ActionType, ActionFunc> = {
    [ActionType.ARCHIVE]: archiveNode,
    [ActionType.CREATE_CATEGORY]: createNode,
    [ActionType.CREATE_SKILL]: createNode,
    [ActionType.CREATE_ROOT_ITEM]: createNode,
    [ActionType.MOVE]: moveNode,
    [ActionType.RENAME]: renameNode,
    [ActionType.RESTORE]: restoreNode,

    [ActionType.CREATE_SHORTCUT]: createShortcut,
    [ActionType.REMOVE]: removeShortcut,
}

export const applyCrItem = (map: FlowNodesMap, item: CRItem) => NodesActions[item.action](map, item)

export const applyChangeRequest = (map: FlowNodesMap, items: CRItem[]) => {
    if (isEmpty(map) || isEmpty(items)) {
        return
    }
    items.forEach((item: CRItem) => applyCrItem(map, item))
}
