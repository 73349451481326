import { NotificationClose } from 'images'
import { SnackbarKey, useSnackbar } from 'notistack'
import React, { FC } from 'react'
import { Container } from './CloseButton.styled'

interface CloseSnackbarProps {
    keyId: SnackbarKey
}

export const CloseButton: FC<CloseSnackbarProps> = ({ keyId }) => {
    const { closeSnackbar } = useSnackbar()

    return (
        <Container>
            <NotificationClose onClick={() => closeSnackbar(keyId)} />
        </Container>
    )
}
