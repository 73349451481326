import { Divider } from '@mui/material'
import { Polygon } from 'images'
import React, { FC } from 'react'
import { useFindOriginalNode } from 'utils/hooks/useFindOriginalNode'
import { defaultGrey } from 'utils/theme/colors'
import { useTaxonomyContext } from '../../context/TaxonomyContext'
import { useNodeActions } from '../../hooks/useNodeActions'
import { ActionType } from '../../types'
import ContextMenu from '../ContextMenu/ContextMenu'
import { ContextMenuItem } from '../ContextMenu/ContextMenuItem'

interface NodeActionMenuProps {
    inWizardMode?: boolean
}

const NodeActionMenu: FC<NodeActionMenuProps> = ({ inWizardMode }) => {
    const {
        showActionDialog,
        reactFlowNodesMap,
        menuAnchor,
        hideMenu,
        selectedNode,
        selectedNodeId,
        updateNodesView,
    } = useTaxonomyContext()
    const { archiveNode, restoreNode, removeShortcut } = useNodeActions()
    const findOriginalNodeIfShortcutClicked = useFindOriginalNode(selectedNode?.data.nodeIdPriorToShortcut)

    const handleAction = (action: ActionType) => {
        showActionDialog(selectedNodeId!, action)
    }

    const handleSHortcutRemove = () => {
        hideMenu()
        removeShortcut(selectedNodeId!)
    }

    const redirectToOriginalNode = () => {
        hideMenu()
        findOriginalNodeIfShortcutClicked()
        updateNodesView()
    }

    if (selectedNode?.data.isArchived && reactFlowNodesMap[selectedNode?.data?.parentId!]?.data?.isArchived) {
        return <div />
    }

    if (selectedNode?.data.isArchived && !reactFlowNodesMap[selectedNode?.data?.parentId!]?.data?.isArchived)
        return (
            <ContextMenu
                id="main-menu"
                menuAnchor={menuAnchor}
                open={!!menuAnchor}
                onClose={hideMenu}
                transitionDuration={0}
            >
                <ContextMenuItem
                    id="restore"
                    onClick={() => {
                        if (!selectedNodeId) {
                            return
                        }
                        restoreNode(selectedNodeId)
                    }}
                >
                    Restore
                </ContextMenuItem>
            </ContextMenu>
        )

    return (
        <ContextMenu
            id="main-menu"
            menuAnchor={menuAnchor}
            open={!!menuAnchor}
            onClose={hideMenu}
            transitionDuration={0}
        >
            {inWizardMode ? (
                <ContextMenuItem id="go-to-original-node" onClick={redirectToOriginalNode}>
                    {' '}
                    Go to original node{' '}
                </ContextMenuItem>
            ) : selectedNode?.data.isShortcut ? (
                <div>
                    {!selectedNode.data.isTemporaryShortcut && (
                        <ContextMenuItem id="remove-shortcut" onClick={handleSHortcutRemove}>
                            {' '}
                            Remove{' '}
                        </ContextMenuItem>
                    )}
                    <ContextMenuItem id="go-to-original-node" onClick={redirectToOriginalNode}>
                        {' '}
                        Go to original node{' '}
                    </ContextMenuItem>
                </div>
            ) : (
                <div>
                    <ContextMenuItem id="rename" onClick={() => handleAction(ActionType.RENAME)}>
                        Edit...
                    </ContextMenuItem>
                    {selectedNode?.data.isCategory ? (
                        <ContextMenuItem
                            id="add-new-child"
                            subMenu={[
                                <ContextMenuItem
                                    id="create-category"
                                    onClick={() => {
                                        handleAction(ActionType.CREATE_CATEGORY)
                                    }}
                                >
                                    Category
                                </ContextMenuItem>,

                                <ContextMenuItem
                                    id="create-shortcut"
                                    onClick={() => {
                                        handleAction(ActionType.CREATE_SHORTCUT)
                                    }}
                                >
                                    Shortcuts
                                </ContextMenuItem>,

                                <ContextMenuItem
                                    id="create-skill"
                                    onClick={() => {
                                        handleAction(ActionType.CREATE_SKILL)
                                    }}
                                >
                                    Skill
                                </ContextMenuItem>,
                            ]}
                        >
                            Add new child
                            <span>&nbsp;&nbsp;</span>
                            <Polygon fill="white" />
                        </ContextMenuItem>
                    ) : null}
                    {selectedNode?.data.parent ? (
                        <ContextMenuItem id="move" onClick={() => handleAction(ActionType.MOVE)}>
                            Move to...
                        </ContextMenuItem>
                    ) : null}
                    <Divider sx={{ borderColor: defaultGrey }} />

                    <ContextMenuItem
                        id="archive"
                        onClick={() => {
                            if (!selectedNodeId) {
                                return
                            }
                            archiveNode(selectedNodeId)
                        }}
                    >
                        Archive
                    </ContextMenuItem>
                </div>
            )}
        </ContextMenu>
    )
}

export default NodeActionMenu
