import { styled, TextField } from 'core'
import { white } from 'utils/theme/colors'

export const FormStyled = styled('form')`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    margin: 2rem 0 0;
    min-width: 20rem;
`

export const TextFieldStyled = styled(TextField)`
    .MuiFilledInput-root {
        background-color: ${white};
    }
`
