export enum Actions {
    ViewLoginPage = 'lgn',
    ViewProfilePage = 'prof',
    ViewOthersProfilePages = 'oprof',
    EditSkillsOfSubordinates = 'esub',
    AddDeleteNewSkills = 'eskl',
    ViewSkillsChangingScreen = 'vscs',
    ViewRoleMatrix = 'vrm',
    AddCompetenceToList = 'acmp',
    AddCategoryToList = 'actg',
    AddSkillToList = 'askl',
    SetRoleToUser = 'setrl',
    CreateCr = 'cuacr',
}

export enum Roles {
    admin = 'adm',
    externalConsultant = 'ec',
    employee = 'emp',
    solidLineManager = 'slm',
}

export enum SortingOptions {
    asc = 'asc',
    desc = 'desc',
}

export enum SortColumns {
    competence = 'Competence',
    role = 'Role',
}

export enum AreasOfSearch {
    skills = 'skills',
    competence = 'competence',
    employees = 'employees',
}

export enum SkillsModalRadios {
    competence = 'Competence',
    category = 'Category',
    skill = 'Skill',
}

export enum InterviewDuration {
    halfHour = '30 min',
    oneHour = '1 hour',
    oneAndHalf = '1 hour 30 min',
    twoHour = '2 hours',
}

export enum Presets {
    userPresets = 'My Presets',
    systemPresets = 'System Presets',
}

export enum SharedAction {
    AddCategory = '@@shared/add-category',
    AddCompetence = '@@shared/add-competence',
    UpdateUserActions = '@@shared/update-user-actions',
    clearProfile = '@@shared/clear-profile',
    DeleteUserSkill = '@@shared/delete-skill',
}

export enum Levels {
    DWTL = `Don't want to learn`,
    WTL = 'Want to learn',
    Novice = 'Novice',
    Basic = 'Basic',
    Middle = 'Middle',
    Advanced = 'Advanced',
    Expert = 'Expert',
}

export const LEVEL_ABBREVIATIONS = [...Object.values(Levels)]

export enum SkillStatuses {
    WTL = 'Want to learn',
    Recent = 'Recent',
    Dislike = "Don't want to learn",
}

export enum WizardStage {
    Competencies = 'Competencies',
    Categories = 'Categories',
    Skills = 'Skills',
}
