import { useNotistackWrapper } from 'notifications'

export const useSystemInfo = (infoType: string) => {
    const { enqueueError } = useNotistackWrapper()

    return () => ({
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        onError: () => enqueueError(`Error getting ${infoType}`),
    })
}
