import React, { FC, ReactNode } from 'react'
import { TooltipStyled } from './Tooltip.styled'

interface Props {
    text: string
    children: ReactNode
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top'
    isVisible?: boolean
}

export const Tooltip: FC<Props> = ({ text, placement, children, isVisible }) => {
    return isVisible ? (
        <TooltipStyled title={text} arrow placement={placement || 'top-end'}>
            <div>{children}</div>
        </TooltipStyled>
    ) : (
        <>{children}</>
    )
}
