import React, { FC } from 'react'
import { capitalizeFirstLetter } from 'utils/functions/common'
import { RoleTableData, RoleTableRowAction } from 'utils/types/admin'
import { AreaCellStyled, CheckboxCellStyled, TableRowStyled, VisibilityCellStyled } from './styled'
import { Checkbox } from '@mui/material'

interface Props {
    data: RoleTableData
    handleCheckbox: (roleId: number, checked: boolean, actionId: number) => void
}

export const RoleBody: FC<Props> = ({ data, handleCheckbox }) => {
    const renderActionDataRow = (action: RoleTableRowAction) => {
        return (
            <>
                <VisibilityCellStyled>{capitalizeFirstLetter(action.description)}</VisibilityCellStyled>
                {action.permissions.map((permission, i) => {
                    return (
                        <CheckboxCellStyled key={i}>
                            <Checkbox
                                key={permission.role_id}
                                name={permission.role_id + action.code}
                                checked={permission.checked}
                                disabled={false}
                                onClick={() => {
                                    handleCheckbox(permission.role_id, permission.checked, action.id)
                                }}
                                size="small"
                            />
                        </CheckboxCellStyled>
                    )
                })}
            </>
        )
    }
    return (
        <>
            {Object.values(data).map((area) => {
                return area.actions.map((action, g) => {
                    if (g === 0) {
                        return (
                            <React.Fragment key={area.areaName + g}>
                                <TableRowStyled>
                                    <AreaCellStyled rowSpan={area.actions.length + 1}>
                                        {capitalizeFirstLetter(area.areaName)}
                                    </AreaCellStyled>
                                </TableRowStyled>
                                <TableRowStyled>{renderActionDataRow(action)}</TableRowStyled>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <TableRowStyled key={area.areaName + g}>
                                {renderActionDataRow(action)}
                            </TableRowStyled>
                        )
                    }
                })
            })}
        </>
    )
}
