import { uuid } from 'core'
import { ActionType, CreateNodeActionType, CRItemType } from '../types'
import { useChangeRequestActions } from './useChangeRequestActions'

interface UseNodeActionsValue {
    archiveNode: (itemId: string) => void
    createNode: (name: string, action: CreateNodeActionType, parentId?: string | null) => void
    moveNode: (itemId: string, newParentId?: string | null) => void
    renameNode: (itemId: string, newName: string) => void
    restoreNode: (itemId: string) => void
    createShortcut: (parentId: string, childId: string) => void
    removeShortcut: (itemId: string) => void
}

export const useNodeActions = (): UseNodeActionsValue => {
    const { pushAndApplyCrItem } = useChangeRequestActions()

    return {
        archiveNode(itemId) {
            pushAndApplyCrItem({
                itemType: CRItemType.NODE,
                itemId,
                action: ActionType.ARCHIVE,
            })
        },

        createNode(name, action, parentId) {
            pushAndApplyCrItem({
                itemId: uuid(),
                itemType: CRItemType.NODE,
                action,
                name,
                isCategory: action !== ActionType.CREATE_SKILL,
                parentId: parentId || null,
            })
        },

        moveNode(itemId, newParentId) {
            pushAndApplyCrItem({
                itemId,
                itemType: CRItemType.NODE,
                action: ActionType.MOVE,
                newParentId,
            })
        },

        renameNode(itemId, newName) {
            pushAndApplyCrItem({
                itemId,
                itemType: CRItemType.NODE,
                action: ActionType.RENAME,
                newName,
            })
        },

        restoreNode(itemId) {
            pushAndApplyCrItem({
                itemId,
                itemType: CRItemType.NODE,
                action: ActionType.RESTORE,
            })
        },

        createShortcut(parentId, childId) {
            pushAndApplyCrItem({
                itemId: uuid(),
                itemType: CRItemType.SHORTCUT,
                action: ActionType.CREATE_SHORTCUT,
                parentId,
                childId,
            })
        },

        removeShortcut(itemId) {
            pushAndApplyCrItem({
                itemId,
                itemType: CRItemType.SHORTCUT,
                action: ActionType.REMOVE,
            })
        },
    }
}
