import { EmployeeAvailability } from 'components/EmployeeAvailability'
import { LazyImage } from 'components/LazyImage'
import { NothingHereYet } from 'components/NoContents/styled'
import { DefaultIconGray } from 'images'
import { setErrorNotification } from 'notifications'
import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MoonLoader } from 'react-spinners'
import { RootState, useAppDispatch } from 'store/store'
import { getStringParts } from 'utils/functions/presenters'
import {
    LoaderContainer,
    NameSpan,
    ProfileTextContainer,
    Root,
    SEmployeeEmailText,
    SEmployeeStatusText,
    SPositionRoleText,
    SProfileInfoSection,
    SProfileInfoSectionText,
    SProfileInfoSectionTitle,
    SUserAvatar,
} from './styled'
import { ProfileState } from '../../store/slices/profile'
import { ExtraProfileInfo } from '../../utils/types/profile'
import { UserProfile } from '../../utils/types/auth'
import { ChangeEmployeeRole } from 'parts/ChangeEmployeeRole/ChangeEmployeeRole'
import { StaffingIcon } from 'components/IconComponents/StaffingIcon'
import { openStaffingProfile } from 'utils/functions/openStaffingPorfile'
import { CursorPointer } from 'utils/theme/Lib'
import { Tooltip } from 'components/Tooltip'
import { StyledLink } from 'components/StyledLink'
import { NameBlock } from 'search/ui/EmployeeTable/styled'
import { blue400 } from 'utils/theme/colors'
import { selectAllUsers } from 'store/slices/users/selectors'

interface ProfileAdditionalInfoProps {
    employeeProfile?: UserProfile | null
    loadingAdditionalInfo?: boolean
    extraProfileInfo?: ExtraProfileInfo | null
    solidLineUserId?: string
}

const ProfileAdditionalInfo: FC<ProfileAdditionalInfoProps> = ({
    employeeProfile,
    loadingAdditionalInfo,
    extraProfileInfo,
    solidLineUserId,
}) => {
    if (loadingAdditionalInfo && extraProfileInfo === undefined) {
        return (
            <LoaderContainer>
                <MoonLoader />
            </LoaderContainer>
        )
    }

    if (!loadingAdditionalInfo && extraProfileInfo === null) {
        return (
            <LoaderContainer>
                <NothingHereYet>Can&apos;t get additional info</NothingHereYet>
            </LoaderContainer>
        )
    }

    if (!extraProfileInfo || !employeeProfile) {
        return <></>
    }
    const certificates = extraProfileInfo?.certificates

    return (
        <>
            <SPositionRoleText data-automation-id="label-user-position">
                {extraProfileInfo!.position}
            </SPositionRoleText>
            <SEmployeeStatusText data-automation-id="label-user-external-consultant">
                {extraProfileInfo!.isExternalConsultant ? 'Contractor' : 'Employee'}
            </SEmployeeStatusText>
            <SPositionRoleText data-automation-id="label-user-role">Role</SPositionRoleText>
            <SEmployeeStatusText>
                <ChangeEmployeeRole employee={employeeProfile} />
            </SEmployeeStatusText>
            <SEmployeeEmailText data-automation-id="label-user-email">
                {employeeProfile?.email}
            </SEmployeeEmailText>
            <SProfileInfoSection>
                <SProfileInfoSectionTitle>Solid-line manager</SProfileInfoSectionTitle>
                <SProfileInfoSectionText data-automation-id="label-user-solidline">
                    <StyledLink to={`/profile/${solidLineUserId}`} baseColor={blue400}>
                        <NameBlock>{extraProfileInfo!.solidLineManager}</NameBlock>
                    </StyledLink>
                </SProfileInfoSectionText>
            </SProfileInfoSection>

            {certificates.length > 0 && (
                <SProfileInfoSection>
                    <SProfileInfoSectionTitle>Certificates</SProfileInfoSectionTitle>
                    {certificates.map((c) => (
                        <SProfileInfoSectionText key={c}>{c}</SProfileInfoSectionText>
                    ))}
                </SProfileInfoSection>
            )}

            <SProfileInfoSection>
                <EmployeeAvailability
                    data-automation-id="label-user-availability"
                    availability={extraProfileInfo!.availability}
                    nearestAvailability={extraProfileInfo.nearestAvailability}
                    nearestAvailabilityDay={extraProfileInfo.nearestAvailabilityDay}
                />
            </SProfileInfoSection>
        </>
    )
}

/* TODO: add to db and send solid-line details (photo, name) for each candidate */
export const ProfileSummary: FC = () => {
    const {
        profile: employeeProfile,
        loadingAdditionalInfo,
        extraInfo: extraProfileInfo,
    } = useSelector<RootState, ProfileState>((state) => state.profile)

    const users = useSelector(selectAllUsers)

    const dispatch = useAppDispatch()

    const solidLineUserId = users.find(
        (item) => item.name === extraProfileInfo?.solidLineManager
    )?.employee_id

    useEffect(() => {
        if (employeeProfile !== null && !loadingAdditionalInfo && extraProfileInfo === null) {
            dispatch(setErrorNotification("Can't get additional info from staffing"))
        }
    }, [loadingAdditionalInfo, extraProfileInfo, employeeProfile, dispatch])

    return (
        <Root data-automation-id="container-user-common-info">
            <SUserAvatar>
                <LazyImage
                    width="151px"
                    height="151px"
                    borderRadius="50%"
                    withoutBorder
                    src={employeeProfile?.staffing_pic || employeeProfile?.google_pic}
                    fallbackImg={<DefaultIconGray />}
                    data-automation-id="image-user-lazy-image"
                />
            </SUserAvatar>
            <ProfileTextContainer>
                {getStringParts(employeeProfile?.name, NameSpan)}
                <Tooltip isVisible text="Staffing">
                    <CursorPointer
                        onClick={() =>
                            employeeProfile?.staffing_id &&
                            openStaffingProfile(employeeProfile.staffing_id.toString())
                        }
                    >
                        <StaffingIcon />
                    </CursorPointer>
                </Tooltip>
                <ProfileAdditionalInfo
                    employeeProfile={employeeProfile}
                    loadingAdditionalInfo={loadingAdditionalInfo}
                    extraProfileInfo={extraProfileInfo}
                    solidLineUserId={solidLineUserId}
                />
            </ProfileTextContainer>
        </Root>
    )
}
