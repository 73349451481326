import { BrowserHistory } from 'history'
import React, { useLayoutEffect, useState } from 'react'
import { BrowserRouterProps, Router as RRDRouter } from 'react-router-dom'

interface Props extends BrowserRouterProps {
    history: BrowserHistory
}
export const Router = ({ basename, history, children }: Props) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    })

    useLayoutEffect(() => history.listen(setState), [history])

    return (
        <RRDRouter
            navigator={history}
            location={state.location}
            navigationType={state.action}
            children={children}
            basename={basename}
        />
    )
}
