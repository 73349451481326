import React from 'react'
import { useTaxonomyContext } from '../../context/TaxonomyContext'
import { ActionType } from '../../types'
import CreateCategoryDialog from './CreateCategoryDialog'
import CreateRootItem from './CreateRootItem'
import CreateShortcutDialog from './CreateShortcutDialog'
import CreateSkillDialog from './CreateSkillDialog'
import EditDialog from './EditDialog'
import MoveDialog from './MoveDialog'

const ActionDialogSwitch = () => {
    const { action } = useTaxonomyContext()
    const dialog = () => {
        switch (action) {
            case ActionType.MOVE:
                return <MoveDialog />
            case ActionType.RENAME:
                return <EditDialog />
            case ActionType.CREATE_CATEGORY:
                return <CreateCategoryDialog />
            case ActionType.CREATE_SKILL:
                return <CreateSkillDialog />
            case ActionType.CREATE_SHORTCUT:
                return <CreateShortcutDialog />
            case ActionType.CREATE_ROOT_ITEM:
                return <CreateRootItem />
            default:
                ;<></>
        }
    }

    return <>{dialog()}</>
}

export default ActionDialogSwitch
