import { instance } from './instance'
import { responseWrapper } from './responseWrapper'

const get = async <T = any>(url: string, header?: object): Promise<T> => {
    const res = await responseWrapper(() => instance.get(url, header))
    return res.data
}

const _delete = async (url: string) => {
    const res = await responseWrapper(() => instance.delete(url))
    return res.data
}

const put = async <T = any>(url: string, body?: any): Promise<T> => {
    const res = await responseWrapper(() => instance.put(url, body))
    return res.data
}

const post = async <T = any>(url: string, body?: any, header?: object): Promise<T> => {
    const res = await responseWrapper(() => instance.post(url, body, header))
    return res.data
}

const patch = async <T = any>(url: string, body?: any, header?: object): Promise<T> => {
    const res = await responseWrapper(() => instance.patch(url, body, header))
    return res.data
}

export const client = {
    get,
    put,
    _delete,
    post,
    patch,
}
