export interface TabProps {
    isActive?: boolean
    width: number
    height: number
    marginLeft?: string
}

export enum IconSize {
    Big = 'Big',
    Medium = 'Medium',
    Small = 'Small',
}

export interface ModalRootProps {
    width?: string
    height?: string
    padding?: string
}

export interface SelectWrapperProps {
    isSecondChild?: boolean
    doesContainError?: boolean
}
export type ColumnProps = {
    size: number
}

export interface HeaderRootProps {
    spaceBetween?: boolean
}

export interface InfoProps extends IconBaseColorModel {
    top: number
}
export interface IconBaseColorModel {
    color?: string
}
export interface LazyImageWrapper {
    isInterviewer?: boolean
    withoutBorder?: boolean
}
export interface FallBackProps extends ModalRootProps {
    borderRadius: string | undefined
}
export interface RowProps {
    isVisible: boolean
    paddingBottom?: string
    shouldBeGreyed?: boolean
    userIsInterviewer?: boolean
}
export interface HeaderRow extends RowProps {
    shouldRenderBorderBottom: boolean
}
export interface CellProps extends ModalRootProps {
    isBold?: boolean
    textAlign?: 'center' | 'left' | 'right'
    fontSize?: '14px' | '16px'
    pointer?: boolean
}
export interface HeaderCell extends CellProps {
    index: number
    shouldBeOpen?: boolean
}
export interface InputWrapperProps {
    doesContainError?: boolean
}
export interface SMBProps extends ModalRootProps {}

export interface PositionTL {
    top: number
    left: number
    display: boolean
}

export interface OverflowPositionProps {
    hidden?: boolean
}
