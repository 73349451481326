import React, { DetailedHTMLProps, HTMLAttributes, LegacyRef, useRef } from 'react'
import { List, CellMeasurer, CellMeasurerCache, ListRowProps } from 'react-virtualized'

// for Autosuggest only
export const VirtualizedList = () =>
    React.forwardRef(function VirtualizedWrapper(
        props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
        ref
    ) {
        const { children, ...other } = props
        const itemCount = Array.isArray(children) ? children.length : 0

        const cache = new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100,
        })

        function renderRow(props: ListRowProps) {
            const { index, key, style, parent } = props

            return (
                <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                    {({ registerChild }) => (
                        <div style={style} ref={registerChild as LegacyRef<HTMLDivElement> | undefined}>
                            {
                                //@ts-ignore
                                children[index]
                            }
                        </div>
                    )}
                </CellMeasurer>
            )
        }

        const sourceElementId = other?.id?.replace('-listbox', '')
        const sourceElement = document.getElementById(sourceElementId || '')

        const defaultHeight = 300
        const defaultWidth = 400

        const windowHeight = useRef(window.innerHeight)?.current
        const calculatedHeight = windowHeight * 0.4

        const calculatedWidth = sourceElement?.parentElement?.parentElement?.clientWidth

        return (
            <div ref={ref as LegacyRef<HTMLDivElement> | undefined}>
                <div {...other}>
                    <List
                        height={windowHeight ? calculatedHeight : defaultHeight}
                        width={calculatedWidth || defaultWidth}
                        rowHeight={cache.rowHeight}
                        deferredMeasurementCache={cache}
                        overscanRowCount={5}
                        rowCount={itemCount}
                        rowRenderer={renderRow}
                    />
                </div>
            </div>
        )
    })
