import React from 'react'
import { Availability, Circle, Root } from './styled'
import { lightGreen, red } from '../../utils/theme/colors'
import { Tooltip } from 'components/Tooltip'

interface AvailabilityProp {
    availability: number
    nearestAvailability: number
    nearestAvailabilityDay: number
}

const getAvailabilityColor = (availability: number, nearestAvailability: number) => {
    // if (avl > 75) {
    //     return red
    // }
    // if (avl > 25 && avl < 75) {
    //     return yellow
    // }
    // return lightGreen

    if (availability === 0 && nearestAvailability === 0) return red
    else return lightGreen
}

export const EmployeeAvailability: React.FC<AvailabilityProp> = ({
    availability,
    nearestAvailability,
    nearestAvailabilityDay,
}) => {
    const isTooltipVisible = () => !(nearestAvailability === 0 && nearestAvailabilityDay === 0)

    const tooltipText = `
    ${nearestAvailability} FTE in ${nearestAvailabilityDay} days
    `

    return (
        <Root>
            <Circle
                data-automation-id="availability-icon"
                style={{ backgroundColor: getAvailabilityColor(availability, nearestAvailability) }}
            />
            <Tooltip isVisible={isTooltipVisible()} text={tooltipText}>
                <Availability>Availability</Availability>
            </Tooltip>
        </Root>
    )
}
