import React, { FC } from 'react'
import { Role } from 'utils/types/admin'
import { AreaCellStyled, LabelTableCellStyled, VisibilityCellStyled } from './styled'

interface HeaderProps {
    roles: Role[]
}

export const RoleHeader: FC<HeaderProps> = ({ roles }) => {
    return (
        <>
            <AreaCellStyled>Area</AreaCellStyled>
            <VisibilityCellStyled>Areas of visibility & permitted actions</VisibilityCellStyled>
            {roles.map((role) => (
                <LabelTableCellStyled key={role.id}>{role.name}</LabelTableCellStyled>
            ))}
        </>
    )
}
