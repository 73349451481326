import { styled } from '../../utils/core.styled'
import { CheckboxLabelProps, CheckboxProps } from '../../utils/core.types'
import { getCheckboxIcon } from './getCheckboxIcon'

export const CheckboxStyled = styled('input')<CheckboxProps>`
    position: absolute;
    display: none;

    & + label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        width: max-content;
        cursor: pointer;
        ${({ disabled }) => (disabled ? 'opacity: 0.6' : 'cursor: pointer')}
    }

    & + label::before {
        border: none;
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        flex-grow: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
        box-sizing: border-box;
        background-image: url(${({ getIcon = getCheckboxIcon, ...params }) => getIcon(params)});
        ${({ disabled }) => disabled && 'opacity: 0.6'};
    }

    &:checked + label::before {
        box-shadow: none;
    }
`
export const Label = styled('label')<CheckboxLabelProps>`
    color: ${({ color }) => color};
    font-size: ${({ labelFontSize }) => labelFontSize || '14px'};
`
