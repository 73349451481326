import { useSelector } from 'react-redux'
import { AdminState } from '../../store/slices/admin'
import { RolesForSkillsSubscreen } from '../helpers/constants'
import { RootState } from '../../store/store'

export const useRoles = () =>
    useSelector<RootState, AdminState>((state) => state.admin).roles.map((role) => ({
        ...role,
        name: Object.values(RolesForSkillsSubscreen).find((item) => item.role === role.code.toUpperCase())!
            .name,
    }))
