import { Box } from '@mui/material'
import { ArrowDownGrey, ArrowUpGrey } from 'images'
import React, { FC, memo, useEffect, useRef } from 'react'
import { Handle, Position } from 'react-flow-renderer'
import { useSelector } from 'react-redux'
import { selectAutoSuggestValue } from 'store/slices/autosuggest/selectors'
import { useTaxonomyContext } from '../../context/TaxonomyContext'
import { NodeData } from '../../types'
import { CountStyled, CustomNodeStyled, LabelStyled } from './CustomNode.styled'

interface CustomNodeProp {
    data: NodeData
}

export const CustomNode: FC<CustomNodeProp> = memo(({ data }) => {
    const { menuAnchor } = useTaxonomyContext()
    const nodeElement = useRef(null)
    const isNodeSearchedForInTaxonomy = useSelector(selectAutoSuggestValue).autoSuggestValue === data.id
    const isHighlightedNodeIfShortcutClicked =
        useSelector(selectAutoSuggestValue).nodeIsHighlighted === data.id

    const defineIsHighlighted = () => {
        return !!isHighlightedNodeIfShortcutClicked || !!isNodeSearchedForInTaxonomy
    }

    useEffect(() => {
        defineIsHighlighted()
    }, [isNodeSearchedForInTaxonomy, isHighlightedNodeIfShortcutClicked])

    const childrenCount = data.children.filter((node) => !node.data.isArchived).length
    const isArchivedParent = Boolean(data.isArchived) && Boolean(data.parent?.data.isArchived)
    const dataType = data.isShortcut
        ? 'shortcut'
        : data.isCategory
        ? data.parentId == null
            ? 'rootCategory'
            : 'category'
        : 'skill'
    return (
        <CustomNodeStyled
            className="CustomNode-root"
            ref={nodeElement}
            data={data}
            data-automation-id={`${data.label}-${dataType}`}
            nodeMenuShown={menuAnchor ? nodeElement.current === menuAnchor : false}
            isHighlighted={defineIsHighlighted()}
            hoverMenuShown={!isArchivedParent}
        >
            <Handle type="target" position={Position.Left} style={{ visibility: 'hidden' }} />

            <LabelStyled title={data.label}>{data.label}</LabelStyled>
            <CountStyled className="NumberLabel">{childrenCount || null}</CountStyled>
            <Box component="div" className="arrow-button-wrapper">
                {menuAnchor ? <ArrowUpGrey /> : <ArrowDownGrey />}
            </Box>

            <Handle type="source" position={Position.Right} style={{ visibility: 'hidden' }} />
        </CustomNodeStyled>
    )
})
