import React from 'react'

export enum Direction {
    downwards = 'downwards',
    upwards = 'upwards',
}

export const Arrow = ({ onClick }: { color?: string; direction?: Direction; onClick?: () => void }) => {
    return (
        <svg
            onClick={onClick}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7071 10.707C18.0976 10.3165 18.0976 9.6833 17.7071 9.29277C17.3166 8.90225 16.6834 8.90225 16.2929 9.29277L11.9999 13.5857L7.70711 9.2929C7.31658 8.90238 6.68341 8.90238 6.29289 9.2929C5.90237 9.68343 5.90237 10.3166 6.29289 10.7071L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071C12.7093 15.7048 12.7116 15.7026 12.7138 15.7003L17.7071 10.707Z"
                fill="#95A5B6"
            />
        </svg>
    )
}
