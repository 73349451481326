import { navigate } from 'app/store'
import { Panda, Plus } from 'images'
import React, { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { userIdSelector } from 'store/selectors/auth-selectors'
import { useUserProfile } from 'utils/hooks/useUserProfile'
import { Button } from '../Button'
import { FileWrapper, TextBlock, ImageBlock, TextPoint, PointsWrapper } from './NoSkillsProfile.styled'

interface NoSkillsProfileProps {
    children?: ReactNode
}

export const NoSkillsProfile: FC<NoSkillsProfileProps> = () => {
    const currentUserId = useSelector(userIdSelector)
    const { userId } = useUserProfile()

    return (
        <FileWrapper data-automation-id="container-no-content">
            {currentUserId === userId ? (
                <>
                    <TextBlock>
                        <PointsWrapper>
                            <TextPoint>Fill your skills as you feel it</TextPoint>
                            <TextPoint>Review them with your solid line manager</TextPoint>
                            <TextPoint>Profit !!!</TextPoint>
                        </PointsWrapper>
                    </TextBlock>
                    <ImageBlock>
                        <Button
                            variant="contained"
                            startIcon={<Plus />}
                            onClick={() => navigate(`/skills`)}
                            id="button-add-first-skill"
                        >
                            Add your first skill
                        </Button>
                        <Panda />
                    </ImageBlock>
                </>
            ) : (
                <>
                    <TextBlock>
                        <PointsWrapper>
                            <TextPoint>The user hasn't specified any skills yet</TextPoint>
                        </PointsWrapper>
                    </TextBlock>
                    <ImageBlock>
                        <Panda />
                    </ImageBlock>
                </>
            )}
        </FileWrapper>
    )
}
