import { white } from 'utils/theme/colors'
import { styled } from '../../utils/core.styled'

export const FileWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 74px;
    background: ${white};
    border-radius: 5px;
    width: 100%;
`
export const TextBlock = styled('div')`
    display: flex;
    justify-content: flex-start;
    height: 286px;
    margin-bottom: 25px;
`
export const PointsWrapper = styled('ul')`
    margin: 0;
`
export const TextPoint = styled('li')`
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
`
export const ImageBlock = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 34px;
`
