export const anthracite = '#393E42'
export const tannGrey = '#959CB4'
export const tannBlue = '#2977FF'
export const blue3 = '#BCE1FF'
export const darkerGrey = '#DCE1F0'
export const lightGreen = '#79D8AA'
export const lightBackgroundGrey = '#D1D1D1'
export const separatorGrey = '#E0E6F5'
export const black = '#000000'
export const brown = '#964B00'
export const deepBrown = '#654321'
export const featuredBlue = '#0094B7'
export const lightSky = '#C4DEE3'
export const sky = '#0494BB'
export const lightFeaturedBlue = '#19B0D3'
export const disabledFeaturedBlue = '#0D4552'
export const lightBlue = '#E6F7FD'
export const aliceBlue = '#EAF9FF'
export const lightCyan = '#E7F0F2'
export const deepBlue = '#2F80ED'
export const blue = '#E2E8EA'
export const blue400 = '#407CE5;'
export const white = '#FFFFFF'
export const offWhite = '#EDEDED'
export const grey = '#A9A9A9'
export const grey7 = '#C6CED8'
export const grey15 = '#8597AB'
export const grey20 = '#60758C'
export const grey95 = '#F2F2F2'
export const lightBlueGrey = '#E7ECF9'
export const brightGrey = '#4D5360'
export const greyProfile = '#F5F5F5'
export const gainsboro = '#E0E0E0'
export const platinum = '#E5E5E5'
export const silver = '#BABABA'
export const chineseSilver = '#CFD6ED'
export const classicGrey = '#828282'
export const deepGrey = '#666666'
export const lightGrey = '#999999'
export const lightestGrey = '#F4F4F4'
export const green = '#9ACD32'
export const brightGreen = '#6FCF97'
export const yellow = '#FCF4A3'
export const orange = '#F2994A'
export const warmYellow = '#F2C94C'
export const deepYellow = '#F5D010'
export const violet = '#BE7AD7'
export const red = '#EF413D'
export const cherryRed = '#D73A08'
export const bloodRed = '#FC0B38'
export const lightRed = '#EECFCF'
export const tutu = '#8597AB'
export const transparent = 'transparent'
export const azure = '#F0F4F5'
export const whiteGrey = '#FBFCFE'
export const purple = '#E7ECF9'
export const backGroundColor = '#F5F7FD'
export const streetGrey = '#BEC7D2'
export const blueMarine = '#E3F3FF'
export const moonGrey = '#EAEDF7'
export const moonBlue = '#BCE1FF'
export const lightPurple = '#F7F8F9'
export const cornBlue = '#F4FAFF'
export const borderGrey = '#D6DCE3'
export const defaultGrey = '#425468'
export const darkPurple = '#3e2460'
export const cyanGray = '#a5b3c1'
export const navyGrey = '#7c90a5'
export const purpleLavender = '#5900CB'
export const purpleRose = '#F7F2FE'
export const yellowMacaroon = '#FDF0C3'
export const rolesTableHeaderBgColor = '#FAFAFA'
export const bluePrimary = '#3764D9'

export const enum NodesColors {
    BLUE_BORDER = '#5CBBFF',
    GREEN_CR_TITLE = '#39C49B',
}

export const enum ChangeRequestColors {
    GREEN_CR_TITLE = '#39C49B',
    GREY_CR_TITLE = '#ADBAC7',
    YELLOW_COMMENT_BACKGROUND = '##FFEFB9',
    ORANGE_CR_TITLE = '#F2A000',
}

export const enum NotificationColors {
    RED_BORDER = '#FFCCBC',
    GREEN_BORDER = '#ADF6CF',
    GREY_BORDER = '#DEE3E9',
    BLUE_BORDER = '#BCE1FF',
    GREEN = 'rgba(242, 254, 247, 0.8)',
    RED = 'rgba(253, 244, 243, 0.8)',
    RED_HEX = '#FDF4F3',
    CLOSE_GREEN = '#98E1CC',
    CLOSE_RED = '#FFCCBC',
}

export const enum TypographyColors {
    LAVENDER_GREY = '#95A5B6',
    DARK_CYAN = '#173348',
    DARK_GREY = '#212121',
    GREY = '#C9D0D5',
    BLACK = '#000000',
    BLUE = '#2E65EC',
    LIGHT_GREY = '#9098AA',
    LIGHT_BLUE = '#1F8BFF',
}

export const enum ButtonColors {
    BLUE_REGULAR = '#2977FF',
    BLUE_DISABLED = '#B7D1FF',
    BLUE_HOVER = '#6AA0FF',
    WHITE = '#FFFFFF',
    GREY = '#95A5B6',
    TEXT = '#173348',
}

export enum SkillLevelColors {
    EXPERT = '#4C86EB',
    ADVANCED = '#79D8AA',
    MIDDLE = '#B0A3F3',
    BASIC = '#EF8B6C',
    NOVICE = '#F8DB75',
    WTL = '#9ACD32',
    DWTL = '#DEE3E9',
}

export const enum BackgroundColor {
    DARK_GREY = '#E7EAEE',
    INACTIVE_GREY = '#F7F8F9',
    BORDER_GREY = '#DEE3E9',
    SHADOW_COLOR = '#0000001A',
    BLUE_MARINE = '#E3F3FF',
    GREY_SHADOW = '#EFF1F4',
    BLUE_GREY = '#E7ECF9',
}
