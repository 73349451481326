import ErrorBoundary from 'components/ErrorBoundary'
import React, { FC, useEffect } from 'react'
import { useUserProfile } from '../../../utils/hooks/useUserProfile'
import { FastAddingSkills } from './FastAddingSkills'
import { FocusSkills } from './FocusSkills'

export const SkillsHeader: FC = () => {
    const { isEditing } = useUserProfile()
    const { editMode } = useUserProfile()

    useEffect(() => {
        editMode(false)
    }, [])

    return (
        <ErrorBoundary>
            <FocusSkills />
            {isEditing && <FastAddingSkills />}
        </ErrorBoundary>
    )
}
