import React, { FC, memo, useState } from 'react'
import {
    Autocomplete,
    AutocompleteProps,
    AutocompleteRenderInputParams,
    AutocompleteRenderOptionState,
    ListItem,
    TextField,
} from '@mui/material'
import { CrossIconSmall } from 'images'
import { SelectArrowDownIcon } from '../Select'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'

export interface AutoSuggestProps
    extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput' | 'onInputChange'> {
    id: string
    options: any[]
    label?: string
    placeholder?: string
    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
    value?: any
    onInputChange?: (value: string) => void
    inputValue?: string
    ListboxComponent?: any
    onChange?: (value: any) => void
    onClear?: () => void
    getName?: (option: any) => string
    getId?: (option: any) => string | number
    renderOption?: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: any,
        state: AutocompleteRenderOptionState,
        getName?: (option: any) => string
    ) => React.ReactNode
    InputProps?: Partial<StandardInputProps>
}

export enum AutoSuggestReasons {
    Select = 'selectOption',
    Remove = 'removeOption',
    Clear = 'clear',
    Input = 'input',
    Reset = 'reset',
}

const getAutoSuggestDefaultRenderInput =
    (props: AutoSuggestProps) => (params: AutocompleteRenderInputParams) =>
        (
            <TextField
                {...params}
                label={props.label}
                placeholder={props.placeholder}
                InputProps={{
                    ...params.InputProps,
                    ...props.InputProps,
                }}
            />
        )

const AutoSuggestDefaultRenderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
    getName: (option: any) => string
): React.ReactNode => {
    return (
        <ListItem {...props} key={option.id}>
            {getName(option)}
        </ListItem>
    )
}

export const AutoSuggest: FC<AutoSuggestProps> = memo((props) => {
    const {
        onInputChange,
        onChange,
        onClear,
        getName = (option) => option.name,
        id,
        renderOption,
        ...restProps
    } = props

    const [currentInputValue, setCurrentInputValue] = useState('')

    const handleValueChange = (event: React.SyntheticEvent, value: any, reason: string) => {
        if (value !== currentInputValue) setCurrentInputValue(value)

        switch (reason) {
            case AutoSuggestReasons.Select: {
                onChange?.(value)
                break
            }
            case AutoSuggestReasons.Remove: {
                onChange?.(value)
                break
            }
            case AutoSuggestReasons.Clear: {
                onClear?.()
                break
            }
            default:
        }
    }

    const handleInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
        switch (reason) {
            case AutoSuggestReasons.Input:
                onInputChange?.(value)
                break
            default:
        }
    }

    return (
        <Autocomplete
            {...restProps}
            id={id}
            data-automation-id={id}
            isOptionEqualToValue={(option, value) => getName(option) === getName(value)}
            autoHighlight
            clearIcon={<CrossIconSmall />}
            getOptionLabel={(option) => getName(option) || ''}
            renderOption={(props, option, state) => {
                const fn = renderOption || AutoSuggestDefaultRenderOption
                return fn(props, option, state, getName)
            }}
            renderInput={props.renderInput || getAutoSuggestDefaultRenderInput(props)}
            onInputChange={handleInputChange}
            onChange={handleValueChange}
            autoComplete={true}
            multiple={props.multiple || false}
            freeSolo={!!currentInputValue}
            popupIcon={
                props.popupIcon || <SelectArrowDownIcon className="MuiSelect-icon MuiSelect-iconOutlined" />
            }
        />
    )
})
