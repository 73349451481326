import { ChangeRequest, HistoryType } from 'taxonomy/types'
import { ChangeRequestColors, purpleLavender, white } from 'utils/theme/colors'

export const isActiveChangeRequest = (cr?: ChangeRequest | null) => {
    if (cr === null || !cr) return false
    if (cr.status === HistoryType.APPROVED || cr.status === HistoryType.ARCHIVED) return false
    return true
}

export const showIntTimeFormat = (cr: ChangeRequest): string => {
    const crTime = cr.history[cr.history.length - 1].timestamp

    const timing = new Intl.DateTimeFormat('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h24',
    })
    return timing.format(crTime)
}

export const listActiveCRsFirst = (a: ChangeRequest, b: ChangeRequest) => {
    const keyC: any = new Date(a.history[a.history.length - 1].timestamp)
    const keyD: any = new Date(b.history[b.history.length - 1].timestamp)
    return keyD - keyC
}

export const getChangeRequestTitleColor = (status: HistoryType) => {
    switch (status) {
        case HistoryType.APPROVED:
            return ChangeRequestColors.GREEN_CR_TITLE
        case HistoryType.ARCHIVED:
            return ChangeRequestColors.GREY_CR_TITLE
        case HistoryType.ADJUSTMENT_REQUESTED:
            return purpleLavender
        case HistoryType.ON_REVIEW:
            return ChangeRequestColors.ORANGE_CR_TITLE
        default:
    }
}

export const getCRsCommentBackground = (status: HistoryType) => {
    switch (status) {
        case HistoryType.APPROVED:
            return white
        case HistoryType.ARCHIVED:
            return white
        case HistoryType.DRAFT:
            return ChangeRequestColors.YELLOW_COMMENT_BACKGROUND
        case HistoryType.ADJUSTMENT_REQUESTED:
            return ChangeRequestColors.YELLOW_COMMENT_BACKGROUND
        default:
    }
}
