import { setErrorNotification } from 'notifications'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { clearProfile } from 'store/shared/actions'
import { getEmployeeProfile } from 'store/slices/profile/requests'
import { RootState, useAppDispatch } from 'store/store'
import { LoaderWrapper } from 'utils/functions/presenters'
import { UserProfile } from 'utils/types/auth'
import { Profile } from '../../parts/Profile/Profile'
import { withAppLayout } from '../../parts/AppLayout/AppLayout'
import { Helmet } from 'react-helmet-async'
import { AuthState } from 'store/slices/auth'

export const ProfilePage = withAppLayout(() => {
    const loadingProfile = useSelector<RootState, boolean>((state) => state.profile.loadingProfile)
    const profile = useSelector<RootState, UserProfile | null | undefined>((state) => state.profile.profile)
    const authInfo = useSelector<RootState, AuthState | null | undefined>((state) => state.auth)

    const { id } = useParams<'id'>()

    const dispatch = useAppDispatch()

    const nothingHere = !loadingProfile && profile === null

    useEffect(() => {
        if (!id) return

        dispatch(getEmployeeProfile(id))
        return () => {
            dispatch(clearProfile())
        }
    }, [id])

    useEffect(() => {
        if (!loadingProfile && profile === null) {
            dispatch(setErrorNotification("Can't get profile"))
        }
    }, [loadingProfile, profile])

    const tabTitle =
        profile?.user_id === authInfo?.user?.user_id ? (
            <title>Skills Matrix</title>
        ) : (
            <title>{`${profile?.name} | Skills Matrix`}</title>
        )

    return (
        <LoaderWrapper isLoading={loadingProfile} hasNoContent={nothingHere}>
            <Helmet>{tabTitle}</Helmet>
            <Profile />
        </LoaderWrapper>
    )
})
