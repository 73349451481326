import React, { FC, useEffect } from 'react'
import { useSearchContext } from 'search/context/SearchContext'
import EmployeeTable from 'search/ui/EmployeeTable'
import { FilterControls } from 'search/ui/FilterControls'
import AppliedFilters from 'search/ui/AppliedFilters'
import { AdditionalFiltersStyled, SContainerWrapper, SWrapper } from './EmployeesSearchPage.styled'
import ErrorBoundary from '../../components/ErrorBoundary'
import { withAppLayout } from '../../parts/AppLayout/AppLayout'

export const EmployeesSearch: FC = () => {
    const {
        setSkills,
        setCompetencies,
        setSearchString,
        setLocations,
        setAvailableOnly,
        setPreset,
        setOnBench,
    } = useSearchContext()

    useEffect(() => {
        return () => {
            setSkills({})
            setCompetencies([])
            setSearchString('')
            setAvailableOnly(false)
            setLocations([])
            setPreset(null)
            setOnBench(false)
        }
    }, [])

    return (
        <SWrapper>
            <SContainerWrapper>
                <FilterControls />
                <AppliedFilters />
                <AdditionalFiltersStyled />
                <EmployeeTable />
            </SContainerWrapper>
        </SWrapper>
    )
}

export const EmployeesSearchPage: FC = withAppLayout(
    () => {
        return (
            <ErrorBoundary>
                <EmployeesSearch />
            </ErrorBoundary>
        )
    },
    { wideView: true }
)
