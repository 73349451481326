import { styled } from 'core'
import { cherryRed, cyanGray } from 'utils/theme/colors'

export const SDialogLabel = styled('label')`
    font-size: 14px;
    line-height: 16px;
`
export const NameErrorContainer = styled(SDialogLabel)`
    display: flex;
    flex-direction: column;
`

export const ErrorLabel = styled('label')`
    color: ${cherryRed};
    font-size: 14px;
`
export const ErrorButton = styled('label')`
    cursor: pointer;
`

export const SDialogHeader = styled('div')`
    display: flex;
    justify-content: space-between;
    height: 24px;
    padding: 20px;
    cursor: move;
    border-bottom: 1px solid ${cyanGray};
`

export const SDialogContent = styled('div')`
    display: flex;
    flex-direction: column;
    margin: 20px;
    gap: 20px;
`
