import { Chip, Table, TableBody, TableHead, TableRow } from '@mui/material'
import React, { useCallback, useMemo, useRef } from 'react'
import { searchUsersRequest } from 'search/api/requests'
import { constructSearchBody } from 'search/context/converters'
import { useSearchContext } from 'search/context/SearchContext'
import { HeaderItem } from './HeaderItem'
import { Waypoint } from 'react-waypoint'
import EmployeeTableRow from './EmployeeTableRow'
import { PageLoader } from 'components/MoonLoader'

const EmployeeTable = () => {
    const {
        results,
        skills,
        locations,
        orderBy,
        competencies,
        searchString,
        availableOnly,
        setResults,
        loading,
        offset,
        setOffset,
        total,
        onBench,
        // comparingPeople,
        // showCompareTable,
    } = useSearchContext()
    const tableRef = useRef(null)
    const loadMore = useCallback(async () => {
        if (total && total > results.length) {
            const result = await searchUsersRequest(
                constructSearchBody({
                    skills,
                    locations,
                    competencies,
                    searchString,
                    availableOnly,
                    orderBy,
                    offset,
                    onBench,
                })
            )
            if (result) {
                setResults((prev) => [...prev, ...result?.data])
                setOffset((prev) => prev + 20)
            }
        }
    }, [
        total,
        results.length,
        skills,
        locations,
        competencies,
        searchString,
        availableOnly,
        orderBy,
        offset,
        setResults,
        setOffset,
        onBench,
    ])

    // const getComparingPeople = () => {
    //     const setOfId = new Set(comparingPeople)
    //     return results.filter((employee) => setOfId.has(employee.user_id))
    // }

    return useMemo(() => {
        return loading ? (
            <PageLoader />
        ) : (
            <>
                <Chip label={`Found: ${total} employees`} sx={{ marginTop: '16px', fontSize: '16px' }} />

                <Table>
                    <TableHead>
                        <TableRow>
                            <HeaderItem name="name" title="Name" />
                            <HeaderItem name="competence" title="Business unit" />
                            <HeaderItem name="grade" title="Grade" />
                            <HeaderItem name="availability" title="Availability" />
                            <HeaderItem title="Skills" />
                            <HeaderItem name="location" title="Location" />
                            <HeaderItem title="Actions" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.map((row) => (
                            <EmployeeTableRow row={row} key={row.user_id} onBench={onBench} />
                        ))}
                    </TableBody>
                </Table>
                <Waypoint scrollableAncestor={tableRef.current} onEnter={loadMore}></Waypoint>
            </>
        )
    }, [results, loading, loadMore])
}

export default EmployeeTable
