import { Box } from '@mui/material'
import { css, styled } from 'core'
import { moonBlue, separatorGrey, tannBlue } from 'utils/theme/colors'

export const Container = styled('div')`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    direction: rtl;

    div:hover ~ div,
    div:hover {
        background-color: #2977ff;
    }
`

interface Prop {
    isActive?: boolean
    highlight?: boolean
    isCurrentValue?: boolean
}

const getColor = (isActive: boolean | undefined, highlight: boolean | undefined) => {
    if (highlight && !isActive) return moonBlue
    return !isActive ? separatorGrey : tannBlue
}

const hoverStyles = css`
    > svg {
        visibility: visible;
    }
`

export const Circle = styled(Box)<Prop>`
    background-color: ${({ isActive, highlight }) => getColor(isActive, highlight)};
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 4px;
    position: relative;

    :last-child {
        margin-left: 0;
    }

    > svg {
        visibility: hidden;
        position: absolute;
        top: 4px;
        left: 4px;
    }

    :hover {
        ${({ isCurrentValue }) => isCurrentValue && hoverStyles}
    }
`
