import * as React from 'react'
import MuiMenu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

type Option =
    | string
    | {
          [key: string]: any
      }

interface MenuProps<T> {
    options: T[]
    getName?: (value: T) => string
    getId?: (value: T) => string | number
    defaultValue?: T
    getIsDisabled?: (value: T) => boolean | undefined
    getIsSelected?: (value: T) => boolean | undefined
    onChange?: (value: T) => void
    placeholder?: string
    renderToggle: (isOpen?: boolean) => React.ReactNode | string
}

export const Menu = <T extends Option>({
    options,
    onChange,
    getIsSelected,
    getIsDisabled,
    renderToggle,
    getName = (value) => (typeof value === 'string' ? value : value?.name),
    getId = (value) => (typeof value === 'string' ? value : value?.id),
}: MenuProps<T>) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, selectOption: T) => {
        setAnchorEl(null)
        onChange && onChange(selectOption)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <div onClick={handleClickListItem}>{renderToggle(open)}</div>

            <MuiMenu
                id="lock-menu"
                variant={'menu'}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {options &&
                    options.map((option) => (
                        <MenuItem
                            onClick={(event) => handleMenuItemClick(event, option)}
                            disabled={getIsDisabled?.(option)}
                            selected={getIsSelected?.(option)}
                            key={getId(option) ?? option}
                        >
                            {getName(option)}
                        </MenuItem>
                    ))}
            </MuiMenu>
        </>
    )
}
