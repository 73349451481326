import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import { Arrow } from 'components/Arrow'
import { ListItemText, MenuItem } from '@mui/material'
import { MoonLoader } from 'react-spinners'
import { styled } from '../../utils/core.styled'

export const SLoaderContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
`

export const SelectArrowDownIcon: React.FC<any> = (props: any) => (
    <span {...props} className={`material-icons ${props.className || ''}`}>
        <Arrow />
    </span>
)

interface PropsBase<T> extends Omit<MuiSelectProps<T>, 'value' | 'onChange'> {
    options: T[] | null
    renderOption?: (value: T) => React.ReactNode
    renderInput?: (value: T) => React.ReactNode
    getId?: (value: T) => string | number
    getName?: (value: T) => string
    getIsDisabled?: (value: T) => boolean
    id: string
    IconComponent?: React.ElementType<any>
}
export interface SelectPropsSingle<T> extends PropsBase<T> {
    multiple?: false
    value: T | null | undefined
    onChange: (value: T | null) => void
}
export interface SelectPropsMultiple<T> extends PropsBase<T> {
    multiple: true
    value: T[] | null | undefined
    onChange: (value: T[] | null) => void
}

export type SelectProps<T> = SelectPropsSingle<T> | SelectPropsMultiple<T>

export const Select = <T extends Record<string, any>>({
    id,
    value,
    label,
    multiple,
    options,
    onChange,
    variant,
    getId = (value: T) => (typeof value === 'string' ? value : value.id),
    getIsDisabled = (value: T) => (typeof value === 'string' ? false : value.isDisabled),
    getName = (value: T) => (typeof value === 'string' ? value : value.name),
    renderOption = (option: T) => <ListItemText primary={getName(option)} />,
    IconComponent = SelectArrowDownIcon,
    ...restProps
}: SelectProps<T>) => {
    return (
        <FormControl size={restProps.size} variant={variant}>
            <InputLabel id="demo-multiple-checkbox-label">{(label as any) ?? ''}</InputLabel>
            <MuiSelect
                {...restProps}
                label={label}
                multiple={multiple}
                data-automation-id={id}
                value={value ?? (multiple ? [] : ('' as any))}
                renderValue={(value: T | T[] | null) =>
                    value ? (Array.isArray(value) ? value.map(getName).join(', ') : getName(value)) : ''
                }
                onChange={(e) => onChange(e.target.value as any)}
                IconComponent={IconComponent}
            >
                {options && options?.length > 0 ? (
                    options.map((option) => (
                        <MenuItem key={getId(option)} disabled={getIsDisabled(option)} value={option as any}>
                            {renderOption(option)}
                        </MenuItem>
                    ))
                ) : (
                    <SLoaderContainer>
                        <MoonLoader size={15} />
                    </SLoaderContainer>
                )}
            </MuiSelect>
        </FormControl>
    )
}
