import { keyBy } from 'lodash'
import { Preset, PresetDTO } from 'utils/types/presets'

export const transformPresetDTOIntoPreset = (value: PresetDTO): Preset => ({
    id: value.id,
    name: value.name,
    skills: keyBy(value.skills, 'id'),
    competencies: value.competencies || [],
    locations: value.locations || [],
    text: value.text || '',
    available: value.available || false,
})
