import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAllUsers, fetchAllUsersWithCreateCrPermission } from '../../shared/requests'
import { ResponseData } from '../../../utils/types/common'
import { User } from '../../../utils/types/users'

export const getUsersInfo = createAsyncThunk('users/getUsersInfo', async (): Promise<UsersInfo> => {
    const [users] = await Promise.all<ResponseData<User>>([fetchAllUsers()])
    return {
        users: users.data,
    }
})

export const getUsersWithCreateCrPermissionInfo = createAsyncThunk(
    'users/getAdminUsersInfo',
    async (): Promise<UsersWithCreateCrPermissionInfo> => {
        const [users] = await Promise.all<ResponseData<User>>([fetchAllUsersWithCreateCrPermission()])
        return {
            usersWithCreateCrPermission: users.data,
        }
    }
)

export interface UsersInfo {
    users: User[]
}

export interface UsersWithCreateCrPermissionInfo {
    usersWithCreateCrPermission: User[]
}
