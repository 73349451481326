import { omit } from 'lodash'
import { UserPreset, UserSearchRequestBody } from 'search/api/types'
import { FilterSkill, OrderByField, SearchContextValue, SearchFilters } from './types'
import qs from 'qs'
import { NodeWithPath } from 'utils/types/skills'

// stringify url
export const stringifyFilters: (
    filters: Pick<
        SearchContextValue,
        'skills' | 'competencies' | 'locations' | 'searchString' | 'availableOnly' | 'orderBy' | 'onBench'
    >
) => string = (filters) => {
    const { skills, competencies, locations, searchString, availableOnly, orderBy, onBench } = filters
    const params: Record<any, any> = {
        skills: Object.values(skills).map((s) => stringifySkill(s)),
        locations: locations,
        competencies: competencies,
    }
    if (orderBy) {
        params.orderBy = stringifyOrderBy(orderBy)
    }
    if (searchString) {
        params.text = searchString
    }
    if (availableOnly) {
        params.availableOnly = true
    }
    if (onBench) {
        params.onBench = true
    }
    // if encode false, website cannot navigate back
    return qs.stringify(params, { encode: true, arrayFormat: 'repeat' })
}

export const stringifySkill = (s: FilterSkill) => [s.id, s.level_from, s.level_to].join('|')

export const stringifyOrderBy: (orderBy: OrderByField) => string = (orderBy) =>
    [orderBy.field, orderBy.asc].join('|')

// parse url

export const parseFilters: (
    searchParams: URLSearchParams,
    allSkills: NodeWithPath[]
) => Pick<
    SearchContextValue,
    'skills' | 'competencies' | 'locations' | 'searchString' | 'availableOnly' | 'orderBy' | 'onBench'
> = (searchParams, allSkills) => {
    const q = qs.parse(searchParams.toString())

    return {
        skills: q.skills ? parseSkills(q.skills as string | string[], allSkills) : {},
        competencies: q.competencies
            ? ((Array.isArray(q.competencies) ? q.competencies : [q.competencies]) as string[])
            : [],
        locations: q.locations
            ? ((Array.isArray(q.locations) ? q.locations : [q.locations]) as string[])
            : [],
        searchString: (q.text as string) || '',
        availableOnly: q.availableOnly === 'true',
        orderBy: q.orderBy ? parseOrderBy(q.orderBy as string) : null,
        onBench: q.onBench === 'true',
    }
}

export const parseSkills: (
    skills: string | string[],
    allSkills: NodeWithPath[]
) => Record<string, FilterSkill> = (skills, allSkills) => {
    if (Array.isArray(skills)) {
        return skills.reduce((acc, s) => {
            const skill = parseSkill(s, allSkills)
            acc[skill.id] = skill
            return acc
        }, {} as Record<string, FilterSkill>)
    } else {
        const skill = parseSkill(skills, allSkills)
        const result: Record<string, FilterSkill> = {}
        result[skill.id] = skill
        return result
    }
}

export const parseSkill: (skill: string, allSkills: NodeWithPath[]) => FilterSkill = (skill, allSkills) => {
    const skillObj = skill.split('|')
    return {
        id: skillObj[0],
        level_from: parseInt(skillObj[1], 10),
        level_to: parseInt(skillObj[2], 10),
        is_category: allSkills.find((item) => item.node_id.toString() === skillObj[0].toString())?.isCategory,
    } as FilterSkill
}

export const parseOrderBy: (orderBy: string) => OrderByField = (orderBy) => {
    const orderByObj = orderBy.split('|')
    return {
        field: orderByObj[0],
        asc: orderByObj[1] === 'true',
    } as OrderByField
}

// request body

const defineIfExist: (obj: Record<any, any>, value: any, propertyName: string) => void = (
    obj,
    value,
    propertyName
) => {
    if (value && !(Array.isArray(value) && value.length === 0)) {
        obj[propertyName] = value
    }
}

export const constructSearchBody = ({
    skills,
    locations,
    competencies,
    searchString,
    availableOnly,
    orderBy,
    offset,
    onBench,
}: SearchFilters): UserSearchRequestBody => {
    const body: UserSearchRequestBody = {
        skills: Object.values(skills).map((v) => omit(v, 'name')),
        page_size: 20,
        page_offset: offset,
    }
    defineIfExist(body, locations, 'locations')
    defineIfExist(body, competencies, 'competencies')
    defineIfExist(body, searchString, 'text')
    if (orderBy) {
        body.order_by = [orderBy] as OrderByField[]
    }
    if (availableOnly === true) {
        body.available = true
    }
    if (onBench === true) {
        body.on_bench = true
    }
    return body
}

export const constructPreset = ({
    skills,
    locations,
    competencies,
    searchString,
    availableOnly,
}: Omit<SearchFilters, 'offset' | 'orderBy'>): UserPreset => {
    const preset: UserPreset = {
        skills: Object.values(skills).map((v) => ({ ...omit(v, 'name') })),
        locations: locations,
        competencies: competencies,
        text: searchString,
    }
    if (availableOnly === true) {
        preset.available = true
    }
    return preset
}
