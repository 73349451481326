import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const authSelector = (state: RootState) => state.auth
const _userSkillsSelector = (state: RootState) => state.auth.user?.skills
const _userIdSelector = (state: RootState) => state.auth.user?.user_id
const _user = (state: RootState) => state.auth.user

export const authInfo = createSelector(authSelector, (auth) => auth)
export const userSkillsSelector = createSelector(_userSkillsSelector, (skills) => skills || [])
export const userIdSelector = createSelector(_userIdSelector, (id) => id || '')
export const userSelector = createSelector(_user, (user) => user)
