import { styled } from 'core'
import ReactFlow from 'react-flow-renderer'

export enum NodesColors {
    RED = '#FFCCBC',
    LIGHT_GREEN = '#EEFAF6',
    YELLOW = '#FFEFB9',
    LIGHT_BLUE = '#F4FAFF',
    LIGHT_GREY = '#F7F8F9',
    CATEGORY_TEXT_LABEL = '#000000',
    SKILL_TEXT_LABEL = '#173348',
    SHORTCUT_TEXT_LABEL = '#425468',
    SHORTCUT_BORDER = '#95A5B6',
    HIGHLIGHT_GREEN = '#DDF5EE',
    GREEN_NODE_BORDER = '#BAEBDD',
    WHITE = '#FFFFFF',
    BLUE_BORDER = '#5CBBFF',
    GREEN_BORDER = '#60D1AF',
    YELLOW_BORDER = '#FFD239',
}

export const ReactFlowStyled = styled(ReactFlow)`
    flex-grow: 1;
    .react-flow__node {
        border: none;
        padding: 0;
        width: auto;
    }
`
