import { styled } from 'core'
import { lightGrey } from 'utils/theme/colors'

export const LoginRoot = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

export const LogoWrapper = styled('div')`
    display: flex;
    justify-content: center;
`
export const LoginContent = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Copyright = styled('div')`
    font-size: 12px;
    color: ${lightGrey};
    align-self: center;
    text-align: center;
    margin-top: 116px;
`
