import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'
import authReducer from './slices/auth'
import profileReducer from './slices/profile'
import adminReducer from './slices/admin'
import { notificationsSlice } from 'notifications'
import autosuggestReducer from './slices/autosuggest'
import wizardReducer from './slices/wizard'
import usersReducer from './slices/users'
import { initDispatch } from '../axiosClient/dispatch'
import { useDispatch } from 'react-redux'
import treeReducer from './slices/tree'

export const rootReducer = combineReducers({
    auth: authReducer,
    profile: profileReducer,
    admin: adminReducer,
    [notificationsSlice.name]: notificationsSlice.reducer,
    autosuggest: autosuggestReducer,
    wizard: wizardReducer,
    users: usersReducer,
    tree: treeReducer,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['errors/removeDialoguesNotification', 'errors/setDialoguesNotification'],
                ignoredPaths: ['notifications.dialogues'],
            },
        }),
})

initDispatch(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
