import { DialogContentText, TextField } from '@mui/material'
import ActionDialogWrapper from 'core/ui/ActionDialog/ActionDialogWrapper'
import { useNotistackWrapper } from 'notifications'
import React, { memo, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { authInfo } from 'store/selectors/auth-selectors'
import { sendContributionRequest } from 'store/shared/requests'
import { ProfileState, setIsContributeModalShowed } from 'store/slices/profile'
import { getEmployeeProfile } from 'store/slices/profile/requests'
import { RootState, useAppDispatch } from 'store/store'
import { userIdSelector } from 'store/selectors/auth-selectors'

const ContributeModal = memo(() => {
    const { isContributeModalShowed, extraInfo: extraProfileInfo } = useSelector<RootState, ProfileState>(
        (state) => state.profile
    )

    const auth = useSelector(authInfo)
    const userId = useSelector(userIdSelector)

    const [email, setEmail] = useState(auth.user?.email)
    const [position, setPosition] = useState(extraProfileInfo?.position)
    const [message, setMessage] = useState('')

    const dispatch = useAppDispatch()

    const { enqueueSuccess, enqueueError } = useNotistackWrapper()

    const { refetch, isLoading } = useQuery(
        'fetchContribution',
        async () => {
            await sendContributionRequest({
                email: email || '',
                role: position || '',
                message: message || '',
            })
        },
        {
            onSuccess: () => {
                enqueueSuccess('Contribution request was sent')
                dispatch(setIsContributeModalShowed(false))
            },
            onError(err: any) {
                enqueueError('Failed to send contribution request ' + err?.error)
            },
            retry: false,
            refetchOnWindowFocus: false,
            enabled: false,
        }
    )

    useEffect(() => {
        if (extraProfileInfo?.position) setPosition(extraProfileInfo?.position)
    }, [extraProfileInfo?.position])

    useEffect(() => {
        if (userId && !extraProfileInfo?.position) dispatch(getEmployeeProfile(userId))

        return () => {
            setEmail(auth.user?.email)
            setPosition(extraProfileInfo?.position)
            setMessage('')
        }
    }, [userId, isContributeModalShowed])

    return (
        <ActionDialogWrapper
            title={'Contribution'}
            buttonText="Send"
            disabled={isLoading}
            submitAction={async () => await refetch()}
            isOpen={isContributeModalShowed}
            onClose={() => dispatch(setIsContributeModalShowed(false))}
        >
            <DialogContentText>
                Thanks for your interest. To get an access and start contributing please fill a form
            </DialogContentText>
            <TextField
                id="contribute-modal-email"
                placeholder="Email Address"
                label="Email Address"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                autoFocus={true}
            />
            <TextField
                id="contribute-modal-position"
                placeholder="Position"
                label="Position"
                value={position}
                onChange={(event) => setPosition(event.target.value)}
                autoFocus={true}
            />
            <TextField
                id="contribute-modal-message"
                placeholder="Message"
                label="Message"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                autoFocus={true}
            />
        </ActionDialogWrapper>
    )
})

export default ContributeModal
