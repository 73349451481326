import axios from 'axios'
import { BASE_URL } from './instance'

export const refreshInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
    },
})
refreshInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('refresh_token')
    config.headers = {
        ...config.headers,
        Authorization: token ? `Bearer ${token}` : '',
    }
    return config
})
