import { styled } from 'core'
import { Link } from 'react-router-dom'

interface LinkProps {
    color?: string
    noWeight?: boolean
}

export const AppLink = styled(Link)<LinkProps>`
    text-decoration: none;
    color: ${({ color }) => color};
    font-weight: ${({ noWeight }) => (noWeight ? undefined : 700)};
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        ${(color) => `color: ${color}`};
        text-decoration: none;
    }
`
