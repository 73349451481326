import { createSlice } from '@reduxjs/toolkit'
import { FormattedUser, User } from '../../../utils/types/users'
import { getUsersInfo, getUsersWithCreateCrPermissionInfo } from './actions'

export interface UsersState {
    users: FormattedUser[]
    usersWithCreateCrPermission: FormattedUser[]
    loadingUsers: boolean
    loadingUsersWithCreateCrPermission: boolean
    hasErrors: boolean
    error: string
}

const initialState: UsersState = {
    users: [],
    usersWithCreateCrPermission: [],
    loadingUsers: false,
    loadingUsersWithCreateCrPermission: false,
    hasErrors: false,
    error: '',
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUsersInfo.pending, (state) => {
                state.loadingUsers = true
            })
            .addCase(getUsersInfo.fulfilled, (state, action) => {
                state.loadingUsers = false
                state.users = action.payload.users.map((user: User) => ({
                    employee_id: user.employee_id,
                    name: `${user.first_name} ${user.last_name}`,
                    staffing_pic: user.staffing_pic,
                    google_pic: user.google_pic,
                }))
            })
            .addCase(getUsersInfo.rejected, (state) => {
                state.loadingUsers = false
                state.hasErrors = true
                state.error = "Can't get data from server! Try to reload this page."
            })

            .addCase(getUsersWithCreateCrPermissionInfo.pending, (state) => {
                state.loadingUsersWithCreateCrPermission = true
            })
            .addCase(getUsersWithCreateCrPermissionInfo.fulfilled, (state, action) => {
                state.loadingUsersWithCreateCrPermission = false
                state.usersWithCreateCrPermission = action.payload.usersWithCreateCrPermission.map(
                    (user: User) => ({
                        employee_id: user.employee_id,
                        name: `${user.first_name} ${user.last_name}`,
                        staffing_pic: user.staffing_pic,
                        google_pic: user.google_pic,
                    })
                )
            })
            .addCase(getUsersWithCreateCrPermissionInfo.rejected, (state) => {
                state.loadingUsersWithCreateCrPermission = false
                state.hasErrors = true
                state.error = "Can't get data from server! Try to reload this page."
            })
    },
})

export default usersSlice.reducer
