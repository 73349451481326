import {
    brightGreen,
    brown,
    deepBrown,
    disabledFeaturedBlue,
    featuredBlue,
    green,
    lightFeaturedBlue,
    orange,
    red,
    violet,
    warmYellow,
} from '../theme/colors'
import { RoleType } from '../types/admin'

export const yearsRange = 20
export const year = new Date().getFullYear()
export const algName = 'normalized'
export const defaultSeniorityForAdvancedSearch = -1
export const defaultCategoryName = 'Extra'
export const excludedEmployee = 'Employee'
export const excludedExternalConsultant = 'External Consultant'
export const rolesToShortRoles = [
    { name: 'Admin', contracted: 'adm' },
    { name: 'Solid-Line Manager', contracted: 'slm' },
    { name: excludedExternalConsultant, contracted: 'ec' },
    { name: excludedEmployee, contracted: 'emp' },
    { name: 'Competence Head', contracted: 'ch' },
]
export const defaultPlaceholderForSearchBar = 'Start typing'
export const RolesForSkillsSubscreen: RoleType[] = [
    { name: 'Admin', role: 'ADM' },
    { name: 'EC', role: 'EC' },
    { name: 'EM', role: 'EMP' },
    { name: 'SL', role: 'SLM' },
    { name: 'CH', role: 'CH' },
]

export const acceptedFileTypesCV = '.docx,.doc,.pdf'
export const timeFormat: string = 'dd.MM.yyyy'
export const fulfilled = 'fulfilled'
export const StaffingSeniorities: { [level: string]: string } = {
    L0: brightGreen,
    L1: green,
    L2: warmYellow,
    L3: orange,
    L4: red,
    L5: violet,
    M1: lightFeaturedBlue,
    M2: featuredBlue,
    M3: disabledFeaturedBlue,
    M4: brown,
    M5: deepBrown,
}
export const badFaithFactor = 'bad faith factor'
export const fallbackBadFaithFactor = '0'
export const feedSymbol = String.fromCodePoint(0x26a1)
