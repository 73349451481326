import React, { FC, ImgHTMLAttributes, useState } from 'react'
import { LazyLoadImage, ScrollPosition } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { styleForImage } from './presenter'
import { FallbackImageWrapper, IndicatorWrapper, CommonWrapper } from './styled'

interface Props extends ImgHTMLAttributes<any> {
    fallbackImg?: React.ReactElement
    borderRadius?: string
    scrollPosition?: ScrollPosition
    width: string
    height: string
    border?: string
    isInterviewer?: boolean
    indicatorComponent?: JSX.Element
    withoutBorder?: boolean
}

export const LazyImage: FC<Props> = ({
    src,
    width,
    height,
    borderRadius,
    fallbackImg,
    scrollPosition,
    border,
    indicatorComponent,
    isInterviewer,
    withoutBorder = false,
}) => {
    const [error, setError] = useState<boolean>(false)

    const onError = () => {
        if (!error) {
            setError(true)
        }
    }

    const style = styleForImage(borderRadius, border, 'border-box')

    return (
        <CommonWrapper isInterviewer={isInterviewer} withoutBorder={withoutBorder}>
            {!error && src ? (
                <LazyLoadImage
                    style={style}
                    width={width}
                    height={height}
                    placeholder={fallbackImg}
                    scrollPosition={scrollPosition}
                    effect="blur"
                    src={src}
                    onError={onError}
                />
            ) : (
                <FallbackImageWrapper borderRadius={borderRadius} width={width} height={height}>
                    {fallbackImg}
                </FallbackImageWrapper>
            )}
            <IndicatorWrapper>{indicatorComponent && indicatorComponent}</IndicatorWrapper>
        </CommonWrapper>
    )
}
