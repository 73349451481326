import { styled } from 'core'
import { TypographyColors } from 'utils/theme/colors'

export const SHeader = styled('header')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
`

export const SActionBtn = styled('button')`
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: ${TypographyColors.LAVENDER_GREY};
    background-color: transparent;
    border: none;
    padding: 0 12px;
    cursor: pointer;
`
