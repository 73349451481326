import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DialogueModal, NotificationsState } from '../utils/notifications.types'

const initialState: NotificationsState = {
    errors: [],
    infos: [],
    dialogues: [],
}

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setErrorNotification(state, action: PayloadAction<string>) {
            state.errors = state.errors.concat(action.payload)
        },
        setInfoNotification(state, action: PayloadAction<string>) {
            state.infos = state.infos.concat(action.payload)
        },
        setDialoguesNotification(state, action: PayloadAction<DialogueModal>) {
            state.dialogues = state.dialogues.concat(action.payload)
        },
        removeErrorNotification(state, action: PayloadAction<string>) {
            state.errors = state.errors.filter((error) => error !== action.payload)
        },
        removeInfoNotification(state, action: PayloadAction<string>) {
            state.infos = state.infos.filter((info) => info !== action.payload)
        },
        removeDialoguesNotification(state, action: PayloadAction<DialogueModal>) {
            state.dialogues = state.dialogues.filter((question) => question.id !== action.payload.id)
        },
    },
})

export const {
    setErrorNotification,
    removeErrorNotification,
    setInfoNotification,
    removeInfoNotification,
    setDialoguesNotification,
    removeDialoguesNotification,
} = notificationsSlice.actions
