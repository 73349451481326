import React, { ComponentType, FC, ReactNode } from 'react'
import { PageLoader } from '../../components/MoonLoader'
import { MatchLine, MatchPercentageSpan } from '../theme/Lib'
import { LevelOptions } from '../helpers/selects-options'
import { Levels } from '../enums/different'
import { arrayShuffle } from './common'
import { SkillLevelColors, transparent } from '../theme/colors'
import { Skill, SkillsGroupedByCompetency } from 'utils/types/skills'
import { NoContentsFallback } from '../../components/NoContents'

export interface LoaderWrapperProps {
    isLoading: boolean
    hasNoContent: boolean
    noContentElement?: React.ReactNode
    children: ReactNode
}

export const LoaderWrapper: FC<LoaderWrapperProps> = ({
    isLoading,
    hasNoContent,
    noContentElement,
    children,
}) => {
    if (isLoading) {
        return <PageLoader />
    }
    if (hasNoContent) {
        return noContentElement ? <>{noContentElement}</> : <NoContentsFallback />
    }
    return <>{children}</>
}

export const renderMatchPercentage = (weight: number) => {
    return (
        <MatchLine>
            <MatchPercentageSpan>{(weight * 100).toFixed(1)}%</MatchPercentageSpan>
        </MatchLine>
    )
}

export const getIconByLevelName = (level: Levels) => {
    return LevelOptions.find((option) => option.name === level)!.icon
}

export const getIdByLevelName = (level: Levels) => {
    return LevelOptions.find((option) => option.name === level)!.id
}

export const groupSkillsByCompetencies = (skills: Skill[]): SkillsGroupedByCompetency => {
    return skills.reduce((prev: SkillsGroupedByCompetency, curr) => {
        return { ...prev, [curr.parent_id!]: [...(prev[curr.parent_id!] || []), curr] }
    }, {})
}

/**
 * Return random elements from array. If size isn't positive number returns empty array. If size is bigger than given array, returns array
 * @param array - source array.
 * @param size - waited array size.
 */
export const getRandomElements = <T,>(array: T[], size: number): T[] => {
    if (size <= 0 || !array) {
        return []
    }
    if (array.length <= size) {
        return array
    }
    return arrayShuffle(array).slice(0, size)
}

export const getLevelColor = (level: Levels): string => {
    switch (level) {
        case Levels.DWTL:
            return SkillLevelColors.DWTL
        case Levels.WTL:
            return SkillLevelColors.WTL
        case Levels.Novice:
            return SkillLevelColors.NOVICE
        case Levels.Basic:
            return SkillLevelColors.BASIC
        case Levels.Middle:
            return SkillLevelColors.MIDDLE
        case Levels.Advanced:
            return SkillLevelColors.ADVANCED
        case Levels.Expert:
            return SkillLevelColors.EXPERT
        default:
            return transparent
    }
}

export const getLevelById = (id: number | null): Levels => {
    const values = Object.values(Levels)
    if (!id || id >= values.length) {
        return Levels.WTL
    }

    return values[id + 1]
}
export const getStringParts = (
    name: string | null | undefined,
    Component: ComponentType<{ children: ReactNode }>
) => {
    if (!name) {
        return
    }

    const parts = name.split(' ')

    if (!parts.length) {
        return
    }

    const handleDataAutomation = (element: string) => {
        if (parts[0] === element) return 'label-user-name'
        else return 'label-user-surname'
    }

    return parts.map((element, id) => (
        <Component data-automation-id={handleDataAutomation(element)} key={id}>
            {element}
        </Component>
    ))
}
