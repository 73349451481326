import { FlowNode, IntervalType, TaxonomyContextValue } from 'taxonomy/types'

export const onNodeClick = (
    e: React.MouseEvent,
    node: FlowNode,
    taxonomyContext: TaxonomyContextValue,
    timer: React.MutableRefObject<number | undefined>
) => {
    const { toggleNodeOpen, toggleNodeActionMenu, recursiveExpandUpdate } = taxonomyContext

    const clearTimer = () => {
        clearTimeout(timer.current)
        timer.current = undefined
    }

    const handleNodeClick = (node: FlowNode) => {
        if (node.type === 'output') return

        toggleNodeOpen(node)
    }

    const handleNodeDoubleClick = (node: FlowNode) => {
        recursiveExpandUpdate(node)
    }

    const target = e.target as HTMLElement
    const nodeRoot = target.closest('.CustomNode-root')
    const arrow = nodeRoot?.querySelector('.arrow-button-wrapper')
    if (arrow && arrow.contains(target)) {
        if (nodeRoot) toggleNodeActionMenu(nodeRoot, node)
    } else if (timer.current) {
        clearTimer()
        handleNodeDoubleClick(node)
    } else {
        timer.current = setTimeout(() => {
            clearTimer()
            handleNodeClick(node)
        }, IntervalType.DOUBLE_CLICK) as any as number
    }
}
