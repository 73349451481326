import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material'
import { styled } from 'core'
import React from 'react'
import { anthracite } from 'utils/theme/colors'

export const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))`
    & .MuiTooltip-tooltip {
        border-radius: 5px;
        background-color: ${anthracite};
        padding: 8px 9px 8px 11px;
    }

    & .MuiTooltip-arrow::before {
        background-color: ${anthracite};
    }
`
