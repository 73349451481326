import React, { FC } from 'react'
import { Levels } from '../../utils/enums/different'
import { IconSize } from '../../utils/types/styling'
import {
    Advanced,
    AdvancedSmall,
    Basic,
    BasicSmall,
    Expert,
    ExpertSmall,
    Middle,
    MiddleSmall,
    Novice,
    NoviceSmall,
    WantToLearn,
} from '../../images'

interface Prop {
    level: Levels
    size?: IconSize
}

export const LevelIcon: FC<Prop> = ({ level, size = IconSize.Medium, ...restProps }) => {
    switch (level) {
        case Levels.Expert:
            return size === IconSize.Medium ? <ExpertSmall {...restProps} /> : <Expert {...restProps} />

        case Levels.Advanced:
            return size === IconSize.Medium ? <AdvancedSmall {...restProps} /> : <Advanced {...restProps} />

        case Levels.Middle:
            return size === IconSize.Medium ? <MiddleSmall {...restProps} /> : <Middle {...restProps} />

        case Levels.Basic:
            return size === IconSize.Medium ? <BasicSmall {...restProps} /> : <Basic {...restProps} />

        case Levels.Novice:
            return size === IconSize.Medium ? <NoviceSmall {...restProps} /> : <Novice {...restProps} />

        default:
            return <WantToLearn {...restProps} />
    }
}
