import { NodeData } from 'taxonomy/types'
import { blueMarine } from 'utils/theme/colors'
import { NodesColors } from '../ReactFlowGraph/styled'

const getStatusBackgroundColor = (data: NodeData) => {
    const { isChanged, isArchived } = data
    if (isArchived) {
        return NodesColors.RED
    }
    if (isChanged) {
        return NodesColors.YELLOW
    }
}

export const getNodeStyles = (data: NodeData) => {
    const { isCategory, parentId, isChanged, isArchived, isOpen } = data
    const hasStatus = isChanged || isArchived

    if (hasStatus) {
        if (isOpen)
            return `background-color: ${getStatusBackgroundColor(data)};
            border: 1px solid ${NodesColors.YELLOW_BORDER};`
        else return `background-color: ${getStatusBackgroundColor(data)};`
    }
    if (isCategory && parentId) {
        if (isOpen) {
            return `background-color: ${blueMarine};
            border: 1px solid ${NodesColors.BLUE_BORDER};`
        } else {
            return `background-color: ${blueMarine}`
        }
    }
    if (isCategory && !parentId) {
        if (isOpen) {
            return `background-color: ${NodesColors.HIGHLIGHT_GREEN};
            border: 1px solid ${NodesColors.GREEN_BORDER};
            `
        } else {
            return `background-color: ${NodesColors.HIGHLIGHT_GREEN}`
        }
    }

    if (!isCategory) {
        const textColor = `color: ${NodesColors.SKILL_TEXT_LABEL};`
        return `${textColor} background-color: ${NodesColors.LIGHT_GREY}`
    }
}
