import { CloseIcon } from 'images'
import { find, pickBy } from 'lodash'
import React, { FC, memo, useRef } from 'react'
import { useSearchContext } from 'search/context/SearchContext'
import { FilterSkill } from 'search/context/types'
import { Row, SkillRangeIcon, Title } from './styled'
import { useNodeWithPath } from 'utils/hooks/useSkillPathTree'
import { useSelector } from 'react-redux'
import { selectTree } from 'store/slices/tree/selectors'
import { FilterContainerItem } from '../parts/FilterContainer.styled'
import { useTextMoveAnimation } from 'utils/hooks/useTextMoveAnimation'
import { SkillSlider } from 'components/SkillSlider'

interface SkillRangeProps {
    skill: FilterSkill
    setSkillLevel: (level_from: number, level_to: number) => void
}

const SkillRange: FC<SkillRangeProps> = ({ skill, setSkillLevel }) => {
    const { setSkills } = useSearchContext()

    const tree = useSelector(selectTree)
    const allSkills = useNodeWithPath(tree)

    const refTitle = useRef<HTMLDivElement>(null)

    const deleteSkill = () => {
        setSkills((prev) => pickBy(prev, (value) => value.id !== skill.id))
    }

    useTextMoveAnimation(refTitle)

    const title = find(allSkills, { node_id: skill.id })?.name || ''

    return (
        <FilterContainerItem>
            <Row>
                <Title>
                    <span style={{ display: 'inline-block' }} ref={refTitle}>
                        {title}
                    </span>
                </Title>
                <SkillRangeIcon onClick={deleteSkill}>
                    <CloseIcon />
                </SkillRangeIcon>
            </Row>

            {!skill.is_category && (
                <SkillSlider
                    levelFrom={skill?.level_from}
                    levelTo={skill?.level_to}
                    setSkillLevel={setSkillLevel}
                />
            )}
        </FilterContainerItem>
    )
}

export default memo(SkillRange)
