import { ListItemText } from '@mui/material'
import { Select, SelectPropsSingle } from 'core'
import { TrashBin } from 'images'
import React from 'react'
import { IconWrapper } from 'utils/theme/Lib'
import { Preset } from 'utils/types/presets'
import { ContainerTooltip } from './styled'

interface PresetSelectProp extends SelectPropsSingle<Preset> {
    onRemove: (id: number) => void
}

export const PresetSelect = ({ onRemove, ...restProps }: PresetSelectProp) => {
    const handleRemovePreset = (id: number) => {
        onRemove(id)
    }

    return (
        <Select<Preset>
            {...restProps}
            id="select-choose-preset"
            fullWidth={true}
            renderOption={(val) => {
                return (
                    <>
                        <ListItemText primary={val.name} />
                        <ContainerTooltip title="Delete preset">
                            <IconWrapper>
                                <TrashBin
                                    onClick={(e) => {
                                        handleRemovePreset(val.id)
                                        e.stopPropagation()
                                    }}
                                />
                            </IconWrapper>
                        </ContainerTooltip>
                    </>
                )
            }}
        />
    )
}
