import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material'
import React, { FC, useState } from 'react'
import { SearchCrossFilled } from '../../../images'
import { styled } from '../../utils/core.styled'

const SSearchCrossFilled = styled(SearchCrossFilled)`
    cursor: pointer;
`

export interface TextFieldProps extends Omit<MuiTextFieldProps, 'id'> {
    id: string
    clearable?: boolean
    value?: string
}

export const TextField: FC<TextFieldProps> = (props) => {
    const { clearable, ...restProps } = props
    const [value, setValue] = useState<string | undefined>(props.value)
    const customInputProps: MuiTextFieldProps['InputProps'] = {
        ...props.InputProps,
    }
    const updateValue = (newValue: string) => {
        setValue(newValue)
        if (props.onChange) {
            props.onChange({ target: { value: newValue } } as React.ChangeEvent<HTMLInputElement>)
        }
    }

    if (clearable === true && !!value) {
        customInputProps.endAdornment = <SSearchCrossFilled onClick={() => updateValue('')} />
    }
    return (
        <MuiTextField
            {...restProps}
            value={value}
            onChange={(e) => updateValue(e.target.value)}
            inputProps={{ ...props.inputProps, 'data-automation-id': props.id }}
            InputProps={customInputProps}
        />
    )
}
