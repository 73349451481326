import { noop } from 'lodash'
import { createContext, Dispatch, SetStateAction } from 'react'

export interface TabItem {
    id: string
    label: string
}
interface Context {
    addTab: (tab: TabItem) => number
    updateTab: (tab: TabItem, index: number) => void
    currentTab: string
    setCurrentTab: Dispatch<SetStateAction<string>>
}

const TabsContext = createContext<Context>({
    addTab: () => 0,
    updateTab: () => {},
    currentTab: '0',
    setCurrentTab: noop,
})

export { TabsContext }
