import { CloseIcon } from 'images'
import { isEmpty } from 'lodash'
import React, { FC, memo } from 'react'
import { FilterBlock, FilterIcon, FilterLabelContent, Item, LocationName } from './styled'
import { FilterContainerList } from '../parts/FilterContainer.styled'

interface FilterItemProps {
    filter: string
    deleteFilter: (id: string) => void
}

const FilterItem: FC<FilterItemProps> = ({ filter, deleteFilter }) => {
    return (
        <Item key={filter}>
            <FilterIcon onClick={() => deleteFilter(filter)}>
                <CloseIcon />
            </FilterIcon>
            <LocationName>{filter}</LocationName>
        </Item>
    )
}

interface Props<T> {
    filters: T
    setFilters: React.Dispatch<React.SetStateAction<T>>
    label: string
}

const FiltersBlock: FC<Props<string[]>> = ({ filters, setFilters, label }) => {
    const deleteFilter = (id: string) => {
        setFilters((prev) => [...prev.filter((v) => v !== id)])
    }

    return (
        <>
            {!isEmpty(filters) && (
                <FilterBlock>
                    <FilterLabelContent>{label}</FilterLabelContent>

                    <FilterContainerList>
                        {filters.map((filter) => (
                            <FilterItem key={filter} filter={filter} deleteFilter={deleteFilter} />
                        ))}
                    </FilterContainerList>
                </FilterBlock>
            )}
        </>
    )
}

export default memo(FiltersBlock)
