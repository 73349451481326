import { styled } from 'core'
import { borderGrey, white } from 'utils/theme/colors'

export const SWrapper = styled('div')`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${white};
    flex-grow: 1;
    gap: 12px;
    padding: 12px 0;
    box-sizing: border-box;
`

export const Main = styled('main')`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

export const SDelimeter = styled('hr')`
    border: 0;
    border-top: 1px solid ${borderGrey};
    margin: 0 24px;
`
