import { client } from 'axiosClient'
import { ComposedAjaxError } from 'axiosClient/ErrorComposer'
import { setInfoNotification } from 'notifications'
import { getActionCodeListForRole } from 'utils/functions/common'
import { badFaithFactor, fallbackBadFaithFactor } from 'utils/helpers/constants'
import { Role, RoleActions } from 'utils/types/admin'
import { Action, ResponseData } from 'utils/types/common'
import { Employee } from 'utils/types/employee'
import { updateUserActions } from '../../shared/actions'
import { fetchEmployees } from '../../shared/requests'
import { AppThunk, RootState } from '../../store'
import {
    clearEmployees,
    setActions,
    setEmployees,
    setHasValidEmployeeData,
    setHasValidRolesData,
    setRoles,
    updateEmployeeBlockStatus,
} from './index'

const getValidCompetenceData = (employees: ResponseData<Employee>): Employee[] => {
    return employees.data.map((emp) =>
        emp.staffing_competence === null ? { ...emp, staffing_competence: '' } : emp
    )
}
const markProfilesAsBadFaith = (employees: Employee[]): Employee[] => {
    const bf = JSON.parse(localStorage.getItem(badFaithFactor) || fallbackBadFaithFactor)
    return employees.map((e) => {
        // TODO
        // @ts-ignore
        if (e.novice + e.advanced + e.expert + e.want_to_learn < bf) {
            return {
                ...e,
                isBadFaith: true,
            }
        }
        return e
    })
}
export const manageEmployees = (): AppThunk => async (dispatch) => {
    try {
        dispatch(clearEmployees())
        const employeeData: ResponseData<Employee> = await fetchEmployees()
        const validData = getValidCompetenceData(employeeData)
        dispatch(setEmployees(markProfilesAsBadFaith(validData)))
        dispatch(setHasValidEmployeeData(true))
    } catch (ex: any) {
        dispatch(setHasValidEmployeeData(false))
        ComposedAjaxError.handleError('Error getting employees', ex)
    }
}
export const putEmployeeRole =
    (uid: string, data: string | undefined, successCb: () => void): AppThunk =>
    async () => {
        if (data) {
            const body = JSON.stringify({
                code: `${data}`,
            })
            try {
                await client.put(`/api/users/${uid}/role`, body)
                successCb()
            } catch (ex: any) {
                ComposedAjaxError.handleError('Error updating role', ex)
            }
        }
    }
export const blockEmployee =
    (uid: string): AppThunk =>
    async (dispatch) => {
        try {
            await client.patch(`/api/users/${uid}/block`)
            dispatch(updateEmployeeBlockStatus(uid))
        } catch (ex: any) {
            ComposedAjaxError.handleError('Error blocking user', ex)
        }
    }

export const getRoles = (): AppThunk => async (dispatch) => {
    try {
        const roleData = await client.get('/api/roles')
        dispatch(setRoles(roleData.data))
    } catch (ex: any) {
        ComposedAjaxError.handleError('Error while getting roles', ex)
    }
}
export const composeRoles = (): AppThunk => async (dispatch) => {
    try {
        const getRoles: Promise<ResponseData<Role>> = client.get('/api/roles')
        const getActions: Promise<ResponseData<Action>> = client.get('/api/actions')

        const [roles, actions] = await Promise.all([getRoles, getActions])
        dispatch(setRoles(roles.data))
        dispatch(setActions(actions.data))
        dispatch(setHasValidRolesData(true))
    } catch (ex: any) {
        dispatch(setHasValidRolesData(false))
        ComposedAjaxError.handleError('Error composing roles', ex)
    }
}
export const editPermissions =
    (changedRoles: number[], roleActions: RoleActions): AppThunk =>
    async (dispatch, getState: () => RootState) => {
        try {
            await Promise.all(
                changedRoles.map((roleId) =>
                    client.put(
                        `/api/roles/${roleId}/actions`,
                        JSON.stringify(roleActions[roleId].permittedActions)
                    )
                )
            )
            await dispatch(composeRoles())
            dispatch(
                updateUserActions(
                    getActionCodeListForRole(
                        getState().auth.user!.role,
                        getState().admin.roles,
                        getState().admin.actions
                    )
                )
            )
            dispatch(setInfoNotification('Successfully updated permissions'))
        } catch (ex: any) {
            ComposedAjaxError.handleError('Error updating permissions', ex)
        }
    }
