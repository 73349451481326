import { useSnackbar } from 'notistack'
import React from 'react'
import { NotificationAlert } from '../ui/NotificationAlert'
import { NotificationModal } from '../ui/NotificationModal'
import { DialogueModal } from '../utils/notifications.types'

const defaultSnackbarProps = { 'data-automation-id': 'notistack-snackbar' }

export const useNotistackWrapper = () => {
    const { enqueueSnackbar } = useSnackbar()

    return {
        enqueueError: (message: string) => {
            enqueueSnackbar(message, {
                ...defaultSnackbarProps,
                variant: 'error',
                content: (key) => {
                    return <NotificationAlert id={key} message={message} variant="error" />
                },
            })
        },
        enqueueSuccess: (message: string) => {
            enqueueSnackbar(message, {
                ...defaultSnackbarProps,
                variant: 'success',
                content: (key) => {
                    return <NotificationAlert id={key} message={message} variant="success" />
                },
            })
        },
        enqueueModal: (modal: DialogueModal) => {
            enqueueSnackbar(modal.id, {
                ...defaultSnackbarProps,
                variant: 'success',
                persist: true,
                content: (key) => {
                    return <NotificationModal modal={modal} id={key} />
                },
            })
        },
    }
}
