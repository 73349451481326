import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface WizardState {
    opened: boolean
}

const initialState: WizardState = {
    opened: false,
}

const wizardSlice = createSlice({
    name: 'wizard',
    initialState,
    reducers: {
        setOpened(state, action: PayloadAction<boolean>) {
            state.opened = action.payload
        },
    },
})

export const { setOpened } = wizardSlice.actions
export default wizardSlice.reducer
