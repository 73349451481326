import { styled } from '../../core'
import { AdditionalFilters } from '../../search/ui/AdditionalFilters/AdditionalFilters'

export const AdditionalFiltersStyled = styled(AdditionalFilters)`
    margin-top: 17px;
`

export const SWrapper = styled('section')`
    background-color: white;
    height: 100%;
`

export const SContainerWrapper = styled('div')`
    background-color: white;
    margin: 0 auto;
    padding: 24px;
    flex-direction: column;
    width: 1200px;
    height: 100%;
    overflow: auto;
    min-height: 100px;
    box-sizing: border-box;

    ::-webkit-scrollbar-track {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: #c6ced8;
        border-radius: 10px;
    }
`
