export interface NotificationsState {
    errors: string[]
    infos: string[]
    dialogues: DialogueModal[]
}

export enum DialogueModalType {
    DELETE_SKILL = 'DeleteSkill',
    EXIT_FROM_PAGE = 'Exit from page',
}

export interface DialogueModal {
    readonly type: DialogueModalType
    readonly id: string
    readonly message: string
    readonly onClick: () => void
    readonly onSave?: () => void
    readonly canBeHidden: boolean
    readonly redButtonMessage: string
    readonly greenButtonMessage: string
}
