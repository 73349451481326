import { styled } from 'core'
import { grey15, grey20, red } from '../../../utils/theme/colors'

export const ModalHeader = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
`

export const AddPresetFooter = styled('div')`
    display: flex;
    box-sizing: border-box;
    margin-top: 24px;

    .MuiButton-root {
        margin-right: 12px;
    }
`

export const PresetModalInputWrapper = styled('div')`
    width: 304px;
    height: 42px;
    box-sizing: border-box;
`

export const PresetModalInput = styled('input')<{ disabled?: boolean }>`
    font-size: 14px;
    outline: none;
    border: none;
    border-bottom: ${`1px solid ${grey20}`};
    background: transparent;
    padding-bottom: 4px;
    width: 304px;

    ::placeholder {
        color: ${grey15};
    }
`

export const PresetErrorMessage = styled('div')`
    color: ${red};
    margin-top: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
`
