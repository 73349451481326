import { styled, ThemeProvider } from '@mui/material'
import { Notifier, SnackbarProvider } from 'notifications'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/store'
import { PageLoader } from './components/MoonLoader'
import { AppRouter } from './parts/AppRouter/AppRouter'
import { authInfo } from './store/selectors/auth-selectors'
import { checkUserAndToken } from './store/slices/auth/requests'
import { getUsersInfo, getUsersWithCreateCrPermissionInfo } from './store/slices/users/actions'
import { useTree } from 'store/slices/tree/useTree'
import { getTreeNodes } from 'store/slices/tree/requests'
import { AppTheme } from './App.theme'
import { HelmetProvider } from 'react-helmet-async'
import { Helmet } from 'react-helmet-async'
import { Roles } from 'utils/enums/different'

export const Centered = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100vh;
`

function App() {
    const auth = useSelector(authInfo)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(checkUserAndToken())
    }, [dispatch])

    useEffect(() => {
        if (auth.isAuthorized) {
            dispatch(getTreeNodes())
            dispatch(getUsersInfo())
            if (auth.user?.role === Roles.admin) dispatch(getUsersWithCreateCrPermissionInfo())
        }
    }, [auth.isAuthorized, dispatch])
    useTree()

    return (
        <ThemeProvider theme={AppTheme}>
            <SnackbarProvider>
                <HelmetProvider>
                    <Notifier />
                    <Helmet>
                        <title>Skills Matrix</title>
                    </Helmet>
                    <Centered>{auth.authInProgress ? <PageLoader /> : <AppRouter />}</Centered>
                </HelmetProvider>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default App
