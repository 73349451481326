import React, { FC } from 'react'

interface Props {
    width: number
    height: number
}
export const Logo: FC<Props> = ({ width, height }) => {
    return (
        <>
            <svg
                width={`${width}`}
                height={`${height}`}
                viewBox="0 0 108 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M45.9013 9.99217C45.5662 9.99643 45.2336 9.9321 44.9232 9.803C44.6127 9.67389 44.3307 9.4826 44.0937 9.2404C43.8567 8.99821 43.6696 8.70998 43.5432 8.39273C43.4169 8.07548 43.3539 7.73562 43.3581 7.39312C43.3579 6.87892 43.507 6.37621 43.7864 5.94859C44.0658 5.52098 44.4631 5.18767 44.9279 4.99087C45.3928 4.79406 45.9043 4.7426 46.3977 4.84297C46.8912 4.94335 47.3445 5.19106 47.7002 5.55477C47.9477 5.789 48.1445 6.07356 48.278 6.39015C48.4116 6.70675 48.4788 7.04839 48.4755 7.39312C48.4825 7.99346 48.282 8.57706 47.9095 9.04129L48.4057 9.5088L48.0103 9.96047L47.483 9.46919C47.0309 9.82539 46.4719 10.0102 45.9013 9.99217ZM45.9013 9.36617C46.2896 9.37479 46.6702 9.25531 46.9868 9.02545L46.2114 8.28851L46.6069 7.83686L47.3822 8.62925C47.6372 8.27904 47.7733 7.85337 47.7699 7.41689C47.7751 7.15799 47.7298 6.90062 47.6366 6.65981C47.5435 6.419 47.4042 6.19956 47.2272 6.01434C47.05 5.83321 46.8397 5.68953 46.6082 5.5915C46.3768 5.49346 46.1286 5.443 45.878 5.443C45.6275 5.443 45.3794 5.49346 45.1479 5.5915C44.9164 5.68953 44.706 5.83321 44.5289 6.01434C44.3518 6.19956 44.2126 6.419 44.1194 6.65981C44.0262 6.90062 43.9809 7.15799 43.9861 7.41689C43.9796 7.67597 44.0243 7.93373 44.1176 8.17473C44.2108 8.41573 44.3507 8.635 44.5289 8.81942C44.7049 9.00866 44.9183 9.15741 45.155 9.25578C45.3916 9.35416 45.646 9.39989 45.9013 9.38995V9.36617Z"
                    fill="#121212"
                />
                <path
                    d="M52.7247 9.43736C52.3453 9.79431 51.8485 9.99246 51.3329 9.99246C50.8173 9.99246 50.3204 9.79431 49.9411 9.43736C49.7591 9.23974 49.618 9.0067 49.5261 8.75212C49.4341 8.49754 49.3933 8.22664 49.4061 7.95559V4.8811H50.0497V7.96352C50.037 8.1516 50.0613 8.34035 50.1213 8.51864C50.1812 8.69694 50.2755 8.86114 50.3986 9.00156C50.6557 9.23602 50.9883 9.36559 51.3329 9.36559C51.6775 9.36559 52.01 9.23602 52.2672 9.00156C52.3903 8.86114 52.4846 8.69694 52.5445 8.51864C52.6044 8.34035 52.6288 8.1516 52.6161 7.96352V4.8811H53.2597V7.95559C53.2724 8.22664 53.2316 8.49754 53.1397 8.75212C53.0478 9.0067 52.9066 9.23974 52.7247 9.43736Z"
                    fill="#121212"
                />
                <path
                    d="M53.7324 9.90487L55.9112 4.8811H56.4384L58.625 9.90487H57.9504L57.3146 8.43894H54.9885L54.3682 9.90487H53.7324ZM56.1671 5.77651L55.2754 7.84465H57.0588L56.1671 5.77651Z"
                    fill="#121212"
                />
                <path
                    d="M63.5022 9.90487H62.9516L59.9587 5.99045V9.90487H59.3152V4.8811H59.8734L62.8664 8.79553V4.8811H63.5022V9.90487Z"
                    fill="#121212"
                />
                <path
                    d="M65.8049 9.90487V5.5071H64.2542V4.8811H67.9449V5.5071H66.3942V9.90487H65.8049Z"
                    fill="#121212"
                />
                <path
                    d="M70.6897 9.99206C70.3551 9.99416 70.0235 9.92837 69.7141 9.79851C69.4046 9.66864 69.1234 9.47727 68.8868 9.2355C68.6503 8.99374 68.463 8.70638 68.3359 8.3901C68.2088 8.07382 68.1445 7.73491 68.1465 7.39301C68.148 6.96601 68.2523 6.54596 68.4504 6.16998C68.6485 5.79401 68.9341 5.47369 69.2821 5.23736C69.6301 5.00103 70.0297 4.85595 70.4456 4.81497C70.8615 4.774 71.2809 4.83838 71.6667 5.00241C72.0524 5.16644 72.3927 5.42508 72.6574 5.75546C72.9221 6.08583 73.1031 6.47775 73.1843 6.8966C73.2656 7.31545 73.2446 7.74832 73.1233 8.15692C73.0019 8.56551 72.7839 8.93725 72.4885 9.23927C72.2545 9.48314 71.9743 9.67568 71.665 9.80511C71.3558 9.93454 71.0239 9.99816 70.6897 9.99206ZM70.6897 9.36606C70.9411 9.37263 71.1911 9.32524 71.4235 9.22697C71.6558 9.1287 71.8655 8.98173 72.0388 8.79554C72.217 8.61112 72.3569 8.39185 72.4502 8.15085C72.5434 7.90985 72.5881 7.65208 72.5816 7.39301C72.5868 7.13411 72.5415 6.87673 72.4483 6.63592C72.3551 6.39511 72.2159 6.17567 72.0388 5.99046C71.8617 5.80933 71.6514 5.66565 71.4199 5.56761C71.1884 5.46958 70.9403 5.41911 70.6897 5.41911C70.4391 5.41911 70.191 5.46958 69.9595 5.56761C69.728 5.66565 69.5177 5.80933 69.3406 5.99046C69.1635 6.17567 69.0243 6.39511 68.9311 6.63592C68.8379 6.87673 68.7926 7.13411 68.7978 7.39301C68.7913 7.65208 68.836 7.90985 68.9292 8.15085C69.0225 8.39185 69.1624 8.61112 69.3406 8.79554C69.5125 8.98525 69.7213 9.13613 69.9537 9.23848C70.186 9.34082 70.4367 9.39238 70.6897 9.38983V9.36606Z"
                    fill="#121212"
                />
                <path
                    d="M74.1943 9.90486V4.88109H75.8691C76.2633 4.85842 76.6504 4.99504 76.9469 5.26145C77.079 5.39083 77.1826 5.5475 77.2508 5.72103C77.319 5.89456 77.3502 6.08095 77.3423 6.26778C77.3495 6.43291 77.3243 6.59784 77.2684 6.75294C77.2125 6.90804 77.1269 7.05021 77.0167 7.17112C76.7793 7.42099 76.4638 7.578 76.125 7.61486L78.0169 9.90486H77.2415L75.3496 7.62277H74.8689V9.90486H74.1943ZM74.8379 7.0364H75.7993C76.0346 7.04865 76.267 6.9788 76.4584 6.8383C76.5403 6.7699 76.6049 6.68234 76.6468 6.58304C76.6886 6.48374 76.7064 6.37561 76.6987 6.26778C76.7052 6.15894 76.6868 6.05006 76.6451 5.94969C76.6034 5.84932 76.5395 5.76019 76.4584 5.68932C76.2685 5.54585 76.035 5.47567 75.7993 5.49123H74.8379V7.0364Z"
                    fill="#121212"
                />
                <path d="M78.5906 9.90487V4.8811H79.2341V9.90487H78.5906Z" fill="#121212" />
                <path
                    d="M48.8477 28.8986C47.7752 28.9128 46.7139 28.6742 45.7462 28.2012C44.8604 27.7793 44.1102 27.1088 43.583 26.2678L45.847 24.3344C46.1931 24.8671 46.6617 25.3052 47.2117 25.6101C47.7432 25.9192 48.3447 26.0804 48.9563 26.0776C49.4535 26.1024 49.9454 25.9636 50.3597 25.6814C50.5288 25.5716 50.6676 25.4192 50.7626 25.2388C50.8577 25.0584 50.9058 24.8562 50.9024 24.6513C50.903 24.4469 50.8604 24.2448 50.7774 24.0588C50.6944 23.8728 50.5731 23.7073 50.4217 23.5737C49.9645 23.2019 49.4363 22.9319 48.871 22.7813L47.6537 22.3455C45.2862 21.5055 44.1051 20.103 44.1102 18.1379C44.0835 17.5316 44.1916 16.9271 44.4267 16.3698C44.6617 15.8126 45.0175 15.3171 45.4671 14.9207C46.4509 14.0938 47.6991 13.6677 48.9717 13.7242C49.8223 13.7118 50.6659 13.8841 51.4465 14.2297C52.2271 14.5753 52.927 15.0863 53.4999 15.729L51.5305 17.7813C51.233 17.3782 50.8456 17.0536 50.4005 16.8344C49.9554 16.6153 49.4656 16.5079 48.9717 16.5214C48.4976 16.5078 48.0288 16.6255 47.6148 16.8621C47.4413 16.9527 47.2962 17.0915 47.1961 17.2624C47.096 17.4334 47.045 17.6296 47.0488 17.8288C47.0428 18.0258 47.0843 18.2213 47.1694 18.3982C47.2546 18.575 47.3809 18.7278 47.5373 18.8431C48.0748 19.1829 48.6535 19.4493 49.2587 19.6355L50.4139 20.0713C52.7193 20.9588 53.8617 22.4168 53.841 24.4453C53.8543 25.0465 53.7359 25.6432 53.4944 26.1916C53.2529 26.74 52.8945 27.2263 52.4454 27.6149C51.4399 28.4776 50.1601 28.9342 48.8477 28.8986Z"
                    fill="#121212"
                />
                <path
                    d="M65.3706 28.645L59.1678 21.9731V28.645H56.2446V13.9382H59.1678V20.1744L64.3549 13.9382H67.9836L62.0288 20.9985L69.1854 28.645H65.3706Z"
                    fill="#121212"
                />
                <path d="M70.2788 28.645V13.9382H73.2174V28.645H70.2788Z" fill="#121212" />
                <path d="M76.4194 28.645V13.9382H79.3658V25.7845H85.5299V28.645H76.4194Z" fill="#121212" />
                <path d="M87.6157 28.645V13.9382H90.5621V25.7845H96.765V28.645H87.6157Z" fill="#121212" />
                <path
                    d="M102.968 28.8986C101.895 28.9149 100.834 28.6762 99.8666 28.2013C98.9772 27.7817 98.2239 27.1109 97.6956 26.2679L99.9674 24.3344C100.311 24.8669 100.776 25.305 101.324 25.6102C101.856 25.9175 102.458 26.0786 103.069 26.0777C103.569 26.1042 104.064 25.9653 104.48 25.6815C104.647 25.5702 104.784 25.4172 104.878 25.2371C104.971 25.0569 105.018 24.8554 105.015 24.6514C105.017 24.4475 104.976 24.2456 104.894 24.0595C104.812 23.8735 104.692 23.7078 104.542 23.5737C104.084 23.2029 103.556 22.9331 102.991 22.7814L101.782 22.3455C99.4091 21.5056 98.2306 20.1031 98.2306 18.1379C98.2038 17.5317 98.312 16.9272 98.547 16.3699C98.7821 15.8126 99.1378 15.3172 99.5874 14.9208C100.574 14.0934 101.825 13.6673 103.1 13.7243C103.957 13.7098 104.808 13.8834 105.595 14.2333C106.381 14.5832 107.085 15.1014 107.659 15.7528L105.69 17.8051C105.394 17.3966 105.006 17.0667 104.56 16.8433C104.113 16.6199 103.62 16.5095 103.123 16.5215C102.649 16.5058 102.179 16.6237 101.766 16.8622C101.592 16.9525 101.446 17.0909 101.344 17.2617C101.243 17.4326 101.19 17.6291 101.192 17.8289C101.186 18.0259 101.228 18.2214 101.313 18.3982C101.398 18.5751 101.525 18.7278 101.681 18.8432C102.218 19.183 102.797 19.4494 103.402 19.6356L104.565 20.0714C106.86 20.9589 108 22.4169 107.985 24.4454C107.998 25.0466 107.879 25.6433 107.638 26.1917C107.397 26.7401 107.038 27.2264 106.589 27.615C105.579 28.4858 104.289 28.9432 102.968 28.8986Z"
                    fill="#121212"
                />
                <path
                    d="M24.3929 21.8781C29.8185 21.8781 34.2167 17.3832 34.2167 11.8384C34.2167 6.29372 29.8185 1.79883 24.3929 1.79883C18.9674 1.79883 14.5691 6.29372 14.5691 11.8384C14.5691 17.3832 18.9674 21.8781 24.3929 21.8781Z"
                    fill="#3980E6"
                />
                <path
                    d="M7.48999 28.3676C11.6266 28.3676 14.98 24.9406 14.98 20.7131C14.98 16.4856 11.6266 13.0586 7.48999 13.0586C3.35338 13.0586 0 16.4856 0 20.7131C0 24.9406 3.35338 28.3676 7.48999 28.3676Z"
                    fill="#BCBEBF"
                />
                <path
                    d="M17.0735 35C19.4372 35 21.3534 33.0417 21.3534 30.626C21.3534 28.2103 19.4372 26.252 17.0735 26.252C14.7097 26.252 12.7935 28.2103 12.7935 30.626C12.7935 33.0417 14.7097 35 17.0735 35Z"
                    fill="#80D4A6"
                />
                <path
                    d="M17.0735 8.74802C19.4372 8.74802 21.3534 6.78971 21.3534 4.37401C21.3534 1.95831 19.4372 0 17.0735 0C14.7097 0 12.7935 1.95831 12.7935 4.37401C12.7935 6.78971 14.7097 8.74802 17.0735 8.74802Z"
                    fill="#121212"
                />
                <path
                    d="M25.8504 24.3818C25.4817 23.7279 25.3815 22.9513 25.5719 22.2221C25.7623 21.4929 26.2277 20.8705 26.8662 20.4912C27.506 20.1143 28.2659 20.012 28.9795 20.2065C29.693 20.4011 30.302 20.8767 30.6732 21.5292C31.0419 22.1831 31.1421 22.9597 30.9517 23.689C30.7613 24.4182 30.2959 25.0406 29.6574 25.4199C29.2365 25.6714 28.7572 25.8028 28.2696 25.8002C27.7802 25.8005 27.2993 25.6697 26.8748 25.4209C26.4503 25.172 26.0971 24.8138 25.8504 24.3818Z"
                    fill="#BCBEBF"
                />
            </svg>
        </>
    )
}
