import { styled } from 'core'

interface IconComponentWrapperProp {
    isOpen: boolean
}
export const IconComponentWrapper = styled('span', {
    shouldForwardProp: (prop: string) => prop !== 'isOpen',
})<IconComponentWrapperProp>`
    ${({ isOpen }) => isOpen && 'transform: rotate(0.5turn);'}
    ${({ isOpen }) => isOpen && 'padding-top: 2px;'}
    display: inline-block;
`
export const WrapperRenderProp = styled('div')`
    display: flex;
    align-items: center;
    width: 170px;
    justify-content: space-between;
    cursor: pointer;
`
