import { AutoSuggest } from 'core'
import React, { useEffect, useMemo, useState } from 'react'
import { isSubNodeOfTree, getNodeAncestors } from 'taxonomy/utils/nodes'
import { focusTimer } from 'utils/functions/common'
import { useTaxonomyContext } from '../../context/TaxonomyContext'
import { useNodeActions } from '../../hooks/useNodeActions'
import { FlowNode } from '../../types'
import ActionDialogWrapper from '../../../core/ui/ActionDialog/ActionDialogWrapper'
import { SDialogLabel } from './styled'
import { skillSelectOptionRenderFn } from '../../../parts/EmployeeAndSkillAutoSuggestOption/EmployeeAndSkillAutoSuggestOption'
import { VirtualizedList } from 'components/VirtualizedList'

const MoveDialog = () => {
    const id = 'select-toNode-shortcut'
    const { selectedNodeId, selectedNode, reactFlowNodesMap, flowNodes, hideActionDialog, action } =
        useTaxonomyContext()
    const { createShortcut } = useNodeActions()
    const [shortcutedTo, setShortcutedTo] = useState<FlowNode | null>(null)
    const [searchInput, setSearchInput] = useState<string>('')

    const onChangeAutosuggest = (value: FlowNode) => {
        setShortcutedTo(value)
        setSearchInput(value.data.name)
    }

    const onInputChange = (value: string) => {
        setSearchInput(value)
    }

    const onClear = () => {
        setShortcutedTo(null)
        setSearchInput('')
    }

    const possibleShortcuts = useMemo(() => {
        const arrOfShortcuts = selectedNode?.data.children
            .filter((node) => node.data.isShortcut === true)
            .map((node) => node.data.nodeIdPriorToShortcut)

        // find selected node shortcuts if any, then find its ancestors to filter them out to avoid infinite nesting
        const nodeShortcutAncestors = (selectedNode?: FlowNode) => {
            const shortcuts = flowNodes.filter(
                (node) =>
                    node.type === 'shortcut' && node.data.nodeIdPriorToShortcut === selectedNode?.data.id
            )
            if (!shortcuts.length) return []
            const ancestors: any = []
            shortcuts.forEach((shortcut) => ancestors.push(...getNodeAncestors(shortcut)))
            return ancestors
        }

        if (selectedNode) {
            return flowNodes
                .filter(
                    (node) =>
                        !(
                            node.data.isArchived === true ||
                            node.data.isShortcut ||
                            selectedNode?.data.children.includes(node) ||
                            isSubNodeOfTree(selectedNode, node) ||
                            arrOfShortcuts?.includes(node.data.id) ||
                            // case: add to node1 shortcut of node2 then try to add node1 to one of node2's children as shortcut -- website crashes
                            // filter out nodes that will have actual node as a shortcut
                            nodeShortcutAncestors(selectedNode).includes(node)
                        )
                )
                .map((node) => ({
                    ...node,
                    isCategory: node.data.isCategory,
                }))
        }
        return []
    }, [flowNodes, selectedNodeId])

    const handleSubmitAction = () => {
        if (selectedNodeId && shortcutedTo) {
            createShortcut(selectedNodeId, shortcutedTo.id)
        }
    }

    useEffect(() => {
        focusTimer(id)
        return () => {
            clearTimeout(focusTimer(id))
        }
    }, [])

    return (
        <ActionDialogWrapper
            title={'Add new shortcut'}
            buttonText="Add"
            disabled={!shortcutedTo}
            submitAction={handleSubmitAction}
            onClose={hideActionDialog}
            isOpen={!!action}
        >
            <SDialogLabel>to {reactFlowNodesMap[selectedNodeId!]?.data.label}</SDialogLabel>
            <AutoSuggest
                id={id}
                value={shortcutedTo}
                inputValue={searchInput}
                onClear={onClear}
                options={possibleShortcuts}
                ListboxComponent={VirtualizedList()}
                getName={(option) => option.data.name}
                getId={(option) => option.id}
                renderOption={skillSelectOptionRenderFn}
                getOptionDisabled={(option) =>
                    !!selectedNode?.data.children.find((i) => i.data.id === option.data.id)
                }
                onChange={onChangeAutosuggest}
                onInputChange={onInputChange}
            />
        </ActionDialogWrapper>
    )
}

export default MoveDialog
