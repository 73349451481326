import React, { FC, useEffect } from 'react'
import { ReactFlowInstance } from 'react-flow-renderer'
import { useSelector } from 'react-redux'
import { selectAutoSuggestValue } from 'store/slices/autosuggest/selectors'
import ErrorBoundary from 'components/ErrorBoundary'
import { useFindOriginalNode } from 'utils/hooks/useFindOriginalNode'
import { Box } from '@mui/material'
import { AutoSuggest } from 'core'
import { LoopIcon } from 'images'
import { skillSelectOptionRenderFn } from 'parts/EmployeeAndSkillAutoSuggestOption/EmployeeAndSkillAutoSuggestOption'
import { setAutoSuggestValue } from 'store/slices/autosuggest'
import { useDispatch } from 'react-redux'
import { selectTree } from 'store/slices/tree/selectors'
import { useNodeWithPath } from 'utils/hooks/useSkillPathTree'
import { SActionBtn, SHeader } from '../../../taxonomy/ui/TaxonomyHeader/styled'
import { VirtualizedList } from 'components/VirtualizedList'

type SkillsTreeHeaderProps = {
    handleCollapseAll: (instance?: ReactFlowInstance) => void
    handleExpandAll: (instance?: ReactFlowInstance) => void
    updateNodesView: () => void
}

export const SkillsTreeHeader: FC<SkillsTreeHeaderProps> = (props) => {
    const { handleCollapseAll, handleExpandAll, updateNodesView } = props
    const tree = useSelector(selectTree)
    const allNodes = useNodeWithPath(tree)
    // const reactFlow = useReactFlow()
    const dispatch = useDispatch()
    const autoSuggestValue = useSelector(selectAutoSuggestValue).autoSuggestValue
    const findOriginalNode = useFindOriginalNode(autoSuggestValue)

    const clearAutosuggestValue = () => {
        dispatch(setAutoSuggestValue(''))
    }

    useEffect(() => {
        findOriginalNode()
        updateNodesView()
    }, [autoSuggestValue])

    return (
        <ErrorBoundary>
            <SHeader data-automation-id="skills-tree-header">
                <Box component="div" sx={{ display: 'flex' }}>
                    {/* <SActionBtn
                        onClick={() => handleExpandAll()}
                        sx={{
                            borderRight: `1px solid ${borderGrey}`,
                        }}
                    >
                        Expand all
                    </SActionBtn> */}
                    <SActionBtn onClick={() => handleCollapseAll()}>Collapse all</SActionBtn>
                </Box>

                <Box component="div" sx={{ width: '320px' }}>
                    <AutoSuggest
                        id="wizard-header-skill-search"
                        placeholder="Search"
                        options={allNodes}
                        ListboxComponent={VirtualizedList()}
                        renderOption={skillSelectOptionRenderFn}
                        InputProps={{ startAdornment: <LoopIcon /> }}
                        size="small"
                        onChange={(skill) => dispatch(setAutoSuggestValue(skill.node_id))}
                        onClear={clearAutosuggestValue}
                    />
                </Box>
            </SHeader>
        </ErrorBoundary>
    )
}
