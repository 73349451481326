import { Edge, Node as NodeReact, ReactFlowInstance } from 'react-flow-renderer'
import { UseQueryResult } from 'react-query'
import { UserProfile } from 'utils/types/auth'
import { Skill } from 'utils/types/skills'

export type FlowNode = NodeReact<NodeData> & { ref?: React.RefObject<any> }

export interface NodeData extends Node {
    label: string
    children: NodeReact<NodeData>[]
    parent?: NodeReact<NodeData> | null
    isOpen?: boolean
    isShortcut?: boolean
    isChanged: boolean
    parentNode?: null | string
    extent?: null | string
    childrenIds?: string[]
    nodeIdPriorToShortcut?: string
    //  flag's purpose is used to differentiate shortcuts from its subtree and to provide its menu accordingly
    isTemporaryShortcut?: boolean
}

export enum ActionType {
    ARCHIVE = 'archive',
    CREATE_CATEGORY = 'create-category',
    CREATE_ROOT_ITEM = 'create-rootItem',
    CREATE_SKILL = 'create-skill',
    MOVE = 'move',
    RENAME = 'rename',
    RESTORE = 'restore',
    CREATE_SHORTCUT = 'create-shortcut',
    REMOVE = 'remove',
}

export type CreateNodeActionType =
    | ActionType.CREATE_CATEGORY
    | ActionType.CREATE_SKILL
    | ActionType.CREATE_ROOT_ITEM

export enum HistoryType {
    DRAFT = 'draft',
    ON_REVIEW = 'on_review',
    APPROVED = 'approved',
    ADJUSTMENT_REQUESTED = 'adjustment_requested',
    REASSIGNED = 'reassigned',
    ARCHIVED = 'archived',
}

export const ActionTypeTranslations: Record<ActionType, string> = {
    [ActionType.RENAME]: 'Rename',
    [ActionType.CREATE_CATEGORY]: 'Add',
    [ActionType.CREATE_ROOT_ITEM]: 'Add',
    [ActionType.CREATE_SKILL]: 'Add',
    [ActionType.MOVE]: 'Move',
    [ActionType.ARCHIVE]: 'Archive',
    [ActionType.REMOVE]: 'Remove',
    [ActionType.CREATE_SHORTCUT]: 'Add',
    [ActionType.RESTORE]: 'Restore',
}
export interface NodeDTO {
    id: string
    name: string
    is_archived: boolean
    is_category: boolean
    parent_id?: string | null
    shortcuts?: []
}
export interface Node {
    id: string
    name: string
    parentId?: string | null
    isArchived: boolean
    isCategory: boolean
}

export interface Shortcut {
    id: string
    parentId: string
    childId: string // original node of the shortcut
}
export interface ShortcutDto {
    id: string
    parent_id: string
    child_id: string
}

export interface SkillsTreeStatus {
    isLocked: boolean
    ownerId: string | null
    lockedAt: string | null
}

export interface ChangeRequestDto {
    id: number
    owner: UserProfile
    items: CRItemDto[]
    history: ChangeRequestHistoryItemDto[]
    status: string
}

export interface ChangeRequest {
    id: number
    // createdAt: Date
    owner: UserProfile
    items: CRItem[]
    status: HistoryType
    history: ChangeRequestHistoryItem[]
}
export interface CRItemDto {
    item_type: string
    action: string
    item_id: string

    is_category?: boolean
    name?: string
    prev_name?: string
    new_name?: string

    parent_id?: string | null
    parent_name?: string
    new_parent_id?: string | null
    new_parent_name?: string
    prev_parent_id?: string
    prev_parent_name?: string

    child_id?: string | null
    child_name?: string
}

export interface CRItem {
    itemType: CRItemType
    itemId: string
    action: ActionType

    isCategory?: boolean
    name?: string
    prevName?: string
    newName?: string

    parentId?: string | null
    parentName?: string
    newParentId?: string | null
    newParentName?: string
    prevParentId?: string | null
    prevParentName?: string

    childId?: string | null
    childName?: string
}

export enum CRItemType {
    NODE = 'node',
    SHORTCUT = 'shortcut',
}
export enum CRItemDtoAction {
    CREATE = 'create',
    RENAME = 'rename',
    MOVE = 'move',
    REMOVE = 'remove',
    ARCHIVE = 'archive',
    RESTORE = 'restore',
}
export interface ChangeRequestHistoryItem {
    id: number
    user: UserProfile
    status: HistoryType
    timestamp: Date
    comment: string | null
}
export interface ChangeRequestHistoryItemDto {
    id: number
    user: UserProfile
    status: string
    timestamp: string
    comment: string | null
}

export enum IntervalType {
    DOUBLE_CLICK = 300,
}

export type FlowNodesMap = Record<string, FlowNode>

export interface TaxonomyContextValue {
    nodes: FlowNode[]
    isRefetchingActiveCr: boolean
    edges: Edge<any>[]
    flowNodes: FlowNode[]
    categories: FlowNode[]
    isLoading: boolean
    сhildToParentExpand: (node: FlowNode, isShowArchived: boolean) => void
    updateNodesView: () => void
    focusNode: (node: FlowNode, instance: ReactFlowInstance) => void
    isActionInProgress: boolean
    setIsActionInProgress: React.Dispatch<React.SetStateAction<boolean>>
    visibleNodes: FlowNode[]
    changeRequest: ChangeRequest | null
    fetchedActiveChangeRequest?: ChangeRequest[]
    action: ActionType | null
    selectedNode: FlowNode | undefined
    selectedNodeId: string | null
    reactFlowNodesMap: FlowNodesMap
    menuAnchor: (EventTarget & Element) | null
    isShowArchived: boolean
    countSkillsInNode: Record<string, number>
    renderCount: number
    toggleNodeOpen: (node: FlowNode) => void
    recursiveExpandUpdate: (node: FlowNode) => void
    setReactFlowNodesMap: React.Dispatch<React.SetStateAction<Record<string, FlowNode>>>
    setAction: React.Dispatch<React.SetStateAction<ActionType | null>>
    handleExpandAll: (instance?: ReactFlowInstance) => void
    handleCollapseAll: (instance?: ReactFlowInstance) => void
    showActionDialog: (id: string, action: ActionType) => void
    hideActionDialog: () => void
    hideMenu: () => void
    handleVisibleArchived: () => void
    setIsShowArchived: (newState: boolean) => void
    toggleNodeActionMenu: (element: Element, node: FlowNode) => void
    refetch: () => Promise<UseQueryResult>
    shortcutRefetch: () => Promise<UseQueryResult>
    nodeRefetch: () => Promise<UseQueryResult>
    updateCountUserSkills: (data: NodeData, skills: Skill[]) => void
    setRenderCount: (newState: React.SetStateAction<number>) => void
    handleRootCreation: () => void
}
