import { ColorBox } from 'components/ColorBox'
import { Tooltip } from 'components/Tooltip'
import { CrossIconSmall } from 'images'
import {
    changeSkillModal,
    DialogueModalType,
    setDialoguesNotification,
    useNotistackWrapper,
} from 'notifications'
import React, { FC, memo, useCallback } from 'react'
import { stealSkill } from 'store/slices/auth/requests'
import { deleteEmployeeSkill } from 'store/slices/profile/requests'
import { useAppDispatch } from 'store/store'
import { Levels } from 'utils/enums/different'
import { useUserProfile } from 'utils/hooks/useUserProfile'
import { Skill } from 'utils/types/skills'
import { ButtonContainer, RowFlex } from './styled'
import { AppLink } from 'components/StyledLink/styled'
import { TypographyColors } from 'utils/theme/colors'

interface Prop {
    skill: Skill
    level: Levels
}

export const SkillName: FC<Prop> = memo(({ skill, level }) => {
    const { enqueueSuccess } = useNotistackWrapper()

    const { userId, isEditing } = useUserProfile()

    const showDeleteCross = isEditing

    const dispatch = useAppDispatch()

    const deleteUserSkill = useCallback(
        (skill: Skill) => () => {
            const successCb = () => enqueueSuccess('Skill successfully deleted')
            const doubledWantToLearn = skill.want_to_learn && skill.seniority_level
            const doubledDislike = skill.dislike && skill.seniority_level

            const deleteSKill = () => {
                if (doubledWantToLearn && level === Levels.WTL) return { ...skill, want_to_learn: false }
                if (doubledWantToLearn && level !== Levels.WTL)
                    return { ...skill, recent: false, seniority_level: null }

                if (doubledDislike && level === Levels.DWTL) return { ...skill, dislike: false }
                if (doubledDislike && level !== Levels.DWTL)
                    return { ...skill, recent: false, seniority_level: null }
                return skill
            }

            if (doubledWantToLearn || doubledDislike) {
                if (!localStorage.getItem(DialogueModalType.DELETE_SKILL)) {
                    const modal = changeSkillModal({
                        skill: deleteSKill(),
                        onClick: () => dispatch(stealSkill(userId, deleteSKill(), successCb)),
                    })
                    dispatch(setDialoguesNotification(modal))
                    return
                }

                dispatch(stealSkill(userId, deleteSKill(), successCb))
                return
            }
            dispatch(deleteEmployeeSkill(userId, skill.node_id))
        },
        [skill, userId]
    )

    return (
        <Tooltip isVisible={skill.is_archived} text={'This skill was archived'} placement="bottom">
            <ColorBox bordered={skill.is_archived}>
                <RowFlex data-automation-id={`label-skill-name-${skill.name}`}>
                    {skill.is_archived ? (
                        skill.name
                    ) : (
                        <AppLink
                            to={`/search?skills=${skill.node_id}%7C1%7C5`}
                            noWeight
                            color={TypographyColors.DARK_GREY}
                            target="_blank"
                        >
                            {skill.name}
                        </AppLink>
                    )}

                    {showDeleteCross && (
                        <ButtonContainer
                            data-automation-id={`button-skill-name-close-${skill.name}`}
                            onClick={deleteUserSkill(skill)}
                        >
                            <CrossIconSmall />
                        </ButtonContainer>
                    )}
                </RowFlex>
            </ColorBox>
        </Tooltip>
    )
})
