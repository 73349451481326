import { Table, TableBody, TableHead } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { roleDataSelector } from 'store/selectors/admin-selectors'
import { editPermissions, getRoles } from 'store/slices/admin/requests'
import { useAppDispatch } from 'store/store'
import { useRoles } from 'utils/hooks/useRoles'
import { Root } from 'utils/theme/Lib'
import { Role, RoleActions } from 'utils/types/admin'
import { RoleBody } from './RoleBody'
import { RoleFooter } from './RoleFooter'
import { RoleHeader } from './RoleHeader'
import { HeaderTableRowStyled, TableContainerStyled } from './styled'
import { useCheckboxHandler } from './useCheckboxHandler'

export const RoleSubscreen: FC = () => {
    const data = useSelector(roleDataSelector)

    const { currentPermissions, handleCheckbox, setCurrentPermissions, changedRoles } = useCheckboxHandler()

    useEffect(() => {
        if (data) setCurrentPermissions(data)
    }, [data])
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getRoles())
    }, [])

    const roles: Role[] = useRoles()
    const resetPermissions = () => {
        setCurrentPermissions(data)
    }
    const updatePermissions = async (body: RoleActions) => {
        await dispatch(editPermissions(changedRoles, body))
    }
    return (
        <Root>
            <TableContainerStyled>
                <Table stickyHeader aria-label="role table">
                    <TableHead>
                        <HeaderTableRowStyled>
                            <RoleHeader roles={roles} />
                        </HeaderTableRowStyled>
                    </TableHead>

                    <TableBody>
                        <RoleBody data={currentPermissions} handleCheckbox={handleCheckbox} />
                    </TableBody>
                </Table>
            </TableContainerStyled>

            <RoleFooter
                rolesArray={roles}
                resetPermissions={resetPermissions}
                updatePermissions={updatePermissions}
                currentPermissions={currentPermissions}
            />
        </Root>
    )
}
