import { AxiosResponse } from 'axios'
import { setAccessToken } from '../store/slices/auth'
import { ComposedAjaxError } from './ErrorComposer'
import { refreshInstance } from './refreshInstance'
import { uncheckUser } from '../store/slices/auth/requests'
import { dispatch } from './dispatch'

let request: ReturnType<typeof _postToRefreshToken> | undefined

const _postToRefreshToken = (url: string, body?: any) => {
    return refreshInstance.post(url, body)
}

const forceLogout = () => {
    dispatch(uncheckUser())
}

const refreshTokenIfResponseIsUnauthorized = async (ex: any) => {
    const refreshToken = localStorage.getItem('refresh_token')
    if (ex.response.status === 401 && refreshToken) {
        request =
            request ??
            _postToRefreshToken('/api/refresh', {
                refresh_token: refreshToken,
            })

        const newTokenResponse = await request

        // resetting request in case of multiple refreshes
        setTimeout(() => {
            request = undefined
        }, 50)

        if (newTokenResponse.status === 200) {
            const access_token = newTokenResponse.data.access_token
            localStorage.setItem('access_token', access_token)
            dispatch(setAccessToken(access_token))
        }
    }
}

export const responseWrapper = async <T = any>(
    func: () => Promise<AxiosResponse<T>>
): Promise<AxiosResponse<T>> => {
    let res: AxiosResponse<T>
    try {
        res = await func()
        return res
    } catch (ex: any) {
        try {
            await refreshTokenIfResponseIsUnauthorized(ex)
            return await func()
        } catch (ex: any) {
            if (ex.response.status !== 401 && ex.response.status !== 422) {
                return Promise.reject(new ComposedAjaxError(ex))
            }

            forceLogout()
        }
        throw new ComposedAjaxError(ex)
    }
}
