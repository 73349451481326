import { client } from 'axiosClient'
import { ComposedAjaxError } from 'axiosClient/ErrorComposer'
import { find } from 'lodash'
import { CvSkill, FilterSkill, SearchRequestResponse } from 'search/context/types'
import { ResponseData } from 'utils/types/common'
import { CreatedId, PresetDTO } from 'utils/types/presets'
import { NodeWithPath } from 'utils/types/skills'
import { UserPresetRequestBody, UserSearchRequestBody } from './types'
import { constructSearchBody } from 'search/context/converters'

export const getLocations = async () => {
    try {
        const response = await client.get('api/users/locations')
        return response.data
    } catch (error: any) {
        ComposedAjaxError.handleError('Error receiving locations', error)
    }
}

export const getCompetencies = async () => {
    try {
        const response = await client.get('api/users/competencies')
        return response.data
    } catch (error: any) {
        ComposedAjaxError.handleError('Error receiving competencies', error)
    }
}

export const getPresets = async () => {
    try {
        const response = await client.get('api/presets/')
        return response.data
    } catch (error: any) {
        ComposedAjaxError.handleError('Error receiving presets', error)
    }
}

export const searchUsersRequest = async (
    body: UserSearchRequestBody
): Promise<SearchRequestResponse | undefined> => {
    try {
        const response = await client.post('api/users/search', body)
        return { data: response.data, total: response.total }
    } catch (error: any) {
        ComposedAjaxError.handleError('Error searching users', error)
    }
}

export const searchEmployees = async (input?: string) => {
    const result = await searchUsersRequest(
        constructSearchBody({
            skills: {},
            locations: [],
            competencies: [],
            searchString: input || '',
            availableOnly: null,
            orderBy: null,
            offset: 0,
            onBench: false,
        })
    )
    return result?.data
}

export const createPreset = async (body: UserPresetRequestBody): Promise<PresetDTO | undefined> => {
    try {
        const response: CreatedId = await client.post('/api/presets/', body)
        const [id] = response.created_id
        return {
            ...body,
            id,
        }
    } catch (ex: any) {
        if (ex.error.response.status !== 409)
            ComposedAjaxError.handleError('Something went wrong while saving presets', ex)

        throw ex
    }
}

export const removePreset = async (preset_id: number) => {
    try {
        await client._delete(`/api/presets/${preset_id}`)
    } catch (ex: any) {
        ComposedAjaxError.handleError('Error removing preset', ex)
    }
}

export const getSkillRulesByCv = async (file: File): Promise<CvSkill[]> => {
    const formData = new FormData()
    formData.append('file', file)
    const response = await client.post<ResponseData<CvSkill>>('/api/cv/admin', formData, {
        Accept: '*/*',
    })

    return response.data
}

export const filterResultSkills = (
    result: PresetDTO,
    allSkills: NodeWithPath[],
    enqueueError: (message: string) => void
) => {
    const newSkills = [...result?.skills!]
    const skillsWithName: FilterSkill[] = []
    newSkills.forEach((skill) => {
        find(allSkills, { node_id: skill.id })?.name && skillsWithName.push(skill)
    })
    if (newSkills.length !== skillsWithName.length) {
        enqueueError(`${newSkills.length - skillsWithName.length} skills couldn't be found`)
    }
    return skillsWithName
}

export const createPresetByCv = async (file: File): Promise<PresetDTO | undefined> => {
    try {
        const extractedSkills = await getSkillRulesByCv(file)
        const skillSet: FilterSkill[] = extractedSkills.map((v) => ({
            id: v.id,
            level_from: 1,
            level_to: 5,
        }))
        const preset = {
            skills: skillSet,
            locations: [],
            competencies: [],
            text: '',
            name: file.name,
        }
        const result = await createPreset(preset)
        return result
    } catch (ex: any) {
        throw ex
    }
}

export const replacePreset = async (
    body: UserPresetRequestBody,
    id: number
): Promise<PresetDTO | undefined> => {
    try {
        await removePreset(id)
        const result = await createPreset(body)
        return result
    } catch (ex: any) {
        throw ex
    }
}
