import React, { useEffect, useMemo, useState } from 'react'
import { removeSpaces } from 'utils/functions/common'
import { useTaxonomyContext } from '../../context/TaxonomyContext'
import { useNodeActions } from '../../hooks/useNodeActions'
import { ActionType } from '../../types'
import ActionDialogWrapper from '../../../core/ui/ActionDialog/ActionDialogWrapper'
import { UniqueNameError } from './CreateCategoryDialog'
import { SDialogLabel } from './styled'
import { TextField } from '../../../core'

const CreateSkillDialog = () => {
    const { selectedNodeId, reactFlowNodesMap, flowNodes, hideActionDialog, action } = useTaxonomyContext()
    const { createNode } = useNodeActions()

    const [nodeTitle, setNodeTitle] = useState<string>('')
    const [error, setError] = useState<boolean>(false)

    const existingNodeName = useMemo(() => {
        const lowerCaseTitle = removeSpaces(nodeTitle).toLowerCase()
        return flowNodes.some((node) => removeSpaces(node.data.label).toLowerCase() === lowerCaseTitle)
    }, [nodeTitle, flowNodes])

    useEffect(() => {
        if (existingNodeName) setError(true)
        else setError(false)
    }, [existingNodeName])

    return (
        <ActionDialogWrapper
            title={'Add new skill'}
            buttonText="Add"
            disabled={!nodeTitle || error}
            submitAction={() => createNode(nodeTitle, ActionType.CREATE_SKILL, selectedNodeId)}
            onClose={hideActionDialog}
            isOpen={!!action}
        >
            <SDialogLabel>to {reactFlowNodesMap[selectedNodeId!]?.data.label}</SDialogLabel>
            <TextField
                id="new-skill-input"
                value={nodeTitle}
                placeholder={'Type Skill Name'}
                label={'New Skill Name'}
                onChange={(e) => setNodeTitle(e.target.value)}
                autoFocus={true}
            />
            <UniqueNameError error={error} errorLabel={'Skill name should be unique,'} />
        </ActionDialogWrapper>
    )
}

export default CreateSkillDialog
