import React, { FC } from 'react'
import { LazyComponentProps, trackWindowScroll } from 'react-lazy-load-image-component'
import { LazyImage } from '../LazyImage'
import { EmployeeIcon } from '../EmployeeIcon'
import { lightGrey } from '../../utils/theme/colors'

interface Props extends LazyComponentProps {
    pic: string | undefined
    isDismissed?: boolean
    indicatorComponent?: JSX.Element
    withoutBorder?: boolean
}

const EmployeeImagesGallery: FC<Props> = ({
    pic,
    isDismissed,
    indicatorComponent,
    withoutBorder,
    ...props
}) => {
    const getColor = () => {
        if (isDismissed) return lightGrey
    }
    return (
        <LazyImage
            width="30px"
            height="30px"
            borderRadius="50%"
            src={pic}
            scrollPosition={props.scrollPosition}
            fallbackImg={<EmployeeIcon color={getColor()} />}
            indicatorComponent={indicatorComponent}
            withoutBorder={withoutBorder}
        />
    )
}
export default trackWindowScroll(EmployeeImagesGallery)
