import { styled } from 'core'
import { Link } from 'react-router-dom'
import { darkerGrey } from 'utils/theme/colors'
import { HeaderRootProps, InfoProps } from 'utils/types/styling'

export const SWrapper = styled('div')<HeaderRootProps>`
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    box-shadow: inset 0 -1px 0 ${darkerGrey};
    overflow-x: hidden;
`

export const SHeaderContent = styled('div')`
    display: flex;
    align-items: center;
    top: 10px;
    width: 1200px;
`

export const SLink = styled(Link)`
    cursor: pointer;
`

export const InfoWrapper = styled('div')<InfoProps>`
    top: ${(props) => `${props.top}px`};
    color: ${({ color }) => color};
`

export const SecuredLine = styled('span')`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 125px;
`

export const SRightPanel = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
