import { AxiosError } from 'axios'
import { setErrorNotification } from 'notifications'
import { dispatch } from './dispatch'

export const isComposedAjaxError = (e: unknown): e is ComposedAjaxError => {
    return e instanceof ComposedAjaxError
}

const logError = (err: ComposedError | Error) => {
    // eslint-disable-next-line no-console
    console.log(err)
}

export interface ComposedError {
    readonly message: string
    readonly error: AxiosError

    handleGlobally(): void

    getError(): AxiosError
}

export class ComposedAjaxError implements ComposedError {
    name: string = 'ComposedAjaxError'
    public readonly message: string
    public readonly error: AxiosError

    private _globallyHandled: boolean = false

    constructor(error: AxiosError) {
        this.error = error
        const statusCode = error.response ? error.response.status : null

        switch (statusCode) {
            case 400:
                this.message = 'Bad request'
                break

            case 401:
                this.message = 'Please login to access this resource'
                break

            case 403:
                this.message = 'Forbidden'
                break

            case 404:
                this.message = 'The requested resource does not exist or has been deleted'
                break

            default:
                if (typeof error.response?.status === 'undefined') {
                    // must be CORS error or something unknown
                    this.message =
                        'A network error occurred. ' +
                        'This could be a CORS issue or a dropped internet connection'
                    break
                }
                // 500 etc
                this.message = 'Something went wrong and request was not completed'
        }
    }

    public getError(): AxiosError {
        return this.error
    }

    public static showErrorMessage(message: string) {
        dispatch(setErrorNotification(message))
    }

    public handleGlobally(): void {
        if (this._globallyHandled) return
        this._globallyHandled = true
        ComposedAjaxError.showErrorMessage(this.message)
    }

    public static handleError(customMessage: string, error: ComposedError | Error): void {
        logError(error) // shows only in dev mode
        if (!isComposedAjaxError(error)) {
            this.showErrorMessage(error.message)
            return
        }
        const statusCode = error.getError().response?.status
        switch (statusCode) {
            case 400:
            case 404:
            case 409:
            case 500:
                this.showErrorMessage(customMessage)
                break
            default:
                statusCode !== 401 && error.handleGlobally()
        }
    }
}
