import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useRoutes } from 'react-router-dom'
import { authInfo } from '../../store/selectors/auth-selectors'
import { RedirectComponent } from '../../components/RedirectComponent'
import { PageLoader } from '../../components/MoonLoader'
import { LoginPage } from 'pages/login'
import { useAppRoutesConfig } from './useAppRoutesConfig'
import { MicrosoftAuth } from 'auth/ui/MicrosoftAuth'
import { BasicAuth } from 'auth'

export const AppRouter = () => {
    const auth = useSelector(authInfo)
    const location = useLocation()

    const redirect = new URLSearchParams(location.search).get('redirect_url')
    const redirectUrl = redirect ?? `profile/${auth.user?.user_id}`
    const routesConfig = useAppRoutesConfig(redirectUrl)
    const routes = useRoutes(routesConfig.routes)

    return auth.isAuthorized !== null && !auth.authInProgress ? (
        auth.isAuthorized ? (
            <>{routes}</>
        ) : (
            <Routes>
                <Route path="login_redirect" element={<RedirectComponent />} />
                <Route path="login" element={<LoginPage />}>
                    <Route index element={<MicrosoftAuth />} />
                    <Route path="basic" element={<BasicAuth />} />
                </Route>
                <Route path="*" element={<Navigate to="login" replace />} />
            </Routes>
        )
    ) : (
        <PageLoader />
    )
}
