import ErrorBoundary from '../../components/ErrorBoundary'
import { ChangeRequestsList, TaxonomyContextProvider } from '../../taxonomy'
import React, { useEffect } from 'react'
import { LoaderWrapper } from '../../utils/functions/presenters'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../store/store'
import { AdminState } from '../../store/slices/admin'
import { composeRoles } from '../../store/slices/admin/requests'
import { withAppLayout } from '../../parts/AppLayout/AppLayout'

export const ChangeRequestsPage = withAppLayout(
    () => {
        const dispatch = useAppDispatch()
        const admin = useSelector<RootState, AdminState>((state) => state.admin)
        useEffect(() => {
            dispatch(composeRoles())
        }, [])

        const isLoadingRoleData = admin.hasValidRolesData === null

        return (
            <LoaderWrapper isLoading={isLoadingRoleData} hasNoContent={admin.hasValidRolesData === false}>
                <TaxonomyContextProvider workMode={2}>
                    <ErrorBoundary>
                        <ChangeRequestsList />
                    </ErrorBoundary>
                </TaxonomyContextProvider>
            </LoaderWrapper>
        )
    },
    { wideView: true }
)
