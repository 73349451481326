import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { UserProfile } from 'utils/types/auth'
import { useTaxonomyContext } from '../../context/TaxonomyContext'
import { SCRPopupWrapper } from './styled'
import CRAccordion from './CRAccordion'
import { ArrowUpGrey } from 'images'

const ChangeRequestWindow: FC = () => {
    const user = useSelector<RootState, UserProfile | undefined>((state) => state.auth.user)
    const { changeRequest } = useTaxonomyContext()
    const isOwnerOrAdmin = user?.role === 'adm' || changeRequest?.owner.email === user?.email

    if (!isOwnerOrAdmin) {
        return <></>
    }

    return (
        <SCRPopupWrapper>
            <CRAccordion changeRequest={changeRequest} expandIcon={<ArrowUpGrey />} />
        </SCRPopupWrapper>
    )
}

export default ChangeRequestWindow
