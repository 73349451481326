import { styled } from '../../core/utils/core.styled'
import { bluePrimary } from 'utils/theme/colors'
import { CircleIcon, FolderIcon } from 'images'

export const SLabel = styled('span')`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 5px;
`

export const SEmpLabel = styled(SLabel)`
    margin-left: 6px;
`

export const SSkillPath = styled('span')`
    margin: 3px 6px 0px 6px;
    font-size: 11px;
    color: ${bluePrimary};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const SEmployeeConpetence = styled(SSkillPath)`
    color: #7489a0;
`

export const SCircleIcon = styled(CircleIcon)`
    min-width: 17px;
    max-width: 17px;
    height: 13px;
    margin-right: 5px;
`

export const SFolderIcon = styled(FolderIcon)`
    min-width: 17px;
    max-width: 17px;
    height: 13px;
    margin-right: 5px;
`
