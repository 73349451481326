import { useCallback, useState } from 'react'
import { RoleTableData } from 'utils/types/admin'

export const useCheckboxHandler = () => {
    const [currentPermissions, setCurrentPermissions] = useState<RoleTableData>({})
    const [changedRoles, setChangedRoles] = useState<number[]>([])

    const handleCheckbox = useCallback(
        (roleId: number, checked: boolean, actionId: number) => {
            const newData = Object.keys(currentPermissions).reduce(
                (acc, area) => ({
                    ...acc,
                    [area]: {
                        areaName: area,
                        actions: currentPermissions[area].actions.map(
                            (action) => ({
                                ...acc,
                                code: action.code,
                                description: action.description,
                                id: action.id,
                                permissions: action.permissions.map((perm) => {
                                    if (perm.role_id === roleId && action.id === actionId)
                                        return { ...perm, checked: !checked }
                                    return perm
                                }),
                            }),
                            []
                        ),
                    },
                }),
                {}
            )
            setChangedRoles((prevState) =>
                prevState.some((id) => id === roleId) ? prevState : [...prevState, roleId]
            )
            setCurrentPermissions(newData)
        },
        [currentPermissions, setCurrentPermissions]
    )

    return { currentPermissions, setCurrentPermissions, handleCheckbox, changedRoles }
}
