//@ts-nocheck
import { useRef, useState } from 'react'
import React from 'react'
import { CubicBezierLine } from '@react-three/drei'
import { NodesContext } from './NodesContext'

export function Nodes({ children }) {
    const group = useRef()
    const [nodes, setNodes] = useState([])

    const lines = nodes
        .map((item) => item.connectedTo)
        .filter((item) => item.length)
        .flatMap((item) => item)

    return (
        <NodesContext.Provider value={setNodes}>
            <group ref={group}>
                {lines.map((line, index) => (
                    <group key={index + 'group-pair'}>
                        <CubicBezierLine
                            key={index + 'pair'}
                            {...line}
                            color="#b1b1b7"
                            lineWidth={0.4}
                            opacity={1}
                            segments={100}
                        />
                    </group>
                ))}
            </group>
            {children}
            {/* TODO: arrows svgs */}
            {/* {lines.map(({ start, end }, index) => (
                <group key={index} position-z={1}>
                    <NodeElement position={start} />
                    <NodeElement position={end} />
                </group>
            ))} */}
        </NodesContext.Provider>
    )
}
