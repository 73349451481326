import { Paper, styled } from '@mui/material'
import { TextField } from '../../../core/ui/TextField'
import { vars } from 'utils/theme/vars'

export const SkillRangeContainer = styled(Paper)`
    position: relative;
    width: 196px;
    padding: 8px 20px 8px 20px;
    background-color: ${vars.inputBgColor};
    border-radius: ${vars.borderRadius};
    border-style: solid;
    border-width: 1px;
    border-color: ${vars.inputColor};
    box-shadow: none;

    :hover {
        border-color: ${vars.borderColor};
    }
`
export const SelectedFiltersWrapper = styled('div')`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;
`

export const SWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 10px;
`

export const FilterContainer = styled('div')`
    width: 161px;
    max-height: 73px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 11px;
    border: 1px solid #c6ced8;
    background: #f7f8f9;
    border-radius: 5px;
    position: relative;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: #c6ced8;
        border-radius: 10px;
    }
`

export const LocationName = styled('div')`
    color: #173348;
    font-size: 13px;
    margin-left: 8px;
`

export const Item = styled('div')`
    display: flex;
`

export const Title = styled('div')`
    margin-bottom: 22px;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 700;
    width: 90%;
    white-space: nowrap;
`

export const SkillRangeIcon = styled('div')`
    cursor: pointer;
`

export const FilterIcon = styled('div')`
    cursor: pointer;
`

export const FilterLabel = styled('div')`
    position: absolute;
    top: -8px;
`

export const FilterLabelContent = styled('div')`
    display: flex;
    align-items: center;
    color: #54687d;
    font-size: 12px;
    padding-left: 8px;
    line-height: 20px;
    margin-bottom: 5px;
`
export const FilterLabelContentWrapper = styled('div')`
    display: flex;
`

export const FilterBlock = styled('div')`
    display: flex;
    flex-direction: column;
`
export const SearchEmployeesTextField = styled(TextField)`
    width: 312px;
    height: 32px;
    border: 1px solid #2977ff;
    border-radius: 8px;
`

export const Row = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`
