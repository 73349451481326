import React, { FC } from 'react'
import { MoonLoader } from 'react-spinners'
import { MoonLoaderWrapper } from './styled'

export const PageLoader: FC = () => {
    return (
        <MoonLoaderWrapper>
            <MoonLoader size={60} />
        </MoonLoaderWrapper>
    )
}
