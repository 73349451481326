import React, { FC } from 'react'
import { featuredBlue } from '../../utils/theme/colors'

interface Props {
    color?: string
}
export const EmployeeIcon: FC<Props> = ({ color = featuredBlue }) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.0077 30C23.2078 30 30 23.2078 30 14.9923C30 6.79216 23.1924 0 14.9923 0C6.79216 0 0 6.79216 0 14.9923C0 23.2078 6.80763 30 15.0077 30ZM15.0077 20.0052C10.6911 20.0052 7.36462 21.5523 5.86385 23.2852C3.88345 21.1037 2.69211 18.1949 2.69211 14.9923C2.69211 8.15369 8.15369 2.66117 14.9923 2.66117C21.8308 2.66117 27.3234 8.15369 27.3388 14.9923C27.3388 18.1949 26.132 21.1037 24.1516 23.3007C22.6509 21.5523 19.3244 20.0052 15.0077 20.0052ZM15.0077 17.5451C17.8236 17.5761 20.0361 15.1625 20.0361 12.0217C20.0361 9.06653 17.8081 6.6065 15.0077 6.6065C12.1919 6.6065 9.9639 9.06653 9.97937 12.0217C9.99484 15.1625 12.1764 17.5297 15.0077 17.5451Z"
                fill={color}
            />
        </svg>
    )
}
