import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AutoSuggestState {
    autoSuggestValue: string
    nodeIsHighlighted: string
}

const initialState: AutoSuggestState = {
    autoSuggestValue: '',
    nodeIsHighlighted: '',
}

const autoSuggestSlice = createSlice({
    name: 'autosuggest',
    initialState,
    reducers: {
        setAutoSuggestValue(state, action: PayloadAction<string>) {
            state.autoSuggestValue = action.payload
        },
        setOriginNodeHighlighted(state, action: PayloadAction<string>) {
            state.nodeIsHighlighted = action.payload
        },
    },
})

export const { setAutoSuggestValue, setOriginNodeHighlighted } = autoSuggestSlice.actions
export default autoSuggestSlice.reducer
