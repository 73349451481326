import { forEach, get, set } from 'lodash'
import { Edge, MarkerType, Position, ReactFlowInstance } from 'react-flow-renderer'
import { CRItem, FlowNode, FlowNodesMap, Node, Shortcut } from '../types'
import { applyChangeRequest } from './actions'
const dagre = require('dagreskillmatrix')

export const getLayoutedElements = (nodes: FlowNode[], edges: Edge[], workMode: number) => {
    const dagreGraph = new dagre.graphlib.Graph()
    dagreGraph.setDefaultEdgeLabel(() => ({}))
    const nodeWidth = 203
    const nodeHeight = 24
    dagreGraph.setGraph({ rankdir: 'LR', nodesep: 8, align: 'UL' })

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
    })

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target)
    })

    dagre.layout(dagreGraph, { minlen: 1 })

    const findSkillXPosition = (nodes: FlowNode[]) => {
        let highestXSkillPosition = 1003
        nodes.forEach((node) => {
            if (
                (node.type === 'default' || (node.type === 'shortcut' && node.data.isCategory)) &&
                node.position.x + 240 > highestXSkillPosition
            ) {
                highestXSkillPosition = node.position.x + 240
            }
        })
        return highestXSkillPosition
    }

    const findSkillYPosition = (nodes: FlowNode[], node: FlowNode) => {
        const ratio = workMode === 2 ? 30 : 61
        const skills = nodes
            .filter((n) => n.data.isCategory === node.data.isCategory)
            .sort((a, b) => {
                let parentYPositionA = a.data.parent!.position.y
                let parentYPositionB = b.data.parent!.position.y
                return parentYPositionA - parentYPositionB
            })
        const index = skills.indexOf(node)
        return index * ratio
    }

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id)
        node.targetPosition = Position.Left
        node.sourcePosition = Position.Right
        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2.1,
            y: nodeWithPosition.y - nodeHeight / 2 + nodeHeight,
        }
        if (node.type === 'output' || (!node.data.isCategory && node.data.isShortcut)) {
            node.position = {
                x: findSkillXPosition(nodes),
                y: findSkillYPosition(nodes, node),
            }
        }

        return node
    })
    return { nodes, edges }
}

const getFlowNodeType = (node: Node) => {
    if (!node.parentId && node.isCategory) return 'input'
    if (node.parentId && !node.isCategory) return 'output'
    if (node.parentId && node.isCategory) return 'default'
}

export const getFlowNodeFromNode = (node: Node): FlowNode => {
    const type = getFlowNodeType(node)
    return {
        id: node.id,
        data: {
            ...node,
            label: node.name,
            isChanged: false,
            isShortcut: false,
            children: [],
            parent: null,
        },
        type,
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
        position: { x: 200, y: 200 },
        hidden: type !== 'input',
    }
}

export const getFlowNodeFromShortcut = (map: FlowNodesMap, shortcut: Shortcut): FlowNode => {
    const { id, parentId, childId } = shortcut
    const child = get(map, childId)
    return {
        id,
        data: {
            ...child.data,
            id,
            parentId,
            isArchived: false,
            isShortcut: true,
            isChanged: false,
            children: [],
            nodeIdPriorToShortcut: childId,
        },
        type: 'shortcut',
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
        position: { x: 200, y: 200 },
        hidden: true,
    }
}

export const initFlowNodeParent = (map: FlowNodesMap, flowNode: FlowNode) => {
    if (!flowNode.data.parentId) {
        return
    }
    const parent = get(map, flowNode.data.parentId)
    if (!parent) {
        return
    }
    parent.data.children.push(flowNode)
    flowNode.data.parent = parent
    flowNode.hidden = parent.hidden || !parent.data.isOpen
}

const cloneSubTree = (map: FlowNodesMap, sourceParentNodeId: string, targetParentNodeId: string) => {
    const originalNode = map[sourceParentNodeId]
    const shortcutNode = map[targetParentNodeId]
    let copiedChildren: FlowNode[] = originalNode.data.children
    if (!copiedChildren.length) return

    copiedChildren.forEach((child) => {
        const newId = `shortcut_${child.id}_${targetParentNodeId}`
        const newNode = {
            ...child,
            id: newId,
            data: {
                ...child.data,
                id: newId,
                parentId: shortcutNode.id,
                isShortcut: true,
                nodeIdPriorToShortcut: child.id,
                children: [],
                parent: shortcutNode,
                isTemporaryShortcut: true,
            },
            type: 'shortcut',
        }
        shortcutNode.data.children.push(newNode)
        map[newId] = newNode

        if (!child.data.isTemporaryShortcut) cloneSubTree(map, child.id, newId)
    })
}

type MapToFlowNodes = (nodes: Node[], shortcuts?: Shortcut[], crItems?: CRItem[]) => FlowNodesMap

export const mapToFlowNodes: MapToFlowNodes = (nodes, shortcuts = [], crItems = []) => {
    const map: FlowNodesMap = {}
    forEach(nodes, (node) => {
        set(map, node.id, getFlowNodeFromNode(node))
    })
    forEach(shortcuts, (shortcut) => {
        set(map, shortcut.id, getFlowNodeFromShortcut(map, shortcut))
    })
    forEach(map, (flowNode) => initFlowNodeParent(map, flowNode))

    // if shortcut is of category, we must show the same subTree with children exactly as its original node
    // we find all shortcuts from one single category and will duplicate subtree for each shortcut,
    // subtree shortcuts only will have an extra flag called isTemporaryShortcut
    forEach(shortcuts, (shortcut) => cloneSubTree(map, shortcut.childId, shortcut.id))

    applyChangeRequest(map, crItems)
    return map
}

export const showEdges = (visibleNodes: FlowNode[]): Edge[] => {
    return visibleNodes
        .filter((node) => node.data.parentId)
        .map((node) => {
            return {
                id: `edge-${node.data.parentId}-${node.id}`,
                source: node.data.parentId!,
                target: node.id,
                type: MarkerType.Arrow,
                markerEnd: {
                    type: MarkerType.Arrow,
                },
            }
        })
}

export const applyVisibility = (nodes: FlowNode[]): FlowNode[] => {
    return nodes.map((node: FlowNode) => {
        if (node.type === 'input') {
            node.hidden = false
        }
        return node
    })
}

export const openNode = (node: FlowNode) => {
    node.data.isOpen = true
    node.data.children.forEach((n) => (n.hidden = false))
}

export const recursiveCollapse = (node: FlowNode) => {
    node.data.isOpen = false
    const children = node.data.children
    children.forEach((child: FlowNode) => {
        child.hidden = true
        recursiveCollapse(child)
    })
}
export const recursiveExpand = (node: FlowNode, isShowArchived: boolean) => {
    node.data.isOpen = true
    const children = node.data.children
    children.forEach((child: FlowNode) => {
        child.hidden = child.data.isArchived && !isShowArchived
        recursiveExpand(child, isShowArchived)
    })
}
export const сhildToParentExpand = (node: FlowNode, isShowArchived: boolean) => {
    let parent = node.data.parent
    parent?.data.children.forEach((child: FlowNode) => {
        child.data.isOpen = true
        child.hidden = child.data.isArchived && !isShowArchived
    })
    while (parent) {
        parent.data.isOpen = true
        parent.hidden = parent.data.isArchived && !isShowArchived
        parent = parent?.data.parent
    }
}
export const isSubNodeOfTree = (node: FlowNode, tree: FlowNode) => {
    let currNode: FlowNode | undefined | null = node
    while (currNode) {
        if (currNode.id === tree.id) {
            return true
        }
        currNode = currNode.data.parent
    }
    return false
}

export const getNodeAncestors = (shortcut: FlowNode) => {
    const ancestors: any[] = []
    const getAncestor = (node: FlowNode) => {
        let currNode = node
        const parent = currNode.data.parent
        if (parent) {
            ancestors.push(parent)
            getAncestor(parent)
        } else return
    }
    getAncestor(shortcut)
    return ancestors
}

export const recursiveArchive = (nodes: FlowNode[]) => {
    if (!nodes?.length) return

    nodes.forEach((child: FlowNode) => {
        child.data.isArchived = true
        child.data.isChanged = true

        recursiveArchive(child.data.children)
    })
}

export const isCategory = (nodeId: string | null, nodes: Record<string, FlowNode>) => {
    if (nodeId) {
        return nodes[nodeId]!.data.isCategory
    }
}

export const resetViewport = (instance: ReactFlowInstance) => {
    instance.setViewport({ x: 0, y: 0, zoom: 1 })
}

export const isNodeOpen = (node: FlowNode) => node.data.isOpen === true

export const compareNodes = function compare(a: FlowNode, b: FlowNode) {
    if (a.data.label < b.data.label) {
        return -1
    }
    if (a.data.label > b.data.label) {
        return 1
    }
    return 0
}
