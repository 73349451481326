import { DialogPopUp } from 'components/DialogPopUp'
import React, { Component, ErrorInfo, ReactNode } from 'react'

interface Props {
    children?: ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    }

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo)
    }

    public render() {
        if (this.state.hasError) {
            return (
                <DialogPopUp
                    title="An error ocured!"
                    btnOkTitle="Go Back"
                    open={this.state.hasError}
                    isConfirmationDialog
                    onApprove={() => {
                        this.setState({ hasError: false })
                    }}
                ></DialogPopUp>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary
