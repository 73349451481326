import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { PageLoader } from '../MoonLoader'
import { RootState, useAppDispatch } from '../../store/store'
import { checkUserAndToken, getTokens } from '../../store/slices/auth/requests'

export const RedirectComponent = () => {
    const dispatch = useAppDispatch()
    const accessToken = useSelector<RootState, string | null>((state) => state.auth.accessToken)
    const location = useLocation()

    useEffect(() => {
        if (location.search) {
            dispatch(getTokens(new URLSearchParams(location.search).get('code')))
        }
    }, [dispatch, location])

    useEffect(() => {
        if (accessToken) {
            dispatch(checkUserAndToken())
        }
    }, [accessToken, dispatch])

    return <PageLoader />
}
