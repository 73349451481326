//@ts-nocheck
import * as THREE from 'three'
import { useMemo, useLayoutEffect, forwardRef, useContext } from 'react'
import { Html } from '@react-three/drei'

import { NodesContext } from './NodesContext'
import { NodeData } from 'taxonomy/types'

type NodeProps = {
    color: string
    data: NodeData
    name: string
    children: any
    connectedTo: any[]
    position: [number, number, number]
    onClick?: (event?: ThreeEvent<MouseEvent>) => void
}

export const Node = forwardRef(
    ({ color = 'black', children, connectedTo = [], position = [0, 0, 0], ...props }: NodeProps, ref) => {
        const setNodesContext = useContext(NodesContext)

        // const [pos, setPos] = useState(() => new THREE.Vector3(...position))
        const pos = () => new THREE.Vector3(...position)
        const state = useMemo(() => ({ position: pos, connectedTo }), [pos, connectedTo])

        // Register this node on mount, unregister on unmount
        useLayoutEffect(() => {
            setNodesContext((nodes) => [...nodes, state])
            return () => setNodesContext((nodes) => nodes.filter((n) => n !== state))
        }, [state, pos])

        // Drag n drop, hover
        // const { size, camera } = useThree()
        // const [hovered, setHovered] = useState(false)
        // useEffect(() => void (document.body.style.cursor = hovered ? 'grab' : 'auto'), [hovered])
        // const bind = useDrag(({ down, xy: [x, y] }) => {
        //   document.body.style.cursor = down ? 'grabbing' : 'grab'
        //   setPos(
        // new THREE.Vector3((x / size.width) * 2 - 1, -(y / size.height) * 2 + 1, 0)
        //       .unproject(camera)
        //       .multiply({ x: 1, y: 1, z: 0 })
        //       .clone()
        //   )
        // })

        // useFrame(() => {
        //   if (ref.current) {
        //     ref.current.position.set(pos.x, pos.y, 0);
        //   }
        // });

        return (
            <mesh
                ref={ref}
                // {...bind()}
                opacity={0.2}
                radius={0.5}
                color={color}
                position={position}
                {...props}
            >
                <Html distanceFactor={1}>{children}</Html>
            </mesh>
        )
    }
)
