import React, { useEffect, useMemo, useState } from 'react'
import { removeSpaces } from 'utils/functions/common'
import { useTaxonomyContext } from '../../context/TaxonomyContext'
import { useNodeActions } from '../../hooks/useNodeActions'
import { isCategory } from '../../utils/nodes'
import ActionDialogWrapper from '../../../core/ui/ActionDialog/ActionDialogWrapper'
import { ErrorLabel } from './styled'
import { TextField } from '../../../core'

const EditDialog = () => {
    const { selectedNodeId, reactFlowNodesMap, flowNodes, hideActionDialog, action } = useTaxonomyContext()
    const { renameNode } = useNodeActions()

    const [nodeTitle, setNodeTitle] = useState<string>(
        selectedNodeId ? reactFlowNodesMap[selectedNodeId].data.name : ''
    )
    const [error, setError] = useState<boolean>(false)

    const existingNodeName = useMemo(() => {
        const lowerCaseTitle = removeSpaces(nodeTitle).toLowerCase()
        return flowNodes.some(
            (node) =>
                removeSpaces(node.data.label).toLowerCase() === lowerCaseTitle && selectedNodeId !== node.id
        )
    }, [nodeTitle, flowNodes])

    useEffect(() => {
        if (existingNodeName) setError(true)
        else setError(false)
    }, [existingNodeName])

    let nodeType = isCategory(selectedNodeId, reactFlowNodesMap) ? 'Category' : 'Skill'
    return (
        <ActionDialogWrapper
            title={`Edit ${nodeType}`}
            buttonText="Apply"
            disabled={!nodeTitle || error}
            submitAction={() => renameNode(selectedNodeId!, nodeTitle)}
            onClose={hideActionDialog}
            isOpen={!!action}
        >
            <TextField
                id="edit-node-input"
                value={nodeTitle}
                label={`Edit ${nodeType}`}
                onChange={(e) => setNodeTitle(e.target.value)}
                autoFocus={true}
            />
            {error && <ErrorLabel>Name should be unique</ErrorLabel>}
        </ActionDialogWrapper>
    )
}

export default EditDialog
