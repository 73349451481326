import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { clearProfile, deleteUserSkill } from 'store/shared/actions'
import { ExtraProfileInfo, ExtraProfileInfoRaw } from 'utils/types/profile'
import { Skill } from 'utils/types/skills'
import { UserProfile } from 'utils/types/auth'
import { toExtraProfileInfo } from 'utils/functions/converters'

import { getExtraProfileInfo, getProfile } from './actions'

export interface ProfileState {
    profile: UserProfile | null | undefined
    extraInfo: ExtraProfileInfo | null | undefined
    isUserUpdatingSkills: boolean
    isUserDeletingSkills: boolean
    isContributeModalShowed: boolean
    loadingProfile: boolean
    loadingAdditionalInfo: boolean
    error: string
    editingSkills: boolean
}

const initialState: ProfileState = {
    profile: undefined,
    extraInfo: undefined,
    isUserUpdatingSkills: false,
    isUserDeletingSkills: false,
    isContributeModalShowed: false,
    loadingProfile: false,
    loadingAdditionalInfo: false,
    error: '',
    editingSkills: false,
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setInfo: (state, action: PayloadAction<UserProfile>) => {
            state.profile = action.payload
        },
        setExtraInfo: (state, action: PayloadAction<ExtraProfileInfo>) => {
            state.extraInfo = action.payload
        },

        setIsContributeModalShowed: (state, action: PayloadAction<boolean>) => {
            state.isContributeModalShowed = action.payload
        },

        updateSkills: (state, action: PayloadAction<Skill[]>) => {
            if (state.profile) {
                state.profile.skills = action.payload
            }
        },
        setIsUserUpdatingSkills: (state, action: PayloadAction<boolean>) => {
            state.isUserUpdatingSkills = action.payload
        },
        setIsUserDeletingSkills: (state, action: PayloadAction<boolean>) => {
            state.isUserDeletingSkills = action.payload
        },
        toggleMuteNotifications: (state) => {
            if (state.profile) {
                state.profile = {
                    ...state.profile,
                    is_notifications_muted: !state.profile.is_notifications_muted,
                }
            }
        },
        clearError: (state) => {
            state.error = ''
            state.profile = undefined
            state.extraInfo = undefined
        },
        setEditingSkills: (state, action) => {
            state.editingSkills = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(clearProfile, (state) => {
                state.profile = initialState.profile
                state.extraInfo = initialState.extraInfo
                state.error = initialState.error
                state.loadingProfile = false
                state.loadingAdditionalInfo = false
            })
            .addCase(getProfile.pending, (state) => {
                state.loadingProfile = true
                state.profile = undefined
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.profile = action.payload
                state.loadingProfile = false
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.loadingProfile = false
                state.profile = null
                if (!state.error) {
                    state.error = action.payload as string
                    return
                }
                state.error = ''
            })
            .addCase(getExtraProfileInfo.pending, (state) => {
                state.loadingAdditionalInfo = true
                state.extraInfo = undefined
            })
            .addCase(getExtraProfileInfo.fulfilled, (state, action) => {
                state.loadingAdditionalInfo = false
                state.extraInfo = toExtraProfileInfo(action.payload as ExtraProfileInfoRaw)
            })
            .addCase(getExtraProfileInfo.rejected, (state, action) => {
                state.loadingAdditionalInfo = false
                state.extraInfo = null
                if (!state.error) {
                    state.error = action.payload as string
                    return
                }
                state.error = ''
            })
            .addCase(deleteUserSkill, (state, action) => {
                const skillId = action.payload
                if (state.profile) {
                    state.profile = {
                        ...state.profile,
                        skills: state.profile.skills.filter((skill) => skill.node_id !== skillId),
                    }
                }
            })
    },
})

export const {
    toggleMuteNotifications,
    updateSkills,
    setIsUserUpdatingSkills,
    setIsUserDeletingSkills,
    setExtraInfo,
    setInfo,
    setIsContributeModalShowed,
} = profileSlice.actions

export default profileSlice.reducer
