import EmployeeImagesGallery from 'components/EmployeeImagesGallery'
import { StaffingIcon } from 'components/IconComponents/StaffingIcon'
import { StyledLink } from 'components/StyledLink'
import { Tooltip } from 'components/Tooltip'
import React, { FC } from 'react'
// import { useSearchContext } from 'search/context/SearchContext'
import { EmployeeInfo } from 'search/context/types'
import {
    DontWantToLearnIconStyled,
    WantToLearnIconStyled,
} from 'taxonomy/ui/CustomWizardNode/CustomWizardNode.styled'
import { Levels } from 'utils/enums/different'
import { openStaffingProfile } from 'utils/functions/openStaffingPorfile'
import { getLevelColor } from 'utils/functions/presenters'
import { blue400 } from 'utils/theme/colors'
import { CursorPointer } from 'utils/theme/Lib'
import SkillItem from './SkillItem'
import { Cell, Icons, NameBlock, Row, Skills } from './styled'

interface Props {
    row: EmployeeInfo
    onBench: boolean
}

const EmployeeTableRow: FC<Props> = ({ row, onBench }) => {
    // const { comparingPeople, setComparingPeople } = useSearchContext()

    // const onAddToComparing = (id: string) => () => {
    //     if (comparingPeople.some((employeeId) => employeeId === id)) {
    //         setComparingPeople(comparingPeople.filter((employeeId) => employeeId !== id))
    //         return
    //     }

    //     if (comparingPeople.length === 4) {
    //         setComparingPeople([...comparingPeople.slice(1), id])
    //         return
    //     }
    //     setComparingPeople([...comparingPeople, id])
    // }

    // const handleCompareClick = onAddToComparing(row.user_id)

    return (
        <Row key={row.user_id}>
            <Cell>
                <StyledLink to={`/profile/${row.user_id}`} baseColor={blue400}>
                    <NameBlock>
                        <EmployeeImagesGallery withoutBorder pic={row?.google_pic || row?.staffing_pic} />
                        {row.name}
                    </NameBlock>
                </StyledLink>
            </Cell>
            <Cell>{row.staffing_competence}</Cell>
            <Cell>{row.staffing_grade}</Cell>
            <Cell>
                <EmployeeAvailabilityCell row={row} onBench={onBench} />
            </Cell>
            <Cell>
                <Skills>
                    {row.requested_skills.map((v) => (
                        <>
                            <Tooltip
                                text={
                                    v.seniority_level
                                        ? Object.values(Levels)[v.seniority_level + 1] + ' level'
                                        : ''
                                }
                                placement="bottom"
                                isVisible={!!v.seniority_level}
                            >
                                <SkillItem
                                    key={v.node_id}
                                    name={v.name}
                                    color={getLevelColor(
                                        v.seniority_level
                                            ? Object.values(Levels)[v.seniority_level + 1]
                                            : Levels.WTL
                                    )}
                                />
                            </Tooltip>
                            {v.want_to_learn && (
                                <Tooltip text="Want to learn" placement="bottom" isVisible={true}>
                                    <WantToLearnIconStyled isActive={false} isDisabled={false} />
                                </Tooltip>
                            )}
                            {v.dislike && (
                                <Tooltip text="Don't want to learn" placement="bottom" isVisible={true}>
                                    <DontWantToLearnIconStyled isActive={false} isDisabled={false} />
                                </Tooltip>
                            )}
                        </>
                    ))}
                </Skills>
            </Cell>
            <Cell>{row.staffing_location}</Cell>
            <Cell>
                <Icons>
                    {/* <AddToCompareIcon
                        onClick={handleCompareClick}
                        isChecked={comparingPeople.some((chosenId) => chosenId === row.user_id)}
                    /> */}
                    <Tooltip isVisible text="Staffing">
                        <CursorPointer onClick={() => openStaffingProfile(row.staffing_id)}>
                            <StaffingIcon />
                        </CursorPointer>
                    </Tooltip>
                </Icons>
            </Cell>
        </Row>
    )
}

const EmployeeAvailabilityCell: FC<Props> = ({ row, onBench }) => {
    if (row.staffing_availability === 0 && row.staffing_nearest_availability === 0) {
        return <>Not available</>
    }

    return (
        <>
            {onBench === true && <div>Billable load: {row.staffing_total_billable_load} FTE</div>}
            {row.staffing_availability > 0 && <div>{row.staffing_availability} FTE now</div>}
            {row.staffing_nearest_availability > 0 && (
                <div>
                    {row.staffing_nearest_availability} FTE in {row.staffing_nearest_availability_day} days
                </div>
            )}
        </>
    )
}

export default EmployeeTableRow
