import { Paper, styled } from '@mui/material'
import { lightPurple } from 'utils/theme/colors'

export const FilterContainerItem = styled(Paper)`
    position: relative;
    box-sizing: border-box;
    width: 190px;
    padding: 10px 20px 10px 20px;
    background-color: ${lightPurple};
    border-radius: 5px;
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    height: 80px;
`

export const FilterContainerList = styled(Paper)`
    box-sizing: border-box;
    width: 190px;
    height: 80px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 11px;
    background-color: ${lightPurple};
    border-radius: 5px;
    box-shadow: none;
    position: relative;

    ::-webkit-scrollbar-track {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: #c6ced8;
        border-radius: 10px;
    }
`
