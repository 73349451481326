import { DialogTitle, FormControlLabel } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useAppDispatch } from 'store/store'
import { setDialogToHidden, setDialogToVisible } from '../../store/slices/auth/requests'
import { Reason } from '../../utils/types/common'
import { ButtonsWrapper, CheckboxWrapper, ConditionalDialogButton, StyledDialog } from './styled'
import Checkbox from '@mui/material/Checkbox'
import { Button } from '../../core'

interface DialogPopUpProps {
    title: string
    btnOkTitle: string
    btnCancelTitle?: string
    open: boolean
    onClose?: () => void
    onApprove?: () => void
    shouldBeRendered?: boolean
    isConfirmationDialog?: boolean
}

export const DialogPopUp: FC<DialogPopUpProps> = ({
    title,
    btnOkTitle,
    btnCancelTitle,
    open,
    onClose,
    onApprove,
    shouldBeRendered,
    isConfirmationDialog,
}) => {
    const [checked, setChecked] = useState(false)
    const dispatch = useAppDispatch()
    const handleCheckboxChange = () => {
        setChecked(!checked)
    }
    useEffect(() => {
        if (open) {
            if (checked) {
                dispatch(setDialogToHidden())
            } else {
                dispatch(setDialogToVisible())
            }
        }
    }, [checked, dispatch, open])

    const close = (event: {}, reason: Reason) => {
        if (reason !== 'backdropClick') {
            return onClose && onClose()
        }
    }

    return (
        <div>
            <StyledDialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={close}
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                {!isConfirmationDialog && (
                    <CheckboxWrapper shouldBeRendered={shouldBeRendered}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onClick={handleCheckboxChange}
                                    name="dialog checkbox"
                                />
                            }
                            label="Do not show this message again"
                        />
                    </CheckboxWrapper>
                )}
                <ButtonsWrapper>
                    {!isConfirmationDialog ? (
                        <>
                            <Button onClick={() => onClose && onClose()}>{btnCancelTitle}</Button>
                            <ConditionalDialogButton
                                onClick={() => {
                                    onApprove && onApprove()
                                }}
                            >
                                {btnOkTitle}
                            </ConditionalDialogButton>
                        </>
                    ) : (
                        <Button onClick={onApprove}>{btnOkTitle}</Button>
                    )}
                </ButtonsWrapper>
            </StyledDialog>
        </div>
    )
}
