import { Typography, Box } from '@mui/material'
import { PointerSelector } from 'components/PointerSelector'
import { Tooltip } from 'components/Tooltip'
import { AutoSuggest, Button } from 'core'
import { CloseIcon, LoopIcon } from 'images'
import { isEmpty, isString } from 'lodash'
import { useNotistackWrapper } from 'notifications'
import { skillSelectOptionRenderFn } from 'parts/EmployeeAndSkillAutoSuggestOption/EmployeeAndSkillAutoSuggestOption'
import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { stealSkill } from 'store/slices/auth/requests'
import { selectTree } from 'store/slices/tree/selectors'
import { useAppDispatch } from 'store/store'
import {
    DontWantToLearnIconStyled,
    WantToLearnIconStyled,
} from 'taxonomy/ui/CustomWizardNode/CustomWizardNode.styled'
import { SkillStatuses } from 'utils/enums/different'
import { useUserProfile } from 'utils/hooks/useUserProfile'
import { NodeWithPath, Skill } from 'utils/types/skills'
import { SearchContainer, SFastAddSkillContainer } from './styled'
import { useNodeWithPath } from 'utils/hooks/useSkillPathTree'
import { VirtualizedList } from 'components/VirtualizedList'

export const FastAddingSkills: FC = () => {
    const DEFAULT_LEVEL = 0
    const dispatch = useAppDispatch()
    const { userId, editMode, skills } = useUserProfile()
    const { enqueueSuccess, enqueueError } = useNotistackWrapper()
    const [chosenSkill, setChosenSkill] = useState<NodeWithPath | null>(null)
    const [level, setLevel] = useState<number>(0)
    const [status, setStatus] = useState<SkillStatuses | null>(null)
    const tree = useSelector(selectTree)
    const allSkills = useNodeWithPath(tree, false, true)
    const [renderKey, setRenderKey] = useState<number>(0)

    const clearAll = () => {
        setLevel(DEFAULT_LEVEL)
        setStatus(null)
        setChosenSkill(null)
        setRenderKey(renderKey + 1)
    }

    const changeStatus = (newStatus: SkillStatuses) => {
        if (!chosenSkill) {
            enqueueError('Select skill first')
            return
        }
        if (!level && newStatus === SkillStatuses.Recent) {
            enqueueError('Specify skill level first')
            return
        }

        if (status === newStatus) {
            setStatus(null)
        } else {
            setStatus(newStatus)
        }
    }

    const addSkill = () => {
        if (!chosenSkill) return

        const skill: Skill = {
            ...chosenSkill,
            want_to_learn: status === SkillStatuses.WTL,
            dislike: status === SkillStatuses.Dislike,
            recent: status === SkillStatuses.Recent,
            seniority_level: level || null,
            node_id: chosenSkill.node_id,
            parent_id: tree[chosenSkill.node_id].data.parentId,
        }

        dispatch(
            stealSkill(userId, skill, () => {
                enqueueSuccess('Successfully added skill to profile')
                clearAll()
            })
        )
    }

    const setSelectValue = (value: NodeWithPath | null) => {
        setChosenSkill(value)

        const skill = skills.find((x: { node_id: string }) => x.node_id === value?.node_id)

        if (!skill) {
            setLevel(0)
            return
        }

        setLevel(skill.seniority_level || 0)
    }

    const onChangeAutosuggest = (value: NodeWithPath | null) => {
        setSelectValue(value)

        const targetSkill = skills.find((item) => item.node_id === value?.node_id)
        if (targetSkill?.dislike) setStatus(SkillStatuses.Dislike)
        else if (targetSkill?.want_to_learn) setStatus(SkillStatuses.WTL)
    }

    const handleLevelChange = (level: number) => {
        setLevel(level)
    }

    const path = useMemo(() => allSkills.find((elem) => elem.node_id === chosenSkill?.node_id), [chosenSkill])

    return (
        <SFastAddSkillContainer data-automation-id="container-fast-adding">
            <Typography variant="h4" data-automation-id="header-fast-adding">
                Add Skills
            </Typography>
            <SearchContainer>
                <AutoSuggest
                    key={renderKey}
                    id="input-fast-adding-autosuggest"
                    options={allSkills}
                    ListboxComponent={VirtualizedList()}
                    size="small"
                    InputProps={{ startAdornment: <LoopIcon /> }}
                    renderOption={skillSelectOptionRenderFn}
                    onChange={onChangeAutosuggest}
                    value={chosenSkill}
                    onClear={clearAll}
                    sx={{
                        flexGrow: 1,
                    }}
                    label={isString(path?.nodePathForAutoSuggest) ? path?.nodePathForAutoSuggest : undefined}
                />
                <PointerSelector
                    data-automation-id="select-level"
                    onClick={handleLevelChange}
                    defSelected={level ?? 0}
                    disabled={isEmpty(chosenSkill)}
                    enqueueError={enqueueError}
                />
                <Tooltip text="Want to learn" placement="bottom" isVisible>
                    <WantToLearnIconStyled
                        onClick={() => changeStatus(SkillStatuses.WTL)}
                        isActive={status === SkillStatuses.WTL}
                        isDisabled={isEmpty(chosenSkill)}
                    />
                </Tooltip>
                <Tooltip text="Don't want to learn" placement="bottom" isVisible>
                    <DontWantToLearnIconStyled
                        onClick={() => changeStatus(SkillStatuses.Dislike)}
                        isActive={status === SkillStatuses.Dislike}
                        isDisabled={isEmpty(chosenSkill)}
                    />
                </Tooltip>
                <Button
                    data-automation-id="button-add"
                    onClick={addSkill}
                    disabled={
                        !chosenSkill ||
                        (level === 0 && status !== SkillStatuses.WTL && status !== SkillStatuses.Dislike)
                    }
                    variant="contained"
                >
                    Add
                </Button>
            </SearchContainer>
            <Box
                component="div"
                data-automation-id="button-fast-adding-close"
                onClick={() => editMode(false)}
                sx={{ cursor: 'pointer' }}
            >
                <CloseIcon />
            </Box>
        </SFastAddSkillContainer>
    )
}
