import React, { FC, memo, useContext, useMemo, useState } from 'react'
import { Button, TabsContext, IconButton } from 'core'
import { ButtonsBox, ButtonsContainer, CommentBox } from './styled'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { UserProfile } from 'utils/types/auth'
import { ChangeRequest, HistoryType } from 'taxonomy/types'
import ReassignDialog from '../ChangeRequestsList/ReassignDialog'
import { reassignSubmittedChangeRequest } from 'taxonomy/api/requests'
import { isActiveChangeRequest } from 'taxonomy/utils/changeRequests'
import { useChangeRequestActions } from 'taxonomy/hooks/useChangeRequestActions'
import { TrashBin2, Reassign } from 'images'
import { ButtonColors } from 'utils/theme/colors'
import { Tooltip } from 'components/Tooltip'
import { useTaxonomyContext } from 'taxonomy/context/TaxonomyContext'
import { FormattedUser } from 'utils/types/users'

interface AccordionEditableContainerProp {
    changeRequest: ChangeRequest | null
}

export const AccordionEditableContainer: FC<AccordionEditableContainerProp> = memo(({ changeRequest }) => {
    const { applyChangesInChangeRequest, discardChangeRequest, revokeChangeRequest, adjustChangeRequest } =
        useChangeRequestActions()
    const { isActionInProgress } = useTaxonomyContext()
    const { setCurrentTab, currentTab } = useContext(TabsContext)
    const user = useSelector<RootState, UserProfile | undefined>((state) => state.auth.user)
    const usersWithCreateCrPermission = useSelector<RootState, FormattedUser[]>(
        (state) => state.users.usersWithCreateCrPermission
    )

    const [comment, setComment] = useState<string>('')
    const [isOpenReassignDialog, setIsOpenReassignDialog] = useState<boolean>(false)

    const isAdmin = user!.role === 'adm'
    const divertedChangeRequest = useMemo(
        () =>
            changeRequest?.status === HistoryType.REASSIGNED ||
            changeRequest?.status === HistoryType.ADJUSTMENT_REQUESTED,
        [changeRequest]
    )
    const isAdminAndOnReview = useMemo(
        () => isAdmin && changeRequest?.status === HistoryType.ON_REVIEW,
        [changeRequest, user]
    )
    const isOwnerAndOnReview = useMemo(
        () => !isAdmin && changeRequest?.status === HistoryType.ON_REVIEW,
        [changeRequest, user]
    )
    const inStatusAdjustmentOrReassigned =
        changeRequest?.status === HistoryType.ADJUSTMENT_REQUESTED ||
        changeRequest?.status === HistoryType.REASSIGNED
    const isOnwerAndNotOnReview = useMemo(
        () => changeRequest?.status !== HistoryType.ON_REVIEW && !isAdmin,
        [changeRequest, user]
    )

    const applyChanges = () => {
        applyChangesInChangeRequest(changeRequest!.id, comment)
        setComment('')
    }

    const handleDiscard = () => {
        discardChangeRequest(changeRequest!.id, comment)
        setComment('')
    }

    const handleRequestAdjustment = () => {
        adjustChangeRequest(changeRequest!.id, comment)
        setComment('')
    }

    const handleReassign = (userId: string) => {
        reassignSubmittedChangeRequest(changeRequest!.id, comment, userId)
        setComment('')
    }

    return (
        <>
            {isOpenReassignDialog && (
                <ReassignDialog
                    onReassign={handleReassign}
                    isOpen={isOpenReassignDialog}
                    setIsOpen={setIsOpenReassignDialog}
                    crId={changeRequest!.id.toString()}
                    ownerId={changeRequest?.owner.id}
                    users={usersWithCreateCrPermission}
                />
            )}
            {isActiveChangeRequest(changeRequest) && (
                <div>
                    {!isOwnerAndOnReview && (
                        <CommentBox
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            fullWidth
                            maxRows={2}
                            placeholder="Comment your changes"
                        />
                    )}
                    <ButtonsBox>
                        <ButtonsContainer>
                            {isOwnerAndOnReview && (
                                <Button
                                    id="accordion-revoke"
                                    variant="contained"
                                    disabled={isActionInProgress}
                                    onClick={() => revokeChangeRequest(changeRequest!.id, comment)}
                                >
                                    Revoke
                                </Button>
                            )}
                            {isAdmin && !divertedChangeRequest && (
                                <Button
                                    id="accordion-apply-changes"
                                    variant="outlined"
                                    onClick={applyChanges}
                                    disabled={isActionInProgress}
                                >
                                    Apply changes
                                </Button>
                            )}
                            {isOnwerAndNotOnReview && (
                                <Button
                                    id="accordion-submit-for-review"
                                    variant="outlined"
                                    onClick={applyChanges}
                                    disabled={inStatusAdjustmentOrReassigned || isActionInProgress}
                                >
                                    Submit for Review
                                </Button>
                            )}
                            {isAdminAndOnReview && (
                                <Button
                                    id="accordion-requested-adjustment"
                                    variant="contained"
                                    onClick={handleRequestAdjustment}
                                    disabled={isActionInProgress}
                                >
                                    Request adjustment
                                </Button>
                            )}
                            {currentTab === '4' && (
                                <Button
                                    id="accordion-view"
                                    variant="outlined"
                                    onClick={() => setCurrentTab('3')}
                                >
                                    View
                                </Button>
                            )}
                        </ButtonsContainer>
                        <ButtonsContainer>
                            {isAdminAndOnReview &&
                                (currentTab === '4' ? (
                                    <Button
                                        id="accordion-reassign"
                                        onClick={() => setIsOpenReassignDialog(true)}
                                        startIcon={<Reassign fill={ButtonColors.GREY} />}
                                        disabled={isActionInProgress}
                                    >
                                        Reassign
                                    </Button>
                                ) : (
                                    <Tooltip text="Reassign" isVisible={isAdminAndOnReview}>
                                        <IconButton
                                            id="accordion-reassign"
                                            onClick={() => setIsOpenReassignDialog(true)}
                                            disabled={isActionInProgress}
                                        >
                                            <Reassign fill={ButtonColors.GREY} />
                                        </IconButton>
                                    </Tooltip>
                                ))}
                            {!isOwnerAndOnReview &&
                                (currentTab === '4' ? (
                                    <Button
                                        id="accordion-discard-changes"
                                        onClick={handleDiscard}
                                        variant="text"
                                        startIcon={<TrashBin2 fill={ButtonColors.GREY} />}
                                        disabled={isActionInProgress}
                                    >
                                        Discard
                                    </Button>
                                ) : (
                                    <Tooltip text="Discard" isVisible={isAdminAndOnReview}>
                                        <IconButton
                                            id="accordion-discard-changes"
                                            onClick={handleDiscard}
                                            disabled={isActionInProgress}
                                        >
                                            <TrashBin2 fill={ButtonColors.GREY} />
                                        </IconButton>
                                    </Tooltip>
                                ))}
                        </ButtonsContainer>
                    </ButtonsBox>
                </div>
            )}
        </>
    )
})
