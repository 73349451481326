import { useNotistackWrapper } from 'notifications'
import { useSelector } from 'react-redux'
import { getTreeNodes } from 'store/slices/tree/requests'
import { RootState, useAppDispatch } from 'store/store'
import {
    adjustSubmittedChangeRequest,
    approveChangeRequestChanges,
    discardActiveChangeRequest,
    reassignSubmittedChangeRequest,
    revokeSubmittedChangeRequest,
    submitChangeRequestChanges,
    updateCurrentOrCreateCR,
} from 'taxonomy/api/requests'
import { applyCrItem } from 'taxonomy/utils/actions'
import { mapToFlowNodes } from 'taxonomy/utils/nodes'
import { UserProfile } from 'utils/types/auth'
import { useTaxonomyContext } from '../context/TaxonomyContext'
import { CRItem, HistoryType, Node, Shortcut } from '../types'
import { updateUserInfo } from 'store/slices/auth/requests'

interface useChangeRequestActionsProp {
    pushNewChangeRequestItem: (crItem: CRItem) => void

    pushAndApplyCrItem: (crItem: CRItem) => void

    applyChangesInChangeRequest: (id: number, comment: string) => void
    revokeChangeRequest: (id: number, comment: string) => void
    reassignChangeRequest: (id: number, comment: string, newOwnerId: string) => void
    adjustChangeRequest: (id: number, comment: string) => void
    discardChangeRequest: (id: number, comment: string) => void
}

export const useChangeRequestActions = (): useChangeRequestActionsProp => {
    const user = useSelector<RootState, UserProfile | undefined>((state) => state.auth.user)
    const {
        nodeRefetch,
        refetch,
        shortcutRefetch,
        changeRequest,
        reactFlowNodesMap,
        hideActionDialog,
        setReactFlowNodesMap,
        setIsActionInProgress,
    } = useTaxonomyContext()
    const dispatch = useAppDispatch()
    const { enqueueSuccess } = useNotistackWrapper()

    const pushNewChangeRequestItem = async (item: CRItem) => {
        const response = await updateCurrentOrCreateCR(item)
        if (response) {
            refetch()
            enqueueSuccess(`Change Request successfully updated`)
        }
        return response
    }

    const discardChangeRequest = async (id: number, comment: string) => {
        setIsActionInProgress(true)
        const response = await discardActiveChangeRequest(id, comment)
        if (response) {
            await refetch()
            setIsActionInProgress(false)
            enqueueSuccess(`Change Request #${id} successfully discarded`)
        }
    }

    const revokeChangeRequest = async (id: number, comment: string) => {
        setIsActionInProgress(true)
        const response = await revokeSubmittedChangeRequest(id, comment)
        if (response) {
            await refetch()
            setIsActionInProgress(false)
            enqueueSuccess(`Change Request #${id} successfully revoked`)
        }
    }

    const adjustChangeRequest = async (id: number, comment: string) => {
        setIsActionInProgress(true)
        const response = await adjustSubmittedChangeRequest(id, comment)
        if (response) {
            await refetch()
            setIsActionInProgress(false)
            enqueueSuccess(`Change Request #${id} successfully sent for reajustments`)
        }
    }

    const reassignChangeRequest = async (id: number, comment: string, newOwnerId: string) => {
        setIsActionInProgress(true)
        const response = await reassignSubmittedChangeRequest(id, comment, newOwnerId)
        if (response) {
            await refetch()
            setIsActionInProgress(false)
            enqueueSuccess(`Change Request #${id} successfully discarded`)
        }
    }

    const applyChangesInChangeRequest = async (id: number, comment: string) => {
        setIsActionInProgress(true)
        const isAdmin = user?.role === 'adm'
        const isOnReview = changeRequest?.status === HistoryType.ON_REVIEW
        const response = await (isAdmin && isOnReview
            ? approveChangeRequestChanges(id, comment)
            : submitChangeRequestChanges(id, comment))
        if (response) {
            await Promise.all([refetch(), shortcutRefetch(), nodeRefetch()]).then((values) => {
                const shortcuts: Shortcut[] = values[1].data as Shortcut[]
                const nodes: Node[] = values[2].data as Node[]
                const nodesMap = mapToFlowNodes(nodes, shortcuts)

                const map = { ...reactFlowNodesMap }

                for (let item in nodesMap) {
                    nodesMap[item].hidden = map[item].hidden
                    nodesMap[item].type = map[item].type
                    nodesMap[item].data.isOpen = map[item].data.isOpen
                }

                setReactFlowNodesMap(nodesMap)
                dispatch(getTreeNodes())
                dispatch(updateUserInfo())
            })
            setIsActionInProgress(false)
            enqueueSuccess(
                `Change Request #${id} successfully  ${
                    changeRequest?.owner.role === 'adm' || user?.role === 'adm'
                        ? 'approved'
                        : 'submitted, administrator was notified'
                }`
            )
        }
    }

    const pushAndApplyCrItem = async (crItem: CRItem) => {
        setIsActionInProgress(true)
        hideActionDialog()
        const map = { ...reactFlowNodesMap }
        const change = await pushNewChangeRequestItem(crItem)
        if (change) {
            applyCrItem(map, crItem)
            setReactFlowNodesMap(map)
        }
        setIsActionInProgress(false)
    }

    return {
        discardChangeRequest,
        revokeChangeRequest,
        adjustChangeRequest,
        reassignChangeRequest,
        applyChangesInChangeRequest,
        pushNewChangeRequestItem,
        pushAndApplyCrItem,
    }
}
