import React from 'react'
import { ProfileSkills } from 'parts/Profile/parts/ProfileSkills'
import { ProfileSummary } from '../ProfileSummary'
import { Stack } from '@mui/material'

export const Profile = () => {
    return (
        <Stack direction="row" spacing={3}>
            <ProfileSummary />
            <ProfileSkills />
        </Stack>
    )
}
