import { Box, Modal } from '@mui/material'
import { AutoSuggest } from 'core'
import { PresetSelect } from 'core/ui/presetSelect'
import { TreeIcon } from 'images'
import { useNotistackWrapper } from 'notifications'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { createPresetByCv, filterResultSkills, removePreset } from 'search/api/requests'
import { useSearchContext } from 'search/context/SearchContext'
import { transformPresetDTOIntoPreset } from 'search/utils/functions/transformPresetDTO'
import { selectTree } from 'store/slices/tree/selectors'
import { acceptedFileTypesCV } from 'utils/helpers/constants'
import { Preset } from 'utils/types/presets'
import { NodeWithPath } from 'utils/types/skills'
import AddPresetModal from '../AddPresetModal'
import { IdNameItem, MultiAutoSuggest } from '../../../core/ui/MultiAutoSuggest'
import { ButtonSaveLoadPreset, ControlsWrapper } from './FilterControls.styled'
import { UploadButton } from './UploadButton'
import { skillSelectOptionRenderFn } from '../../../parts/EmployeeAndSkillAutoSuggestOption/EmployeeAndSkillAutoSuggestOption'
import { useNodeWithPath } from 'utils/hooks/useSkillPathTree'
import { sortSkillsByCategory } from 'utils/functions/common'
import { isEmpty } from 'lodash'
import { VirtualizedList } from 'components/VirtualizedList'

const convertOptionsToValues: (items: IdNameItem[]) => string[] = (items) => items.map((i) => i.name)

const convertValuesToOptions: (values: string[]) => IdNameItem[] = (values) =>
    values.map((val) => ({
        id: val,
        name: val,
    }))

export const FilterControls = () => {
    const { enqueueError } = useNotistackWrapper()
    const [presetModalOpen, setPresetModalOpen] = useState<boolean>(false)
    const [presetLoading, setPresetLoading] = useState<boolean>(false)
    const [inputKey, setInputKey] = useState<number>(0)
    const {
        skills,
        setSkills,
        fetchedLocations,
        fetchedCompetencies,
        presets,
        locations,
        setLocations,
        competencies,
        setCompetencies,
        preset,
        setPreset,
        setSearchString,
        setPresets,
        setAvailableOnly,
        searchString,
        setOrderBy,
        setOnBench,
    } = useSearchContext()
    const tree = useSelector(selectTree)
    const allSkills = useNodeWithPath(tree)

    const onSkillsChanged = (value: { node_id: string; name: string; isCategory: boolean } | null) => {
        if (value) {
            setSkills((prev) => ({
                ...prev,
                [value.node_id]: {
                    id: value?.node_id,
                    name: value?.name,
                    level_from: 1,
                    level_to: 5,
                    is_category: value.isCategory,
                },
            }))
        }
        setInputKey((prev) => prev + 1)
    }

    const applyPreset = (value: Preset) => {
        if (value) {
            setSkills(value.skills)
            setCompetencies(value.competencies || [])
            setLocations(value.locations || [])
            setSearchString(value.text || '')
            setAvailableOnly(!!value.available)
        } else {
            setSkills({})
        }
    }
    const onUploadedFile = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0]
            setPresetLoading(true)
            try {
                let result = await createPresetByCv(file)

                setPresetLoading(false)
                if (result) {
                    result.skills = filterResultSkills(result, allSkills, enqueueError)
                    const newPreset = transformPresetDTOIntoPreset(result)
                    setPreset(newPreset)
                    setPresets((prev) => [...prev, newPreset])
                    applyPreset(newPreset)
                    event.target.value = ''
                }
            } catch (error: any) {
                const err = Object.values(error.error.response.data)
                enqueueError(err.join(' '))
                event.target.value = ''
                setPresetLoading(false)
            }
        }
    }, [])

    const onPresetSelected = (value: Preset | null) => {
        setPreset(value)
        if (value) {
            applyPreset(value)
        }
    }

    const onRemovePreset = (id: number) => {
        if (presets.length === 1) {
            // Case when user removes the current filter
            const resetFilters = () => {
                setSkills({})
                setSearchString('')
                setLocations([])
                setCompetencies([])
                setAvailableOnly(false)
                setOrderBy(null)
                setOnBench(false)
            }

            resetFilters()
        }

        removePreset(id)
        setPreset(null)
        setPresets((prev) => [...prev.filter((v) => v.id !== id)])
    }

    const skillItems = useMemo(() => allSkills?.filter((v) => !skills[v.node_id]), [allSkills, skills])
    const skillsItemsSorted = useMemo(
        () => skillItems.sort((a, b) => sortSkillsByCategory(a, b)),
        [skillItems]
    )

    const checkIsFiltersEmpty = () => {
        if (isEmpty(skills) && !competencies.length && !locations.length && !searchString) return true
        else return false
    }

    return useMemo(() => {
        return (
            <ControlsWrapper>
                <Box component="div" sx={{ width: '360px' }}>
                    <AutoSuggest
                        id="skills-filter"
                        key={inputKey}
                        placeholder={'Skill'}
                        renderOption={skillSelectOptionRenderFn}
                        ListboxComponent={VirtualizedList()}
                        options={skillsItemsSorted}
                        onChange={onSkillsChanged}
                        InputProps={{ startAdornment: <TreeIcon /> }}
                        isOptionEqualToValue={(option: NodeWithPath, value: NodeWithPath) => option === value}
                    />
                </Box>
                <Box component="div" sx={{ width: '150px' }}>
                    <MultiAutoSuggest<IdNameItem>
                        id="competencies-filter"
                        label={'Business unit'}
                        options={convertValuesToOptions(fetchedCompetencies || [])}
                        value={convertValuesToOptions(competencies)}
                        onChange={(selectedCompetencies) => {
                            setCompetencies(convertOptionsToValues(selectedCompetencies))
                        }}
                        renderTags={() => ''}
                        componentsProps={{
                            paper: {
                                sx: {
                                    width: '500px',
                                },
                            },
                        }}
                    />
                </Box>
                <Box component="div" sx={{ width: '150px' }}>
                    <MultiAutoSuggest<IdNameItem>
                        id="locations-filter"
                        label={'Location'}
                        options={convertValuesToOptions(fetchedLocations || [])}
                        value={convertValuesToOptions(locations)}
                        onChange={(selectedLocations) =>
                            setLocations(convertOptionsToValues(selectedLocations))
                        }
                        hasSelectAllOption={true}
                        renderTags={() => ''}
                        componentsProps={{
                            paper: {
                                sx: {
                                    width: '250px',
                                },
                            },
                        }}
                    />
                </Box>

                <UploadButton
                    id="button-upload-cv"
                    variant="outlined"
                    accept={acceptedFileTypesCV}
                    onInputChange={onUploadedFile}
                    disabled={presetLoading}
                >
                    Upload CV
                </UploadButton>
                <ButtonSaveLoadPreset
                    id="preset-modal-save-button"
                    onClick={() => setPresetModalOpen(true)}
                    variant="text"
                    disabled={checkIsFiltersEmpty()}
                >
                    Save preset
                </ButtonSaveLoadPreset>

                {presets.length > 0 ? (
                    <Box component="div" sx={{ width: '150px' }}>
                        <PresetSelect
                            id="select-choose-preset"
                            label="Load preset"
                            value={preset}
                            options={presets}
                            onChange={onPresetSelected}
                            onRemove={onRemovePreset}
                        />
                    </Box>
                ) : (
                    <ButtonSaveLoadPreset id="preset-load-button" variant="text" disabled={true}>
                        Load preset
                    </ButtonSaveLoadPreset>
                )}

                {presetModalOpen && (
                    <Modal open={presetModalOpen}>
                        <div>
                            <AddPresetModal
                                setIsOpen={(open) => setPresetModalOpen(open)}
                                isOpen={presetModalOpen}
                            />
                        </div>
                    </Modal>
                )}
            </ControlsWrapper>
        )
    }, [
        skills,
        setSkills,
        fetchedLocations,
        fetchedCompetencies,
        presets,
        locations,
        setLocations,
        competencies,
        setCompetencies,
        preset,
        setPreset,
        setSearchString,
        setPresets,
        setAvailableOnly,
        presetModalOpen,
        presetLoading,
        inputKey,
        searchString,
    ])
}
