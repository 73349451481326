import { Levels } from '../enums/different'
import { SkillInfo } from '../types/auth'
import { Entity, LabelEntity } from '../types/common'
import { ExtraProfileInfo, ExtraProfileInfoRaw } from '../types/profile'
import { Skill, SkillRule, SkillRuleDTO } from '../types/skills'

export const convertEntityToLabel = (option: Entity): LabelEntity => {
    return {
        label: option.name,
        id: option.id,
    }
}

export const convertToJSONLevel = (level: Levels): string => level.toLocaleLowerCase().replaceAll(' ', '_')

export const skillToSkillInfo = ({
    want_to_learn,
    node_id,
    seniority_level,
    recent,
    dislike,
}: Skill): SkillInfo => {
    return {
        skill_id: node_id!,
        want_to_learn: want_to_learn,
        seniority_level: seniority_level,
        recent: recent,
        dislike: dislike,
    }
}

export const storedRuleFromSkillDto = ({
    is_main,
    skill_id,
    recent,
    name,
    ...props
}: SkillRuleDTO): SkillRule => {
    return {
        ...props,
        isMain: is_main,
        skillId: skill_id,
        recent: recent,
        name: name,
    }
}

export const storedRuleToSkillDto = ({ isMain, skillId, ...props }: SkillRule): SkillRuleDTO => {
    return {
        ...props,
        is_main: isMain,
        skill_id: skillId,
    }
}

export const nullCompetenceId = (entity: Entity): number | null => {
    return entity?.id === -1 ? null : entity?.id
}

export const toExtraProfileInfo = (info: ExtraProfileInfoRaw): ExtraProfileInfo => {
    const certificates = info.certificates ? info.certificates.split(',').map((res) => res.trim()) : []
    const keySkills = info.skills ? info.skills.split(',').map((res) => res.trim()) : []
    const position = info.position
    const availability = info.availability
    const nearestAvailability = info.nearestAvailability
    const nearestAvailabilityDay = info.nearestAvailabilityDay
    const solidLineManager = info.solidLineManager
    const isExternalConsultant = info.isExternalConsultant

    return {
        availability,
        nearestAvailability,
        nearestAvailabilityDay,
        position,
        solidLineManager,
        isExternalConsultant,
        keySkills,
        certificates,
    }
}
