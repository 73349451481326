import React, { FC } from 'react'
import { SkillStatuses } from '../../utils/enums/different'
import { DontWantToLearnIcon, RecentIcon, WantToLearnIcon } from '../../images'

interface Prop {
    status: SkillStatuses | null
}

export const StatusIcon: FC<Prop> = ({ status, ...restProps }) => {
    switch (status) {
        case SkillStatuses.WTL:
            return <WantToLearnIcon {...restProps} />
        case SkillStatuses.Dislike:
            return <DontWantToLearnIcon {...restProps} />
        case SkillStatuses.Recent:
            return <RecentIcon {...restProps} />
        default:
            return <></>
    }
}
