import { createSelector } from '@reduxjs/toolkit'
import { Actions } from '../../utils/enums/different'
import { authInfo } from './auth-selectors'

export const canViewEmployeeAdminPanel = createSelector(authInfo, (auth) =>
    auth.user?.actions.includes(Actions.ViewOthersProfilePages)
)
export const canViewSkillsChangingScreen = createSelector(authInfo, (auth) =>
    auth.user?.actions.includes(Actions.ViewSkillsChangingScreen)
)
export const canViewRoleMatrix = createSelector(authInfo, (auth) =>
    auth.user?.actions.includes(Actions.ViewRoleMatrix)
)
export const canViewOtherProfiles = createSelector(authInfo, (auth) =>
    auth.user?.actions.includes(Actions.ViewOthersProfilePages)
)
export const canViewTaxonomy = createSelector(authInfo, (auth) =>
    auth.user?.actions.includes(Actions.CreateCr)
)
