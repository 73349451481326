import { Box, Button, ButtonBaseProps } from '@mui/material'
import { tannBlue } from '../../utils/theme/colors'
import React from 'react'
import { useAppHeaderMenuItems } from './useAppHeaderMenuItems'
import { NavLink } from 'react-router-dom'
import { styled } from '../../core'

const SMenuItem = styled(Button)<ButtonBaseProps>`
    height: 56px;
    font-size: 14px;
    padding: 8px;
    text-transform: uppercase;
    border-radius: 0;

    &.active {
        color: ${tannBlue};
    }

    &:hover {
        opacity: 0.75;
    }
`

export const AppHeaderMenu: React.FC = () => {
    const { menuItems } = useAppHeaderMenuItems()

    return (
        <Box component="div" sx={{ flexGrow: 1, display: 'flex', marginLeft: '20px', gap: '4px' }}>
            {menuItems.map((menuItem) => (
                <SMenuItem
                    {...{
                        key: menuItem.id,
                        color: 'inherit',
                        variant: 'text',
                        to: menuItem.url,
                        component: NavLink,
                    }}
                >
                    {menuItem.label}
                </SMenuItem>
            ))}
        </Box>
    )
}
