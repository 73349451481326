import { styled } from 'core'
import { anthracite, separatorGrey, white } from 'utils/theme/colors'
import { RowFlex } from 'utils/theme/styled'

export const Root = styled('div')`
    background: ${white};
    border-radius: 5px;
    box-sizing: border-box;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    height: 100%;
`
export const SProfileInfoSection = styled('div')`
    width: 100%;
    margin: 25px 0 0;
    padding: 25px 0 0;
    border-top: 1px solid ${separatorGrey};
    display: flex;
    flex-direction: column;
`
export const ProfileTextContainer = styled('div')`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
`

export const SUserAvatar = styled('div')`
    display: flex;
    flex-direction: row;
    width: 183px;
    margin-left: 28px;
    align-items: start;
`

export const NameSpan = styled('span')`
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    //color: ${anthracite};
    text-align: center;
`
export const SEmployeeStatusText = styled('span')`
    font-size: 14px;
    line-height: 20px;
    display: flex;
    width: 100%;
    // color: ${anthracite};
    text-align: left;
`
export const SProfileInfoSectionText = styled('span')`
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    //color: ${anthracite};

    :not(:last-child) {
        margin-bottom: 8px;
    }
`

export const SEmployeeEmailText = styled('span')`
    font-size: 12px;
    color: ${anthracite};
    margin-top: 16px;
    line-height: 16px;
`
export const SProfileInfoSectionTitle = styled('span')`
    width: 100px;
    font-size: 12px;
    line-height: 16px;
    color: ${anthracite};
    margin-top: 4px;
    margin-bottom: 4px;
`

export const KeySKillsContainer = styled(RowFlex)`
    width: 232px;
    margin-bottom: 16px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`

export const SPositionRoleText = styled('div')`
    margin-top: 25px;
    margin-bottom: 4px;
    font-size: 14px;
    color: ${anthracite};
    font-weight: 700;
`

export const LoaderContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 255px;
`
