import { FormLabel, styled } from '@mui/material'
import { AutoSuggest } from 'core/index'
import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { AutoSuggestProps } from '../AutoSuggest/AutoSuggest'

export interface IdNameItem {
    id: string
    name: string
}

const SOptionItem = styled('li')`
    &.MuiAutocomplete-option {
        min-height: auto;
        align-items: flex-start;
    }
    .MuiCheckbox-root {
        padding: 2px;
        margin-right: 6px;
    }
`

interface MultiAutoSuggestProps<T> extends AutoSuggestProps {
    // due to 'Select All' requirements we need to update value manually so MultiAutoSuggest is a controllable component
    // and value property is required and should be managed out of the current component
    value: T[]
    options: T[]
    onChange: (newVal: T[]) => void
    getName?: (option: T) => string
    hasSelectAllOption?: boolean
}

export const MultiAutoSuggest = <T extends Record<string, any>>(props: MultiAutoSuggestProps<T>) => {
    const { options, value, hasSelectAllOption, onChange, ...restProps } = props
    const allOptions = React.useMemo(() => {
        const newOptions = options || []
        if (hasSelectAllOption) {
            return [{ id: 'select-all', name: 'Select all' }, ...newOptions]
        }
        return newOptions
    }, [options])

    return (
        <AutoSuggest
            value={value}
            options={allOptions}
            multiple={true}
            disableClearable={true}
            disableCloseOnSelect={true}
            disableListWrap={true}
            getName={(option: T) => option.name}
            onChange={(newValue) => onChange(newValue)}
            renderOption={(props, option, state) =>
                option.id === 'select-all' ? (
                    <SOptionItem
                        {...props}
                        key={option.id}
                        onClick={() => {
                            onChange(value.length === options.length ? [] : options)
                        }}
                    >
                        <Checkbox
                            name={`${restProps.id}_${option.id}`}
                            size="small"
                            checked={value.length === options.length}
                        />
                        <FormLabel sx={{ cursor: 'pointer' }}>Select All</FormLabel>
                    </SOptionItem>
                ) : (
                    <SOptionItem {...props} key={option.id}>
                        <Checkbox
                            name={`${restProps.id}_${option.id}`}
                            size="small"
                            checked={state.selected}
                        />
                        <FormLabel sx={{ cursor: 'pointer' }}>{option.name}</FormLabel>
                    </SOptionItem>
                )
            }
            renderTags={(value) => <>{value.map((v) => v.name).join(', ')}</>}
            {...restProps}
        />
    )
}
