import { createSelector } from '@reduxjs/toolkit'
import { Role, RoleTableData, RoleTableRowActionPermission } from '../../utils/types/admin'
import { RootState } from '../store'

export const selectDropdownOptions = (state: RootState) => state.admin.dropdownOptions
export const selectEmployees = (state: RootState) => state.admin.employees
export const selectOrder = (state: RootState) => state.admin.order

const selectActions = (state: RootState) => state.admin.actions
const selectRoles = (state: RootState) => state.admin.roles
const getActionPermissions = (actionId: number, roles: Role[]): RoleTableRowActionPermission[] => {
    const actionPermissions: RoleTableRowActionPermission[] = []

    for (const role of roles) {
        actionPermissions.push({ role_id: role.id, checked: role.actions.some((id) => id === actionId) })
    }

    return actionPermissions
}
export const roleDataSelector = createSelector(
    selectActions,
    selectRoles,
    (actions, roles): RoleTableData => {
        const data: RoleTableData = {}
        for (const { area, code, description, id } of actions) {
            const actions = data[area] ? data[area].actions : []

            actions.push({
                code,
                description,
                id,
                permissions: getActionPermissions(id, roles),
            })

            data[area] = {
                areaName: area,
                actions,
            }
        }
        return data
    }
)
