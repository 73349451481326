import { Box, Paper, PaperProps, Typography } from '@mui/material'
import { AutoSuggest, Button } from 'core'
import React, { useState, FC, memo, useCallback } from 'react'

import { FormattedUser } from 'utils/types/users'
import { Dialog } from '@mui/material'
import { CloseModalIcon } from 'images'
import Draggable from 'react-draggable'
import { SDialogHeader, SDialogContent } from '../ActionDialog/styled'
import { SDialogLabel } from '../ActionDialog/styled'
import { EmployeeAutoSuggestOption } from 'parts/EmployeeAndSkillAutoSuggestOption/EmployeeAndSkillAutoSuggestOption'
import { VirtualizedList } from 'components/VirtualizedList'

interface ReassignModalProps {
    onReassign: (newUserId: string) => void
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    crId: string
    users: FormattedUser[]
    ownerId?: string
}

const ReassignDialog: FC<ReassignModalProps> = memo(
    ({ onReassign, isOpen, setIsOpen, crId, ownerId, users }) => {
        const [newOwner, setNewOwner] = useState<FormattedUser | null>(null)
        const [searchInput, setSearchInput] = useState<string>('')

        const onChangeAutosuggest = (value: FormattedUser) => {
            setNewOwner(value)
            setSearchInput(value.name)
        }

        const onInputChange = (value: string) => {
            setSearchInput(value)
        }

        const onClear = () => {
            setNewOwner(null)
            setSearchInput('')
        }

        const close = () => {
            setIsOpen(false)
        }

        const onSubmit = () => {
            if (newOwner) {
                onReassign(newOwner.employee_id)
                onClear()
                close()
            }
        }

        const nodeRef = React.useRef(null)

        const PaperComponent = useCallback(
            (props: PaperProps) => {
                return (
                    <Draggable
                        handle="#draggable-dialog-title"
                        cancel={'[class*="MuiDialogContent-root"]'}
                        nodeRef={nodeRef}
                    >
                        <Paper {...props} ref={nodeRef} />
                    </Draggable>
                )
            },
            [nodeRef]
        )

        return (
            <Dialog
                ref={nodeRef}
                open={!!isOpen}
                onClose={close}
                hideBackdrop
                fullWidth={true}
                maxWidth={'xs'}
                aria-labelledby="draggable-dialog-title"
                PaperComponent={PaperComponent}
            >
                <SDialogHeader id="draggable-dialog-title">
                    <Typography variant="h4">Reassign</Typography>
                    <Box component="div" sx={{ cursor: 'pointer' }}>
                        <CloseModalIcon onClick={close} />
                    </Box>
                </SDialogHeader>
                <SDialogContent>
                    <SDialogLabel>{`'Change Request #${crId}' to`}</SDialogLabel>
                    <AutoSuggest
                        id="reassign-to-owner"
                        value={newOwner}
                        inputValue={searchInput}
                        onClear={onClear}
                        options={users}
                        ListboxComponent={VirtualizedList()}
                        renderOption={EmployeeAutoSuggestOption}
                        onChange={onChangeAutosuggest}
                        onInputChange={onInputChange}
                    />
                    <Box component="div">
                        <Button
                            variant="contained"
                            onClick={onSubmit}
                            disabled={newOwner?.employee_id === ownerId || !newOwner}
                        >
                            Reassign
                        </Button>
                    </Box>
                </SDialogContent>
            </Dialog>
        )
    }
)

export default ReassignDialog
