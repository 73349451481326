import { css, styled } from 'core'
import { moonBlue } from 'utils/theme/colors'
import { NodeData } from '../../types'
import { NodesColors } from '../ReactFlowGraph/styled'
import { getNodeStyles } from './utils'

interface CustomNodeProps {
    data: NodeData
    nodeMenuShown: boolean
    isHighlighted?: boolean
    hoverMenuShown: boolean
}

export const ContextMenuWrapper = styled('div')`
    width: 100%;
`

const shortCutCss = css`
    color: ${NodesColors.SHORTCUT_TEXT_LABEL};
    background-color: ${NodesColors.WHITE};
    border: 1px dashed ${NodesColors.SHORTCUT_BORDER};
    box-sizing: border-box;
    border-radius: 2px;
`

export const CustomNodeStyled = styled('div', {
    shouldForwardProp: (prop: string) =>
        prop !== 'nodeMenuShown' && prop !== 'isHighlighted' && prop !== 'hoverMenuShown',
})<CustomNodeProps>`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 204px;
    height: 24px;
    border-radius: 2px;
    border: none;
    padding: 4px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    color: ${NodesColors.CATEGORY_TEXT_LABEL};
    gap: 5px;
    overflow: hidden;

    .arrow-button-wrapper {
        display: ${(props) => (props.nodeMenuShown ? 'block' : 'none')};
    }
    .NumberLabel {
        display: ${(props) => (props.nodeMenuShown ? 'none' : 'block')};
    }
    &:hover {
        .arrow-button-wrapper {
            display: ${(props) => (props.hoverMenuShown ? 'block' : 'none')};
        }
        .NumberLabel {
            display: none;
        }
    }

    ${(props) => getNodeStyles(props.data)}

    ${(props) => props.data.isShortcut && shortCutCss};

    background-color: ${(props) => props.isHighlighted && moonBlue};
`

export const LabelStyled = styled('div')`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const CountStyled = styled('div')`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #9dacbc;
`
