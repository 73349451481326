import { css, styled } from 'core'
import { lightGrey } from './colors'

interface MarginProp {
    margin?: string
}

export const SWrapper = styled('div')<MarginProp>`
    display: flex;
    position: relative;
    flex-grow: 1;
    margin: ${({ margin }) => margin ?? margin};
`

export const NonMarginFlex = styled('div')`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

export const RowFlex = styled('div')`
    display: flex;
`

export const scrollStyles = css`
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0;
        border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: ${lightGrey};
    }
    ::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-box-shadow: none !important;
        margin-bottom: 5px;
        margin-top: 5px;
    }
`
