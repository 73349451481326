import { RemoveSelectionIcon } from 'images'
import React, { FC, memo } from 'react'
import { Circle, Container } from './styled'

interface Prop {
    onClick: (count: number) => void
    enqueueError: (message: string) => void
    defSelected?: number
    disabled?: boolean
    highlight?: boolean
}

export const PointerSelector: FC<Prop> = memo(
    ({ onClick, defSelected = 0, disabled, highlight, enqueueError }) => {
        const onSelection = (select: number) => {
            if (disabled) {
                enqueueError('Select skill first')
                return
            }

            if (select === defSelected) {
                onClick(0)
                return
            }

            onClick(select)
        }

        return (
            <Container>
                <Circle
                    data-automation-id="button-pointerSelector-five"
                    isActive={defSelected > 4}
                    isCurrentValue={defSelected === 5}
                    onClick={() => onSelection(5)}
                    highlight={highlight}
                >
                    <RemoveSelectionIcon />
                </Circle>
                <Circle
                    data-automation-id="button-pointerSelector-four"
                    isActive={defSelected > 3}
                    isCurrentValue={defSelected === 4}
                    onClick={() => onSelection(4)}
                    highlight={highlight}
                >
                    <RemoveSelectionIcon />
                </Circle>
                <Circle
                    data-automation-id="button-pointerSelector-three"
                    isActive={defSelected > 2}
                    isCurrentValue={defSelected === 3}
                    onClick={() => onSelection(3)}
                    highlight={highlight}
                >
                    <RemoveSelectionIcon />
                </Circle>
                <Circle
                    data-automation-id="button-pointerSelector-two"
                    isActive={defSelected > 1}
                    isCurrentValue={defSelected === 2}
                    onClick={() => onSelection(2)}
                    highlight={highlight}
                >
                    <RemoveSelectionIcon />
                </Circle>
                <Circle
                    data-automation-id="button-pointerSelector-one"
                    isActive={defSelected > 0}
                    isCurrentValue={defSelected === 1}
                    highlight={highlight}
                    onClick={() => onSelection(1)}
                >
                    <RemoveSelectionIcon />
                </Circle>
            </Container>
        )
    }
)
