import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { deleteUserSkill, updateUserActions } from '../../shared/actions'
import { UserProfile } from '../../../utils/types/auth'
import { Skill } from '../../../utils/types/skills'

export interface AuthState {
    user?: UserProfile
    isAuthorized: boolean | null
    authInProgress: boolean
    shouldShowDeleteSkillDialog: boolean
    accessToken: string | null
    isRefreshingToken: boolean
}

const initialState: AuthState = {
    isAuthorized: null,
    authInProgress: false,
    user: undefined,
    shouldShowDeleteSkillDialog: true,
    accessToken: null,
    isRefreshingToken: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        isAuthorized: (state, action: PayloadAction<boolean>) => {
            state.isAuthorized = action.payload
        },
        setAuthInProgress: (state, action: PayloadAction<boolean>) => {
            state.authInProgress = action.payload
        },
        setUser: (state, action: PayloadAction<UserProfile>) => {
            state.user = action.payload
        },
        setUserSkills: (state, action: PayloadAction<Skill[]>) => {
            if (state.user) state.user.skills = action.payload
        },
        unsetUser: (state) => {
            state.isAuthorized = false
            state.authInProgress = initialState.authInProgress
            state.user = initialState.user
            state.shouldShowDeleteSkillDialog = initialState.shouldShowDeleteSkillDialog
            state.accessToken = initialState.accessToken
        },
        shouldShowDeleteSkillDialog: (state) => {
            state.shouldShowDeleteSkillDialog = false
        },
        resetShouldShowDeleteDialog: (state) => {
            state.shouldShowDeleteSkillDialog = initialState.shouldShowDeleteSkillDialog
        },
        refreshUserSkills: (state, action: PayloadAction<Skill[]>) => {
            if (state.user) state.user.skills = action.payload
        },
        addSkill: (state, action: PayloadAction<Skill>) => {
            if (state.user) {
                state.user.skills = state.user.skills.concat(action.payload)
            }
        },
        updateSkill: (state, action: PayloadAction<Skill>) => {
            if (state.user) {
                state.user = {
                    ...state.user,
                    skills: state.user.skills.map((skill) =>
                        skill.node_id === action.payload.node_id ? action.payload : skill
                    ),
                }
            }
        },
        removeSkill: (state, action: PayloadAction<string>): void => {
            if (state.user) {
                state.user.skills = state.user.skills.filter((skill) => skill.node_id !== action.payload)
            }
        },
        updateUserRole: (state, action: PayloadAction<string>) => {
            if (state.user) state.user.role = action.payload
        },
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateUserActions, (state, action: PayloadAction<string[]>) => {
                if (state.user) state.user.actions = action.payload
            })
            .addCase(deleteUserSkill, (state, action) => {
                const skillId = action.payload
                if (state.user) {
                    state.user = {
                        ...state.user,
                        skills: state.user.skills.filter((skill) => skill.node_id !== skillId),
                    }
                }
            })
    },
})

export const {
    isAuthorized,
    setAuthInProgress,
    setUser,
    unsetUser,
    shouldShowDeleteSkillDialog,
    resetShouldShowDeleteDialog,
    refreshUserSkills,
    addSkill,
    updateUserRole,
    setAccessToken,
    removeSkill,
    updateSkill,
} = authSlice.actions

export default authSlice.reducer
