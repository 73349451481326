import { PointerSelector } from 'components/PointerSelector'
import { Tooltip } from 'components/Tooltip'
import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { stealSkill } from 'store/slices/auth/requests'
import { useHoverIntent } from 'utils/hooks/useHoverIntent'
import { NodeData, TaxonomyContextValue } from '../../types'
import {
    CountStyled,
    CustomNodeStyled,
    DontWantToLearnIconStyled,
    IconsBlock,
    LabelStyled,
    LevelBlock,
    LoaderWrapper,
    MainBlock,
    NodeIcons,
    NodeInfo,
    WantToLearnIconStyled,
} from './CustomWizardNode.styled'
import { LevelIcon } from 'components/IconComponents/LevelIcon'
import { IconSize } from 'utils/types/styling'
import { LEVEL_ABBREVIATIONS, SkillStatuses } from 'utils/enums/different'
import { StatusIcon } from 'components/IconComponents/StatusIcon'
import { removePersonalSkill } from 'store/slices/profile/requests'
import { MoonLoader } from 'react-spinners'
import { Box } from '@mui/material'
import { ArrowDownGrey, ArrowUpGrey } from 'images'
import { Skill } from 'utils/types/skills'
import { AutoSuggestState } from 'store/slices/autosuggest'

interface CustomNodeProp {
    data: NodeData
    taxonomyContext: TaxonomyContextValue
    dispatch: any
    skills: Skill[]
    userId: string
    enqueueSuccess: (message: string) => void
    enqueueError: (message: string) => void
    autoSuggestState: AutoSuggestState
    onClick?: (event?: any) => void
}

export const CustomWizardNode: FC<CustomNodeProp> = memo(
    ({
        data,
        taxonomyContext,
        dispatch,
        skills,
        userId,
        enqueueSuccess,
        enqueueError,
        autoSuggestState,
        onClick,
    }) => {
        const { updateCountUserSkills, countSkillsInNode, setRenderCount, renderCount, menuAnchor } =
            taxonomyContext
        const [level, setLevel] = useState<number>(0)
        const [loading, setLoading] = useState<boolean>(false)
        const [status, setStatus] = useState<SkillStatuses | null>(null)
        const autoSuggestValue = autoSuggestState.autoSuggestValue
        const nodeSkill = useMemo(() => {
            if (data.isShortcut) {
                return skills.find(
                    (skill: { node_id: string | undefined }) => skill.node_id === data.nodeIdPriorToShortcut
                )
            } else {
                return skills.find((skill: { node_id: string }) => skill.node_id === data.id)
            }
        }, [skills, data])

        const isNodeSearchedForInTaxonomy = autoSuggestState.autoSuggestValue === data.id

        const isHighlightedNodeIfShortcutClicked = autoSuggestState.nodeIsHighlighted === data.id

        const defineIsHighlighted = () => {
            return !!isHighlightedNodeIfShortcutClicked || !!isNodeSearchedForInTaxonomy
        }

        const [isHovering, ref] = useHoverIntent({
            timeout: 100,
            sensitivity: 10,
            interval: 200,
        })

        const addSkill = (newLevel: number, newStatus: SkillStatuses | null) => {
            if (!loading) {
                setLoading(true)
                if (!newLevel && !newStatus) {
                    dispatch(removePersonalSkill(userId, nodeSkill?.node_id!))

                    setLevel(0)
                    setStatus(null)
                    setLoading(false)
                    return
                }
                const skill = {
                    ...nodeSkill,
                    name: data.name || '',
                    want_to_learn: newStatus === SkillStatuses.WTL,
                    seniority_level: newLevel || null,
                    recent: newStatus === SkillStatuses.Recent,
                    node_id: data.nodeIdPriorToShortcut ? data.nodeIdPriorToShortcut : data.id,
                    dislike: newStatus === SkillStatuses.Dislike,
                    parent_id: data.parentId || null,
                    created_at: nodeSkill?.created_at,
                    updated_at: nodeSkill?.updated_at,
                    is_archived: nodeSkill?.is_archived,
                    is_category: nodeSkill?.is_category,
                }

                dispatch(
                    stealSkill(userId, skill, () => {
                        enqueueSuccess('Successfully added skill to profile')
                        setLoading(false)
                        setRenderCount((prev) => prev + 1)
                    })
                )
            }
        }

        const changeLevel = (newLevel: number) => {
            addSkill(newLevel, status)
            setLevel(newLevel)
        }

        const changeStatus = (newStatus: SkillStatuses) => {
            if (status === newStatus) {
                addSkill(level, null)
                setStatus(null)
            } else {
                addSkill(level, newStatus)
                setStatus(newStatus)
            }
        }

        useEffect(() => {
            defineIsHighlighted()
        }, [autoSuggestValue])

        useEffect(() => {
            if (nodeSkill) {
                setLevel(nodeSkill?.seniority_level!)
            }
        }, [nodeSkill])

        useEffect(() => {
            if (nodeSkill) {
                if (nodeSkill.want_to_learn) setStatus(SkillStatuses.WTL)
                if (nodeSkill.dislike) setStatus(SkillStatuses.Dislike)
            }
        }, [nodeSkill])

        useEffect(() => {
            setLevel(nodeSkill?.seniority_level!)
            if (nodeSkill?.want_to_learn) setStatus(SkillStatuses.WTL)
            else if (nodeSkill?.dislike) setStatus(SkillStatuses.Dislike)
            else setStatus(null)
        }, [data, skills])

        useEffect(() => {
            updateCountUserSkills(data, skills)
        }, [renderCount])

        const dataType = data.isShortcut
            ? 'shortcut'
            : data.isCategory
            ? data.parentId == null
                ? 'rootCategory'
                : 'category'
            : 'skill'

        return (
            <CustomNodeStyled
                className={`CustomNode-root ${isHovering && 'hover'}`}
                ref={ref as React.RefObject<HTMLDivElement>}
                data={data}
                data-automation-id={`${data.label}-${dataType}`}
                isHovering={isHovering}
                isHighlighted={defineIsHighlighted()}
                onClick={onClick}
            >
                {data.isCategory ? (
                    <>
                        <LabelStyled title={data.label}>{data.label}</LabelStyled>
                        {data.isShortcut && isHovering ? (
                            <Box component="div" className="arrow-button-wrapper">
                                {menuAnchor ? <ArrowUpGrey /> : <ArrowDownGrey />}
                            </Box>
                        ) : (
                            <CountStyled className="NumberLabel">
                                {countSkillsInNode[data.id] || null}
                            </CountStyled>
                        )}
                    </>
                ) : (
                    <MainBlock loading={loading}>
                        <NodeInfo>
                            <LabelStyled title={data.label}>{data.label}</LabelStyled>
                            {nodeSkill && !isHovering && (
                                <NodeIcons>
                                    <StatusIcon status={status} data-automation-id={`status-${status}`} />
                                    {level && (
                                        <LevelIcon
                                            level={LEVEL_ABBREVIATIONS[level + 1]}
                                            size={IconSize.Medium}
                                            data-automation-id={`level-${LEVEL_ABBREVIATIONS[level + 1]}`}
                                        />
                                    )}
                                </NodeIcons>
                            )}
                        </NodeInfo>
                        <LevelBlock isHovering={isHovering}>
                            <PointerSelector
                                data-automation-id="select-level"
                                onClick={changeLevel}
                                defSelected={level ?? 0}
                                enqueueError={enqueueError}
                            />
                            <IconsBlock>
                                <Tooltip text="Want to learn" placement="bottom" isVisible>
                                    <WantToLearnIconStyled
                                        onClick={() => changeStatus(SkillStatuses.WTL)}
                                        isActive={status === SkillStatuses.WTL}
                                        isDisabled={false}
                                    />
                                </Tooltip>
                                <Tooltip text="Don't want to learn" placement="bottom" isVisible>
                                    <DontWantToLearnIconStyled
                                        onClick={() => changeStatus(SkillStatuses.Dislike)}
                                        isActive={status === SkillStatuses.Dislike}
                                        isDisabled={false}
                                    />
                                </Tooltip>
                            </IconsBlock>
                        </LevelBlock>
                        {loading && (
                            <LoaderWrapper>
                                <MoonLoader size={30} />
                            </LoaderWrapper>
                        )}
                    </MainBlock>
                )}
            </CustomNodeStyled>
        )
    }
)
