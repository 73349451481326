import { useReactFlow } from 'react-flow-renderer'
import { useDispatch } from 'react-redux'
import { setOriginNodeHighlighted } from 'store/slices/autosuggest'
import { useTaxonomyContext } from 'taxonomy/context/TaxonomyContext'
import { сhildToParentExpand } from 'taxonomy/utils/nodes'

export const useFindOriginalNode = (value: string | undefined) => {
    const { focusNode, flowNodes } = useTaxonomyContext()
    const findNode = flowNodes.find((item) => item.data.id === value)
    const reactFlow = useReactFlow()
    const dispatch = useDispatch()

    const findOriginalNode = () => {
        if (value) {
            if (findNode) {
                сhildToParentExpand(findNode, false)
                dispatch(setOriginNodeHighlighted(value))
                setTimeout(() => {
                    dispatch(setOriginNodeHighlighted(''))
                }, 4000)
                setTimeout(() => {
                    focusNode(findNode, reactFlow)
                }, 0)
            }
        }
    }

    return findOriginalNode
}
