import { NoContentsFallback } from 'components/NoContents'
import React, { FC, Suspense } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { getChangeRequests } from 'taxonomy/api/requests'
import { listActiveCRsFirst } from 'taxonomy/utils/changeRequests'
import { UserProfile } from 'utils/types/auth'
import { SContainerWrapper, SWrapper } from './styled'
import { Box } from '@mui/material'
import { PageLoader } from 'components/MoonLoader'
import { StyledAccordionSummary } from '../ChangeRequestWindow/styled'

export const ChangeRequestsList: FC = () => {
    const user = useSelector<RootState, UserProfile | undefined>((state) => state.auth.user)

    const { data: fetchedChangeRequests, isLoading } = useQuery('fetchCRs', () => getChangeRequests(), {
        refetchOnWindowFocus: false,
    })

    const CRAccordionLazy = React.lazy(() => import('../ChangeRequestWindow/CRAccordion'))

    if (isLoading) return <PageLoader />

    return (
        <SWrapper>
            <SContainerWrapper>
                {fetchedChangeRequests?.length ? (
                    fetchedChangeRequests
                        .filter((cr) => cr.owner.email === user?.email || user?.role === 'adm')
                        .sort(listActiveCRsFirst)
                        .map((cr) => {
                            return (
                                <Box component="div" key={cr.id}>
                                    <Suspense fallback={<StyledAccordionSummary />}>
                                        <CRAccordionLazy changeRequest={cr} />
                                    </Suspense>
                                </Box>
                            )
                        })
                ) : (
                    <NoContentsFallback />
                )}
            </SContainerWrapper>
        </SWrapper>
    )
}
