import { PageLoader } from 'components/MoonLoader'
import React, { FC, useMemo } from 'react'
import { Background } from 'react-flow-renderer'
import { backGroundColor } from '../../../utils/theme/colors'
import { useTaxonomyContext } from '../../context/TaxonomyContext'
import { useNodeClick } from '../../hooks/useNodeClick'
import { FlowNode } from '../../types'
import ActionDialogSwitch from '../ActionDialog/ActionDialogSwitch'
import { CustomNode } from '../CustomNode/CustomNode'
import NodeActionMenu from '../NodeActionMenu/NodeActionMenu'
import { ReactFlowStyled } from './styled'

export const ReactFlowGraph: FC = () => {
    const { action, nodes, edges, toggleNodeActionMenu, isLoading } = useTaxonomyContext()

    const nodeTypes = useMemo(
        () => ({
            default: CustomNode,
            input: CustomNode,
            output: CustomNode,
            shortcut: CustomNode,
        }),
        []
    )

    const handleNodeActionMenu = (e: React.MouseEvent<Element, MouseEvent>, node: FlowNode) => {
        e.preventDefault()
        toggleNodeActionMenu(e.currentTarget, node)
    }

    return (
        <>
            <ReactFlowStyled
                onNodeContextMenu={handleNodeActionMenu}
                nodes={nodes}
                edges={edges}
                onNodeClick={useNodeClick()}
                nodeTypes={nodeTypes}
            >
                <Background color={backGroundColor} />
            </ReactFlowStyled>

            {action && <ActionDialogSwitch />}
            {isLoading && <PageLoader />}

            <NodeActionMenu />
        </>
    )
}
