import React, { FC, useEffect } from 'react'
import { useTaxonomyContext } from '../../context/TaxonomyContext'
import { SHeader, SActionBtn } from './styled'
import { useReactFlow } from 'react-flow-renderer'
import { useSelector } from 'react-redux'
import { selectAutoSuggestValue } from 'store/slices/autosuggest/selectors'
import { setAutoSuggestValue } from 'store/slices/autosuggest'
import { useFindOriginalNode } from '../../../utils/hooks/useFindOriginalNode'
import { AutoSuggest } from 'core'
import { LoopIcon } from 'images'
import { skillSelectOptionRenderFn } from 'parts/EmployeeAndSkillAutoSuggestOption/EmployeeAndSkillAutoSuggestOption'
import { useDispatch } from 'react-redux'
import { selectTree } from 'store/slices/tree/selectors'
import { useNodeWithPath } from 'utils/hooks/useSkillPathTree'
import { Box } from '@mui/material'
import { VirtualizedList } from 'components/VirtualizedList'

export const TaxonomyHeader: FC = () => {
    const {
        // handleExpandAll,
        handleCollapseAll,
        handleVisibleArchived,
        isShowArchived,
        handleRootCreation,
        updateNodesView,
    } = useTaxonomyContext()
    const tree = useSelector(selectTree)
    const allNodes = useNodeWithPath(tree)
    const reactFlow = useReactFlow()
    const dispatch = useDispatch()
    const autoSuggestValue = useSelector(selectAutoSuggestValue).autoSuggestValue
    const findOriginalNode = useFindOriginalNode(autoSuggestValue)

    const clearAutosuggestValue = () => {
        dispatch(setAutoSuggestValue(''))
    }

    useEffect(() => {
        findOriginalNode()
        updateNodesView()
    }, [autoSuggestValue])

    return (
        <SHeader>
            <Box component="div" sx={{ display: 'flex' }}>
                {/* <SActionBtn
                    data-automation-id="expand-all-button"
                    onClick={() => handleExpandAll(reactFlow)}
                    sx={{
                        borderRight: `1px solid ${borderGrey}`,
                    }}
                >
                    Expand all
                </SActionBtn> */}
                <SActionBtn
                    data-automation-id="collapse-all-button"
                    onClick={() => handleCollapseAll(reactFlow)}
                >
                    Collapse all
                </SActionBtn>
                <SActionBtn data-automation-id="add-root-item-button" onClick={handleRootCreation}>
                    Add root item
                </SActionBtn>
                <SActionBtn data-automation-id="hide-show-archived-button" onClick={handleVisibleArchived}>
                    {isShowArchived ? 'Hide archived' : 'Show archived'}
                </SActionBtn>
            </Box>
            <Box component="div" sx={{ width: '320px' }}>
                <AutoSuggest
                    id="taxonomy-header-skill-search"
                    placeholder="Search"
                    options={allNodes}
                    ListboxComponent={VirtualizedList()}
                    renderOption={skillSelectOptionRenderFn}
                    InputProps={{ startAdornment: <LoopIcon /> }}
                    size="small"
                    onChange={(skill) => dispatch(setAutoSuggestValue(skill.node_id))}
                    onClear={clearAutosuggestValue}
                />
            </Box>
        </SHeader>
    )
}
