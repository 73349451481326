import { AppThunk } from '../../store'
import jwt_decode from 'jwt-decode'
import { JWTObject } from '../../../utils/types/common'
import { client } from '../../../axiosClient'
import { Tokens } from '../../../utils/types/auth'
import { ComposedAjaxError } from '../../../axiosClient/ErrorComposer'
import { Skill } from '../../../utils/types/skills'
import { clearProfile } from '../../shared/actions'
import {
    addSkill,
    isAuthorized,
    refreshUserSkills,
    removeSkill,
    resetShouldShowDeleteDialog,
    setAccessToken,
    setAuthInProgress,
    setUser,
    shouldShowDeleteSkillDialog,
    unsetUser,
} from './index'
import { navigate } from '../../../app/store'
import { skillToSkillInfo } from '../../../utils/functions/converters'
import { fetchUser } from '../../shared/requests'
import { notEmptySkill } from '../../../utils/functions/common'
import axios from 'axios'
import { BASE_URL } from 'axiosClient/instance'

export const checkUserAndToken = (): AppThunk => async (dispatch, getState) => {
    dispatch(setAuthInProgress(true))

    const token = localStorage.getItem('access_token')

    if (!token) {
        dispatch(isAuthorized(false))
        dispatch(setAuthInProgress(false))
        return
    }

    const userId = jwt_decode<JWTObject>(token).sub
    try {
        const user = await fetchUser(userId)
        dispatch(setUser(user))
        dispatch(isAuthorized(true))
    } catch (ex: any) {
        if (getState().auth.isAuthorized !== null) {
            ComposedAjaxError.handleError('Error while logging in', ex)
        }
        dispatch(isAuthorized(false))
    }

    dispatch(setAuthInProgress(false))
}

export const checkUser = (): AppThunk => async (dispatch, getState) => {
    const userId = getState().auth.user?.user_id

    if (userId) {
        try {
            const user = await fetchUser(userId)
            dispatch(setUser(user))
        } catch (ex: any) {
            ComposedAjaxError.handleError('Error while changing role', ex)
        }
    }
}

export const updateUserInfo = (): AppThunk => async (dispatch, getState) => {
    const userId = getState().auth.user?.user_id

    if (userId) {
        try {
            const user = await fetchUser(userId)
            dispatch(setUser(user))
        } catch (ex: any) {
            ComposedAjaxError.handleError('Error while updating user info', ex)
        }
    }
}

export const getTokens =
    (code: string | null): AppThunk =>
    async (dispatch) => {
        if (code === null) return
        try {
            const resp = await client.post<Tokens>(
                '/api/login',
                { code },
                {
                    'X-Auth-Token': 'Bearer ' + code,
                }
            )
            const accessToken = resp.access_token
            if (accessToken) {
                localStorage.setItem('access_token', accessToken)
                dispatch(setAccessToken(accessToken))
                localStorage.setItem('isOnStartup', JSON.stringify(false))
            }
            const refreshToken = resp.refresh_token
            refreshToken && localStorage.setItem('refresh_token', refreshToken)
        } catch (ex: any) {
            ComposedAjaxError.handleError(
                'Authorization failed. User email not available or not verified by Microsoft',
                ex
            )
            navigate('/')
        }
    }
export const uncheckUser =
    (shouldSendLogoutReq?: boolean): AppThunk =>
    async (dispatch) => {
        const isOnStartup: string | null = localStorage.getItem('isOnStartup')
        if (JSON.parse(isOnStartup || 'true')) return
        try {
            if (shouldSendLogoutReq) await client.get('/api/logout')
            localStorage.clear()
            dispatch(unsetUser())
            navigate('/')
            localStorage.setItem('isOnStartup', 'true')
            dispatch(clearProfile())
        } catch (ex: any) {
            ComposedAjaxError.handleError('Error logging out', ex)
        }
    }
export const setDialogToHidden = (): AppThunk => (dispatch) => {
    dispatch(shouldShowDeleteSkillDialog())
}
export const setDialogToVisible = (): AppThunk => (dispatch) => {
    dispatch(resetShouldShowDeleteDialog())
}
export const stealSkill =
    (userId: string, preSkill: Skill, successCb?: () => void): AppThunk =>
    async (dispatch) => {
        const { node_id } = preSkill
        const skill = skillToSkillInfo(preSkill)
        const body = JSON.stringify([skill])
        try {
            await client.put(`/api/users/${userId}/skills`, body)
            dispatch(removeSkill(node_id!))
            dispatch(addSkill({ ...preSkill }))
            successCb && successCb()
        } catch (ex: any) {
            ComposedAjaxError.handleError('Error adding skill to profile', ex)
        }
    }

export const saveSkills =
    (userId: string, preSkills: Skill[], onFinal: () => void, onSuccess: () => void): AppThunk =>
    async (dispatch) => {
        const skills = preSkills.filter(notEmptySkill).map((skill) => skillToSkillInfo(skill))
        const body = JSON.stringify(skills)
        try {
            await client.post(`/api/users/${userId}/skills`, body)
            dispatch(refreshUserSkills(preSkills.filter(notEmptySkill)))
        } catch (ex: any) {
            ComposedAjaxError.handleError('Error adding skills to profile', ex)
        } finally {
            onFinal()
        }
        onSuccess()
    }

export const loginWithBasicAuth =
    (email: string): AppThunk =>
    async (dispatch) => {
        const encoded = window.btoa(`${email}:`)
        try {
            const axiosResponse = await axios.get<Tokens>('/api/get-jwt', {
                baseURL: BASE_URL,
                headers: {
                    Authorization: `Basic ${encoded}`,
                },
            })
            const { access_token: accessToken, refresh_token: refreshToken } = axiosResponse.data
            if (accessToken) {
                localStorage.setItem('access_token', accessToken)
                localStorage.setItem('refresh_token', refreshToken)
                await dispatch(setAccessToken(accessToken))
                localStorage.setItem('isOnStartup', JSON.stringify(false))
            }
        } catch (ex: any) {
            ComposedAjaxError.handleError('Authorization failed. User email not verified', ex)
        }
    }
