import { anthracite, borderGrey, ButtonColors, cyanGray, lightPurple, tannGrey, white } from './colors'

export interface AppThemeVars {
    fontFamily: string
    fontPrimaryColor: string

    borderRadius: string
    borderColor: string

    buttonPrimaryColor: string
    buttonHoverColor: string
    buttonDisabledColor: string

    inputColor: string
    inputLabelColor: string
    inputBgColor: string
    inputActiveColor: string
    inputHoverColor: string

    checkboxColor: string
    checkboxCheckedColor: string

    accordionSummaryBgColor: string
}

export const vars: AppThemeVars = {
    fontFamily: 'Roboto, sans-serif',
    fontPrimaryColor: anthracite,

    borderRadius: '8px',
    borderColor: cyanGray,

    buttonPrimaryColor: ButtonColors.BLUE_REGULAR,
    buttonHoverColor: ButtonColors.BLUE_HOVER,
    buttonDisabledColor: ButtonColors.BLUE_DISABLED,

    inputColor: borderGrey,
    inputLabelColor: tannGrey,
    inputBgColor: white,
    inputActiveColor: ButtonColors.BLUE_REGULAR,
    inputHoverColor: tannGrey,

    checkboxColor: anthracite,
    checkboxCheckedColor: ButtonColors.BLUE_REGULAR,

    accordionSummaryBgColor: lightPurple,
}
