import { createTheme } from '@mui/material/styles'
import { BackgroundColor, ButtonColors } from './utils/theme/colors'
import { vars } from './utils/theme/vars'

export const AppTheme = createTheme({
    typography: {
        fontFamily: vars.fontFamily,
        h4: {
            fontSize: 18,
            fontWeight: 700,
        },
        h6: {
            fontSize: 14,
            fontWeight: 700,
        },
    },

    palette: {
        text: {
            primary: vars.fontPrimaryColor,
        },
    },

    shape: {
        borderRadius: 8,
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '32px',
                    boxShadow: 'none',
                    textShadow: 'none',
                    whiteSpace: 'nowrap',
                    minWidth: 'max-content',

                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '.Mui-disabled': {
                        cursor: 'not-allowed',
                    },
                },

                sizeLarge: {
                    height: '40px',
                    fontSize: '20px',
                },
                sizeMedium: {
                    height: '32px',
                    fontSize: '16px',
                    padding: '0 24px',
                },
                sizeSmall: {
                    height: '24px',
                    fontSize: '12px',
                    padding: '0 16px',
                },

                contained: {
                    color: ButtonColors.WHITE,
                    fill: ButtonColors.WHITE,
                    backgroundColor: vars.buttonPrimaryColor,
                    '&:hover': {
                        backgroundColor: vars.buttonHoverColor,
                    },
                    '&.Mui-disabled': {
                        color: ButtonColors.WHITE,
                        backgroundColor: vars.buttonDisabledColor,
                        cursor: 'not-allowed',
                    },
                },
                outlined: {
                    backgroundColor: 'transparent',
                    border: `2px solid ${vars.buttonPrimaryColor}`,
                    color: vars.buttonPrimaryColor,
                    fill: vars.buttonPrimaryColor,

                    '&:hover': {
                        backgroundColor: 'transparent',
                        border: `2px solid ${vars.buttonHoverColor}`,
                        color: vars.buttonHoverColor,
                        fill: vars.buttonHoverColor,
                    },

                    '&.Mui-disabled': {
                        border: `2px solid ${vars.buttonDisabledColor}`,
                        color: vars.buttonDisabledColor,
                        fill: vars.buttonDisabledColor,
                    },
                },
                text: {
                    color: ButtonColors.TEXT,
                    fontWeight: 700,

                    '&:hover': {
                        backgroundColor: BackgroundColor.GREY_SHADOW,
                    },

                    '&:active': {
                        backgroundColor: BackgroundColor.BORDER_GREY,
                    },
                },
            },
        },

        // forms
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                },
                inputAdornedStart: {
                    paddingLeft: '8px !important',
                    paddingRight: '8px !important',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: vars.inputBgColor,
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: vars.inputHoverColor,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: vars.inputActiveColor,
                    },
                },
                notchedOutline: {
                    fontSize: '14px',
                    borderColor: vars.inputColor,
                    borderRadius: vars.borderRadius,
                },
                input: {
                    paddingTop: '10.5px !important',
                    paddingBottom: '10.5px !important',
                },
                inputSizeSmall: {
                    paddingTop: '4.5px !important',
                    paddingBottom: '4.5px !important',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    marginTop: '12px !important',
                },
                sizeSmall: {
                    marginTop: '3px !important',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    color: vars.inputLabelColor,

                    // label position: focused
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(14px, -7px) scale(0.75)',
                    },
                    // label position: medium size
                    '&:not(.Mui-focused):not(.MuiInputLabel-shrink)': {
                        '&.MuiInputLabel-outlined': {
                            transform: 'translate(14px, 11px) scale(1)',
                        },
                        '&.MuiInputLabel-standard': {
                            transform: 'translate(12px, 17px) scale(1)',
                            zIndex: 1,
                        },
                    },
                    // label position: small size
                    '&.MuiInputLabel-sizeSmall:not(.Mui-focused):not(.MuiInputLabel-shrink)': {
                        transform: 'translate(14px, 5px) scale(1)',
                    },

                    '&.Mui-focused': {
                        color: vars.inputActiveColor,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                iconOutlined: {
                    // arrow down icon position
                    height: '24px',
                    top: 'calc(50% - 0.75em)',
                },
                iconStandard: {
                    height: '24px',
                    top: 'calc(50% - 0.75em)',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                input: {
                    paddingTop: '1.5px !important',
                    paddingBottom: '1.5px !important',
                },
                inputRoot: {
                    '&.MuiInputBase-sizeSmall': {
                        paddingTop: '3px',
                        paddingBottom: '3px',
                    },
                },
                endAdornment: {
                    // arrow down icon position
                    '&, .MuiButtonBase-root, .MuiButtonBase-root .MuiSelect-icon': {
                        width: '24px',
                        height: '24px',
                    },
                },
                option: {
                    paddingLeft: '8px !important',
                    paddingRight: '8px !important',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: vars.checkboxColor,
                    '&.Mui-checked': {
                        color: vars.checkboxCheckedColor,
                    },
                },
            },
        },

        // accordion
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: 0,
                    border: `1px solid ${vars.borderColor}`,
                    borderBottomWidth: 0,

                    '&:first-of-type': {
                        borderTopLeftRadius: vars.borderRadius,
                        borderTopRightRadius: vars.borderRadius,
                    },
                    '&:last-of-type': {
                        borderBottomLeftRadius: vars.borderRadius,
                        borderBottomRightRadius: vars.borderRadius,
                        borderBottomWidth: 1,
                    },
                    '&.Mui-expanded': {
                        margin: 0,
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    fontFamily: vars.fontFamily,
                    height: '56px',
                    backgroundColor: vars.accordionSummaryBgColor,
                    fontWeight: 'bold',
                    fontSize: '18px',
                    padding: '20px',
                    boxShadow: 'none',
                    borderRadius: vars.borderRadius,
                    '&.Mui-expanded': {
                        minHeight: '56px',
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: '20px',
                    borderTop: `1px solid ${vars.borderColor}`,
                },
            },
        },

        // slider
        MuiSlider: {
            styleOverrides: {
                root: {
                    margin: 0,
                    width: '190px',
                },
                mark: {
                    width: '6px',
                    height: '6px',
                    borderRadius: '6px',
                    backgroundColor: '#54687D',
                },
                markActive: {
                    width: '6px',
                    height: '6px',
                    borderRadius: '6px',
                },
                markLabel: {
                    fontSize: '12px',
                    top: '-13px',
                },
                markLabelActive: {
                    visibility: 'visible',
                    fontSize: '12px',
                    color: '#54687D',
                },
                rail: {
                    color: '#DEE3E9',
                    height: '3px',
                    width: '190px',
                },
                track: {
                    color: '#54687D',
                    height: '3px',
                    width: '190px',
                },
                thumb: {
                    width: '16px',
                    height: '16px',
                    border: '2px solid #54687D',
                    borderRadius: '16px',
                    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                        boxShadow: 'none',
                    },
                },
            },
        },

        MuiMenuItem: {
            styleOverrides: {
                root: {
                    minWidth: 170,
                },
            },
        },

        MuiDialog: {
            styleOverrides: {
                root: {
                    fontFamily: vars.fontFamily,
                },
            },
        },
    },
})
