import { styled } from 'core'
import { tannGrey } from 'utils/theme/colors'

export const FileWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 491px;
    justify-content: center;
`
export const TextWrapper = styled('div')`
    margin-bottom: 8px;
    color: ${tannGrey};
    text-align: center;
`
export const NothingHereYet = styled('div')`
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 21px;
`
