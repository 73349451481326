import {
    ActionType,
    ChangeRequest,
    ChangeRequestDto,
    ChangeRequestHistoryItem,
    ChangeRequestHistoryItemDto,
    CRItem,
    CRItemDto,
    CRItemType,
    HistoryType,
    Node,
    NodeDTO,
    Shortcut,
    ShortcutDto,
} from '../types'

export const getNodeFromDto = (node: NodeDTO): Node => {
    return {
        id: node.id,
        name: node.name,
        parentId: node.parent_id,
        isArchived: node.is_archived,
        isCategory: node.is_category,
    }
}

const getStatusFromDto = (str: string): HistoryType => {
    switch (str) {
        case 'draft':
            return HistoryType.DRAFT
        case 'approved':
            return HistoryType.APPROVED
        case 'adjustment_requested':
            return HistoryType.ADJUSTMENT_REQUESTED
        case 'archived':
            return HistoryType.ARCHIVED
        case 'on_review':
            return HistoryType.ON_REVIEW
        case 'reassigned':
            return HistoryType.REASSIGNED
        default:
            return HistoryType.DRAFT
    }
}

export const getHistoryFromDto = (history: ChangeRequestHistoryItemDto): ChangeRequestHistoryItem => {
    return {
        id: history.id,
        user: history.user,
        status: getStatusFromDto(history.status),
        timestamp: new Date(history.timestamp),
        comment: history.comment,
    }
}
export const getDtoFromCRHistory = (history: ChangeRequestHistoryItem): ChangeRequestHistoryItemDto => {
    return {
        id: history.id,
        user: history.user,
        status: history.status,
        timestamp: history.timestamp.toISOString(),
        comment: history.comment,
    }
}
export const getChangeRequestFromDto = (cr: ChangeRequestDto): ChangeRequest => {
    return {
        id: cr.id,
        owner: cr.owner,
        items: cr.items.map(getItemFromDto),
        status: getStatusFromDto(cr.status),
        history: cr.history.map(getHistoryFromDto),
    }
}

export const getActionFromDto = (item: CRItemDto): ActionType => {
    if (item.action === 'rename') {
        return ActionType.RENAME
    }
    if (item.action === 'move') {
        return ActionType.MOVE
    }
    if (item.action === 'create') {
        if (item.item_type === 'shortcut') return ActionType.CREATE_SHORTCUT
        if (item.is_category && !item.parent_id) return ActionType.CREATE_ROOT_ITEM
        if (item.is_category && item.parent_id) return ActionType.CREATE_CATEGORY
        if (!item.is_category) return ActionType.CREATE_SKILL
    }
    if (item.action === 'archive') return ActionType.ARCHIVE
    if (item.action === 'remove') return ActionType.REMOVE
    else return ActionType.RESTORE
}

const getActionToDto = (action: ActionType) => {
    switch (action) {
        case ActionType.RENAME:
            return 'rename'
        case ActionType.ARCHIVE:
            return 'archive'
        case ActionType.CREATE_CATEGORY:
        case ActionType.CREATE_ROOT_ITEM:
        case ActionType.CREATE_SHORTCUT:
        case ActionType.CREATE_SKILL:
            return 'create'
        case ActionType.MOVE:
            return 'move'
        case ActionType.RESTORE:
            return 'restore'
        case ActionType.REMOVE:
            return 'remove'
    }
}

const getItemTypeFromDto = (str: string): CRItemType => {
    switch (str) {
        case 'node':
            return CRItemType.NODE
        case 'shortcut':
            return CRItemType.SHORTCUT
        default:
            return CRItemType.NODE
    }
}

const toUpperCase = (input?: string | null) => (input ? input.toUpperCase() : input)

export const getItemFromDto = (item: CRItemDto): CRItem => {
    return {
        itemType: getItemTypeFromDto(item.item_type),
        itemId: item.item_id.toUpperCase(),
        action: getActionFromDto(item),
        isCategory: item.is_category,
        name: item.name,
        newName: item.new_name,
        parentId: toUpperCase(item.parent_id),
        parentName: item.parent_name,
        prevParentId: toUpperCase(item.prev_parent_id),
        prevParentName: item.prev_parent_name,
        newParentId: toUpperCase(item.new_parent_id),
        newParentName: item.new_parent_name,
        childId: toUpperCase(item.child_id),
        childName: item.child_name,
        prevName: item.prev_name,
    }
}

export const getDtoFromCRItem = (item: CRItem): CRItemDto => {
    return {
        item_type: item.itemType,
        item_id: item.itemId,
        action: getActionToDto(item.action),
        is_category: item.isCategory,
        name: item.name,
        parent_id: item.parentId,
        child_id: item.childId,
        new_name: item.newName,
        new_parent_id: item.newParentId,
    }
}
export const getShortcutFromDto = (shortcutDto: ShortcutDto): Shortcut => {
    return {
        id: shortcutDto.id,
        parentId: shortcutDto.parent_id,
        childId: shortcutDto.child_id,
    }
}
