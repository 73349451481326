import { RefObject, useEffect } from 'react'

export const useTextMoveAnimation = (textRef: RefObject<HTMLDivElement>) => {
    const moveTextAnimationKeyframes = [
        { transform: 'translateX(0%)', left: '0%' },
        { transform: 'translateX(-100%)', left: '100%' },
    ]

    const moveTextAnimationTiming = {
        duration: 5000,
        iterations: 1,
        easing: 'linear',
    }

    useEffect(() => {
        if (textRef?.current) {
            textRef.current.onmouseenter = () => {
                if (
                    textRef.current?.clientWidth &&
                    textRef.current.parentElement?.clientWidth &&
                    textRef.current?.clientWidth > textRef.current.parentElement?.clientWidth
                )
                    textRef.current?.animate(moveTextAnimationKeyframes, moveTextAnimationTiming)
            }

            textRef.current.onmouseleave = () => textRef?.current?.getAnimations()[0]?.cancel()
        }
    }, [textRef.current])
}
