import { Menu } from '@mui/material'
import { styled } from 'core'
import { tannBlue } from 'utils/theme/colors'

export const MenuStyled = styled(Menu)`
    .MuiList-root {
        padding: 15px 0;
        background-color: #173348;
    }
    .MuiMenuItem-root {
        padding: 6px 15px;
        color: #ffffff;
        &:hover {
            background-color: ${tannBlue};
        }
    }
`

export const SubMenuStyled = styled(MenuStyled)`
    pointer-events: none;

    .MuiPopover-paper {
        pointer-events: auto;
    }
`
