import { styled, TableCell, TableRow } from '@mui/material'

interface ColorProps {
    color: string
}

interface SortIconContainerProps {
    reversed: boolean
    hidden: boolean
}

export const HeaderCell = styled(TableCell)`
    font-style: normal;
    font-weight: 400;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #54687d;
`

export const Cell = styled(TableCell)`
    border-bottom: none;
`

export const Row = styled(TableRow)`
    &:nth-of-type(2n) {
        background: #f7f8f9;
    }
`

export const HeaderItemContent = styled('div')`
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const Skill = styled('div')`
    display: flex;
    align-items: center;
`

export const SkillColor = styled('div')<ColorProps>`
    width: 7px;
    height: 7px;
    border-radius: 7px;
    margin-right: 6px;
    background: ${({ color }) => color};
`

export const SkillName = styled('span')`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #173348;
`

export const Skills = styled('div')`
    display: flex;
    overflow: hidden;
    gap: 6px;
`

export const SortIconContainer = styled('div')<SortIconContainerProps>`
    display: flex;
    align-items: center;

    > svg {
        ${({ reversed }) => reversed && `transform: rotate(180deg);`}
        ${({ hidden }) => `visibility: ${hidden ? 'hidden' : 'visible;'}`}
    }
`

export const NameBlock = styled('div')`
    display: flex;
    align-items: center;
    gap: 12px;
`

export const Icons = styled('div')`
    display: flex;
    align-items: center;
    gap: 20px;
`
