import { useEffect } from 'react'
import { useAppDispatch } from 'store/store'
import { mapToFlowNodes } from 'taxonomy/utils/nodes'
import * as tree from 'store/slices/tree'
import { FlowNodesMap, NodeData } from 'taxonomy/types'
import { Node as NodeReact } from 'react-flow-renderer'
import { useSelector } from 'react-redux'
import { selectTreeNodes } from './selectors'

export const useTree = () => {
    const treeNodes = useSelector(selectTreeNodes)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!treeNodes) {
            return
        }
        const nodesMap: FlowNodesMap = mapToFlowNodes(treeNodes)

        for (let id in nodesMap) {
            const nodeData: NodeData = nodesMap[id].data
            nodeData.childrenIds = nodeData.children?.map((child: NodeReact<NodeData>) => {
                return child.id
            })
            nodeData.children = []
        }
        dispatch(tree.initTree(nodesMap))
    }, [dispatch, treeNodes])
}
