import { client } from 'axiosClient'
import { ComposedAjaxError } from 'axiosClient/ErrorComposer'
import {
    createDeleteSkillModal,
    DialogueModalType,
    setDialoguesNotification,
    setInfoNotification,
} from 'notifications'
import { deleteUserSkill } from '../../shared/actions'
import { AppDispatch } from '../../store'
import { removeSkill } from '../auth'
import { getExtraProfileInfo, getProfile } from './actions'
import { setIsUserDeletingSkills, toggleMuteNotifications } from './index'

export const getEmployeeProfile = (id: string) => async (dispatch: AppDispatch) => {
    dispatch(getExtraProfileInfo(id))
    dispatch(getProfile(id))
}
export const deleteEmployeeSkill =
    (userId: string, id: string, showModal: boolean = true) =>
    async (dispatch: AppDispatch) => {
        dispatch(setIsUserDeletingSkills(true))

        if (showModal && !localStorage.getItem(DialogueModalType.DELETE_SKILL)) {
            const modal = createDeleteSkillModal({
                skillId: id,
                onClick: () => dispatch(deleteEmployeeSkill(userId, id, false)),
            })
            dispatch(setDialoguesNotification(modal))
            return
        }

        try {
            await client._delete(`/api/users/${userId}/skills/${id}`)
            dispatch(deleteUserSkill(id))
            dispatch(setInfoNotification('Successfully deleted skill'))
        } catch (ex: any) {
            ComposedAjaxError.handleError("Error deleting employee's skills", ex)
        } finally {
            dispatch(setIsUserDeletingSkills(false))
        }
    }

export const removePersonalSkill = (userId: string, id: string) => async (dispatch: AppDispatch) => {
    dispatch(setIsUserDeletingSkills(true))
    try {
        await client._delete(`/api/users/${userId}/skills/${id}`)
        dispatch(removeSkill(id))
        dispatch(setInfoNotification('Successfully deleted skill'))
    } catch (ex: any) {
        ComposedAjaxError.handleError("Error deleting employee's skills", ex)
    } finally {
        dispatch(setIsUserDeletingSkills(false))
    }
}
export const muteEmailNotifications =
    (userId: string, successCb: () => void) => async (dispatch: AppDispatch) => {
        try {
            await client.patch(`/api/users/${userId}/mute_notifications`)
            dispatch(toggleMuteNotifications())
            successCb()
        } catch (ex: any) {
            ComposedAjaxError.handleError('Error muting notifications', ex)
        }
    }
