import { AutoSuggest } from 'core'
import { LoopIcon } from 'images'
import React, { memo, useEffect, useMemo, useState } from 'react'
import {
    EmployeeAutoSuggestOption,
    NodeAutoSuggestOption,
} from '../EmployeeAndSkillAutoSuggestOption/EmployeeAndSkillAutoSuggestOption'
import { navigate } from 'app/store'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import { stringifyFilters } from 'search/context/converters'
import { EmployeeInfo } from 'search/context/types'
import { selectTree } from 'store/slices/tree/selectors'
import { useNodeWithPath } from 'utils/hooks/useSkillPathTree'
import { showMoreSkillItems, sortSkillsByCategory } from './../../utils/functions/common'
import { AutocompleteRenderOptionState, Box } from '@mui/material'
import { SMoreBtn } from './styled'
import { NodeWithPath } from 'utils/types/skills'
import { searchEmployees } from 'search/api/requests'
import { useSearchContext } from 'search/context/SearchContext'

export const QuickSearch = memo(() => {
    const tree = useSelector(selectTree)
    const allSkills = useNodeWithPath(tree)
    const [visibleSkillsCount, setVisibleSkillsCount] = useState(6)
    const [searchedEmployeesList, setSearchedEmployeesList] = useState<EmployeeInfo[]>([])
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [inputKey, setInputKey] = useState<number>(0)

    const { setSkills } = useSearchContext()

    const filterEmployeesByInput = debounce(async (input: string) => {
        const result = await searchEmployees(input)
        setSearchedEmployeesList(result || [])
    }, 500)

    const onChangeAutosuggest = (value: any) => {
        setInputKey((prev) => prev + 1)
        if (value.user_id) {
            navigate(`/profile/${value.user_id}`)
        }
        if (value.node_id) {
            const convertedSkill = {
                id: value.node_id,
                level_from: 1,
                level_to: 5,
                is_category: value.isCategory,
            }
            navigate(
                `/search?${stringifyFilters({
                    skills: { convertedSkill },
                    locations: [],
                    competencies: [],
                    searchString: '',
                    availableOnly: false,
                    orderBy: null,
                    onBench: false,
                })}`
            )
            setSkills((prev) => ({
                ...prev,
                [value.node_id]: {
                    id: value?.node_id,
                    name: value?.name,
                    level_from: 1,
                    level_to: 5,
                    is_category: value.isCategory,
                },
            }))
        }
    }

    const handleInputChange = (input: string) => {
        filterEmployeesByInput(input)
        setSearchQuery(input)
        setVisibleSkillsCount(6)
    }

    const skillsAndEmployeesCustomedOptions = useMemo(() => {
        const skillList = allSkills.filter((skill) =>
            skill.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
        const moreSkillsBtn = {
            buttonId: 'MoreSkillsId',
            name: searchQuery,
            onClick: () => showMoreSkillItems(allSkills, visibleSkillsCount, setVisibleSkillsCount),
        }
        const skillsToRender =
            skillList.length > visibleSkillsCount
                ? //@ts-ignore
                  skillList.slice(0, visibleSkillsCount).concat(moreSkillsBtn)
                : //@ts-ignore
                  skillList

        const skillsToRenderSorted = skillsToRender.sort((a, b) => sortSkillsByCategory(a, b))

        return [...skillsToRenderSorted, ...searchedEmployeesList]
    }, [allSkills, searchQuery, visibleSkillsCount, setVisibleSkillsCount])

    const skillAndEmployeeSelectOptionRenderFn = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: any,
        state: AutocompleteRenderOptionState,
        getName?: (option: any) => string
    ) => {
        if (option.user_id) {
            return EmployeeAutoSuggestOption(props, option)
        }
        if (option.node_id) {
            return NodeAutoSuggestOption(props, option, state, (option: NodeWithPath) =>
                getName ? getName(option) : option.name
            )
        }
        //@ts-ignore
        if (option.buttonId) {
            return (
                <SMoreBtn id={option.buttonId} key={option.buttonId} onClick={option.onClick}>
                    More skills...
                </SMoreBtn>
            )
        }
        return null
    }

    useEffect(() => {
        // on enter click go to /search and search for names matching input
        document.getElementById('quick-search-input')?.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                const input = (e.target as any).value
                navigate(
                    `/search?${stringifyFilters({
                        skills: {},
                        locations: [],
                        competencies: [],
                        searchString: input,
                        availableOnly: false,
                        orderBy: null,
                        onBench: false,
                    })}`
                )
            }
        })
    }, [])

    useEffect(() => {
        // render initial employee results
        ;(async () => {
            try {
                const result = await searchEmployees()
                setSearchedEmployeesList(result || [])
            } catch (error) {
                console.log(error)
            }
        })()
    }, [])

    return (
        <Box component="div" sx={{ width: '300px' }}>
            <AutoSuggest
                id="quick-search-input"
                key={inputKey}
                size="small"
                placeholder="Search"
                onInputChange={handleInputChange}
                renderOption={skillAndEmployeeSelectOptionRenderFn}
                options={skillsAndEmployeesCustomedOptions}
                onChange={onChangeAutosuggest}
                InputProps={{ startAdornment: <LoopIcon /> }}
                color="white"
            />
        </Box>
    )
})
