import { MenuItem, MenuItemProps } from '@mui/material'
import React, { ReactNode, FC, useRef, useState, useContext } from 'react'
import { MenuContext } from 'taxonomy/context/MenuContext'
import { useTaxonomyContext } from 'taxonomy/context/TaxonomyContext'
import { SubMenuStyled } from './styled'

interface CustomMenuItemProps extends MenuItemProps {
    id: string
    subMenu?: ReactNode
    onClick?: () => void
    children: ReactNode
}

const addKeyPropFromId = (elements: ReactNode) =>
    React.Children.map(elements, (elem) => {
        if (React.isValidElement(elem)) return React.cloneElement(elem, { key: elem.props.id })
    })

export const ContextMenuItem: FC<CustomMenuItemProps> = ({ id, subMenu, children, onClick, ...props }) => {
    const subMenuRef = useRef(null)
    const [subMenuAnchor, setSubMenuAnchor] = useState<(EventTarget & Element) | null>(null)

    const context = useContext(MenuContext)
    const { isRefetchingActiveCr, isActionInProgress } = useTaxonomyContext()
    const handleMouseLeave = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const related = e.relatedTarget as HTMLLIElement
        const subMenu = subMenuRef.current as HTMLDivElement | null

        if (
            subMenu &&
            (related?.closest(`#${subMenu.id}`) ||
                (e.currentTarget?.closest(`#${subMenu.id}`) && related.localName === 'div'))
        )
            return
        setSubMenuAnchor(null)
    }

    return (
        <>
            <MenuItem
                disabled={isRefetchingActiveCr || isActionInProgress}
                id={id}
                data-automation-id={id}
                onMouseEnter={(e) => {
                    if (subMenu) context?.setIsMenuOpen(true)
                    setSubMenuAnchor(e.currentTarget)
                }}
                onMouseLeave={(e) => handleMouseLeave(e)}
                onClick={() => {
                    context?.onMenuItemClick()
                    if (onClick && !subMenu) {
                        context?.handleMenuClose()
                        onClick()
                    }
                }}
                {...props}
            >
                {children}
            </MenuItem>
            {subMenu && (
                <SubMenuStyled
                    id={`${id}-sub-menu`}
                    data-automation-id={`${id}-sub-menu`}
                    anchorEl={subMenuAnchor}
                    open={!!subMenuAnchor && !!context?.isMenuOpen}
                    onClose={() => setSubMenuAnchor(null)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                    ref={subMenuRef}
                    hideBackdrop
                >
                    {addKeyPropFromId(subMenu)}
                </SubMenuStyled>
            )}
        </>
    )
}
