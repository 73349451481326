import React, { FC, ReactNode } from 'react'
import { AppLink } from './styled'

interface StyledLinkProps {
    to: string
    baseColor: string
    visitedColor?: string
    children?: ReactNode
}

export const StyledLink: FC<StyledLinkProps> = ({ baseColor, to, children }) => {
    return (
        <AppLink to={to} color={baseColor} target="_blank">
            {children}
        </AppLink>
    )
}
