import { AutoSuggest } from 'core'
import React, { useEffect, useMemo, useState } from 'react'
import { isSubNodeOfTree } from 'taxonomy/utils/nodes'
import { focusTimer } from 'utils/functions/common'
import { useTaxonomyContext } from '../../context/TaxonomyContext'
import { useNodeActions } from '../../hooks/useNodeActions'
import { FlowNode } from '../../types'
import ActionDialogWrapper from '../../../core/ui/ActionDialog/ActionDialogWrapper'
import { SDialogLabel } from './styled'
import { skillSelectOptionRenderFn } from '../../../parts/EmployeeAndSkillAutoSuggestOption/EmployeeAndSkillAutoSuggestOption'
import { VirtualizedList } from 'components/VirtualizedList'

const MoveDialog = () => {
    const { selectedNodeId, selectedNode, reactFlowNodesMap, categories, hideActionDialog, action } =
        useTaxonomyContext()
    const { moveNode } = useNodeActions()
    const id = 'move-to-autosuggest'

    const filteredCategories = useMemo(
        () =>
            categories
                .filter(
                    (elem) =>
                        selectedNode &&
                        !isSubNodeOfTree(elem, selectedNode) &&
                        elem.data.isArchived === false &&
                        selectedNode !== elem &&
                        !elem.data.isShortcut &&
                        !elem.data.isTemporaryShortcut
                )
                .map((node) => ({
                    ...node,
                    isCategory: node.data.isCategory,
                })),

        [selectedNode]
    )

    const [chosenNode, setChosenNode] = useState<FlowNode | null>(null)
    const [searchInput, setSearchInput] = useState<string>('')

    const onChangeAutosuggest = (value: FlowNode) => {
        setChosenNode(value)
        setSearchInput(value.data.name)
    }

    const onInputChange = (value: string) => {
        setSearchInput(value)
    }

    const onClear = () => {
        setChosenNode(null)
        setSearchInput('')
    }

    useEffect(() => {
        focusTimer(id)
        return () => {
            clearTimeout(focusTimer(id))
        }
    }, [])

    return (
        <ActionDialogWrapper
            title={`Move`}
            buttonText="Move"
            submitAction={() => moveNode(selectedNodeId!, chosenNode?.id)}
            onClose={hideActionDialog}
            isOpen={!!action}
        >
            <SDialogLabel>{`${reactFlowNodesMap[selectedNodeId!].data.name} to`}</SDialogLabel>
            <AutoSuggest
                id={id}
                value={chosenNode}
                inputValue={searchInput}
                onClear={onClear}
                options={filteredCategories}
                ListboxComponent={VirtualizedList()}
                getName={(option) => option.data.name}
                getId={(option) => option.id}
                renderOption={skillSelectOptionRenderFn}
                onChange={onChangeAutosuggest}
                onInputChange={onInputChange}
            />
        </ActionDialogWrapper>
    )
}

export default MoveDialog
