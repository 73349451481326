import { AppDispatch } from '../store/store'

let appDispatch: AppDispatch

export const initDispatch = (dispatch: AppDispatch) => {
    appDispatch = dispatch
}

export const dispatch: AppDispatch = (action: any) => {
    if (!appDispatch) {
        throw new Error('Dispatch not initialized')
    }
    return appDispatch(action)
}
