import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FlowNodesMap, Node } from 'taxonomy/types'

export interface TreeState {
    tree: FlowNodesMap
    nodes: Node[]
}

const initialState: TreeState = {
    tree: {},
    nodes: [],
}

const treeSlice = createSlice({
    name: 'tree',
    initialState,
    reducers: {
        initTree(state, action: PayloadAction<FlowNodesMap>) {
            state.tree = action.payload
        },
        setSkillNodes(state, action: PayloadAction<Node[]>) {
            state.nodes = action.payload
        },
    },
})

export const { initTree, setSkillNodes } = treeSlice.actions

export default treeSlice.reducer
