import React from 'react'
import { excludedEmployee, excludedExternalConsultant, year, yearsRange } from './constants'
import { SelectOption } from '../theme/Lib'
import { LevelOption } from '../types/common'
import { Levels } from '../enums/different'
import { Advanced, Basic, DontWantToLearn, Expert, Middle, Novice, WantToLearn } from '../../images'

export const exportOptions: SelectOption[] = [
    { value: 0, label: 'CSV' },
    { value: 1, label: 'PDF' },
]

export const isRoleOptionSelected = (option: SelectOption, selected?: SelectOption) =>
    selected && option.value === selected.value

export const employeeRolesOptionForAdminPage = [
    {
        value: 0,
        label: excludedEmployee,
        contraction: 'emp',
    },

    {
        value: 1,
        label: excludedExternalConsultant,
        contraction: 'ec',
    },

    {
        value: 2,
        label: 'Solid-Line Manager',
        contraction: 'slm',
    },

    {
        value: 3,
        label: 'Competence Head',
        contraction: 'ch',
    },

    { value: 4, label: 'Admin', contraction: 'adm' },
]

export const defaultReportEmpTypesOption = employeeRolesOptionForAdminPage[0]

export const YearsOption = Array.from({ length: yearsRange }, (_, i) => ({
    value: year - i,
    label: (year - i).toString(),
}))

export const LevelOptions: Readonly<LevelOption[]> = [
    {
        id: null,
        name: Levels.WTL,
        icon: <WantToLearn />,
    },
    {
        id: null,
        name: Levels.DWTL,
        icon: <DontWantToLearn />,
    },
    {
        id: 1,
        name: Levels.Novice,
        icon: <Novice />,
    },
    {
        id: 2,
        name: Levels.Basic,
        icon: <Basic />,
    },
    {
        id: 3,
        name: Levels.Middle,
        icon: <Middle />,
    },
    {
        id: 4,
        name: Levels.Advanced,
        icon: <Advanced />,
    },
    {
        id: 5,
        name: Levels.Expert,
        icon: <Expert />,
    },
]
