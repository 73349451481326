import { Box } from '@mui/material'
import { styled } from 'core'
import { purple, separatorGrey, TypographyColors } from 'utils/theme/colors'

export const Root = styled('div')`
    width: auto;
    display: flex;
    height: 72px;
    box-sizing: border-box;
`

interface Prop {
    isEditable: boolean
}

export const MainController = styled(Box)<Prop>`
    box-sizing: border-box;
    padding: 24px 47px 24px 24px;
    background-color: ${purple};
    flex-grow: 1;
    display: flex;
    border-radius: ${({ isEditable }) => `5px ${isEditable ? 0 : 5}px 0 0`};
`

export const ToolController = styled('div')`
    box-sizing: border-box;
    display: flex;
    padding: 26px;
    border-radius: 0 5px 0 0;
    background-color: ${separatorGrey};
    background-color: ${purple};
`

export const SkillsContainer = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    overflow-y: hidden;
    white-space: break-spaces;
    width: 632px;
    align-items: center;
    box-sizing: border-box;
`

export const SFastAddSkillContainer = styled('div')`
    height: 72px;
    box-sizing: border-box;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    background-color: ${separatorGrey};
    border-radius: 0;
    box-shadow: inset 0px 6px 0px rgba(0, 0, 0, 0.05);
    border: none;
`

export const SearchContainer = styled('div')`
    gap: 16px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin-right: 27px;
    padding: 8px;
    background-color: #ffffff;
    border-radius: 5px;
    margin-left: 12px;
`

export const NoContent = styled('p')`
    margin: 2px 0;
    color: ${TypographyColors.LAVENDER_GREY};
`
