import { Table, TableCell, TableContainer, TableRow } from '@mui/material'
import { styled } from 'core'
import { greyProfile, platinum, rolesTableHeaderBgColor, white } from 'utils/theme/colors'

export const ControlsWrapper = styled('div')`
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
`
export const InfoContainer = styled('div')`
    display: flex;
    justify-content: space-around;
    right: 41px;
`
export const FooterContainer = styled('div')`
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const DescriptionContainer = styled(InfoContainer)`
    font-size: 14px;
    flex: 0 0 70%;
`
export const HeavySpan = styled('span')`
    font-weight: 700;
`
export const Row = styled('div')`
    height: 52px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    border-bottom: 1px solid ${platinum};

    &:hover {
        background-color: ${greyProfile};
    }
`

export const TableContainerStyled = styled(TableContainer)`
    overflow-x: visible;
`

export const TableStyled = styled(Table)`
    overflow: visible;
    width: 100%;
`

export const TableRowStyled = styled(TableRow)`
    font-size: 16px;

    &:hover {
        background-color: ${greyProfile};
    }
`

export const HeaderTableRowStyled = styled(TableRow)`
    .MuiTableCell-root {
        font-weight: 700;
        background-color: ${rolesTableHeaderBgColor};

        &:hover {
            background-color: ${white};
        }
    }
`

export const AreaCellStyled = styled(TableCell)`
    vertical-align: top;
    text-align: left;
    width: 200px;
    font-weight: 700;
    font-size: 16px;

    &:hover {
        background-color: ${white};
    }
`

export const VisibilityCellStyled = styled(TableCell)`
    width: 575px;
    font-size: 16px;
`

export const LabelTableCellStyled = styled(TableCell)`
    font-weight: 700;
    font-size: 16px;
    width: 120px;
`

export const CheckboxCellStyled = styled(TableCell)`
    padding-left: 19px;
`
