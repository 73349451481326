import { SnackbarProvider as NisSnackbarProvider, SnackbarProviderProps } from 'notistack'
import React, { FC } from 'react'
import { CloseButton } from '../CloseButton'

export const SnackbarProvider: FC<SnackbarProviderProps> = ({ children }) => {
    return (
        <NisSnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
            action={(key) => <CloseButton keyId={key} />}
            hideIconVariant
            autoHideDuration={5000}
        >
            {children}
        </NisSnackbarProvider>
    )
}
